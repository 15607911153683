interface Routes {
  home: string
  grantLicense: string
  grantLicenseForPool: string
  batchGrantLicense: string
  licenses: string
  licensePools: string
  licenseTypes: string
  addMerchant: string
  merchants: string
  customers: string
  addCustomer: string
  journal: string
  addLicensePool: string
  addLicenseType: string
  editLicenseType: string
  editCustomer: string
  editMerchant: string
  editLicense: string
  activateLicense: string
  licenseActivations: string
  users: string
  groupUsers: string
  editLicensePool: string
  addLicensePoolForGroup: string
  addLicensePoolForUser: string
  groups: string
  cloudSubsciptions: string
  createSubsciption: string
  licenseGroups: string
  addLicenseGroup: string
  licenseInstances: string
  createOrder: string
  viewCloudInfo: string
  //createMerchantOrder: string
}

export const getRouteDefinitions = (): Routes => {
  return {
    home: '/home',
    grantLicense: '/grant-license',
    grantLicenseForPool: '/grant-license/pool/:poolID',
    batchGrantLicense: '/batch-grant-license',
    editLicense: '/grant-license/:id',
    licenses: '/licenses',
    licensePools: '/license-pools',
    licenseTypes: '/license-types',
    addMerchant: '/add-merchant',
    merchants: '/merchants',
    customers: '/customers',
    createOrder: '/createOrder/',
    addCustomer: '/add-customer',
    journal: '/journal',
    addLicenseType: '/add-license-type',
    addLicensePool: '/add-license-pool',
    addLicensePoolForGroup: '/add-license-pool/group/:groupID',
    addLicensePoolForUser: '/add-license-pool/user/:userID',
    editCustomer: '/edit-customer/:id',
    editLicenseType: '/edit-license-type/:id',
    editMerchant: '/edit-merchant/:id',
    editLicensePool: '/edit-license-pool/:id',
    activateLicense: '/activate-license',
    licenseActivations: '/license-activations',
    users: '/users',
    groupUsers: '/users/:groupID',
    groups: '/groups',
    cloudSubsciptions: '/subscriptions',
    createSubsciption: '/addSubscription',
    licenseGroups: '/licensegroups',
    addLicenseGroup: '/addlicensegroup',
    licenseInstances: '/license-instances',
    viewCloudInfo: '/licenses/:id',
  }
}

export const ROUTES = getRouteDefinitions()
