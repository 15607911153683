/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Save } from '@material-ui/icons'
import { PageHeading } from '../../partials/PageHeading'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { Text } from '../../partials/Inputs/Text'
import {
  LicenseGroup,
  LicenseTypeInput,
  LicenseTypesDocument,
  useCreateLicenseTypeMutation,
  useLicenseTypeQuery,
  useUpdateLicenseTypeMutation,
} from '../../../../api/models'
import { ResultAlert } from '../../partials/ResultAlert'
import { ROUTES } from '../../../helpers/routes'
import { useHistory, useParams } from 'react-router-dom'
//import { JournalTable } from '../Journal/JournalTable'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { useGetAllLicGroups } from '../../../hooks/useGetLicenseGroups'

const initialFormValues: LicenseTypeInput = {
  licenseType: '',
  groupId: '',
  activationMaximum: 0,
  descriptionKey: 'license.csleakage.guest.description',
  displayNameKey: 'license.csleakage.guest.displayname',
  groupRights: [],
}

type CreateOrUpdateTypeParams = {
  id?: string
}

export const CreateOrUpdateLicenseType: FC = () => {
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState<LicenseTypeInput>(initialFormValues)
  const [showFailAlert, setShowFailAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [lGroups, setLGroups] = useState<LicenseGroup[]>([])
  const allLicenseGroups = useGetAllLicGroups()
  const [createLicenseTypeMutation, createResponse] = useCreateLicenseTypeMutation({
    refetchQueries: [
      {
        query: LicenseTypesDocument,
        variables: {
          paginationInfo: {
            limit: 100,
            Offset: 0,
          },
        },
      },
    ],
  })
  const [updateLicenseTypeMutation, updateResponse] = useUpdateLicenseTypeMutation({
    refetchQueries: [
      {
        query: LicenseTypesDocument,
        variables: {
          paginationInfo: {
            limit: 100,
            Offset: 0,
          },
        },
      },
    ],
  })
  const history = useHistory()
  const { id } = useParams<CreateOrUpdateTypeParams>()
  const { data: existing } = useLicenseTypeQuery({ skip: !id, variables: { id: id as string } })
  const [
    addLicenseTypeLabel,
    updateLicenseTypeLabel,
    createLabel,
    updateLabel,
    typeKeyLabel,
    maximumActivationsLabel,
    //descriptionLabel,
    creationFailedLabel,
    creationSucceededLabel,
  ] = useMemo(() => {
    return [
      t('licenseTypes.add'),
      t('licenseTypes.update'),
      t('actions.create'),
      t('actions.update'),
      t('licenseTypes.key') + '*',
      t('licenses.maximumActivations') + '*',
      t('licenseTypes.description'),
      t('licenseTypes.creationFailed'),
      t('licenseTypes.creationSucceeded'),
    ]
  }, [t])

  useEffect(() => {
    if (!allLicenseGroups.lGroupLoading && allLicenseGroups.lGroups) {
      setLGroups(allLicenseGroups.lGroups)
      //console.log('lgRoups', allLicenseGroups)
      /* if (allLicenseGroups.lGroups.length === 1) {
        setFormValues((prev) => ({
          ...prev,
          groupId: allLicenseGroups.lGroups?.length ? allLicenseGroups.lGroups[0].id : prev.groupId,
        }))
      } */
    }
  }, [allLicenseGroups.lGroupLoading])

  useEffect(() => {
    if (existing && id) {
      setFormValues((prv) => ({
        ...prv,

        licenseType: existing.licenseType?.licenseType ?? prv.licenseType,
        activationMaximum: existing.licenseType?.activationMaximum ?? prv.activationMaximum,
        groupId: id,
        descriptionKey: existing.licenseType?.descriptionKey ?? 'license.csleakage.guest.description',
        displayNameKey: existing.licenseType?.displayNameKey ?? 'license.csleakage.guest.displayname',
      }))
    }
  }, [existing])

  //console.log('lgroups', lGroups)
  useEffect(() => {
    if (
      (!updateResponse.loading && updateResponse.data?.updateLicenseType) ||
      (!createResponse.loading && createResponse.data?.createLicenseType)
    ) {
      history.push(ROUTES.licenseTypes)
    }
  }, [updateResponse, createResponse])
  const confirmCreateOrUpdate = async () => {
    try {
      !id
        ? await createLicenseTypeMutation({
            variables: {
              licenseType: formValues,
            },
          })
        : await updateLicenseTypeMutation({
            variables: {
              id: id,
              licenseType: formValues,
            },
          })
    } catch (er) {
      console.error('error creating licenseType', er)
    }
  }
  console.log('lggroups', lGroups)
  return (
    <>
      <FixedActionBar
        labels={[id ? updateLabel : createLabel]}
        actions={[confirmCreateOrUpdate]}
        icons={[Save]}
        buttonProps={[{ solid: true }]}
      />
      <ResultAlert
        alertText={creationFailedLabel}
        showAlert={showFailAlert}
        modifyShowAlert={setShowFailAlert}
        severity="warning"
      />
      <ResultAlert
        alertText={creationSucceededLabel}
        showAlert={showSuccessAlert}
        modifyShowAlert={setShowSuccessAlert}
      />
      <PageHeading title={id ? updateLicenseTypeLabel : addLicenseTypeLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12}>
          <ProgressBar show={createResponse.loading || updateResponse.loading} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'groupname'}
            fullWidth
            select
            value={formValues.groupId}
            variant="standard"
            onChange={({ target }) =>
              setFormValues((prv) => ({
                ...prv,
                groupId: target.value,
              }))
            }
          >
            {lGroups.map((lGroup) => (
              <MenuItem key={lGroup.id} value={lGroup.id}>
                {lGroup.groupname}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Text
          value={formValues.licenseType}
          label={typeKeyLabel}
          columns={12}
          changeHandler={(typeKey) =>
            setFormValues((pre) => ({
              ...pre,
              licenseType: typeKey,
              displayNameKey: `license.${lGroups
                .find((lg) => lg.id === pre.groupId)
                ?.groupname.replace('-', '')}.${typeKey}.displayname`,
              descriptionKey: `license.${lGroups
                .find((lg) => lg.id === pre.groupId)
                ?.groupname.replace('-', '')}.${typeKey}.description`,
            }))
          }
        />
        <Text
          value={(formValues.activationMaximum as unknown) as string}
          label={maximumActivationsLabel}
          columns={12}
          onlyNumbers
          changeHandler={(maximumActivations) =>
            setFormValues((pre) => ({
              ...pre,
              activationMaximum: (maximumActivations as unknown) as number,
            }))
          }
        />
        {/* <Text
          value={formValues.descriptionKey}
          label={descriptionLabel}
          columns={12}
          changeHandler={(description) =>
            setFormValues((pre) => ({
              ...pre,
              descriptionKey: description,
            }))
          }
        /> */}

        {/*  {id && <JournalTable showTitle filter={{ log_key: id }} />} */}
      </Grid>
    </>
  )
}
