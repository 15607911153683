import { Grid, MenuItem, Select } from '@material-ui/core'
import React, { useCallback } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined'
import { useState } from 'react'

export const contrast = {
  primary: '#e4f6ed',
  secondary: '#ffffff',
  greyText: '#888888',
}

export const useStyles = makeStyles(() =>
  createStyles({
    select: {
      color: contrast.secondary,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input.MuiInputBase-input': {
        paddingRight: 30,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    icon: {
      fill: contrast.primary,
    },
    box: {
      paddingLeft: 60,
    },
  }),
)

export const LanguageSelection: React.FC = React.memo(() => {
  const { i18n } = useTranslation('common')
  const [languageSelected, setLanguageSelected] = useState<string>('de')
  const classes = useStyles()

  const changeLang = useCallback(
    (lng: string) => {
      setLanguageSelected(lng)
      i18n.changeLanguage(lng)
    },
    [i18n],
  )

  return (
    <>
      <Grid item xs={2} lg={3} />
      <LanguageOutlinedIcon fontSize="small" />

      <Select
        className={classes.select}
        variant={'outlined'}
        onChange={({ target }) => changeLang(target.value as string)}
        value={languageSelected ?? ''}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {['en', 'de'].map((l) => {
          return (
            <MenuItem key={l} value={l}>
              {l.toUpperCase()}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
})
LanguageSelection.displayName = 'LanguageSelection'

export default LanguageSelection
