import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import {
  //LicenseInstance,
  PaginationParams,
  //useGetUserProfilesQuery,
  //useActivatedCloudLicensesQuery,
} from '../../../../api/models'
import { PageHeading } from '../../partials/PageHeading'
//import { LicenseInstancesTable } from './LicenseInstancesTable'
import { Grid } from '@material-ui/core'
import { useTranslation } from '../../../hooks/useTranslation'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import { CloudLicenseType, useGetCloudInstances, useGetCloudTrialInstances } from '../../../hooks/cloudLicense'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { useQueryParam } from '../../../hooks/useQueryParam'
//import { useModifyRootAdminMutation } from '../../../../api/ia-service.models'
import { RenameTextModal } from '../../partials/RenameTextModal/RenameTextModal'
import { LicenseFilterParams } from '../../../../typeInterfaces/Interfaces'
import { LicenseInstancesTable } from './LicenseInstancesTable'
import { Search } from '../../partials/Inputs/Search'
import { CsButton } from '@csinstruments/cs-react-theme'

export const ListLicenseInstances = (): ReactElement => {
  const { t } = useTranslation()
  const trialLicense = useQueryParam('trialLicenses')
  //const history = useHistory()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 100, Offset: 0 })
  const [filter, setFilter] = useState<LicenseFilterParams>({})
  //const [activatedUserIds, setActivatedUserIds] = useState<string[]>([])
  //const [selectedID, setSelectedID] = useState('')
  const [myCloudLicdata, setMyCloudLicdata] = useState<CloudLicenseType[]>([])
  const [cloudLicenseIntances, setCloudLicenseInstances] = useState<CloudLicenseType[]>([])
  const [modifyAdminModal, setModifyAdminModel] = useState<boolean>(false)
  const [modifyVariables, setModifyVariables] = useState<{
    realmName: string
    username: string
    keepLicenses: boolean
  }>({
    realmName: '',
    username: '',
    keepLicenses: true,
  })

  //const [modalOpen, setModalOpen] = useState(false)

  /* const { data: usersInfo } = useGetUserProfilesQuery({
    variables: {
      userIds: activatedUserIds,
    },
    skip: !activatedUserIds.length,
  }) */

  /* const { data: createdCloudList, refetch: fetchAgain } = useGetListCloudLicensesQuery({
    variables: {
      paginationInfo: paginationData,
    },
  }) */
  const fetchTrialLicenses = useMemo(() => trialLicense === 'true', [trialLicense])

  const { data, loadingData, refresh } = useGetCloudInstances(paginationData, fetchTrialLicenses, filter.searchText)
  const { data: trialLicensesData } = useGetCloudTrialInstances(paginationData, fetchTrialLicenses)
  //const [modifyRootAdmin] = useModifyRootAdminMutation()

  useEffect(() => {
    if (data.length) {
      setMyCloudLicdata(data)
      //console.log({ data })
      setCloudLicenseInstances(data)
    }
    if (trialLicensesData.length) {
      setMyCloudLicdata(trialLicensesData)
      setCloudLicenseInstances(trialLicensesData)
    }
  }, [data, trialLicensesData])

  const refreshFilter = useCallback(() => {
    refresh()
    if (filter.searchText && myCloudLicdata) {
      const searchPattern = new RegExp(filter.searchText)
      const filteredLicenseInstances = myCloudLicdata.filter(
        (fl) => searchPattern && JSON.stringify(fl).search(searchPattern) > 0,
      )
      //console.log({ filteredLicenseInstances })
      setCloudLicenseInstances(filteredLicenseInstances)
    } else {
      setCloudLicenseInstances(myCloudLicdata)
    }
  }, [refresh, filter.searchText, myCloudLicdata])

  // const submitModifyAdmin = async (): Promise<void> => {
  //   const { realmName, username } = modifyVariables
  //   try {
  //     if (username && realmName && !modifyAdminModal) {
  //       const submission = await modifyRootAdmin({
  //         variables: {
  //           realmName: realmName,
  //           username: username,
  //           keepLicenses: true,
  //         },
  //       })
  //       if (submission.data?.setRootAdmin) {
  //         closeModal()
  //       }
  //     }
  //   } catch (error) {
  //     console.error('error modifyAdmin ', error)
  //   }
  // }
  const closeModal = (): void => {
    setModifyAdminModel(false)
    setModifyVariables({
      realmName: '',
      username: '',
      keepLicenses: false,
    })
  }

  return (
    <>
      <PageHeading title={!fetchTrialLicenses ? t('objects.activations') : t('licenses.trialLicenses')} />

      <Grid container spacing={2} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={4}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Search
                label={t('actions.search')}
                changeFunction={(searchString) =>
                  setFilter((prv) => ({
                    ...prv,
                    searchText: searchString,
                  }))
                }
                onEnter={refreshFilter}
              />
            </Grid>
            <Grid item xs={4}>
              <CsButton solid={true} onClick={refreshFilter}>
                {t('actions.filter')}
              </CsButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ProgressBar show={loadingData} />
        </Grid>
        <RenameTextModal
          open={modifyAdminModal}
          handleConfirm={() => {
            return
          }}
          handleClose={closeModal}
          text={modifyVariables.username}
          handleChange={(text) =>
            setModifyVariables((prv) => ({
              ...prv,
              username: text,
            }))
          }
        />

        <LicenseInstancesTable showHeadline={false} licenseInstances={cloudLicenseIntances} refetch={refreshFilter} />
      </Grid>
    </>
  )
}
