import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client'
import { environment } from '../helpers/environment'
import { setContext } from '@apollo/client/link/context'
import { useKeycloak } from '@react-keycloak/web'
import { customerServiceOperations, iaServices } from '../helpers/serviceOperations'
import { updateToken } from '../helpers/keycloakService'
const {
  CUSTOMERAPI: { GQL_CUSTOMER_HTTP_URI },
  API,
  IA_SERVICE_API,
} = environment

// eslint-disable-next-line
export const useIAMClient = (): ApolloClient<any> => {
  const { keycloak } = useKeycloak()
  const link = new HttpLink({
    uri: (operation) => {
      if (customerServiceOperations.includes(operation.operationName)) {
        return GQL_CUSTOMER_HTTP_URI
      }
      if (operation.operationName === 'getUserProfiles' || operation.operationName === 'getUserProfilesByEmailID') {
        return API.ID_API
      }
      if (iaServices.includes(operation.operationName)) {
        return IA_SERVICE_API
      }
      return API.GQL_HTTP_URI
    },
    credentials: 'same-origin',
  })
  const cache = new InMemoryCache()
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    await updateToken()
    const token = keycloak.token
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  return new ApolloClient({
    link: authLink.concat(link),
    cache,
    defaultOptions,
  })
}
