import { Grid, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { CloudLicensePoolInput, LicensePool, useLicensePoolsQuery } from '../../../../api/models'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { CsButton } from '@csinstruments/cs-react-theme'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { useTranslation } from 'react-i18next'
import { isTrialPool } from '../../../helpers/stringHelpers'
import constants from '../../../../constants/constants'

type Props = {
  packetPoolInput: CloudLicensePoolInput[]
  changePacketPool: (poolInp: CloudLicensePoolInput[]) => void
}

const LicensePacketPool: React.FC<Props> = ({ packetPoolInput, changePacketPool }: Props) => {
  const [availableLicensePools, setAvailableLicensePools] = useState<LicensePool[]>([])
  const { data, loading: licensePoolsLoading } = useLicensePoolsQuery()
  const isLeakageType = (pooltype: string): boolean => pooltype.split('.').includes('csleakage')
  const { t } = useTranslation('common')
  const { DEFAULT_USER_LICENSE_SIZE } = constants
  //const { data: LicGroups } = useGetAllLicenseGroupsQuery()
  useEffect(() => {
    if (data?.licensePools) {
      const licensePoolsAvailable = data.licensePools.reduce(
        (agg: LicensePool[], crPool) =>
          crPool && (crPool?.pool_size as number) - (crPool?.n_in_use as number) > 0 && !isTrialPool(crPool.poolName)
            ? ([...agg, crPool] as LicensePool[])
            : agg,
        [],
      )
      //console.log('licensePoolsAvailable: ', licensePoolsAvailable)

      setAvailableLicensePools(licensePoolsAvailable)
      const guestPool = licensePoolsAvailable.find(
        (p) => p.license_type.licenseType === 'guest' && isLeakageType(p.license_type.displayNameKey),
      )
      const userPool = licensePoolsAvailable.find(
        (p) => p.license_type.licenseType === 'user' && isLeakageType(p.license_type.displayNameKey),
      )
      const defaultGuestLicenseInput = {
        licensePoolID: guestPool?.id ?? '',
        numberOfLicenses: guestPool?.pool_size
          ? guestPool.pool_size - (guestPool.pool_size - 5 * DEFAULT_USER_LICENSE_SIZE)
          : 5 * DEFAULT_USER_LICENSE_SIZE,
      }
      const defaultUserLicenseInput = {
        licensePoolID: userPool?.id ?? '',
        numberOfLicenses: userPool?.pool_size
          ? userPool.pool_size - (userPool.pool_size - DEFAULT_USER_LICENSE_SIZE)
          : DEFAULT_USER_LICENSE_SIZE,
      }
      const defaultCloudLicensesInput: CloudLicensePoolInput[] = [defaultGuestLicenseInput, defaultUserLicenseInput]
      if (!packetPoolInput.length) {
        changePacketPool(defaultCloudLicensesInput)
      }
    }
  }, [packetPoolInput.length, data?.licensePools, changePacketPool, DEFAULT_USER_LICENSE_SIZE])

  const addLicPool = (id: string) => {
    //console.log('id', id)
    const curPool = availableLicensePools.find((pl) => pl.id === id)
    const tmpLicePoolInp: CloudLicensePoolInput = {
      licensePoolID: curPool?.id as string,
      numberOfLicenses: 0,
    }
    const tmpLicensesPoolInp =
      packetPoolInput.length && packetPoolInput.some((lpl) => lpl.licensePoolID === curPool?.id)
        ? packetPoolInput
        : [tmpLicePoolInp, ...packetPoolInput]
    changePacketPool(tmpLicensesPoolInp)
  }

  const removeLicPool = (id: string) => {
    const tmplicensesPoolInp = packetPoolInput.length
      ? packetPoolInput.filter((crPool) => crPool.licensePoolID !== id)
      : []
    changePacketPool(tmplicensesPoolInp)
  }

  const changeNoLicense = useCallback(
    (id: string, noLicenses: string) => {
      const tmpLicensesPoolInp =
        packetPoolInput.length && packetPoolInput.some((lpInp) => lpInp.licensePoolID === id)
          ? packetPoolInput.map((crPoolInp) =>
              crPoolInp.licensePoolID === id
                ? { licensePoolID: id, numberOfLicenses: (noLicenses as unknown) as number }
                : crPoolInp,
            )
          : [{ licensePoolID: id, numberOfLicenses: (noLicenses as unknown) as number }]
      changePacketPool(tmpLicensesPoolInp)
    },
    [changePacketPool, packetPoolInput],
  )
  return (
    <>
      <Grid item container>
        <ProgressBar show={licensePoolsLoading} />
        {availableLicensePools.length &&
          availableLicensePools.map((avPool) => (
            <Grid key={avPool.id} item container spacing={2}>
              <Grid item container xs={2} lg={6}>
                <Typography variant="h5" color="textPrimary" gutterBottom>
                  {avPool.poolName.toUpperCase()}
                </Typography>
                <Grid item container spacing={2}>
                  {packetPoolInput.map(
                    (crInp) =>
                      crInp.licensePoolID === avPool.id && (
                        <Grid item xs={3} lg={5} key={crInp.licensePoolID}>
                          <TextField
                            label={t('licensePools.nLicenses')}
                            type={'number'}
                            //defaultValue={10}
                            error={crInp.numberOfLicenses > (avPool?.pool_size as number) - (avPool.n_in_use as number)}
                            helperText={
                              crInp.numberOfLicenses > (avPool?.pool_size as number) - (avPool.n_in_use as number) &&
                              t('error.exceededLimit', { name: avPool.poolName })
                            }
                            fullWidth
                            value={crInp.numberOfLicenses}
                            onChange={({ target }) => {
                              changeNoLicense(crInp.licensePoolID, target.value)
                            }}
                          />
                        </Grid>
                      ),
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <CsButton
                  disabled={!!packetPoolInput.some((lip) => lip.licensePoolID === avPool.id)}
                  onClick={() => addLicPool(avPool.id)}
                >
                  <AddBoxIcon />
                </CsButton>
              </Grid>
              <Grid item>
                <CsButton
                  disabled={!packetPoolInput.length || !packetPoolInput.some((lip) => lip.licensePoolID === avPool.id)}
                  onClick={() => removeLicPool(avPool.id)}
                >
                  <DeleteForeverIcon />
                </CsButton>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export default LicensePacketPool
