import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CmServiceDashboard = {
  __typename?: 'CMServiceDashboard';
  nCustomers: Scalars['Int'];
  nMerchants: Scalars['Int'];
  lastMerchantCreationDate: Scalars['String'];
  lastCustomerCreationDate: Scalars['String'];
  lastCustomerName: Scalars['String'];
  lastMerchantName: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  salutation?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  customerid?: Maybe<Scalars['ID']>;
};

export type ContactInput = {
  salutation?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  customerid?: Maybe<Scalars['ID']>;
};

export type ContactOrders = {
  __typename?: 'ContactOrders';
  contact?: Maybe<Contact>;
  order: Array<Maybe<Order>>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  contact?: Maybe<Contact>;
  customer_number: Scalars['String'];
  date_deleted?: Maybe<Scalars['Int']>;
  date_created: Scalars['String'];
  date_updated: Scalars['String'];
};

export type CustomerPage = {
  __typename?: 'CustomerPage';
  customers: Array<Customer>;
  total: Scalars['Int'];
};

export type Merchant = {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  contact?: Maybe<Contact>;
  customer_number: Scalars['String'];
  date_modified: Scalars['String'];
};

export type MerchantPage = {
  __typename?: 'MerchantPage';
  merchants: Array<Merchant>;
  total: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createContact: Scalars['ID'];
  createCustomer: Scalars['ID'];
  updateCustomer: Scalars['Boolean'];
  deleteCustomer: Scalars['Boolean'];
  createMerchant: Scalars['ID'];
  updateMerchant: Scalars['Boolean'];
  deleteMerchantr: Scalars['Boolean'];
  createOrder: Scalars['ID'];
  updateOrder: Scalars['Boolean'];
  deleteOrder: Scalars['Boolean'];
};


export type MutationCreateContactArgs = {
  customerID: Scalars['ID'];
  contact: ContactInput;
  allowDuplicateEmail: Scalars['Boolean'];
};


export type MutationCreateCustomerArgs = {
  customerNumber: Scalars['String'];
  contact: ContactInput;
};


export type MutationUpdateCustomerArgs = {
  id: Scalars['ID'];
  customerNumber: Scalars['String'];
  contact: ContactInput;
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationCreateMerchantArgs = {
  customerNumber: Scalars['String'];
  contact: ContactInput;
};


export type MutationUpdateMerchantArgs = {
  id: Scalars['ID'];
  customerNumber: Scalars['String'];
  contact: ContactInput;
};


export type MutationDeleteMerchantrArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrderArgs = {
  order: OrderInput;
};


export type MutationUpdateOrderArgs = {
  orderId: Scalars['ID'];
  order: OrderInput;
};


export type MutationDeleteOrderArgs = {
  orderId: Scalars['ID'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['String'];
  order_number: Scalars['String'];
  position_number?: Maybe<Scalars['String']>;
  software_type?: Maybe<Scalars['String']>;
  order_date: Scalars['String'];
  customer_id?: Maybe<Scalars['String']>;
  merchant_id?: Maybe<Scalars['String']>;
  contact_id: Scalars['String'];
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  merchant?: Maybe<Merchant>;
  customer?: Maybe<Customer>;
  order?: Maybe<Order>;
};

export type OrderInput = {
  order_number: Scalars['String'];
  position_number?: Maybe<Scalars['String']>;
  software_type?: Maybe<Scalars['String']>;
  order_date: Scalars['String'];
  customer_id?: Maybe<Scalars['String']>;
  merchant_id?: Maybe<Scalars['String']>;
  contact_id: Scalars['String'];
};

export type PaginationParams = {
  limit?: Maybe<Scalars['Int']>;
  Offset?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  customer?: Maybe<Customer>;
  getCustomerByCustomerNumber: Customer;
  getContactsByEmail: Array<Maybe<Contact>>;
  customers: CustomerPage;
  cmServicesDashboard?: Maybe<CmServiceDashboard>;
  merchant?: Maybe<Merchant>;
  getMerchantByCustomerNumber: Merchant;
  merchants: MerchantPage;
  getOrder: Order;
  getOrdersByContactID: ContactOrders;
  getAllContactOrders: Array<Maybe<ContactOrders>>;
  getOrdersByCustomerId: Array<Maybe<Order>>;
  getOrdersByMerchantId: Array<Maybe<Order>>;
  getOrderByNumberAndPosition: Order;
  getOrdersByIds: Array<Maybe<OrderInfo>>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryGetCustomerByCustomerNumberArgs = {
  customerNumber: Scalars['String'];
};


export type QueryGetContactsByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryCustomersArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryMerchantArgs = {
  id: Scalars['ID'];
};


export type QueryGetMerchantByCustomerNumberArgs = {
  customerNumber: Scalars['String'];
};


export type QueryMerchantsArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryGetOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryGetOrdersByContactIdArgs = {
  contactID?: Maybe<Scalars['ID']>;
};


export type QueryGetOrdersByCustomerIdArgs = {
  customerID: Scalars['ID'];
};


export type QueryGetOrdersByMerchantIdArgs = {
  merchantID: Scalars['ID'];
};


export type QueryGetOrderByNumberAndPositionArgs = {
  orderNumber: Scalars['String'];
  positionNumber: Scalars['String'];
};


export type QueryGetOrdersByIdsArgs = {
  orderIds: Array<Maybe<Scalars['ID']>>;
};

export type GetContactsByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetContactsByEmailQuery = (
  { __typename?: 'Query' }
  & { getContactsByEmail: Array<Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
  )>> }
);

export type CreateContactMutationVariables = Exact<{
  customerID: Scalars['ID'];
  contact: ContactInput;
  allowDuplicateEmail: Scalars['Boolean'];
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createContact'>
);

export type CmServicesDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type CmServicesDashboardQuery = (
  { __typename?: 'Query' }
  & { cmServicesDashboard?: Maybe<(
    { __typename?: 'CMServiceDashboard' }
    & Pick<CmServiceDashboard, 'nCustomers' | 'nMerchants' | 'lastMerchantCreationDate' | 'lastCustomerCreationDate' | 'lastCustomerName' | 'lastMerchantName'>
  )> }
);

export type GetCustomerByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCustomerByIdQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'customer_number' | 'date_deleted' | 'date_created' | 'date_updated'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
    )> }
  )> }
);

export type GetCustomerByCustomerNumberQueryVariables = Exact<{
  customerNumber: Scalars['String'];
}>;


export type GetCustomerByCustomerNumberQuery = (
  { __typename?: 'Query' }
  & { getCustomerByCustomerNumber: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'customer_number' | 'date_deleted' | 'date_created' | 'date_updated'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
    )> }
  ) }
);

export type GetCustomersQueryVariables = Exact<{
  pageInfo?: Maybe<PaginationParams>;
}>;


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { customers: (
    { __typename?: 'CustomerPage' }
    & Pick<CustomerPage, 'total'>
    & { customers: Array<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'customer_number' | 'date_deleted' | 'date_created' | 'date_updated'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
      )> }
    )> }
  ) }
);

export type CreateCustomerMutationVariables = Exact<{
  customerNumber: Scalars['String'];
  contact: ContactInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCustomer'>
);

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
  customerNumber: Scalars['String'];
  contact: ContactInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCustomer'>
);

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomer'>
);

export type GetMerchantByCustomerNumberQueryVariables = Exact<{
  customerNumber: Scalars['String'];
}>;


export type GetMerchantByCustomerNumberQuery = (
  { __typename?: 'Query' }
  & { getMerchantByCustomerNumber: (
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'customer_number' | 'date_modified'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
    )> }
  ) }
);

export type GetAllmerchantsQueryVariables = Exact<{
  pagination?: Maybe<PaginationParams>;
}>;


export type GetAllmerchantsQuery = (
  { __typename?: 'Query' }
  & { merchants: (
    { __typename?: 'MerchantPage' }
    & Pick<MerchantPage, 'total'>
    & { merchants: Array<(
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'customer_number' | 'date_modified'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
      )> }
    )> }
  ) }
);

export type GetMerchantByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMerchantByIdQuery = (
  { __typename?: 'Query' }
  & { merchant?: Maybe<(
    { __typename?: 'Merchant' }
    & Pick<Merchant, 'id' | 'customer_number' | 'date_modified'>
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
    )> }
  )> }
);

export type CreateMerchantMutationVariables = Exact<{
  customerNumber: Scalars['String'];
  contact: ContactInput;
}>;


export type CreateMerchantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMerchant'>
);

export type UpdateMerchantMutationVariables = Exact<{
  id: Scalars['ID'];
  customerNumber: Scalars['String'];
  contact: ContactInput;
}>;


export type UpdateMerchantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMerchant'>
);

export type DeleteMerchantMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMerchantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMerchantr'>
);

export type GetOrderQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type GetOrderQuery = (
  { __typename?: 'Query' }
  & { getOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'order_number' | 'position_number' | 'software_type' | 'order_date' | 'customer_id' | 'merchant_id' | 'contact_id'>
  ) }
);

export type GetOrderByNumberAndPositionQueryVariables = Exact<{
  orderNumber: Scalars['String'];
  positionNumber: Scalars['String'];
}>;


export type GetOrderByNumberAndPositionQuery = (
  { __typename?: 'Query' }
  & { getOrderByNumberAndPosition: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'order_number' | 'position_number' | 'software_type' | 'order_date' | 'customer_id' | 'contact_id'>
  ) }
);

export type GetOrdersByContactIdQueryVariables = Exact<{
  contactID: Scalars['ID'];
}>;


export type GetOrdersByContactIdQuery = (
  { __typename?: 'Query' }
  & { getOrdersByContactID: (
    { __typename?: 'ContactOrders' }
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
    )>, order: Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'order_number' | 'position_number' | 'software_type' | 'order_date' | 'customer_id' | 'contact_id'>
    )>> }
  ) }
);

export type GetAllContactOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllContactOrdersQuery = (
  { __typename?: 'Query' }
  & { getAllContactOrders: Array<Maybe<(
    { __typename?: 'ContactOrders' }
    & { contact?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
    )>, order: Array<Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'order_number' | 'position_number' | 'software_type' | 'order_date' | 'customer_id' | 'contact_id'>
    )>> }
  )>> }
);

export type GetOrdersByMerchantIdQueryVariables = Exact<{
  merchantID: Scalars['ID'];
}>;


export type GetOrdersByMerchantIdQuery = (
  { __typename?: 'Query' }
  & { getOrdersByMerchantId: Array<Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'order_number' | 'position_number' | 'software_type' | 'order_date' | 'customer_id' | 'merchant_id' | 'contact_id'>
  )>> }
);

export type GetOrdersByCustomerIdQueryVariables = Exact<{
  customerID: Scalars['ID'];
}>;


export type GetOrdersByCustomerIdQuery = (
  { __typename?: 'Query' }
  & { getOrdersByCustomerId: Array<Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'order_number' | 'position_number' | 'software_type' | 'order_date' | 'customer_id' | 'merchant_id' | 'contact_id'>
  )>> }
);

export type GetOrdersByIdsQueryVariables = Exact<{
  orderIds: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type GetOrdersByIdsQuery = (
  { __typename?: 'Query' }
  & { getOrdersByIds: Array<Maybe<(
    { __typename?: 'OrderInfo' }
    & { merchant?: Maybe<(
      { __typename?: 'Merchant' }
      & Pick<Merchant, 'id' | 'customer_number' | 'date_modified'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
      )> }
    )>, customer?: Maybe<(
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'customer_number' | 'date_deleted' | 'date_created' | 'date_updated'>
      & { contact?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'id' | 'salutation' | 'first_name' | 'middle_name' | 'last_name' | 'company' | 'position' | 'phone' | 'mobile' | 'fax' | 'email' | 'website' | 'street' | 'city' | 'state' | 'post_code' | 'country_code' | 'lat' | 'lng' | 'note' | 'customerid'>
      )> }
    )>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'order_number' | 'position_number' | 'software_type' | 'order_date' | 'customer_id' | 'merchant_id' | 'contact_id'>
    )> }
  )>> }
);

export type CreateOrderMutationVariables = Exact<{
  order: OrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrder'>
);

export type UpdateOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
  order: OrderInput;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrder'>
);

export type DeleteOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type DeleteOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOrder'>
);


export const GetContactsByEmailDocument = gql`
    query getContactsByEmail($email: String!) {
  getContactsByEmail(email: $email) {
    id
    salutation
    first_name
    middle_name
    last_name
    company
    position
    phone
    mobile
    fax
    email
    website
    street
    city
    state
    post_code
    country_code
    lat
    lng
    note
    customerid
  }
}
    `;

/**
 * __useGetContactsByEmailQuery__
 *
 * To run a query within a React component, call `useGetContactsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetContactsByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetContactsByEmailQuery, GetContactsByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsByEmailQuery, GetContactsByEmailQueryVariables>(GetContactsByEmailDocument, options);
      }
export function useGetContactsByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsByEmailQuery, GetContactsByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsByEmailQuery, GetContactsByEmailQueryVariables>(GetContactsByEmailDocument, options);
        }
export type GetContactsByEmailQueryHookResult = ReturnType<typeof useGetContactsByEmailQuery>;
export type GetContactsByEmailLazyQueryHookResult = ReturnType<typeof useGetContactsByEmailLazyQuery>;
export type GetContactsByEmailQueryResult = Apollo.QueryResult<GetContactsByEmailQuery, GetContactsByEmailQueryVariables>;
export const CreateContactDocument = gql`
    mutation createContact($customerID: ID!, $contact: ContactInput!, $allowDuplicateEmail: Boolean!) {
  createContact(
    customerID: $customerID
    contact: $contact
    allowDuplicateEmail: $allowDuplicateEmail
  )
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      customerID: // value for 'customerID'
 *      contact: // value for 'contact'
 *      allowDuplicateEmail: // value for 'allowDuplicateEmail'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CmServicesDashboardDocument = gql`
    query cmServicesDashboard {
  cmServicesDashboard {
    nCustomers
    nMerchants
    lastMerchantCreationDate
    lastCustomerCreationDate
    lastCustomerName
    lastMerchantName
  }
}
    `;

/**
 * __useCmServicesDashboardQuery__
 *
 * To run a query within a React component, call `useCmServicesDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmServicesDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmServicesDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useCmServicesDashboardQuery(baseOptions?: Apollo.QueryHookOptions<CmServicesDashboardQuery, CmServicesDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CmServicesDashboardQuery, CmServicesDashboardQueryVariables>(CmServicesDashboardDocument, options);
      }
export function useCmServicesDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CmServicesDashboardQuery, CmServicesDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CmServicesDashboardQuery, CmServicesDashboardQueryVariables>(CmServicesDashboardDocument, options);
        }
export type CmServicesDashboardQueryHookResult = ReturnType<typeof useCmServicesDashboardQuery>;
export type CmServicesDashboardLazyQueryHookResult = ReturnType<typeof useCmServicesDashboardLazyQuery>;
export type CmServicesDashboardQueryResult = Apollo.QueryResult<CmServicesDashboardQuery, CmServicesDashboardQueryVariables>;
export const GetCustomerByIdDocument = gql`
    query getCustomerById($id: ID!) {
  customer(id: $id) {
    id
    contact {
      id
      salutation
      first_name
      middle_name
      last_name
      company
      position
      phone
      mobile
      fax
      email
      website
      street
      city
      state
      post_code
      country_code
      lat
      lng
      note
      customerid
    }
    customer_number
    date_deleted
    date_created
    date_updated
  }
}
    `;

/**
 * __useGetCustomerByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
      }
export function useGetCustomerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
        }
export type GetCustomerByIdQueryHookResult = ReturnType<typeof useGetCustomerByIdQuery>;
export type GetCustomerByIdLazyQueryHookResult = ReturnType<typeof useGetCustomerByIdLazyQuery>;
export type GetCustomerByIdQueryResult = Apollo.QueryResult<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>;
export const GetCustomerByCustomerNumberDocument = gql`
    query getCustomerByCustomerNumber($customerNumber: String!) {
  getCustomerByCustomerNumber(customerNumber: $customerNumber) {
    id
    contact {
      id
      salutation
      first_name
      middle_name
      last_name
      company
      position
      phone
      mobile
      fax
      email
      website
      street
      city
      state
      post_code
      country_code
      lat
      lng
      note
      customerid
    }
    customer_number
    date_deleted
    date_created
    date_updated
  }
}
    `;

/**
 * __useGetCustomerByCustomerNumberQuery__
 *
 * To run a query within a React component, call `useGetCustomerByCustomerNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByCustomerNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByCustomerNumberQuery({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *   },
 * });
 */
export function useGetCustomerByCustomerNumberQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerByCustomerNumberQuery, GetCustomerByCustomerNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerByCustomerNumberQuery, GetCustomerByCustomerNumberQueryVariables>(GetCustomerByCustomerNumberDocument, options);
      }
export function useGetCustomerByCustomerNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerByCustomerNumberQuery, GetCustomerByCustomerNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerByCustomerNumberQuery, GetCustomerByCustomerNumberQueryVariables>(GetCustomerByCustomerNumberDocument, options);
        }
export type GetCustomerByCustomerNumberQueryHookResult = ReturnType<typeof useGetCustomerByCustomerNumberQuery>;
export type GetCustomerByCustomerNumberLazyQueryHookResult = ReturnType<typeof useGetCustomerByCustomerNumberLazyQuery>;
export type GetCustomerByCustomerNumberQueryResult = Apollo.QueryResult<GetCustomerByCustomerNumberQuery, GetCustomerByCustomerNumberQueryVariables>;
export const GetCustomersDocument = gql`
    query getCustomers($pageInfo: PaginationParams) {
  customers(paginationInfo: $pageInfo) {
    customers {
      id
      contact {
        id
        salutation
        first_name
        middle_name
        last_name
        company
        position
        phone
        mobile
        fax
        email
        website
        street
        city
        state
        post_code
        country_code
        lat
        lng
        note
        customerid
      }
      customer_number
      date_deleted
      date_created
      date_updated
    }
    total
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($customerNumber: String!, $contact: ContactInput!) {
  createCustomer(customerNumber: $customerNumber, contact: $contact)
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($id: ID!, $customerNumber: String!, $contact: ContactInput!) {
  updateCustomer(id: $id, customerNumber: $customerNumber, contact: $contact)
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerNumber: // value for 'customerNumber'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($id: ID!) {
  deleteCustomer(id: $id)
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const GetMerchantByCustomerNumberDocument = gql`
    query getMerchantByCustomerNumber($customerNumber: String!) {
  getMerchantByCustomerNumber(customerNumber: $customerNumber) {
    id
    contact {
      salutation
      first_name
      middle_name
      last_name
      company
      position
      phone
      mobile
      fax
      email
      website
      street
      city
      state
      post_code
      country_code
      lat
      lng
      note
      customerid
    }
    customer_number
    date_modified
  }
}
    `;

/**
 * __useGetMerchantByCustomerNumberQuery__
 *
 * To run a query within a React component, call `useGetMerchantByCustomerNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantByCustomerNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantByCustomerNumberQuery({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *   },
 * });
 */
export function useGetMerchantByCustomerNumberQuery(baseOptions: Apollo.QueryHookOptions<GetMerchantByCustomerNumberQuery, GetMerchantByCustomerNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMerchantByCustomerNumberQuery, GetMerchantByCustomerNumberQueryVariables>(GetMerchantByCustomerNumberDocument, options);
      }
export function useGetMerchantByCustomerNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantByCustomerNumberQuery, GetMerchantByCustomerNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMerchantByCustomerNumberQuery, GetMerchantByCustomerNumberQueryVariables>(GetMerchantByCustomerNumberDocument, options);
        }
export type GetMerchantByCustomerNumberQueryHookResult = ReturnType<typeof useGetMerchantByCustomerNumberQuery>;
export type GetMerchantByCustomerNumberLazyQueryHookResult = ReturnType<typeof useGetMerchantByCustomerNumberLazyQuery>;
export type GetMerchantByCustomerNumberQueryResult = Apollo.QueryResult<GetMerchantByCustomerNumberQuery, GetMerchantByCustomerNumberQueryVariables>;
export const GetAllmerchantsDocument = gql`
    query getAllmerchants($pagination: PaginationParams) {
  merchants(paginationInfo: $pagination) {
    merchants {
      id
      contact {
        id
        salutation
        first_name
        middle_name
        last_name
        company
        position
        phone
        mobile
        fax
        email
        website
        street
        city
        state
        post_code
        country_code
        lat
        lng
        note
        customerid
      }
      customer_number
      date_modified
    }
    total
  }
}
    `;

/**
 * __useGetAllmerchantsQuery__
 *
 * To run a query within a React component, call `useGetAllmerchantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllmerchantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllmerchantsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAllmerchantsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllmerchantsQuery, GetAllmerchantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllmerchantsQuery, GetAllmerchantsQueryVariables>(GetAllmerchantsDocument, options);
      }
export function useGetAllmerchantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllmerchantsQuery, GetAllmerchantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllmerchantsQuery, GetAllmerchantsQueryVariables>(GetAllmerchantsDocument, options);
        }
export type GetAllmerchantsQueryHookResult = ReturnType<typeof useGetAllmerchantsQuery>;
export type GetAllmerchantsLazyQueryHookResult = ReturnType<typeof useGetAllmerchantsLazyQuery>;
export type GetAllmerchantsQueryResult = Apollo.QueryResult<GetAllmerchantsQuery, GetAllmerchantsQueryVariables>;
export const GetMerchantByIdDocument = gql`
    query getMerchantById($id: ID!) {
  merchant(id: $id) {
    id
    contact {
      id
      salutation
      first_name
      middle_name
      last_name
      company
      position
      phone
      mobile
      fax
      email
      website
      street
      city
      state
      post_code
      country_code
      lat
      lng
      note
      customerid
    }
    customer_number
    date_modified
  }
}
    `;

/**
 * __useGetMerchantByIdQuery__
 *
 * To run a query within a React component, call `useGetMerchantByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMerchantByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMerchantByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMerchantByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMerchantByIdQuery, GetMerchantByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMerchantByIdQuery, GetMerchantByIdQueryVariables>(GetMerchantByIdDocument, options);
      }
export function useGetMerchantByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMerchantByIdQuery, GetMerchantByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMerchantByIdQuery, GetMerchantByIdQueryVariables>(GetMerchantByIdDocument, options);
        }
export type GetMerchantByIdQueryHookResult = ReturnType<typeof useGetMerchantByIdQuery>;
export type GetMerchantByIdLazyQueryHookResult = ReturnType<typeof useGetMerchantByIdLazyQuery>;
export type GetMerchantByIdQueryResult = Apollo.QueryResult<GetMerchantByIdQuery, GetMerchantByIdQueryVariables>;
export const CreateMerchantDocument = gql`
    mutation createMerchant($customerNumber: String!, $contact: ContactInput!) {
  createMerchant(customerNumber: $customerNumber, contact: $contact)
}
    `;
export type CreateMerchantMutationFn = Apollo.MutationFunction<CreateMerchantMutation, CreateMerchantMutationVariables>;

/**
 * __useCreateMerchantMutation__
 *
 * To run a mutation, you first call `useCreateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMerchantMutation, { data, loading, error }] = useCreateMerchantMutation({
 *   variables: {
 *      customerNumber: // value for 'customerNumber'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<CreateMerchantMutation, CreateMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMerchantMutation, CreateMerchantMutationVariables>(CreateMerchantDocument, options);
      }
export type CreateMerchantMutationHookResult = ReturnType<typeof useCreateMerchantMutation>;
export type CreateMerchantMutationResult = Apollo.MutationResult<CreateMerchantMutation>;
export type CreateMerchantMutationOptions = Apollo.BaseMutationOptions<CreateMerchantMutation, CreateMerchantMutationVariables>;
export const UpdateMerchantDocument = gql`
    mutation updateMerchant($id: ID!, $customerNumber: String!, $contact: ContactInput!) {
  updateMerchant(id: $id, customerNumber: $customerNumber, contact: $contact)
}
    `;
export type UpdateMerchantMutationFn = Apollo.MutationFunction<UpdateMerchantMutation, UpdateMerchantMutationVariables>;

/**
 * __useUpdateMerchantMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantMutation, { data, loading, error }] = useUpdateMerchantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      customerNumber: // value for 'customerNumber'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateMerchantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMerchantMutation, UpdateMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMerchantMutation, UpdateMerchantMutationVariables>(UpdateMerchantDocument, options);
      }
export type UpdateMerchantMutationHookResult = ReturnType<typeof useUpdateMerchantMutation>;
export type UpdateMerchantMutationResult = Apollo.MutationResult<UpdateMerchantMutation>;
export type UpdateMerchantMutationOptions = Apollo.BaseMutationOptions<UpdateMerchantMutation, UpdateMerchantMutationVariables>;
export const DeleteMerchantDocument = gql`
    mutation deleteMerchant($id: ID!) {
  deleteMerchantr(id: $id)
}
    `;
export type DeleteMerchantMutationFn = Apollo.MutationFunction<DeleteMerchantMutation, DeleteMerchantMutationVariables>;

/**
 * __useDeleteMerchantMutation__
 *
 * To run a mutation, you first call `useDeleteMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMerchantMutation, { data, loading, error }] = useDeleteMerchantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMerchantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMerchantMutation, DeleteMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMerchantMutation, DeleteMerchantMutationVariables>(DeleteMerchantDocument, options);
      }
export type DeleteMerchantMutationHookResult = ReturnType<typeof useDeleteMerchantMutation>;
export type DeleteMerchantMutationResult = Apollo.MutationResult<DeleteMerchantMutation>;
export type DeleteMerchantMutationOptions = Apollo.BaseMutationOptions<DeleteMerchantMutation, DeleteMerchantMutationVariables>;
export const GetOrderDocument = gql`
    query getOrder($orderId: ID!) {
  getOrder(orderId: $orderId) {
    id
    order_number
    position_number
    software_type
    order_date
    customer_id
    merchant_id
    contact_id
  }
}
    `;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetOrderByNumberAndPositionDocument = gql`
    query getOrderByNumberAndPosition($orderNumber: String!, $positionNumber: String!) {
  getOrderByNumberAndPosition(
    orderNumber: $orderNumber
    positionNumber: $positionNumber
  ) {
    id
    order_number
    position_number
    software_type
    order_date
    customer_id
    contact_id
  }
}
    `;

/**
 * __useGetOrderByNumberAndPositionQuery__
 *
 * To run a query within a React component, call `useGetOrderByNumberAndPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByNumberAndPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByNumberAndPositionQuery({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *      positionNumber: // value for 'positionNumber'
 *   },
 * });
 */
export function useGetOrderByNumberAndPositionQuery(baseOptions: Apollo.QueryHookOptions<GetOrderByNumberAndPositionQuery, GetOrderByNumberAndPositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderByNumberAndPositionQuery, GetOrderByNumberAndPositionQueryVariables>(GetOrderByNumberAndPositionDocument, options);
      }
export function useGetOrderByNumberAndPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderByNumberAndPositionQuery, GetOrderByNumberAndPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderByNumberAndPositionQuery, GetOrderByNumberAndPositionQueryVariables>(GetOrderByNumberAndPositionDocument, options);
        }
export type GetOrderByNumberAndPositionQueryHookResult = ReturnType<typeof useGetOrderByNumberAndPositionQuery>;
export type GetOrderByNumberAndPositionLazyQueryHookResult = ReturnType<typeof useGetOrderByNumberAndPositionLazyQuery>;
export type GetOrderByNumberAndPositionQueryResult = Apollo.QueryResult<GetOrderByNumberAndPositionQuery, GetOrderByNumberAndPositionQueryVariables>;
export const GetOrdersByContactIdDocument = gql`
    query GetOrdersByContactID($contactID: ID!) {
  getOrdersByContactID(contactID: $contactID) {
    contact {
      id
      salutation
      first_name
      middle_name
      last_name
      company
      position
      phone
      mobile
      fax
      email
      website
      street
      city
      state
      post_code
      country_code
      lat
      lng
      note
      customerid
    }
    order {
      id
      order_number
      position_number
      software_type
      order_date
      customer_id
      contact_id
    }
  }
}
    `;

/**
 * __useGetOrdersByContactIdQuery__
 *
 * To run a query within a React component, call `useGetOrdersByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersByContactIdQuery({
 *   variables: {
 *      contactID: // value for 'contactID'
 *   },
 * });
 */
export function useGetOrdersByContactIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersByContactIdQuery, GetOrdersByContactIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersByContactIdQuery, GetOrdersByContactIdQueryVariables>(GetOrdersByContactIdDocument, options);
      }
export function useGetOrdersByContactIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersByContactIdQuery, GetOrdersByContactIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersByContactIdQuery, GetOrdersByContactIdQueryVariables>(GetOrdersByContactIdDocument, options);
        }
export type GetOrdersByContactIdQueryHookResult = ReturnType<typeof useGetOrdersByContactIdQuery>;
export type GetOrdersByContactIdLazyQueryHookResult = ReturnType<typeof useGetOrdersByContactIdLazyQuery>;
export type GetOrdersByContactIdQueryResult = Apollo.QueryResult<GetOrdersByContactIdQuery, GetOrdersByContactIdQueryVariables>;
export const GetAllContactOrdersDocument = gql`
    query getAllContactOrders {
  getAllContactOrders {
    contact {
      id
      salutation
      first_name
      middle_name
      last_name
      company
      position
      phone
      mobile
      fax
      email
      website
      street
      city
      state
      post_code
      country_code
      lat
      lng
      note
      customerid
    }
    order {
      id
      order_number
      position_number
      software_type
      order_date
      customer_id
      contact_id
    }
  }
}
    `;

/**
 * __useGetAllContactOrdersQuery__
 *
 * To run a query within a React component, call `useGetAllContactOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContactOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContactOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContactOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllContactOrdersQuery, GetAllContactOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllContactOrdersQuery, GetAllContactOrdersQueryVariables>(GetAllContactOrdersDocument, options);
      }
export function useGetAllContactOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllContactOrdersQuery, GetAllContactOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllContactOrdersQuery, GetAllContactOrdersQueryVariables>(GetAllContactOrdersDocument, options);
        }
export type GetAllContactOrdersQueryHookResult = ReturnType<typeof useGetAllContactOrdersQuery>;
export type GetAllContactOrdersLazyQueryHookResult = ReturnType<typeof useGetAllContactOrdersLazyQuery>;
export type GetAllContactOrdersQueryResult = Apollo.QueryResult<GetAllContactOrdersQuery, GetAllContactOrdersQueryVariables>;
export const GetOrdersByMerchantIdDocument = gql`
    query GetOrdersByMerchantId($merchantID: ID!) {
  getOrdersByMerchantId(merchantID: $merchantID) {
    id
    order_number
    position_number
    software_type
    order_date
    customer_id
    merchant_id
    contact_id
  }
}
    `;

/**
 * __useGetOrdersByMerchantIdQuery__
 *
 * To run a query within a React component, call `useGetOrdersByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersByMerchantIdQuery({
 *   variables: {
 *      merchantID: // value for 'merchantID'
 *   },
 * });
 */
export function useGetOrdersByMerchantIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersByMerchantIdQuery, GetOrdersByMerchantIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersByMerchantIdQuery, GetOrdersByMerchantIdQueryVariables>(GetOrdersByMerchantIdDocument, options);
      }
export function useGetOrdersByMerchantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersByMerchantIdQuery, GetOrdersByMerchantIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersByMerchantIdQuery, GetOrdersByMerchantIdQueryVariables>(GetOrdersByMerchantIdDocument, options);
        }
export type GetOrdersByMerchantIdQueryHookResult = ReturnType<typeof useGetOrdersByMerchantIdQuery>;
export type GetOrdersByMerchantIdLazyQueryHookResult = ReturnType<typeof useGetOrdersByMerchantIdLazyQuery>;
export type GetOrdersByMerchantIdQueryResult = Apollo.QueryResult<GetOrdersByMerchantIdQuery, GetOrdersByMerchantIdQueryVariables>;
export const GetOrdersByCustomerIdDocument = gql`
    query GetOrdersByCustomerId($customerID: ID!) {
  getOrdersByCustomerId(customerID: $customerID) {
    id
    order_number
    position_number
    software_type
    order_date
    customer_id
    merchant_id
    contact_id
  }
}
    `;

/**
 * __useGetOrdersByCustomerIdQuery__
 *
 * To run a query within a React component, call `useGetOrdersByCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersByCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersByCustomerIdQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetOrdersByCustomerIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersByCustomerIdQuery, GetOrdersByCustomerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersByCustomerIdQuery, GetOrdersByCustomerIdQueryVariables>(GetOrdersByCustomerIdDocument, options);
      }
export function useGetOrdersByCustomerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersByCustomerIdQuery, GetOrdersByCustomerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersByCustomerIdQuery, GetOrdersByCustomerIdQueryVariables>(GetOrdersByCustomerIdDocument, options);
        }
export type GetOrdersByCustomerIdQueryHookResult = ReturnType<typeof useGetOrdersByCustomerIdQuery>;
export type GetOrdersByCustomerIdLazyQueryHookResult = ReturnType<typeof useGetOrdersByCustomerIdLazyQuery>;
export type GetOrdersByCustomerIdQueryResult = Apollo.QueryResult<GetOrdersByCustomerIdQuery, GetOrdersByCustomerIdQueryVariables>;
export const GetOrdersByIdsDocument = gql`
    query getOrdersByIds($orderIds: [ID]!) {
  getOrdersByIds(orderIds: $orderIds) {
    merchant {
      id
      contact {
        id
        salutation
        first_name
        middle_name
        last_name
        company
        position
        phone
        mobile
        fax
        email
        website
        street
        city
        state
        post_code
        country_code
        lat
        lng
        note
        customerid
      }
      customer_number
      date_modified
    }
    customer {
      id
      contact {
        id
        salutation
        first_name
        middle_name
        last_name
        company
        position
        phone
        mobile
        fax
        email
        website
        street
        city
        state
        post_code
        country_code
        lat
        lng
        note
        customerid
      }
      customer_number
      date_deleted
      date_created
      date_updated
    }
    order {
      id
      order_number
      position_number
      software_type
      order_date
      customer_id
      merchant_id
      contact_id
    }
  }
}
    `;

/**
 * __useGetOrdersByIdsQuery__
 *
 * To run a query within a React component, call `useGetOrdersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersByIdsQuery({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useGetOrdersByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetOrdersByIdsQuery, GetOrdersByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersByIdsQuery, GetOrdersByIdsQueryVariables>(GetOrdersByIdsDocument, options);
      }
export function useGetOrdersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersByIdsQuery, GetOrdersByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersByIdsQuery, GetOrdersByIdsQueryVariables>(GetOrdersByIdsDocument, options);
        }
export type GetOrdersByIdsQueryHookResult = ReturnType<typeof useGetOrdersByIdsQuery>;
export type GetOrdersByIdsLazyQueryHookResult = ReturnType<typeof useGetOrdersByIdsLazyQuery>;
export type GetOrdersByIdsQueryResult = Apollo.QueryResult<GetOrdersByIdsQuery, GetOrdersByIdsQueryVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($order: OrderInput!) {
  createOrder(order: $order)
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($orderId: ID!, $order: OrderInput!) {
  updateOrder(orderId: $orderId, order: $order)
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const DeleteOrderDocument = gql`
    mutation DeleteOrder($orderId: ID!) {
  deleteOrder(orderId: $orderId)
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;

/**
 * __useDeleteOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderMutation, { data, loading, error }] = useDeleteOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export type DeleteOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOrderMutation, DeleteOrderMutationVariables>;