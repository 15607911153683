import { CsButton } from '@csinstruments/cs-react-theme'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  LicenseGroupTypeDetails,
  LicenseTypeCountParams,
  useChangeLicenseTypeCountPerOrderMutation,
  useGetCloudLiceneseDetailsQuery,
} from '../../../../api/models'
import { LicenseTypesCountToUpdate } from '../../../../typeInterfaces/Interfaces'
import { getPacketName } from '../../../helpers/stringHelpers'
import { useStyles } from './ChangeLicenseCountModelStyle'

import { OrderInput, useCreateOrderMutation, useGetOrdersByIdsQuery } from '../../../../api/customer.models'
import CloudOrder from '../../pages/CloudOrder/CloudOrder'

export type LicenseTypeCountInput = {
  count: number
  licenseTypeId: string
}

type Props = {
  licensePacketLicenseTypes: LicenseGroupTypeDetails[]
  packetName: string
  openChangeLicenseCountModel: boolean
  onClose: (close: boolean) => void
  currentTypeCountInput: LicenseTypesCountToUpdate
  organizationName: string
}

export const ChangeLicenseTypesCountModal: React.FC<Props> = ({
  currentTypeCountInput,
  openChangeLicenseCountModel,
  licensePacketLicenseTypes,
  onClose,
  packetName,
  organizationName,
}: Props) => {
  // const initLicenseTypeCountInput = {
  //   count: 0,
  //   licenseTypeId: '',
  // }
  const classes = useStyles()
  const { t } = useTranslation('common')

  //const [licenseTypeCountInput, setLicenseTypeCountInput] = useState<LicenseTypeCountInput[]>([])

  const [openModel, setOpenModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [existingUsers, setExistingUsers] = useState<
    { licTypeId: string; username: string[]; totalLicCount?: number }[]
  >([])
  const [prevOrderId, setPreviousOrderId] = useState<string>()
  const [licTypeCount, setLicTypeCount] = useState<LicenseTypeCountParams[]>([])
  const [newOrderInput, setNewOrderInput] = useState<OrderInput>({
    order_number: '',
    order_date: new Date().toISOString(),
    contact_id: '',
  })
  //const [orderContact, setOrderContact] = useState<Contact>()
  const { keycloak } = useKeycloak()

  const { data } = useGetCloudLiceneseDetailsQuery({
    variables: {
      ID: currentTypeCountInput.cloudLicenseId,
    },
    skip: !currentTypeCountInput.cloudLicenseId,
  })

  const orderInfo = useGetOrdersByIdsQuery({
    variables: {
      orderIds: prevOrderId ? [prevOrderId] : [],
    },
    skip: !prevOrderId,
  })

  const [createNewOrder] = useCreateOrderMutation()

  const [updateLicenseTypesCount, { loading, error }] = useChangeLicenseTypeCountPerOrderMutation()

  const updateUsers = (licTypesCount: LicenseTypeCountParams[]) => {
    const users = licTypesCount.map((lt) => {
      const usrs =
        licensePacketLicenseTypes
          .find((lplt) => lplt.id === lt.licensePacketLicenseTypeId)
          ?.licenseUsers?.filter((lu) => lu?.userId && lu.userId)
          .map((usr) => usr?.userId as string) ?? []
      const exTotalLicCount =
        licensePacketLicenseTypes.find((lplt) => lplt.id === lt.licensePacketLicenseTypeId)?.numberOfLicenses ?? 0
      return {
        licTypeId: lt.licensePacketLicenseTypeId,
        totalLicCount: exTotalLicCount,
        username: usrs,
      }
    })
    setExistingUsers(users)

    //setExistingUsers(users.filter((u) => u) as string[])
    //const allocated = licensePacketLicenseTypes.find((type) => type.id === id)
    //console.log({ allocated })
  }

  useEffect(() => {
    if (openChangeLicenseCountModel) {
      // setLicenseTypeCountInput({
      //   count: currentTypeCountInput.newNumberOfLicense,
      //   licenseTypeId: currentTypeCountInput.licensePacketLicenseTypeId,
      // })
      setLicTypeCount(currentTypeCountInput.licTypesCount)
      updateUsers(currentTypeCountInput.licTypesCount)
      setOpenModal(true)
      //updateUsers(currentTypeCountInput.licensePacketLicenseTypeId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTypeCountInput, openChangeLicenseCountModel])

  useEffect(() => {
    if (data?.getCloudLicenseDetails.cloudLicensePackets?.length) {
      const packetOrderId = data.getCloudLicenseDetails.cloudLicensePackets.find(
        (p) => p?.id === currentTypeCountInput.licensePacketId,
      )?.orderID
      setPreviousOrderId(packetOrderId)
    }
  }, [data?.getCloudLicenseDetails.cloudLicensePackets, currentTypeCountInput.licensePacketId])

  useEffect(() => {
    if (orderInfo.data?.getOrdersByIds.length) {
      const customer = orderInfo.data.getOrdersByIds[0]?.customer
      if (customer?.contact?.id) {
        //console.log({ customer })
        //setOrderContact(customer.contact)
        setNewOrderInput((prv) => ({
          ...prv,
          customer_id: customer.id,
          contact_id: customer?.contact?.id ?? '',
        }))
      }
      const merchant = orderInfo.data.getOrdersByIds[0]?.merchant
      if (merchant?.contact?.id) {
        console.log({ merchant })
        //setOrderContact(merchant.contact)
        setNewOrderInput((prv) => ({
          ...prv,
          merchant_id: merchant.id,
          contact_id: merchant.contact?.id ?? '',
        }))
      }
    }
  }, [orderInfo.data?.getOrdersByIds])

  useEffect(() => {
    if (error) {
      //console.error(error.message)
      setErrorMessage(t('error.updateLicenseCountFailed'))
    }
  }, [error, t])

  // const selectedLicenseType = useMemo(
  //   () => licensePacketLicenseTypes.find((type) => type.id === licenseTypeCountInput.licenseTypeId)?.licenseType ?? '',
  //   [licensePacketLicenseTypes, licenseTypeCountInput],
  // )

  // const updateCount = useMemo(() => {
  //   const selectLicType = licensePacketLicenseTypes.find((type) => type.id === licenseTypeCountInput.licenseTypeId)
  //   return {
  //     countError: selectLicType?.assignedLicenses && licenseTypeCountInput.count < selectLicType?.assignedLicenses,
  //     assigned: selectLicType?.assignedLicenses,
  //   }
  // }, [licensePacketLicenseTypes, licenseTypeCountInput])

  const handleClose = () => {
    //setLicenseTypeCountInput([])
    setLicTypeCount([])
    setOpenModal(false)
    onClose(false)
    setErrorMessage(undefined)
  }

  const disableSubmit = useMemo(() => {
    return (
      !licTypeCount.length ||
      existingUsers.some((et) =>
        licTypeCount.some(
          (lt) => lt.licensePacketLicenseTypeId === et.licTypeId && lt.newNumberOfLicenses < et?.username.length,
        ),
      )
    )
    //const { licenseTypeId, count } = licenseTypeCountInput
    //return licensePacketLicenseTypes.some((lictyp) => lictyp.id === licenseTypeId && lictyp.numberOfLicenses === count)
  }, [licTypeCount, existingUsers])

  const submitNewOrder = async () => {
    try {
      if (newOrderInput.order_number) {
        //console.log({ newOrderInput })
        const resp = await createNewOrder({
          variables: {
            order: newOrderInput,
          },
        })
        return resp.data?.createOrder
      }
    } catch (err) {
      setErrorMessage(t('error.updateLicenseCountFailed'))
      console.log({ err })
    }
  }

  const submitNewLicCount = async (orderId?: string) => {
    if (!licTypeCount.length) return
    //console.log({ licTypeCount }, { currentTypeCountInput })
    try {
      const resp = await updateLicenseTypesCount({
        variables: {
          cloudLicenseId: currentTypeCountInput.cloudLicenseId,
          licensePacketId: currentTypeCountInput.licensePacketId,
          orderId: orderId,
          licenseTypeCount: licTypeCount,
        },
      })
      if (resp.data?.changeLicenseTypeCountPerOrder) {
        handleClose()
        keycloak.login()
      }
    } catch (err) {
      setErrorMessage(t('error.updateLicenseCountFailed'))
      console.log({ err })
    }
  }

  const submitChangeInput = async () => {
    if (newOrderInput.order_number.trim()) {
      const orderId = await submitNewOrder()
      if (orderId) {
        await submitNewLicCount(orderId)
      }
    } else {
      await submitNewLicCount()
    }
  }

  const handleTypeCount = (typeId: string, lcCount: unknown) => {
    const upd = licTypeCount.map((ltc) =>
      ltc.licensePacketLicenseTypeId === typeId
        ? {
            ...ltc,
            newNumberOfLicenses: Number(lcCount),
          }
        : ltc,
    )
    setLicTypeCount(upd)
  }

  const assignedTypeCount = useCallback(
    (typeId: string) => {
      return existingUsers.find((us) => us.licTypeId === typeId)?.username.length ?? 0
    },
    [existingUsers],
  )

  return (
    <>
      <Dialog className={classes.modal} open={openModel} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant={'h5'} color="secondary" gutterBottom align="center">
            {t('licenses.updateLicenseTypeCount')}
          </Typography>
          <Typography variant={'h6'} align="center">
            {organizationName}
          </Typography>
          <Typography variant={'h6'} align="center">{`${t('labels.packet')}: ${getPacketName(
            packetName,
            t,
          )}`}</Typography>
          {errorMessage ? (
            <Typography color="error" align="center" variant={'h6'}>
              {errorMessage}
            </Typography>
          ) : (
            <></>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={3}>
            <CloudOrder
              orderParams={newOrderInput}
              updateContact={() => {
                return
              }}
              updateOrderParams={setNewOrderInput}
            />

            <Grid item container justify="center" alignContent="stretch" spacing={1}>
              {licTypeCount.map((lc, ix) => (
                <Grid
                  item
                  xs={9}
                  justify="center"
                  container
                  key={lc.licensePacketLicenseTypeId + ix}
                  spacing={2}
                  direction="column"
                >
                  <Grid item xs={6} container>
                    <Grid item xs={5}>
                      <Typography variant="h6" color="textPrimary">
                        {licensePacketLicenseTypes.find((lpt) => lpt.id === lc.licensePacketLicenseTypeId)
                          ?.licenseType ?? ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body2" gutterBottom>
                        {t('licenses.assignedLicenses')} {' : '}
                        {/* </Typography>
                    <Typography variant="body2" align="justify" gutterBottom> */}
                        {existingUsers.find((us) => us.licTypeId === lc.licensePacketLicenseTypeId)?.username.length} /
                        {existingUsers.find((us) => us.licTypeId === lc.licensePacketLicenseTypeId)?.totalLicCount}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      //variant="outlined"
                      label={t('licensePools.nLicenses')}
                      type={'number'}
                      required
                      autoFocus
                      error={assignedTypeCount(lc.licensePacketLicenseTypeId) > lc.newNumberOfLicenses}
                      helperText={
                        assignedTypeCount(lc.licensePacketLicenseTypeId) > lc.newNumberOfLicenses &&
                        t('labels.updateHelperText')
                      }
                      fullWidth
                      value={lc.newNumberOfLicenses}
                      onChange={({ target }) => {
                        handleTypeCount(lc.licensePacketLicenseTypeId, target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {/* <TextField autoFocus margin="dense" id="name" label="Email Address" type="email" fullWidth /> */}
        </DialogContent>
        <DialogActions>
          <CsButton onClick={handleClose}>{t('modals.cancel')}</CsButton>

          <CsButton disabled={loading || disableSubmit} onClick={() => submitChangeInput()} solid={true}>
            {t('modals.confirm')}
          </CsButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeLicenseTypesCountModal
