import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CloudLicensePacketDetails,
  CloudLicensePacketGroup,
  LicenseTypeHistory,
  PacketHistory,
} from '../../../../api/models'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { useGetOrderQuery, useGetOrdersByIdsQuery } from '../../../../api/customer.models'
import { useGetUserProfilesQuery } from '../../../../api/user.models'
import { convertToLocaleDate } from '../../../helpers/dateTime'
import { getPacketName, sortDateString } from '../../../helpers/stringHelpers'

export const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function PacketRow(props: {
  licensePacket: CloudLicensePacketDetails
  deleteAction: (id: string) => void
  licensePacketGroups: CloudLicensePacketGroup[]
}) {
  const { licensePacket, licensePacketGroups } = props
  const { data: licPackOrderInfo } = useGetOrderQuery({ variables: { orderId: licensePacket.orderID } })
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()
  const { t } = useTranslation('common')

  const licPackOrderNumber = useMemo(() => {
    return licPackOrderInfo?.getOrder.order_number ? licPackOrderInfo.getOrder.order_number : licensePacket.orderID
  }, [licPackOrderInfo?.getOrder.order_number])

  const packetTypeLogs = useMemo(() => {
    if (!licensePacketGroups.length) return []
    return licensePacketGroups
      .flatMap((pg) => pg.licenseGroupTypeDetails.map((pgt) => (pgt?.typeLogs?.length ? pgt.typeLogs : [])))
      .flat() as LicenseTypeHistory[]
  }, [licensePacketGroups])

  const packetLogs = useMemo(() => {
    if (!licensePacket.packetLog?.length) return []
    return licensePacket.packetLog as PacketHistory[]
  }, [licensePacket.packetLog?.length])

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell>{licensePacket.packetLicenseKey}</TableCell>
        <TableCell>
          {licensePacket.packetName !== 'default' ? licensePacket.packetName : t(`packetType.regular`)}{' '}
        </TableCell>
        <TableCell>{licPackOrderNumber}</TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  {licensePacketGroups.map((pGroup) => (
                    <Grid item container key={pGroup?.groupId}>
                      <Grid item>
                        <Chip label={pGroup?.groupname} style={{ marginRight: '15px' }} />
                      </Grid>

                      {pGroup?.licenseGroupTypeDetails.map((lt) => (
                        <Typography key={lt?.id} gutterBottom style={{ marginRight: '10px' }}>
                          {lt?.licenseType}: {lt?.assignedLicenses} / {lt?.numberOfLicenses}
                        </Typography>
                      ))}
                    </Grid>
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
        <TableCell>{licensePacket.activationStatus ? `✔` : `🞬`}</TableCell>

        <TableCell>{licensePacket.creationDate}</TableCell>
        <TableCell>{licensePacket.activationDate}</TableCell>
        <TableCell>
          {licensePacket.activationStatus && licensePacket.expirationDate
            ? convertToLocaleDate(licensePacket.expirationDate, t)
            : ''}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" color="secondary" gutterBottom component="div">
                {t('objects.journal')}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{'Id'}</TableCell>
                    <TableCell>{'Typ'}</TableCell>
                    <TableCell>{'Datensatz'}</TableCell>
                    <TableCell>{t('licenses.updatedAt')}</TableCell>
                    <TableCell>{t('licenses.updatedBy')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {packetLogs.map((pl) => (
                    <LogHistoryRow key={pl.logId} packetLog={pl} />
                  ))} */}
                  <PacketLogHistory
                    isActivated={licensePacket.activationStatus}
                    packetLog={
                      packetLogs.length
                        ? packetLogs.sort((ptl1, ptl2) => sortDateString(ptl2.dateModified, ptl1.dateModified))
                        : []
                    }
                    typeLog={
                      packetTypeLogs.length
                        ? packetTypeLogs.sort((ptl1, ptl2) => sortDateString(ptl2.dateModified, ptl1.dateModified))
                        : []
                    }
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

type Props = {
  cloudLicensePackets: CloudLicensePacketDetails[]
  showHeadline?: boolean
}

export const CloudLicensePacketsTable: React.FC<Props> = ({ cloudLicensePackets, showHeadline }: Props) => {
  const { t } = useTranslation('common')

  const deactivateLicense = (id: string) => {
    console.warn('function not yet implemented', id)
    return
  }

  return (
    <>
      {showHeadline && (
        <Typography color="secondary" style={{ paddingTop: 50 }} variant={'h5'}>
          {t('labels.licensePacket')} & {t('objects.journal')}
        </Typography>
      )}

      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('licenses.licenseKey')}</TableCell>
            <TableCell>{t('labels.PacketName')}</TableCell>
            {/* <TableCell>{t('labels.PacketType')}</TableCell> */}
            <TableCell>{t('objects.orderNumber')}</TableCell>
            <TableCell>{t('licensePools.nLicenses')}</TableCell>
            <TableCell>{t('licenses.activationStatus')}</TableCell>
            <TableCell>{t('licenses.createdAt')}</TableCell>
            <TableCell>{t('licenses.activationDate')}</TableCell>
            <TableCell>{t('licenses.expiryDate')}</TableCell>
            {/* <TableCell>{t('licenses.activatedBy')}</TableCell> */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {cloudLicensePackets?.map((pkt, ix) => (
            <PacketRow
              key={pkt.id + ix}
              licensePacket={pkt}
              deleteAction={deactivateLicense}
              licensePacketGroups={
                pkt.cloudlicensePacketGroups.length ? (pkt.cloudlicensePacketGroups as CloudLicensePacketGroup[]) : []
              }
            />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

type PacketLogHistoryProps = {
  packetLog: PacketHistory[]
  typeLog: LicenseTypeHistory[]
  isActivated: boolean
}
const PacketLogHistory = ({ packetLog, typeLog, isActivated }: PacketLogHistoryProps) => {
  const { t } = useTranslation('common')
  const [userIds, setUserIds] = useState<string[]>([])
  const [packetOrderIds, setPacketOrderIds] = useState<string[]>([])
  const { data: userProfiles } = useGetUserProfilesQuery({ variables: { userIds: userIds }, skip: !userIds.length })
  const packetOrderInfo = useGetOrdersByIdsQuery({
    variables: {
      orderIds: packetOrderIds,
    },
    skip: !packetOrderIds.length,
  })

  useEffect(() => {
    if (packetLog.length) {
      const packetModifiedUsers = packetLog.map((pl) => pl.modifiedBy)
      const typeModifiedUsers = typeLog.map((tl) => tl.modifiedBy)
      const allUserIds = [...packetModifiedUsers, ...typeModifiedUsers]
      if (allUserIds.length && !userIds.length) {
        setUserIds(allUserIds.filter((u, ix) => allUserIds.findIndex((alu) => alu === u) === ix))
      }
      const ordInf = packetLog.reduce(
        (ords: string[], pl) => (pl.orderID && !ords.includes(pl.orderID) ? [...ords, pl.orderID] : ords),
        [],
      )
      ordInf.length && setPacketOrderIds(ordInf)
    }
  }, [packetLog.length])

  const userModifierList = useMemo(() => {
    if (!userProfiles?.getUserProfiles?.length) return []
    return userProfiles.getUserProfiles.map((u) => ({ id: u.userId, email: u.Email }))
  }, [userProfiles?.getUserProfiles])

  const packetOrderDetails = useMemo(() => {
    if (!packetOrderInfo.data?.getOrdersByIds.length) return []
    return packetOrderInfo.data?.getOrdersByIds.map((pki) => ({
      orderId: pki?.order?.id,
      orderNumber: pki?.order?.order_number ?? '',
      merchant: pki?.merchant?.contact?.company,
      customer: pki?.customer?.contact?.company,
    }))
  }, [packetOrderInfo])

  const contactInfo = (orderId?: string) => {
    const merchantLabel = t('objects.merchant')
    const customerLabel = t('objects.customer')
    if (packetOrderDetails.find((po) => po.orderId === orderId)?.merchant) {
      return merchantLabel + ': ' + packetOrderDetails.find((po) => po.orderId === orderId)?.merchant
    }
    return customerLabel + ': ' + packetOrderDetails.find((po) => po.orderId === orderId)?.customer
  }
  if (!packetLog.length) return <TableRow></TableRow>
  return (
    <>
      {packetLog.map((packet, ix) => (
        <TableRow key={packet.logId + ix}>
          <TableCell>{packet.logId}</TableCell>
          <TableCell>{packet.actionType}</TableCell>
          <TableCell>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {packet.packetName && `${t('labels.packet')}: ${getPacketName(packet.packetName, t)}, \n`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {isActivated && packet.expirationDate
                        ? t('licenses.expiryDate') + ' ' + convertToLocaleDate(packet.expirationDate, t)
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t('objects.orderNumber')} {':'}
                      {packetOrderDetails.find((po) => po.orderId === packet.orderID)?.orderNumber ?? ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{packet.orderID ? contactInfo(packet.orderID) : ''}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </TableCell>

          <TableCell>{convertToLocaleDate(packet.dateModified, t)}</TableCell>
          <TableCell>{userModifierList.find((u) => u.id === packet.modifiedBy)?.email ?? ''}</TableCell>
        </TableRow>
      ))}

      {typeLog.map((typel, ix) => (
        <TableRow key={typel.typeId + ix}>
          <TableCell>{typel.typeId}</TableCell>
          <TableCell>{typel.actionType} </TableCell>
          <TableCell>
            <Table size="small">
              <TableBody>
                <TableRow key={typel.typeName + ix}>
                  <TableCell>
                    {t('licenseTypes.key')} : {typel.typeName}
                  </TableCell>
                  <TableCell>
                    {t('licenses.nLicenses')} : {typel.numberOfLicenses}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
          <TableCell>{convertToLocaleDate(typel.dateModified, t)}</TableCell>
          <TableCell>{userModifierList.find((u) => u.id === typel.modifiedBy)?.email ?? ''}</TableCell>
        </TableRow>
      ))}
    </>
  )
}
