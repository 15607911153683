import React from 'react'
import Grid from '@material-ui/core/Grid'
import { paddingTopBottom } from '../../../styles/shared'

export type SimpleActionPageProps = {
  children: React.ReactNode
}

export const SimpleActionPage: React.FC<SimpleActionPageProps> = ({ children }: SimpleActionPageProps) => {
  return (
    <div style={paddingTopBottom}>
      <div /* style={standardContentPadding} */>
        <div style={{ padding: 35 }}>
          <Grid container justify="flex-start" spacing={4}>
            <Grid item xs={1} />
            <Grid item xs={10}>
              {children}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default SimpleActionPage
