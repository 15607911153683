import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetOrdersByIdsQuery } from '../../../../api/customer.models'
import {
  CloudLicenseLog,
  CloudLicensePacketDetails,
  //CloudLicense,
  useGetCloudLiceneseDetailsQuery,
  //useViewCloudLicenseQuery,
} from '../../../../api/models'
import { LicenseTypeSizes } from '../../../../typeInterfaces/Interfaces'
import { convertToLocaleDate } from '../../../helpers/dateTime'
import { ContactInfo, ContactType } from '../../../hooks/customerServiceHooks'
import { Text } from '../../partials/Inputs/Text'
//import { PageHeading } from '../../partials/PageHeading'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { calcLicMinToMonths, calcMonth } from '../CloudSubscriptions/CloudTypes'
import { CloudLicensePacketsTable } from './CloudLicensePacketsTable'
import { sortDateString } from '../../../helpers/stringHelpers'
import { useGetUserProfilesQuery } from '../../../../api/user.models'

//import { useQueryParam } from '../../../hooks/useQueryParam'
type CloudIdParms = {
  id: string
}

type CustomerMerchantInfo = {
  contact?: ContactInfo
  contactType?: ContactType
  orderNumber?: string
}

export const ViewLicenseInfo: React.FC = () => {
  const { t } = useTranslation('common')
  const { id } = useParams<CloudIdParms>()
  //const [cloudLiceseInfo, setCloudLicenseInfo] = useState<CloudLicense>()
  const [
    //licensesLabel,
    //idLabel,
    keyLabel,
    //licencePoolsLabel,
    merchantLabel,
    customerLabel,
    //noteLabel,
    //maximumActivationsLabel,
    //activationsLabel,
    //createdAtLabel,
    //updatedAtLabel,
    //updatedByLabel,
    expiryDate,
    //activationDate,
    //activationStatusLabel,
    //activatedLicenseLabel,
    //notActivatedLicenseLabel,
    //orderNumberLabel,
    //licensePacketsLabel,
    //packetNameLabel,
    //packetTypeLabel,
    //packetLabel,
  ] = useMemo(() => {
    return [
      //t('objects.licenses'),
      //t('objects.id'),
      t('licenses.licenseKey'),
      //t('objects.licensePools'),
      t('objects.merchant'),
      t('objects.customer'),
      //t('objects.note'),
      //t('licenses.maximumActivations'),
      //t('licenses.activations'),
      //t('licenses.createdAt'),
      //t('licenses.updatedAt'),
      t('licenses.expiryDate'),
      //t('licenses.activationDate'),
      //t('licenses.activatedBy'),
      //t('licenses.activationStatus'),
      //t('licenses.activated'),
      //t('licenses.notActivated'),
      //t('journal.OrderNumber'),
      //t('labels.licensePacket'),
      //t('labels.PacketName'),
      //t('labels.PacketType'),
      //t('labels.packet'),
    ]
  }, [t])
  const [orderIds, setOrderIds] = useState<string[]>([])
  const { data, loading } = useGetCloudLiceneseDetailsQuery({
    variables: {
      ID: id,
    },
    skip: !id,
  })
  //console.log(data?.getCloudLicenseDetails)
  const { data: viewOrderInfo } = useGetOrdersByIdsQuery({
    variables: {
      orderIds: orderIds.filter((o) => o != 'null'),
    },
    skip: !orderIds.length,
  })
  const cloudLicInfo = useMemo(() => {
    if (data?.getCloudLicenseDetails) {
      const activatedOn = data?.getCloudLicenseDetails?.dateActivated
        ? convertToLocaleDate(`${data?.getCloudLicenseDetails.dateActivated}`, t)
        : ''
      return {
        licenseKey: data?.getCloudLicenseDetails.licenseKey,
        organizationName: data?.getCloudLicenseDetails.organizationName,
        orderId: data?.getCloudLicenseDetails.order_id,
        updatedOn: convertToLocaleDate(`${data?.getCloudLicenseDetails?.dateModified}`, t),
        activationStatus: !!data?.getCloudLicenseDetails.dateActivated,
        activatedOn: activatedOn,
        validDays: calcMonth(data.getCloudLicenseDetails.licenseIntMinutes ?? 0, t),
        expiryDate: convertToLocaleDate(`${data?.getCloudLicenseDetails.expirationDate}`, t),
        activatedBy: data?.getCloudLicenseDetails.activatedBy ?? '',
        licGroups: data?.getCloudLicenseDetails.cloudLicensePackets ?? [],
      }
    }
  }, [data?.getCloudLicenseDetails, t])

  useEffect(() => {
    if (data?.getCloudLicenseDetails.cloudLicensePackets) {
      const order_ids = data.getCloudLicenseDetails.order_id ?? ''
      setOrderIds([order_ids])
    }
  }, [data?.getCloudLicenseDetails])

  const licensePacketInfo = useMemo(() => {
    if (data?.getCloudLicenseDetails.cloudLicensePackets) {
      const { cloudLicensePackets } = data?.getCloudLicenseDetails
      //const filteredDefaultPackets = cloudLicensePackets?.filter((licpkt) => licpkt?.packetType !== 'regular')
      return cloudLicensePackets.map((fp) => ({
        ...fp,
        creationDate: convertToLocaleDate(`${fp?.creationDate}`, t),
        activationDate: fp?.activationDate ? convertToLocaleDate(`${fp?.activationDate}`, t) : '',
      })) as CloudLicensePacketDetails[]
    }
    return []
  }, [data?.getCloudLicenseDetails, t])

  const totalLicenses = useMemo(() => {
    const licGroups =
      data?.getCloudLicenseDetails?.cloudLicensePackets?.flatMap((pg) => pg?.cloudlicensePacketGroups ?? []) ?? []

    //console.log({ licGroups })

    const totalLicenceTypes =
      licGroups?.flatMap(
        (lg) =>
          lg?.licenseGroupTypeDetails?.map((lgt) => ({
            id: lgt?.id ?? '',
            licenseType: lgt?.licenseType ?? '',
            groupName: lg.groupname ?? '',
            assigned: lgt?.assignedLicenses ?? 0,
            totalSize: lgt?.numberOfLicenses ?? 0,
          })) ?? [],
      ) ?? []

    //console.log({ totalLicenceTypes })

    const nTotal = totalLicenceTypes.map((cr) => ({
      licenseType: cr.licenseType,
      id: cr.id,
      groupName: cr.groupName,
      licenseTypeTotal: totalLicenceTypes
        .filter((tl) => tl.groupName === cr.groupName && cr.licenseType === tl.licenseType)
        .map((tg) => ({ totalSize: tg.totalSize, assigned: tg.assigned })),
    }))

    /* filter duplicates */

    const nFilterTotal = nTotal.filter(
      (x, ix) => nTotal.findIndex((nf) => nf.groupName === x.groupName && nf.licenseType === x.licenseType) === ix,
    )

    //console.log({ nTotal, nFilterTotal })

    const nlicTypes = nFilterTotal.map((p) => ({
      ...p,
      licenseTypeTotal: p.licenseTypeTotal.reduce(
        (agg: LicenseTypeSizes, cr) => ({
          totalSize: cr.totalSize + agg.totalSize,
          assigned: cr.assigned + agg.assigned,
        }),
        { totalSize: 0, assigned: 0 },
      ),
    }))

    //console.log({ nlicTypes })

    return nlicTypes.map((cr) => ({
      type: cr.licenseType,
      allocation: `${cr.licenseTypeTotal.assigned}/${cr.licenseTypeTotal.totalSize}`,
    }))
  }, [data?.getCloudLicenseDetails.cloudLicensePackets])

  // const licensePacketWithOrderNumber = useMemo(() => {
  //   // if (viewOrderInfo?.getOrdersByIds.length) {
  //   //   return licensePacketInfo.map((p) => {
  //   //     const orderNumberInfo = viewOrderInfo.getOrdersByIds.find((o) => o?.order?.id === p.orderID)?.order
  //   //     const cldOrderDetails = !orderNumberInfo ? p.orderID : orderNumberInfo?.order_number
  //   //     return {
  //   //       ...p,
  //   //       orderID: cldOrderDetails,
  //   //     }
  //   //   })
  //   // }
  //   return licensePacketInfo
  // }, [licensePacketInfo])

  const cloudLicenseOrderInfo: CustomerMerchantInfo = useMemo(() => {
    if (viewOrderInfo?.getOrdersByIds.length) {
      const customerOrMerchant = viewOrderInfo.getOrdersByIds[0]
      if (customerOrMerchant?.customer) {
        return {
          contact: {
            id: customerOrMerchant.customer.id ?? '',
            city: customerOrMerchant.customer.contact?.city ?? '',
            customerNumber: customerOrMerchant.customer.customer_number ?? '',
            company: customerOrMerchant.customer.contact?.company ?? '',
            country: customerOrMerchant.customer.contact?.country_code ?? '',
          },
          contactType: 'customer',
          orderNumber: customerOrMerchant.order?.order_number ?? '',
        } as CustomerMerchantInfo
      }
      if (customerOrMerchant?.merchant) {
        return {
          contact: {
            id: customerOrMerchant.merchant.id ?? '',
            city: customerOrMerchant.merchant.contact?.city ?? '',
            customerNumber: customerOrMerchant.merchant.customer_number ?? '',
            company: customerOrMerchant.merchant.contact?.company ?? '',
            country: customerOrMerchant.merchant.contact?.country_code ?? '',
          },
          contactType: 'merchant',
          orderNumber: customerOrMerchant.order?.order_number ?? '',
        } as CustomerMerchantInfo
      }
    }
    return {}
  }, [viewOrderInfo?.getOrdersByIds])
  const customerMerchantLabel = useMemo(() => {
    return cloudLicenseOrderInfo.contactType === 'customer' ? customerLabel : merchantLabel
  }, [cloudLicenseOrderInfo.contactType, customerLabel, merchantLabel])

  const custOrMerchInfo = useMemo(() => {
    if (cloudLicenseOrderInfo.contact) {
      const { customerNumber, company, country, city } = cloudLicenseOrderInfo.contact
      return `${customerNumber} // ${company} // ${city} // ${country}`
    }
    return ''
  }, [cloudLicenseOrderInfo.contact])

  const cloudLicHist = useMemo(() => {
    if (!data?.getCloudLicenseDetails.detailLog?.length) return []
    return data.getCloudLicenseDetails.detailLog as CloudLicenseLog[]
  }, [data?.getCloudLicenseDetails.detailLog?.length])
  //console.log({ cloudLicHist })
  return (
    <>
      <ProgressBar show={loading} />
      {/* <PageHeading title={cloudLicInfo?.organizationName ?? ''} /> */}

      <Grid item container spacing={2}>
        <Grid item xs={12} lg={12} md={12} />
        <Grid item container spacing={2} direction="row">
          <Grid
            item
            xs={7}
            md={7}
            lg={7}
            container
            style={{ border: '0.1rem solid', borderRadius: '0.5em' }}
            spacing={1}
          >
            <Grid item />
            <Grid item container alignContent="stretch">
              <Text label={customerMerchantLabel} aloneInRow value={custOrMerchInfo} />
              <Text label={keyLabel} aloneInRow value={cloudLicInfo?.licenseKey ?? ''} />

              {cloudLicInfo?.activationStatus && (
                <Text label={expiryDate} aloneInRow value={cloudLicInfo?.expiryDate} />
              )}
              {!cloudLicInfo?.activationStatus && (
                <Text label={t('licenses.nMonths')} aloneInRow value={`${cloudLicInfo?.validDays}`} />
              )}
            </Grid>
            <Grid item />
          </Grid>
          <Grid item xs md lg />
          <Grid
            item
            xs={4}
            md={4}
            lg={4}
            container
            style={{ border: '0.1rem solid', borderRadius: '0.5em' }}
            justify="flex-end"
            spacing={1}
          >
            <Grid item container alignItems="center" justify="center" spacing={2}>
              <Grid item>
                <Typography color="secondary" gutterBottom>
                  {t('licenses.nLicenses')}
                </Typography>
              </Grid>
              {totalLicenses.map((tl) => (
                <Grid item container key={tl.type} spacing={2} justify="center">
                  <Grid item>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                      {tl.type} {':'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                      {tl.allocation}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography color="secondary" style={{ paddingTop: 50 }} variant={'h5'}>
            {t('licenses.grantedLicenses')} {t('objects.journal')}
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Table>
            <TableContainer>
              <TableHead>
                <TableRow key={1}>
                  <TableCell>{'id'}</TableCell>
                  <TableCell>{'Typ'}</TableCell>
                  <TableCell>{t('licenses.nMonths')}</TableCell>
                  {/* <TableCell>{t('licenses.expiryDate')}</TableCell> */}
                  <TableCell> {t('licenses.updatedAt')}</TableCell>
                  <TableCell> {t('licenses.updatedBy')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cloudLicHist.map((clhis, ix) => (
                  <CloudHistRow key={clhis.id + ix} organizationHist={clhis} />
                ))}
              </TableBody>
            </TableContainer>
          </Table>
        </Grid>
      </Grid>
      {licensePacketInfo?.length && (
        <CloudLicensePacketsTable
          showHeadline={true}
          cloudLicensePackets={licensePacketInfo.sort((lp1, lp2) => sortDateString(lp2.creationDate, lp1.creationDate))}
        />
      )}
    </>
  )
}

type CloudHistRowProp = {
  organizationHist: CloudLicenseLog
}
const CloudHistRow = ({ organizationHist }: CloudHistRowProp) => {
  const { t } = useTranslation('common')
  const { data } = useGetUserProfilesQuery({
    variables: {
      userIds: [organizationHist.editorId],
    },
    skip: !organizationHist.editorId,
  })

  const userInfo = useMemo(() => {
    if (!data?.getUserProfiles?.length) return ''
    return data.getUserProfiles[0].Email ?? ''
  }, [data?.getUserProfiles])

  return (
    <>
      <TableRow key={organizationHist.id + organizationHist.dateModified}>
        <TableCell>{organizationHist.id}</TableCell>
        <TableCell>{organizationHist.actionType}</TableCell>
        <TableCell>{calcMonth(organizationHist.licenseIntMinutes ?? 0, t)}</TableCell>
        {/* <TableCell>
          {organizationHist.expirationDate && convertToLocaleDate(organizationHist.expirationDate, t)}
        </TableCell> */}
        <TableCell>{convertToLocaleDate(organizationHist.dateModified, t)}</TableCell>
        <TableCell>{userInfo}</TableCell>
      </TableRow>
    </>
  )
}
