import { AdminLicenseInput, CloudLicenseConfigInput, CloudLicensePoolInput } from '../../../../api/models'
import { CloudLicenseInput } from '../../../hooks/hookhelpers/types'
import { TFunction } from 'i18next'

export const initClLicenses: CloudLicensePoolInput = {
  licensePoolID: '',
  numberOfLicenses: 1,
}

export const initClSingleUserInp: AdminLicenseInput = {
  groupname: '',
  licenseType: '',
}
export type CloudLicenseInputVariable = {
  licenseKey: string
  orderId: string
  organizationName: string
  licenses: CloudLicensePoolInput[]
  config: CloudLicenseConfigInput
  adminLicenses: AdminLicenseInput[]
  appGroupName: string
}
export const defaultExpiryDate = new Date(new Date(Date.now() + 86400000 * 365)).toISOString() // default expiry date 1 year

export const initCloudLicenseInputVar: CloudLicenseInput = {
  licenseKey: '',
  organizationName: '',
  licenses: [] as CloudLicensePoolInput[],
  config: {
    licIntMinutes: 1,
    expirationDate: defaultExpiryDate,
  },
  adminLicenses: [] as AdminLicenseInput[],
}

const INT_MIN_FACTOR = 24 * 60
export const calcLicIntMinutes = (days: number): number => {
  return days * INT_MIN_FACTOR
}
export const calcYears = (intMinutes: number): number => {
  //return Math.ceil(intMinutes / (INT_MIN_FACTOR * 365))
  return Math.floor(intMinutes / (INT_MIN_FACTOR * 365))
}

export const calcRemMonths = (intMinutes: number): number => {
  const remTotalDaysOfYear = calcLicDays(intMinutes) % 365
  return remTotalDaysOfYear > 0 ? Math.floor(remTotalDaysOfYear / 30) : 0
}

export const calcMonth = (intMinutes: number, t: TFunction): string => {
  const totalYears = calcYears(intMinutes)
  const remainingMonths = calcRemMonths(intMinutes)
  const yearsTrans = t('labels.nYears')
  const monthsTrans = t('labels.nMonths')

  return remainingMonths > 0
    ? `${totalYears} ${yearsTrans}, ${remainingMonths} ${monthsTrans}`
    : `${totalYears} ${yearsTrans}`
}

export const calcLicDays = (licMin: number): number => {
  return licMin / INT_MIN_FACTOR
}

export const calcLicMinToMonths = (licMin: number): number => {
  const isGreaterThanYear = calcLicDays(licMin) > 365
  switch (calcLicDays(licMin)) {
    case calcLicIntMinutes(3 * 30):
      return 3
    case calcLicIntMinutes(6 * 30):
      return 6
    case calcLicIntMinutes(365):
      return 12
    default:
      return isGreaterThanYear ? parseFloat(((calcLicDays(licMin) * 12) / 365).toFixed(2)) : 12
  }
}
//type MinMonths = 3 | 6 | 12
export const caclLicMonthsToMin = (months: number): number => {
  switch (months) {
    case 3:
      return calcLicIntMinutes(3 * 30)
    case 6:
      return calcLicIntMinutes(6 * 30)
    case 12:
      return calcLicIntMinutes(365)
    default:
      return calcLicIntMinutes(months * 30)
  }
}

export const calcExpDate = (days: number): string => {
  const result = new Date(Date.now())
  result.setDate(result.getDate() + days)
  return result.toISOString()
}

export const calcIntMinToExpDate = (licMin: number): string => {
  const days = calcLicDays(licMin)
  return calcExpDate(days)
}

export const dateToIntMinutes = (date: string): number => {
  const diffTime = new Date(date).getTime() - new Date(Date.now()).getTime()
  const days = Math.ceil(diffTime / (1000 * 3600 * 24))
  return calcLicIntMinutes(days)
}
