import React, { FC, ReactElement } from 'react'
import { PageHeading } from '../../partials/PageHeading'
import { useTranslation } from '../../../hooks/useTranslation'
//import { JournalFilter } from '../../../../api/models'
import { Grid } from '@material-ui/core'
//import { JournalFilterUpdater } from './JournalFilterUpdater'
//import { JournalTable } from './JournalTable'

export const Journal: FC = ({}): ReactElement => {
  const { t } = useTranslation()

  //const [filter, setFilter] = useState<JournalFilter>({})

  return (
    <>
      <PageHeading title={t('objects.journal')} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        {/*  <JournalFilterUpdater setFilter={setFilter} />

        <JournalTable filter={filter} /> */}
      </Grid>
    </>
  )
}
