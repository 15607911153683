import { TFunction } from 'i18next'

export const poolToString = (typeKey: string, inUse: number, available?: number | null): string => {
  return `${typeKey} [${inUse}/${available ?? '∞'}]`
}

export const contactToString = (firstName?: string | null, lastName?: string | null): string => {
  return (firstName ? `${firstName}` : '') + (lastName ? `${firstName ? ' ' + lastName : lastName}` : '')
}

export const valueOrNullToString = (s?: string | null | number): string => {
  return s && s !== 'null' ? `${s}` : ''
}

export const numberOrInfiniteToString = (n?: number | null): string => {
  return n ? `${n}` : '∞'
}

export const isLeakageType = (pooltype: string): boolean => pooltype.split('.').includes('csleakage')
export const isTrialPool = (poolname: string): boolean => poolname.split('-').includes('trial')

export const transformRealmName = (company?: string): string =>
  company?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/gi, '') ?? ''

export const sortDateString = (date1: string, date2: string): number => {
  return new Date(date1).getTime() - new Date(date2).getTime() > 0 ? -1 : 1
}

export const getPacketName = (packetName: string, t: TFunction): string => {
  if (packetName !== 'default') return packetName
  return t('packetType.regular')
}
