import React, { /* useEffect, */ useState } from 'react'
import { ROUTES } from './helpers/routes'
import { Route, Switch } from 'react-router-dom'
import { Dashboard } from './components/pages/Dashboard/Dashboard'
import { completeContainer } from '../styles/shared'
import { FollowDrawer } from '@csinstruments/cs-react-theme'
import { LicenseServerNavigation } from './components/partials/Navigation/LicenseServerNavigation'
import LicenseServerAppTop from './components/partials/Navigation/LicenseServerAppTop'
import { PageContent } from './components/partials/PageContent'
//import { GrantOrUpdateLicense } from './components/pages/Licenses/GrantOrUpdateLicense'
import { CreateOrEditContact } from './components/pages/Contacts/CreateOrEditContact'
import { ListContacts } from './components/pages/Contacts/ListContacts'
import { CreateOrUpdateLicenseType } from './components/pages/LicenseType/CreateOrUpdateLicenseType'
import { ListLicenseTypes } from './components/pages/LicenseType/ListLicenseTypes'
import { ListLicensePools } from './components/pages/LicensePools/ListLicensePools'
import { CreateOrUpdateLicensePool } from './components/pages/LicensePools/CreateOrUpdateLicensePool'
import { ListLicenses } from './components/pages/Licenses/ListLicenses'
import { Journal } from './components/pages/Journal/Journal'
//import { ActivateLicense } from './components/pages/ActivateLicense'
import { LicenseActivations } from './components/pages/LicenseActivations'
import { useKeycloak } from '@react-keycloak/web'
import { /* getTokens, */ hasUserRoles } from './helpers/keycloakService'
import { Users } from './components/pages/Users'
import { GroupsOverview } from './components/pages/Groups'
import { ApolloProvider } from '@apollo/client'
//import { getApolloClient } from './helpers/apolloClient'
import AccessDenied from './components/partials/AccessDenied'
import { useIAMClient } from './hooks/useIAMclient'
//import CloudSubscriptions from './components/pages/CloudSubscriptions'
import CreateEditCloud from './components/pages/CloudSubscriptions/CreateEditCloud'
import ListLicenseGroups from './components/pages/LicenseGroups/ListLicenseGroups'
import { ListLicenseInstances } from './components/pages/Licenses/ListLicenseInstances'
import CreateOrders from './components/pages/Contacts/CreateOrders'
import { BatchGrantLicenses } from './components/pages/BatchGrantLicenses'
import { ViewLicenseInfo } from './components/pages/Licenses/ViewLicenseInfo'

function App(): React.ReactElement {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true)
  const { initialized, keycloak } = useKeycloak()
  const apolloClient = useIAMClient()
  //const [tokensAvailable, setTokensAvailable] = useState(false)
  //const [token, setToken] = useState('')

  /* useEffect(() => {
    const [token] = getTokens()
    if (!token) {
      // wait for local storage
      setTimeout(() => {
        const [token] = getTokens()
        if (token) {
          setTokensAvailable(true)
          setToken(token)
        }
      }, 500)
    } else {
      setTokensAvailable(true)
      setToken(token)
    }
  }, []) */

  if (!initialized || !keycloak.token) {
    return <></>
  }

  // no access to app for users without license access configured in keycloak. exception: activateLicense can done by everyone
  if (!hasUserRoles(keycloak.token).anyRole && !window.location.pathname.includes(ROUTES.activateLicense)) {
    //console.log(window.location)
    return <AccessDenied />
  }

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <Switch>
          {/* <Route exact path={ROUTES.activateLicense}>
            <ActivateLicense />
          </Route> */}
          <Route exact path={ROUTES.licenseActivations} render={() => <LicenseActivations />} />
          <Route>
            <div style={completeContainer}>
              <LicenseServerNavigation onDrawerAction={(open) => setDrawerOpen(open)} />
              <FollowDrawer open={drawerOpen}>
                <LicenseServerAppTop />
                <PageContent>
                  <Switch>
                    <Route exact path={ROUTES.grantLicense} render={() => <CreateEditCloud />} />
                    <Route exact path={ROUTES.grantLicenseForPool} render={() => <CreateEditCloud />} />
                    <Route exact path={ROUTES.batchGrantLicense} render={() => <BatchGrantLicenses />} />
                    <Route exact path={ROUTES.editLicense} render={() => <CreateEditCloud />} />
                    <Route exact path={ROUTES.home} render={() => <Dashboard />} />
                    <Route exact path={ROUTES.addCustomer} render={() => <CreateOrEditContact />} />
                    <Route exact path={ROUTES.addMerchant} render={() => <CreateOrEditContact />} />
                    <Route exact path={ROUTES.editCustomer} render={() => <CreateOrEditContact />} />
                    <Route exact path={ROUTES.createOrder} render={() => <CreateOrders />} />
                    <Route exact path={ROUTES.editMerchant} render={() => <CreateOrEditContact />} />
                    <Route exact path={ROUTES.editLicensePool} render={() => <CreateOrUpdateLicensePool />} />
                    <Route exact path={ROUTES.merchants} render={() => <ListContacts />} />
                    <Route exact path={ROUTES.customers} render={() => <ListContacts />} />
                    <Route exact path={ROUTES.addLicenseType} render={() => <CreateOrUpdateLicenseType />} />
                    <Route exact path={ROUTES.editLicenseType} render={() => <CreateOrUpdateLicenseType />} />
                    <Route exact path={ROUTES.licenseTypes} render={() => <ListLicenseTypes />} />
                    <Route exact path={ROUTES.licensePools} render={() => <ListLicensePools />} />
                    <Route exact path={ROUTES.licenseInstances} render={() => <ListLicenseInstances />} />
                    <Route exact path={ROUTES.addLicensePool} render={() => <CreateOrUpdateLicensePool />} />
                    <Route exact path={ROUTES.addLicensePoolForGroup} render={() => <CreateOrUpdateLicensePool />} />
                    <Route exact path={ROUTES.addLicensePoolForUser} render={() => <CreateOrUpdateLicensePool />} />
                    <Route exact path={ROUTES.licenses} render={() => <ListLicenses />} />
                    <Route exact path={ROUTES.journal} render={() => <Journal />} />
                    <Route exact path={ROUTES.users} render={() => <Users />} />
                    <Route exact path={ROUTES.viewCloudInfo} render={() => <ViewLicenseInfo />} />
                    <Route exact path={ROUTES.groupUsers} render={() => <Users />} />
                    {/* <Route exact path={ROUTES.cloudSubsciptions} render={() => <CloudSubscriptions />} /> */}
                    <Route exact path={ROUTES.createSubsciption} render={() => <CreateEditCloud />} />
                    <Route exact path={ROUTES.groups} render={() => <GroupsOverview />} />
                    <Route exact path={ROUTES.licenseGroups} render={() => <ListLicenseGroups />} />
                    <Route render={() => <Dashboard />} />
                  </Switch>
                </PageContent>
              </FollowDrawer>
            </div>
          </Route>
        </Switch>
      </ApolloProvider>
    </>
  )
}

export default App
