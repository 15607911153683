/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
//import { JournalFilter, useActions_JournalQuery } from '../../../../api/models'
//import { toLocaleDateTime } from '../../../helpers/dateTime'
import { Grid, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import { CloudLicenseDetails, useGetCloudLicenseDetailsByOrderIdQuery } from '../../../../api/models'
import { Order } from '../../../../api/customer.models'
import { useGetUserProfilesLazyQuery } from '../../../../api/user.models'
import { useGetCloudLicenseInfoLazyQuery } from '../../../../api/organization.models'
import { sortDateString } from '../../../helpers/stringHelpers'

type Props = { orders: Order[]; filter?: any; showTitle?: boolean }

export const JournalTable: FC<Props> = ({ /* filter, */ showTitle, orders }): ReactElement => {
  const { t } = useTranslation()

  const [orderIds, setOrderIds] = useState<string[]>([])
  const [cloudLicenses, setCloudLicenses] = useState<CloudLicenseDetails[]>([])

  const { data } = useGetCloudLicenseDetailsByOrderIdQuery({
    variables: {
      orderIDs: orderIds.filter((o) => o != 'null' || !o),
    },
    skip: !orderIds.length,
  })
  const [getRealmInfo, realmProfiles] = useGetCloudLicenseInfoLazyQuery()

  const [getProfiles, profiles] = useGetUserProfilesLazyQuery()

  useEffect(() => {
    if (orders.length) {
      const ordIDs = orders.map((o) => o.id)
      setOrderIds(ordIDs)
    }
  }, [orders, orders.length])

  useEffect(() => {
    if (data?.getCloudLicenseDetailsByOrderID.length) {
      //console.log(data.getCloudLicenseDetailsByOrderID)
      setCloudLicenses(data.getCloudLicenseDetailsByOrderID as CloudLicenseDetails[])
      const editors = data.getCloudLicenseDetailsByOrderID.map((o) => o?.editorId ?? '')
      if (editors.length) {
        getProfiles({
          variables: {
            userIds: editors,
          },
        })
        const realmIds = data.getCloudLicenseDetailsByOrderID
          .map((co) => co?.realmId)
          .filter((rid) => rid && rid) as string[]
        if (realmIds.length) {
          getRealmInfo({
            variables: {
              organizationIDs: realmIds,
            },
          })
        }
      }
    }
  }, [data?.getCloudLicenseDetailsByOrderID, getProfiles, getRealmInfo])

  /* const changeDataToString = useCallback(
    (changes: any): React.ReactElement => {
      if (!changes) return <></>
      const keys = Object.keys(changes)

      return (
        <>
          {keys
            .filter((k) => !!changes[k])
            .map((k, i) => (
              <div style={{ whiteSpace: 'nowrap' }} key={k}>
                {i + 1}. {t(`journal.${k}`)}: <span style={{ color: 'green' }}>{changes[k]}</span>
              </div>
            ))}
        </>
      )
    },
    [t],
  ) */

  /* const getPacketInformations = useCallback(
    (orderId: string, packets?: CloudLicensePacketDetails[]) => {
      if (!packets?.length) return ''
      if (packets.some((p) => p.orderID === orderId)) {
        const orderPackets = packets.filter((p) => p.packetType != 'regular')
        return orderPackets.map((orderPacket) => {
          return orderPacket?.id
            ? `${t('labels.PacketName')}: ${orderPacket.packetName} \n ${t('licenses.licenseKey')}: ${
                orderPacket.packetLicenseKey
              } `
            : ''
        })
      }
      return ''
    },
    [t],
  ) */
  //console.log({ cloudLicenses })
  const rows = useMemo(() => {
    const cldLicenseOrderMap = cloudLicenses.map((cld) => ({
      orderID: cld.order_id,
      realmName: cld.organizationName,
      licenseKey: cld.licenseKey,
      realmId: cld.realmId,
      activated: cld.activatedBy,
      packets: cld.cloudLicensePackets,
      editorID: cld.editorId,
    }))
    const packHist = cloudLicenses
      .map((p) =>
        p.cloudLicensePackets?.length
          ? p.cloudLicensePackets.map((plc) => (plc?.packetLog?.length ? plc.packetLog : []))
          : [],
      )
      .flat()
      .flat()
    const deDupPackLog = packHist.length ? packHist : []

    //console.log({ deDupPackLog })

    //const deDupPackLog = packLogHist.filter((pkl, ix) => packLogHist.findIndex((pi) => pi?.logId === pkl?.logId) === ix)
    const packLogHist = deDupPackLog
      .filter((pkl, ix) => deDupPackLog.findIndex((pi) => pi?.logId === pkl?.logId) === ix)
      .sort((p1, p2) => (p1?.dateModified && p2?.dateModified ? sortDateString(p1?.dateModified, p2?.dateModified) : 1))

    if (packLogHist?.length && orders.length) {
      return packLogHist.map((pl) => {
        if (pl && orders.some((or) => or.id === pl?.orderID)) {
          const orderInfo = orders.find((or) => or.id === pl.orderID)
          const orgInfo = cloudLicenses.find((cld) => cld.order_id === orderInfo?.id)
          const dispayOrgName =
            realmProfiles.data?.getCloudLicenseInfo.cloudLicenseOrganizations.find(
              (org) => orgInfo?.realmId === org?.realmId,
            )?.displayName ?? orgInfo?.organizationName
          return {
            id: pl?.logId ?? '',
            action_type: pl?.actionType ?? '',
            description: dispayOrgName ?? pl.packetName,
            licenseKey: pl?.licenseKey ?? '',
            time: new Date(pl?.dateModified).toLocaleString('de-DE'),
            changes: orderInfo?.order_number ? orderInfo.order_number : '',
            activationstatus: '',
            action_user: '',
          }
        }
        return {
          id: '',
          description: '',
          licenseKey: '',
          time: '',
          changes: '',
          activationstatus: '',
          action_user: '',
        }
      })
    }
    return []
    // return orders.map((o) => {
    //   //const packets = cldLicenseOrderMap.map((c) => c.packets as CloudLicensePacketDetails[]).flat()
    //   //const cldPackets = packets.length && packets ? packets : undefined
    //   if (cldLicenseOrderMap.some((cld) => cld.orderID === o.id)) {
    //     const editorUser = cldLicenseOrderMap.find((c) => c.orderID === o.id)?.editorID
    //     const userCreated = profiles?.data?.getUserProfiles?.length
    //       ? profiles.data.getUserProfiles.find((u) => u?.userId === editorUser)?.Email ?? ''
    //       : ''
    //     const foundRealmInfo = cldLicenseOrderMap.find((c) => c.orderID === o.id)
    //     const dispayOrgName = realmProfiles.data?.getCloudLicenseInfo.cloudLicenseOrganizations.find(
    //       (org) => org?.realmId === foundRealmInfo?.realmId,
    //     )?.displayName
    //     let desc = ''
    //     let lKey = ''
    //     if (cldLicenseOrderMap.some((c) => c.orderID === o.id)) {
    //       desc = `${t('contact.company')}: ${dispayOrgName ? dispayOrgName : foundRealmInfo?.realmName ?? ''}`
    //       lKey = ` ${t('objects.license')}: ${cldLicenseOrderMap.find((c) => c.orderID === o.id)?.licenseKey}`
    //     }
    //     const isActivated = !!cldLicenseOrderMap.find((c) => c.orderID === o.id)?.activated
    //     return {
    //       id: o.id,
    //       action_type: 'CREATE',
    //       description: desc,
    //       licenseKey: lKey,
    //       time: new Date(o.order_date).toLocaleString('de-DE'),
    //       changes: o.order_number,
    //       activationstatus: isActivated ? t('licenses.activated') : t('licenses.notActivated'),
    //       action_user: userCreated,
    //     }
    //   }
    //   return {
    //     id: '',
    //     description: '',
    //     licenseKey: '',
    //     time: '',
    //     changes: '',
    //     activationstatus: '',
    //     action_user: '',
    //   }
    // })
  }, [
    cloudLicenses,
    orders,
    profiles?.data?.getUserProfiles,
    realmProfiles.data?.getCloudLicenseInfo.cloudLicenseOrganizations,
    t,
  ])

  return (
    <Grid item xs={12} style={{ paddingBottom: 150 }}>
      {showTitle && (
        <Typography color="secondary" style={{ paddingTop: 20 }} variant={'h5'}>
          {t('objects.journal')}
        </Typography>
      )}
      <MaterialTable
        components={{
          // eslint-disable-next-line react/display-name
          Container: (props) => <div>{props.children}</div>,
        }}
        /* actions={[
          {
            icon: 'DeleteIcon',
            tooltip: 'Delete CloudLicense Packet',
            onClick: (e: any, data: any) => console.log(data.id),
          },
        ]} */
        options={{
          draggable: false,
          paging: false,
          search: false,
          showTitle: false,
          sorting: false,
          //selection: true,
          //showSelectAllCheckbox: true,
          //actionsColumnIndex: 1,
        }}
        columns={[
          { title: 'ID', field: 'id' },
          { title: 'Typ', field: 'action_type' },

          {
            title: 'Datensatz',
            field: 'description',
            // eslint-disable-next-line react/display-name
            render: (rowData) => (
              <>
                <Typography variant="subtitle2" color="secondary">
                  {rowData.description}{' '}
                </Typography>
                <Typography variant="subtitle2">{rowData.licenseKey} </Typography>
              </>
            ),
          },
          { title: t('journal.OrderDate'), field: 'time' },
          { title: t('objects.orderNumber'), field: 'changes' },
          { title: t('licenses.activationStatus'), field: 'activationstatus' },
          { title: 'Benutzer', field: 'action_user' },
        ]}
        //data={[]}
        data={rows}
      />
    </Grid>
  )
}
