import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  LicensePool,
  LicensePoolFilter,
  LicensePoolsDocument,
  //LicensePoolPageEntry,
  PaginationParams,
  useDeleteLicensePoolMutation,
  useLicensePoolsQuery,
  useLicenseTypesQuery,
} from '../../../../api/models'
import { DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import Search from '../../partials/Inputs/Search'
import { DataTable } from '../../partials/DataTable/DataTable'
//import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import { convertToLocaleDate } from '../../../helpers/dateTime'
import { Dropdown } from '../../partials/Inputs/Dropdown'
import { DatePicker } from '../../partials/Inputs/DatePicker/DatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { CsButton, FixedActionBar } from '@csinstruments/cs-react-theme'
import { hasUserRoles } from '../../../helpers/keycloakService'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { Add } from '@material-ui/icons'
import { useKeycloak } from '@react-keycloak/web'
import { TFunction } from 'i18next'
import { useGetUserProfilesQuery } from '../../../../api/user.models'

const poolsToTableRows = (pools: LicensePool[], t: TFunction): DataTableRow[] => {
  return (
    pools?.map((p) => {
      return {
        id: p.id,
        values: [
          p.id,
          p.poolName,
          p.license_type.licenseType ?? '',
          p.editor_id,
          p.pool_size ? `${p.pool_size}` : '∞',
          p.n_in_use ? `${p.n_in_use}` : '0',
          (p.pool_size as number) - p.n_in_use !== 0 ? Math.ceil((p.pool_size as number) - p.n_in_use) : '∞',
          convertToLocaleDate(p.date_Created, t),
        ] as string[],
      }
    }) || []
  )
}

export const ListLicensePools: FC = () => {
  const { t } = useTranslation()
  const { keycloak } = useKeycloak()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 100, Offset: 0 })
  const [searchTerm, setSearchTerm] = useState('')
  const [preliminaryFilter, setPreliminaryFilter] = useState<LicensePoolFilter>({})
  const [filter, setFilter] = useState<LicensePoolFilter>({})
  const [userIds, setUserIds] = useState<string[]>([])
  const history = useHistory()
  const { data, refetch } = useLicensePoolsQuery({
    variables: { paginationInfo: paginationData, filter: filter },
  })

  const { data: usersInfo } = useGetUserProfilesQuery({
    variables: {
      userIds: userIds,
    },
    skip: !userIds.length,
  })
  const [deleteMutation] = useDeleteLicensePoolMutation({
    refetchQueries: [
      {
        query: LicensePoolsDocument,
        variables: { paginationInfo: paginationData, filter: filter },
      },
    ],
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedID, setSelectedID] = useState('')

  const { data: licenseTypesData } = useLicenseTypesQuery()

  const licenseTypeNames = useMemo(
    () => ['-', ...(licenseTypesData?.licenseTypes.licenseTypes.map((lt) => lt.licenseType) || [])],
    [licenseTypesData],
  )

  const confirmDeleteLicensePool = async () => {
    try {
      const result = await deleteMutation({
        variables: { id: selectedID },
      })
      if (result.data?.deleteLicensePool) {
        refetch()
      }
    } catch (er) {
      console.error('error deleting', er)
    }
  }

  const [
    licensePoolsLabel,
    searchLabel,
    idLabel,
    licenseTypeLabel,
    creatorLabel,
    nLicensesLabel,
    grantedLicensesLabel,
    availableLicensesLabel,
    createdAtLabel,
    dateFromLabel,
    dateUntilLabel,
    filterLabel,
  ] = useMemo(() => {
    return [
      t('objects.licensePools'),
      t('actions.search'),
      t('objects.id'),
      t('objects.licenseType'),
      t('licensePools.creator'),
      t('licensePools.nLicenses'),
      t('licensePools.grantedLicenses'),
      t('licensePools.availableLicenses'),
      t('licensePools.createdAt'),
      t('licensePools.dateFrom'),
      t('licensePools.dateUntil'),
      t('actions.filter'),
    ]
  }, [t])

  const token = keycloak.token
  const masterUser = useMemo(() => {
    return hasUserRoles(token).master
  }, [token])

  const columns: DataTableColumn[] = useMemo(
    () => [
      { label: idLabel },
      { label: 'poolname' },
      { label: licenseTypeLabel },
      { label: creatorLabel },
      { label: nLicensesLabel },
      { label: grantedLicensesLabel },
      { label: availableLicensesLabel },
      { label: createdAtLabel },
    ],
    [
      idLabel,
      licenseTypeLabel,
      creatorLabel,
      nLicensesLabel,
      createdAtLabel,
      grantedLicensesLabel,
      availableLicensesLabel,
    ],
  )
  useEffect(() => {
    if (data?.licensePools.length) {
      const ids = data.licensePools.map((p) => p?.editor_id as string)
      setUserIds(ids)
    }
  }, [data])

  const rows: DataTableRow[] = useMemo(() => {
    const poolsInfo = (data?.licensePools as LicensePool[]) ?? []
    const userEmails = usersInfo?.getUserProfiles?.map((up) => ({ id: `${up?.userId}`, email: `${up?.Email}` })) ?? []
    const replacedEmailPools = poolsInfo.map((p) => ({
      ...p,
      editor_id: userEmails.find((ue) => ue.id === p.editor_id)?.email ?? '',
    }))
    //console.log({ replacedEmailPools })
    return userEmails.length ? poolsToTableRows(replacedEmailPools as LicensePool[], t) : []
  }, [data?.licensePools, t, usersInfo?.getUserProfiles])

  const searchTermChanged = useCallback((term: string) => {
    setSearchTerm(term)
  }, [])

  return (
    <>
      <StandardConfirmationModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        text={t('modals.standardText')}
        handleConfirm={confirmDeleteLicensePool}
      />
      {masterUser && (
        <FixedActionBar
          labels={[t('actions.add')]}
          actions={[() => history.push(ROUTES.addLicensePool)]}
          icons={[Add]}
          buttonProps={[{ solid: true }]}
        />
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <PageHeading title={licensePoolsLabel} />
        <Grid container spacing={3} style={{ paddingTop: 20 }}>
          <Grid item xs={12} md={6}>
            <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Search label={searchLabel} changeFunction={searchTermChanged} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Dropdown
              valueSelected={(s) =>
                s.length >= 1 &&
                setPreliminaryFilter((filter) => {
                  return { ...filter, type_key: s[0] === '-' ? undefined : s[0] }
                })
              }
              values={licenseTypeNames}
              label={licenseTypeLabel}
            />
          </Grid>

          <DatePicker
            xs={4}
            label={dateFromLabel}
            onChange={(date) =>
              setPreliminaryFilter((filter) => {
                return { ...filter, date_from: date.getTime() / 1000 }
              })
            }
          />
          <DatePicker
            xs={4}
            label={dateUntilLabel}
            onChange={(date) =>
              setPreliminaryFilter((filter) => {
                return { ...filter, date_until: date.getTime() / 1000 }
              })
            }
          />
          <Grid item xs={4}>
            <CsButton solid={true} onClick={() => setFilter(preliminaryFilter)}>
              {filterLabel}
            </CsButton>
          </Grid>
          <Grid item xs={12}>
            <DataTable
              columns={columns}
              filterString={searchTerm}
              onButtonClicked={(id, action) => {
                if (action === 'grantLicense') {
                  history.push(generatePath(ROUTES.grantLicenseForPool, { poolID: id }))
                } else if (action === 'delete') {
                  setModalOpen(true)
                  setSelectedID(id)
                } else if (action === 'edit') {
                  history.push(generatePath(ROUTES.editLicensePool, { id }))
                }
              }}
              rows={rows}
              allowedActions={{
                sortable: true,
                licenseGrantable: !masterUser,
                deletable: masterUser,
                editable: masterUser,
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <LicenseServerPagination
              paginationParams={paginationData}
              setPaginationParams={setPaginationData}
              nTotal={data?.licensePools.total || 0}
            />
          </Grid> */}
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  )
}
