import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import { Cancel } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

export type SearchProps = {
  label: string
  changeFunction: (search: string) => void
  onEnter?: () => void
}

export const Search: React.FC<SearchProps> = ({ label, changeFunction, onEnter }) => {
  const [textValue, setTextValue] = useState<string>('')
  const textChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    //console.log('textChanged')
    const searchText = e.target.value
    changeFunction(searchText)
    setTextValue(searchText)
  }

  const handleCancel = (): void => {
    changeFunction('')
    setTextValue('')
  }

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          onEnter && onEnter()
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {textValue ? (
                  <IconButton onClick={handleCancel} edge="end">
                    <Cancel />
                  </IconButton>
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          fullWidth
          id="standard-basic"
          label={label}
          value={textValue}
          onChange={textChanged}
        />
      </form>
    </>
  )
}

export default Search
