const checkForUndefinedVar = (variable: string | undefined) => {
  if (variable && variable === 'undefined') {
    return undefined
  }
  return variable
}

interface API {
  GQL_HTTP_URI: string
  GQL_WS_URI: string
  GQL_SCHEMA_URI: string
  ID_API: string
}
interface CAPI {
  GQL_CUSTOMER_HTTP_URI: string
}

export interface Environment {
  APP_NAME: string
  APP_VERSION: string
  STAGE: string
  WEB_URL: string
  SENTRY_DSN: string
  DATA_SERVICE_URI: string
  LOGIN_URL: string
  LOGIN_REALM: string
  API: API
  CUSTOMERAPI: CAPI
  CSV_EXPORT_URL: string
  IA_SERVICE_API: string
}

export const environment: Environment = {
  APP_NAME: process.env.REACT_APP_NAME || '',
  APP_VERSION: process.env.REACT_APP_VERSION || '',
  DATA_SERVICE_URI: process.env.REACT_APP_DATA_SERVICE_URI || '',
  STAGE: checkForUndefinedVar(process.env.REACT_APP_STAGE) || 'local',
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL || '',
  LOGIN_REALM: process.env.REACT_APP_LOGIN_REALM || '',
  WEB_URL: checkForUndefinedVar(process.env.REACT_APP_WEB_URL) || '',
  SENTRY_DSN: checkForUndefinedVar(process.env.REACT_APP_SENTRY_DSN) || '',
  CSV_EXPORT_URL: checkForUndefinedVar(process.env.REACT_APP_CSV_EXPORT_URL) || '',
  API: {
    ID_API: checkForUndefinedVar(process.env.REACT_APP_IDAPI_GQL_HTTP_URI) || '',
    GQL_HTTP_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_HTTP_URI) || '',
    GQL_WS_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_HTTP_URI) || '',
    GQL_SCHEMA_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_HTTP_URI) || '',
  },
  CUSTOMERAPI: {
    GQL_CUSTOMER_HTTP_URI: checkForUndefinedVar(process.env.REACT_APP_CAPI_GQL_HTTP_URI) || '',
  },
  IA_SERVICE_API: checkForUndefinedVar(process.env.REACT_APP_IA_SERVICE_GQL_URI) || '',
}
