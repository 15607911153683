/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react'
import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import ExtensionIcon from '@material-ui/icons/Extension'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  CloudLicensePacketDetails,
  CloudLicensePacketGroup,
  LicenseGroupTypeDetails,
  useDeactivateLicensePacketMutation,
} from '../../../../api/models'
import { convertToLocaleDate } from '../../../helpers/dateTime'
import { AddToPhotos } from '@material-ui/icons'
//import { GraphQLEndpoints } from '../../../helpers/apolloClient'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/EditOutlined'
//import { generatePath, useHistory } from 'react-router-dom'
//import { ROUTES } from '../../../helpers/routes'
import { CloudLicenseType } from '../../../hooks/cloudLicense'
import { ROUTES } from '../../../helpers/routes'
import { generatePath, useHistory } from 'react-router-dom'
import { getLicCount } from '../../../helpers/licCounts'
import { getPacketName } from '../../../helpers/stringHelpers'
import { useKeycloak } from '@react-keycloak/web'
import ChangeLicenseCountModal from '../../partials/ChangeLicenseInputModal/ChangeLicenseCountModal'
import { ChangeLicenseNumberInput, LicenseTypesCountToUpdate } from '../../../../typeInterfaces/Interfaces'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { calcMonth, calcYears } from '../CloudSubscriptions/CloudTypes'
import ExtendLicenseModal from '../../partials/ExtendLicenseModal/ExtendLicenseModal'
import ChangeLicenseTypesCountModal from '../../partials/ChangeLicenseInputModal/ChangeLicenseTypesCountModal'
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function LicensesPacketRows(Props: {
  packetGroups?: CloudLicensePacketDetails[]
  cloudlicenseId: string
  licenseKey: string
  organizationName: string
}) {
  const { packetGroups, licenseKey, cloudlicenseId, organizationName } = Props
  const { t } = useTranslation()
  const [openChangeModel, setOpenChangeModal] = useState<boolean>(false)
  const [packetName, setPacketName] = useState<string>('')
  const [openRenew, setOpenRenew] = React.useState<boolean>(false)
  const [extendLicensePacketId, setExtendLicensePacketId] = useState<string>('')
  const [deactivatePacket] = useDeactivateLicensePacketMutation()
  const [changeLicenseCountInput, setChangeLicenseCountInput] = useState<ChangeLicenseNumberInput>({
    cloudLicenseId: '',
    licensePacketId: '',
    licensePacketLicenseTypeId: '',
    newNumberOfLicense: 0,
  })
  const [licenseTypesCountInput, setLicenseTypesCountInput] = useState<LicenseTypesCountToUpdate>({
    cloudLicenseId: '',
    licensePacketId: '',
    licTypesCount: [],
  })
  const [packetGroupLicenseTypes, setPacketGroupLicenseTypes] = useState<LicenseGroupTypeDetails[]>([])
  const { keycloak } = useKeycloak()

  const confirmDeactivate = (licenseKey: string) => {
    if (licenseKey) {
      deactivatePacket({
        variables: {
          licenseKey,
        },
      })
        .then((res) => {
          if (res.data?.deactivateLicensePacket) {
            console.log('deactivated Packet Successfully')
            keycloak.login()
          }
        })
        .catch((e) => console.error('some thing gone wrong', e))
    }
    //console.log('deactivated Packet Successfully')
  }
  const handleGoToLicense = (packetId: string, packetGroup: CloudLicensePacketGroup[], packetName: string) => {
    //console.log({ packetId }, { packetGroup })
    //const defaultPacketType = packetGroup[0].licenseGroupTypeDetails.find((type) => type?.licenseType === 'user')

    // setChangeLicenseCountInput({
    //   cloudLicenseId: cloudlicenseId,
    //   licensePacketId: packetId,
    //   licensePacketLicenseTypeId: defaultPacketType?.id ?? '',
    //   newNumberOfLicense: defaultPacketType?.numberOfLicenses ?? 0,

    // })
    const userPacketType = packetGroup[0].licenseGroupTypeDetails.find((type) => type?.licenseType === 'user')
    const guestPacketType = packetGroup[0].licenseGroupTypeDetails.find((type) => type?.licenseType === 'guest')

    const updLicCount: LicenseTypesCountToUpdate = {
      cloudLicenseId: cloudlicenseId,
      licensePacketId: packetId,
      licTypesCount: [
        {
          licensePacketLicenseTypeId: userPacketType?.id ?? '',
          newNumberOfLicenses: userPacketType?.numberOfLicenses ?? 0,
        },
        {
          licensePacketLicenseTypeId: guestPacketType?.id ?? '',
          newNumberOfLicenses: guestPacketType?.numberOfLicenses ?? 0,
        },
      ],
    }
    setLicenseTypesCountInput(updLicCount)
    //console.log(packetGroup[0].licenseGroupTypeDetails)
    setPacketName(packetName)
    setPacketGroupLicenseTypes(packetGroup[0].licenseGroupTypeDetails as LicenseGroupTypeDetails[])
    setOpenChangeModal(true)
  }

  const renewLicense = (selectPacketId: string) => {
    setExtendLicensePacketId(selectPacketId)
    setOpenRenew(true)
  }

  if (!packetGroups?.length) {
    return <TableRow></TableRow>
  }
  return (
    <>
      {/* <ChangeLicenseCountModal
        onClose={setOpenChangeModal}
        openChangeLicenseCountModel={openChangeModel}
        currentTypeCountInput={changeLicenseCountInput}
        licensePacketLicenseTypes={packetGroupLicenseTypes}
        packetName={packetName}
        organizationName={organizationName}
      /> */}
      <ChangeLicenseTypesCountModal
        onClose={setOpenChangeModal}
        openChangeLicenseCountModel={openChangeModel}
        licensePacketLicenseTypes={packetGroupLicenseTypes}
        currentTypeCountInput={licenseTypesCountInput}
        packetName={packetName}
        organizationName={organizationName}
      />
      <ExtendLicenseModal
        modalHeading={organizationName}
        openExtendModal={openRenew}
        closeExtendModal={setOpenRenew}
        cloudLicenseId={cloudlicenseId}
        packetId={extendLicensePacketId}
      />
      {packetGroups.map((pkt) => (
        <TableRow key={pkt?.id}>
          <TableCell component="th" scope="row">
            {pkt?.packetLicenseKey}
          </TableCell>
          <TableCell>{pkt?.orderID}</TableCell>

          <TableCell>{pkt?.packetName && `${t('labels.packet')}: ${getPacketName(pkt.packetName, t)}`}</TableCell>
          <TableCell>
            {pkt?.cloudlicensePacketGroups.map((pGroup) => (
              <Grid item container key={pGroup?.groupId}>
                <Grid item>
                  <Chip label={pGroup?.groupname} style={{ marginRight: '15px' }} />
                </Grid>

                {pGroup?.licenseGroupTypeDetails.map((lt) => (
                  <Typography key={lt?.id} gutterBottom style={{ marginRight: '10px' }}>
                    {lt?.licenseType}: {lt?.assignedLicenses} / {lt?.numberOfLicenses}
                  </Typography>
                ))}
              </Grid>
            ))}
          </TableCell>
          {/* <TableCell>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  {pkt?.cloudlicensePacketGroups.map((pktGroup) => (
                    <TableRow key={pktGroup?.groupname}>
                      {pktGroup?.licenseGroupTypeDetails.some(
                        (ltdetails) => ltdetails?.licenseUsers?.filter((ltuser) => ltuser?.userId).length,
                      ) ? (
                        <TableCell>
                          <>
                            <Box>
                              <Grid item container>
                                <Grid item>
                                  <Chip label={pktGroup?.groupname} style={{ marginRight: '15px' }} />
                                </Grid>
                              </Grid>
                            </Box>
                          </>

                          <Box>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t('objects.type')}</TableCell>
                                  <TableCell>{t('objects.userProfileService')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pktGroup?.licenseGroupTypeDetails.map((types) => (
                                  <TableRow key={types?.id}>
                                    <TableCell>{types?.licenseType}</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TableCell> */}
          <TableCell>
            <IconButton component="span" title={t('licenses.renew')} onClick={() => renewLicense(pkt.id)}>
              <ExtensionIcon />
            </IconButton>
            <IconButton
              color="primary"
              component="span"
              onClick={() =>
                handleGoToLicense(
                  pkt.id,
                  (pkt.cloudlicensePacketGroups as unknown) as CloudLicensePacketGroup[],
                  pkt.packetName,
                )
              }
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

function Row(props: {
  licenseInstance: CloudLicenseType
  deleteAction: (id: string) => void
  //userProfiles?: GetUserProfilesQuery['getUserProfiles']
}) {
  const { licenseInstance } = props
  //const { system_identifiers } = licenseInstance
  //const { RawMessage: SystemIdentifiers } = JSON.parse(system_identifiers || '')
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()
  const { t } = useTranslation()
  const history = useHistory()
  //console.log('org', licenseInstance.cloudLicensesCreated?.licenseKey, 'admin', licenseInstance.adminData)
  //const userProfile = userProfiles?.find((userProfile) => userProfile?.userId === licenseInstance.user_id)
  /* const userProfileContact: Contact | undefined = userProfile
    ? {
        id: userProfile.userId,
        first_name: userProfile.FirstName,
        last_name: userProfile.LastName,
        company: userProfile.CompanyName,
        country_code: userProfile.Country,
      }
    : undefined */

  const handleGoToLicense = () => {
    /* const path = generatePath(ROUTES.editLicense, { id: licenseInstance.license.id })
    history.push(path) */
  }

  const handleBatchGrant = () => {
    const route = licenseInstance.cloudLicensesCreated?.id
      ? ROUTES.batchGrantLicense + `?cloudid=${licenseInstance.cloudLicensesCreated?.id}`
      : ''

    route && history.push(route)
  }

  const licePackets = licenseInstance.cloudLicensesCreated?.cloudLicensePackets?.length
    ? (licenseInstance.cloudLicensesCreated?.cloudLicensePackets as CloudLicensePacketDetails[])
    : []

  const instanceLicenseKey = licenseInstance.cloudLicensesCreated?.licenseKey ?? ''

  const instanceCloudId = licenseInstance.cloudLicensesCreated?.id ?? ''

  const handleViewLicenseInfo = () => {
    const viewRoute = generatePath(ROUTES.viewCloudInfo, { id: licenseInstance.cloudLicensesCreated?.id })
    history.push(viewRoute)
  }

  const organizationName = useMemo(() => {
    const customerCompany = licenseInstance.orderInfo?.customer?.contact?.company
    const merchantCompany = licenseInstance.orderInfo?.merchant?.contact?.company
    if (customerCompany) return t('objects.customers') + ' : ' + customerCompany
    if (merchantCompany) return t('objects.merchants') + ' : ' + merchantCompany

    return ''
  }, [licenseInstance.orderInfo?.customer?.contact?.company, licenseInstance.orderInfo?.merchant?.contact?.company, t])

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{licenseInstance.cloudLicensesCreated?.licenseKey}</TableCell>
        <TableCell>{licenseInstance.orderInfo?.merchant?.contact?.company ?? ''}</TableCell>
        <TableCell>{licenseInstance.orderInfo?.customer?.contact?.company ?? ''}</TableCell>
        <TableCell>{getLicCount(licenseInstance.cloudLicensesCreated)}</TableCell>
        <TableCell>{convertToLocaleDate(`${licenseInstance.cloudLicensesCreated?.dateCreated}`, t)}</TableCell>
        <TableCell>{calcMonth(licenseInstance.cloudLicensesCreated?.licenseIntMinutes ?? 0, t)}</TableCell>
        <TableCell>{licenseInstance.cloudLicensesCreated?.editorId}</TableCell>
        <TableCell style={{ minWidth: '200px' }}>
          {/* <IconButton
            color="primary"
            component="span"
            onClick={() =>
              licenseInstance.cloudLicensesCreated?.id && deleteAction(licenseInstance.cloudLicensesCreated?.id)
            }
          >
            <DeleteOutline />
          </IconButton> */}
          <IconButton
            color="primary"
            title={t('licenses.batchGrantLicense')}
            component="span"
            onClick={() => handleBatchGrant()}
          >
            <AddToPhotos />
          </IconButton>
          <IconButton
            color="primary"
            title={t('objects.license') + ' info'}
            component="span"
            onClick={handleViewLicenseInfo}
          >
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography variant="h6" gutterBottom component="div">
                {t('objects.license')}
              </Typography> */}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('objects.license')}</TableCell>
                    {/* <TableCell>{t('note')}</TableCell> */}
                    {/* <TableCell>{t('licensePools.orderNumber')}</TableCell>
                    <TableCell>{t('objects.licenseTypes')}</TableCell>
                    <TableCell>{t('instance.licenseMetadata')}</TableCell>
                    <TableCell>{t('licenses.maximumActivations')}</TableCell>
                    <TableCell>{t('objects.activations')}</TableCell> */}
                    {/* <TableCell>{t('objects.userProfileService')}</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={licenseInstance.cloudLicensesCreated?.id}>
                    <TableCell>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('licenses.licenseKey')}</TableCell>
                            <TableCell>{t('licensePools.orderNumber')}</TableCell>
                            <TableCell>{t('objects.licenseTypes')}</TableCell>

                            <TableCell>{t('licenses.nLicenses')}</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <LicensesPacketRows
                            packetGroups={licePackets}
                            licenseKey={instanceLicenseKey}
                            cloudlicenseId={instanceCloudId}
                            organizationName={organizationName}
                          />
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            {/* {licenseInstance.adminData?.userId && (
              <ShowContact adminInfo={licenseInstance.adminData} headline={'objects.licenseServer'} />
            )}
            
            {licenseInstance?.orderInfo?.customer?.contact?.id && (
              <ShowContact
                contact={licenseInstance?.orderInfo?.customer?.contact}
                customerNumber={licenseInstance.orderInfo.customer.customer_number}
                headline={'journal.CustomerID'}
              />
            )}
            {licenseInstance?.orderInfo?.merchant?.contact?.id && (
              <ShowContact
                contact={licenseInstance?.orderInfo?.merchant?.contact}
                customerNumber={licenseInstance.orderInfo.merchant.customer_number}
                headline={'journal.MerchantID'}
              />
            )} */}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

type LicenseTableProps = {
  licensesCreated: CloudLicenseType[]
  refetch: () => void
  showHeadline?: boolean
  csvDownload?: boolean
}
const ListLicensesTable: React.FC<LicenseTableProps> = ({
  licensesCreated,
  refetch,
  showHeadline,
  csvDownload,
}: LicenseTableProps) => {
  const { t } = useTranslation()
  const deactivateLicense = (): void => {
    /* if (!deleteLoading) {
      deleteAction({ variables: { id } }).then(() => refetch())
    } */
  }
  return (
    <>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('licenses.licenseKey')}</TableCell>
                <TableCell>{t('objects.merchants')}</TableCell>
                <TableCell>{t('objects.customers')}</TableCell>
                <TableCell>{t('licenses.nLicenses')}</TableCell>
                <TableCell>{t('licenses.createdAt')}</TableCell>
                <TableCell>{t('licenses.nMonths')}</TableCell>
                <TableCell>{t('licenses.updatedBy')}</TableCell>
                <TableCell>{t('licenses.grantLicense')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {licensesCreated.map((instance) => (
                <Row
                  key={instance.cloudLicensesCreated?.id}
                  licenseInstance={instance}
                  deleteAction={deactivateLicense}
                  //userProfiles={instance.registrationData}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}

export default ListLicensesTable
