/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Save } from '@material-ui/icons'
import { ResultAlert } from '../../partials/ResultAlert'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import {
  LicenseGroup,
  LicensePoolInput,
  LicensePoolsDocument,
  useCreateLicensePoolMutation,
  useGetAllLicenseGroupsQuery,
  useLicensePoolQuery,
  useLicenseTypesQuery,
  useUpdateLicensePoolMutation,
  //useUserOfGroupQuery,
} from '../../../../api/models'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import { Text } from '../../partials/Inputs/Text'

import { useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { DatePicker } from '../../partials/Inputs/DatePicker/DatePicker'
//import { convertToLocaleDate } from '../../../helpers/dateTime'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

type CreateOrEditPoolParams = {
  id?: string
  groupID?: string
  userID?: string
}

const initialFormValues: LicensePoolInput = {
  poolName: '',
  groupname: '',
  type_key: '',
  expiration_date: new Date(Date.now()).toLocaleString(),
  order_id: '',
  note: '',
  pool_size: 0,
}

export const CreateOrUpdateLicensePool: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const [showFailAlert, setShowFailAlert] = useState(false)
  const [lGroups, setLGroups] = useState<LicenseGroup[]>([])
  const [formValues, setFormValues] = useState(initialFormValues)
  const [
    createLicensePoolMutation,
    { loading: creatingLicensePool, data: createdLicPool },
  ] = useCreateLicensePoolMutation({
    refetchQueries: [
      {
        query: LicensePoolsDocument,
        variables: {
          paginationInfo: {
            limit: 100,
            Offset: 0,
          },
        },
      },
    ],
  })
  const [
    updateLicensePoolMutation,
    { loading: updatingLicensePool, data: updatedLicPool },
  ] = useUpdateLicensePoolMutation()
  const history = useHistory()
  const { id /* , groupID, userID */ } = useParams<CreateOrEditPoolParams>()
  /* const { data: userOfGroupData, loading: userLoading } = useUserOfGroupQuery({
    variables: { id: `${userID}` },
    skip: !userID,
  }) */
  const { data: getAllLicenseGroups, loading } = useGetAllLicenseGroupsQuery()
  const [
    createLabel,
    updateLabel,
    creationFailedLabel,
    addLicensePoolLabel,
    updateLicensePoolLabel,
    nLicensesLabel,
    licenseTypeLabel,
    //infiniteLicensesLabel,
    groupLabel,
    noteLabel,
    expiryDate,
    orderNumberLabel,
  ] = useMemo(() => {
    return [
      t('actions.create'),
      t('actions.update'),
      t('licensePools.creationFailed'),
      t('licensePools.add'),
      t('licensePools.update'),
      t('licensePools.nLicenses') + '*',
      t('objects.licenseType') + '*',
      //t('licensePools.infiniteLicenses'),
      t('licensePools.group') + '*',
      t('objects.note'),
      t('licensePools.dateUntil'),
      t('licensePools.orderNumber'),
    ]
  }, [t])

  const { data: existing } = useLicensePoolQuery({ skip: !id, variables: { id: id || '' } })
  const { data: licenseTypesData } = useLicenseTypesQuery()

  const licenseTypeNames = useMemo(() => {
    const filterGroupID = lGroups.find((lG) => lG.groupname === formValues.groupname)?.id as string
    return (
      licenseTypesData?.licenseTypes.licenseTypes.reduce(
        (agg: string[], lt) => (lt.groupId === filterGroupID ? [...agg, lt.licenseType] : agg),
        [],
      ) || []
    )
  }, [licenseTypesData, formValues.groupname])

  useEffect(() => {
    if (existing?.licensePool) {
      const { licensePool } = existing
      //console.log('lcc', formValues.expiration_date)
      setFormValues((prv) => ({
        ...prv,
        poolName: licensePool.poolName,
        groupname: licensePool.license_type.groupId,
        type_key: licensePool.license_type.licenseType,
        pool_size: licensePool.pool_size,
        order_id: licensePool.order_id,
        note: licensePool.note,
      }))
    }
    if (getAllLicenseGroups?.getAllLicenseGroups) {
      setLGroups(getAllLicenseGroups?.getAllLicenseGroups as LicenseGroup[])
    }
    if (existing?.licensePool.license_type.groupId && getAllLicenseGroups?.getAllLicenseGroups) {
      //console.log('groupID', getAllLicenseGroups?.getAllLicenseGroups)
      setLGroups(getAllLicenseGroups?.getAllLicenseGroups as LicenseGroup[])
      setFormValues((fv) => {
        return {
          ...fv,
          groupname:
            getAllLicenseGroups.getAllLicenseGroups.find((g) => g?.id === existing?.licensePool.license_type.groupId)
              ?.groupname || '',
        }
      })
    } /* else if (userOfGroupData?.userOfGroup?.group?.id) {
      setFormValues((fv) => {
        return {
          ...fv,
          group: groupData?.groups.find((g) => g.id === userOfGroupData?.userOfGroup?.group?.id)?.name || '',
        }
      })
    } */
  }, [existing, getAllLicenseGroups?.getAllLicenseGroups])

  useEffect(() => {
    if (createdLicPool?.createLicensePool || updatedLicPool?.updateLicensePool) {
      history.push(ROUTES.licensePools)
    }
  }, [createdLicPool, updatedLicPool])

  /* useEffect(() => {
    if (userOfGroupData?.userOfGroup) {
      console.log(`usersOf`, userOfGroupData?.userOfGroup)
    }
  }, [userOfGroupData]) */

  //console.log('form values', formValues)
  const createLicensePool = async () => {
    try {
      if (!id) {
        await createLicensePoolMutation({
          variables: {
            licensePool: {
              ...formValues,
              expiration_date: new Date(formValues.expiration_date).toISOString(),
            },
          },
        })
      } else {
        await updateLicensePoolMutation({
          variables: {
            id: id,
            licensePool: {
              ...formValues,
              expiration_date: new Date(formValues.expiration_date).toISOString(),
            },
          },
        })
      }
    } catch (err) {
      console.error('error updating license pool', err)
    }
  }

  return (
    <>
      <FixedActionBar
        labels={[id ? updateLabel : createLabel]}
        actions={[createLicensePool]}
        icons={[Save]}
        buttonProps={[{ solid: true }]}
        disabled={[
          !formValues.expiration_date || !formValues.groupname || !formValues.type_key || !formValues.poolName,
        ]}
      />
      <ResultAlert
        alertText={creationFailedLabel}
        showAlert={showFailAlert}
        modifyShowAlert={setShowFailAlert}
        severity="warning"
      />
      <PageHeading title={id ? updateLicensePoolLabel : addLicensePoolLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12}>
          <ProgressBar show={creatingLicensePool || updatingLicensePool || loading} />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={groupLabel}
            fullWidth
            select
            value={formValues.groupname}
            variant="standard"
            onChange={({ target }) =>
              setFormValues((prv) => ({
                ...prv,
                groupname: target.value,
              }))
            }
          >
            {lGroups.map((lGroup) => (
              <MenuItem key={lGroup.id} value={lGroup.groupname}>
                {lGroup.groupname}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <TextField
            label={licenseTypeLabel}
            fullWidth
            select
            value={formValues.type_key}
            variant="standard"
            onChange={({ target }) =>
              setFormValues((prv) => ({
                ...prv,
                type_key: target.value,
              }))
            }
          >
            {licenseTypeNames.map((lt) => (
              <MenuItem key={lt} value={lt}>
                {lt}
              </MenuItem>
            ))}
          </TextField>
          {/* <Dropdown
            valueSelected={(v) =>
              v.length > 0 &&
              setFormValues((values) => {
                return { ...values,  type_key: v[0] }
              })
            }
            values={licenseTypeNames}
            label={licenseTypeLabel}
            preselect={[formValues.type_key]}
          /> */}
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={6}>
            <DatePicker
              xs={4}
              label={expiryDate}
              preselect={new Date(formValues.expiration_date)}
              onChange={(date) =>
                setFormValues((val) => ({
                  ...val,
                  expiration_date: date.toString(),
                }))
              }
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Text
          label={nLicensesLabel}
          onlyNumbers={true}
          value={`${formValues.pool_size}`}
          //displayOnly={formValues.infiniteLicenses}
          columns={8}
          changeHandler={(v) =>
            setFormValues((values) => {
              return { ...values, pool_size: (v as unknown) as number }
            })
          }
        />
        <Text
          label={'Poolname'}
          value={formValues.poolName}
          //displayOnly={formValues.infiniteLicenses}
          columns={8}
          changeHandler={(v) =>
            setFormValues((values) => {
              return { ...values, poolName: v }
            })
          }
        />

        <Text
          label={noteLabel}
          columns={12}
          value={formValues.note || ''}
          changeHandler={(v) =>
            setFormValues((values) => {
              return { ...values, note: v }
            })
          }
        />

        <Text
          label={orderNumberLabel}
          columns={12}
          value={formValues.order_id || ''}
          changeHandler={(v) =>
            setFormValues((values) => {
              return { ...values, order_id: v }
            })
          }
        />
        <Grid item xs={12}>
          {/* {groupData?.groups && !userLoading && !groupLoading && (
            <Dropdown
              preselect={formValues.groupn ? [formValues.group] : []}
              label={groupLabel}
              values={groupData?.groups.map((g) => g.name) || []}
              valueSelected={(s) =>
                setFormValues((fv) => {
                  return { ...fv, group: s.length ? s[0] : '' }
                })
              }
            />
          )} */}
        </Grid>
        {/* {id && <JournalTable showTitle filter={{ license_pool_id: id }} />} */}
      </Grid>
    </>
  )
}
