import { CsButton } from '@csinstruments/cs-react-theme'
import DateFnsUtils from '@date-io/date-fns'
import { Grid, TextField } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  OrderInput,
  useCreateOrderMutation,
  useGetCustomerByIdQuery,
  useGetMerchantByIdQuery,
} from '../../../../api/customer.models'

//import { convertToLocaleDate } from '../../../helpers/dateTime'
import { ROUTES } from '../../../helpers/routes'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { DatePicker } from '../../partials/Inputs/DatePicker/DatePicker'
import { PageHeading } from '../../partials/PageHeading'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
const initOrderInput: OrderInput = {
  order_number: '',
  position_number: '',
  order_date: new Date().toISOString(),
  contact_id: '',
  customer_id: '',
}
const CreateOrders: React.FC = () => {
  const preselectedCustomerId = useQueryParam('customer')
  const preselectedMerchantId = useQueryParam('merchant')
  const { t } = useTranslation('common')
  const history = useHistory()
  const [customerOrMerchant, setCustomerOrMerchant] = useState<string>('')
  const [orderInputVar, setOrderInputVar] = useState<OrderInput>(initOrderInput)
  const { data: merchant, loading: merchantLoading } = useGetMerchantByIdQuery({
    variables: {
      id: preselectedMerchantId,
    },
    skip: !preselectedMerchantId,
  })
  const { data: customer, loading: customerLoading } = useGetCustomerByIdQuery({
    variables: {
      id: preselectedCustomerId,
    },
    skip: !preselectedCustomerId,
  })
  const [createNewOrder, { loading: creatingOrder }] = useCreateOrderMutation()
  useEffect(() => {
    if (merchant?.merchant?.contact && !customer?.customer) {
      const { contact } = merchant.merchant
      setOrderInputVar((prv) => ({
        ...prv,
        merchant_id: preselectedMerchantId ?? '',
        contact_id: contact.id ?? '',
      }))
      setCustomerOrMerchant(contact.company ?? '')
    } else if (!merchant?.merchant && customer?.customer?.contact) {
      const { contact } = customer?.customer
      setOrderInputVar((prv) => ({
        ...prv,
        customer_id: preselectedCustomerId ?? '',
        contact_id: contact.id ?? '',
      }))
      setCustomerOrMerchant(contact.company ?? '')
    }
  }, [customer, merchant, preselectedMerchantId, preselectedCustomerId])
  const changeOrderInput = (ord: OrderInput) => {
    setOrderInputVar((prv) => ({
      ...prv,
      ...ord,
    }))
  }

  const submitOrder = async () => {
    try {
      if (orderInputVar.order_number && orderInputVar.order_date && orderInputVar.position_number) {
        const res = await createNewOrder({
          variables: {
            order: {
              ...orderInputVar,
              order_date: orderInputVar.order_date,
            },
          },
        })
        if (res.data?.createOrder) {
          setCustomerOrMerchant('')
          setOrderInputVar(initOrderInput)
          history.push(preselectedCustomerId ? ROUTES.customers : ROUTES.merchants)
        }
      }
    } catch (er) {
      console.error({ er })
    }
  }
  return (
    <>
      <PageHeading title={t('actions.createOrder')} />
      <ProgressBar show={merchantLoading || customerLoading || creatingOrder} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item container alignItems="center" justify="center" spacing={3}>
          <Grid item />

          <Grid item container spacing={2} lg={8}>
            <Grid item xs={5} lg={5}>
              <TextField
                value={customerOrMerchant}
                label={t('contact.company')}
                variant="standard"
                required
                fullWidth
                disabled
                onChange={({ target }) => changeOrderInput({ ...orderInputVar, order_number: target.value })}
              />
            </Grid>
            <Grid item xs={5} lg={5}>
              <DatePicker
                md={12}
                preselect={new Date(`${orderInputVar.order_date}`)}
                label={t('journal.OrderDate')}
                onChange={(date) => changeOrderInput({ ...orderInputVar, order_date: date.toISOString() })}
              />
            </Grid>
            <Grid item xs={5} lg={5}>
              <TextField
                value={orderInputVar.order_number}
                label={t('journal.OrderNumber')}
                variant="standard"
                required
                fullWidth
                //helperText={!orderInputVar.order_number && <>Enter Order Number</>}
                onChange={({ target }) => changeOrderInput({ ...orderInputVar, order_number: target.value })}
              />
            </Grid>

            <Grid item xs={5} lg={5}>
              <TextField
                value={orderInputVar.position_number}
                label={t('journal.PositionNumber')}
                variant="standard"
                required
                fullWidth
                type="text"
                //helperText={!OrganizationName && <p>Enter Organization Name</p>}
                onChange={({ target }) => changeOrderInput({ ...orderInputVar, position_number: target.value })}
              />
            </Grid>
          </Grid>
          <Grid item xs={5} lg={5}>
            <CsButton
              onClick={submitOrder}
              label={t('actions.add')}
              fullwidth
              solid
              disabled={
                creatingOrder ||
                !orderInputVar.order_number ||
                !orderInputVar.order_date ||
                !orderInputVar.position_number
              }
            />
          </Grid>
          <Grid item />
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  )
}

export default CreateOrders
