export const customerServiceOperations: string[] = [
  'getContactsByEmail',
  'createContact',
  'getCustomers',
  'getCustomerById',
  'createCustomer',
  'updateCustomer',
  'deleteCustomer',
  'deleteMerchant',
  'getMerchantByCustomerNumber',
  'getAllmerchants',
  'getMerchantById',
  'createMerchant',
  'updateMerchant',
  'GetOrdersByContactID',
  'deleteMerchant',
  'CreateOrder',
  'getOrder',
  'getAllContactOrders',
  'GetOrdersByCustomerId',
  'GetOrdersByMerchantId',
  'getOrdersByIds',
  'cmServicesDashboard',
]

export const iaServices: string[] = [
  'getCloudLicenseInfo',
  'ModifyRootAdmin',
  'ChangeOrganizationName',
  'ModifyPacketName',
  'deactivateOrganization',
]
