import { CloudLicenseDetails, CloudLicensePacketDetails } from '../../api/models'
import { LicenseTypeSizes } from '../../typeInterfaces/Interfaces'

export const getLicCount = (cloudLicDetails?: CloudLicenseDetails): string[] => {
  if (cloudLicDetails) {
    const licGroups = cloudLicDetails.cloudLicensePackets?.flatMap((pg) => pg?.cloudlicensePacketGroups ?? []) ?? []

    //console.log({ licGroups })

    const totalLicenceTypes =
      licGroups?.flatMap(
        (lg) =>
          lg?.licenseGroupTypeDetails?.map((lgt) => ({
            id: lgt?.id ?? '',
            licenseType: lgt?.licenseType ?? '',
            groupName: lg.groupname ?? '',
            assigned: lgt?.assignedLicenses ?? 0,
            totalSize: lgt?.numberOfLicenses ?? 0,
          })) ?? [],
      ) ?? []

    //console.log({ totalLicenceTypes })

    const nTotal = totalLicenceTypes.map((cr) => ({
      licenseType: cr.licenseType,
      id: cr.id,
      groupName: cr.groupName,
      licenseTypeTotal: totalLicenceTypes
        .filter((tl) => tl.groupName === cr.groupName && cr.licenseType === tl.licenseType)
        .map((tg) => ({ totalSize: tg.totalSize, assigned: tg.assigned })),
    }))

    /* filter duplicates */

    const nFilterTotal = nTotal.filter(
      (x, ix) => nTotal.findIndex((nf) => nf.groupName === x.groupName && nf.licenseType === x.licenseType) === ix,
    )

    //console.log({ nTotal, nFilterTotal })

    const nlicTypes = nFilterTotal.map((p) => ({
      ...p,
      licenseTypeTotal: p.licenseTypeTotal.reduce(
        (agg: LicenseTypeSizes, cr) => ({
          totalSize: cr.totalSize + agg.totalSize,
          assigned: cr.assigned + agg.assigned,
        }),
        { totalSize: 0, assigned: 0 },
      ),
    }))

    //console.log({ nlicTypes })

    const nlicCounts = nlicTypes.map(
      (cr) =>
        ` ${cr.groupName} \n ${cr.licenseType}: ${cr.licenseTypeTotal.assigned}/${cr.licenseTypeTotal.totalSize} `,
    )
    return nlicCounts
  }
  return []
}

export const getPacketLicCount = (packetDetails?: CloudLicensePacketDetails): string[] => {
  if (packetDetails) {
    const licGroups = packetDetails.cloudlicensePacketGroups

    //console.log({ licGroups })

    const totalLicenceTypes =
      licGroups?.flatMap(
        (lg) =>
          lg?.licenseGroupTypeDetails?.map((lgt) => ({
            id: lgt?.id ?? '',
            licenseType: lgt?.licenseType ?? '',
            groupName: lg.groupname ?? '',
            assigned: lgt?.assignedLicenses ?? 0,
            totalSize: lgt?.numberOfLicenses ?? 0,
          })) ?? [],
      ) ?? []

    //console.log({ totalLicenceTypes })

    const nTotal = totalLicenceTypes.map((cr) => ({
      licenseType: cr.licenseType,
      id: cr.id,
      groupName: cr.groupName,
      licenseTypeTotal: totalLicenceTypes
        .filter((tl) => tl.groupName === cr.groupName && cr.licenseType === tl.licenseType)
        .map((tg) => ({ totalSize: tg.totalSize, assigned: tg.assigned })),
    }))

    /* filter duplicates */

    const nFilterTotal = nTotal.filter(
      (x, ix) => nTotal.findIndex((nf) => nf.groupName === x.groupName && nf.licenseType === x.licenseType) === ix,
    )

    //console.log({ nTotal, nFilterTotal })

    const nlicTypes = nFilterTotal.map((p) => ({
      ...p,
      licenseTypeTotal: p.licenseTypeTotal.reduce(
        (agg: LicenseTypeSizes, cr) => ({
          totalSize: cr.totalSize + agg.totalSize,
          assigned: cr.assigned + agg.assigned,
        }),
        { totalSize: 0, assigned: 0 },
      ),
    }))

    //console.log({ nlicTypes })

    const nlicCounts = nlicTypes.map(
      (cr) =>
        ` ${cr.groupName} \n ${cr.licenseType}: ${cr.licenseTypeTotal.assigned}/${cr.licenseTypeTotal.totalSize} `,
    )
    return nlicCounts
  }
  return []
}
