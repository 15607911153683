import { LicenseGroupRightInput } from '../../api/models'

export type ScopeTypes = {
  scope: string
  displayScope: string
}

export const assignScopes: ScopeTypes[] = [
  { scope: 'access', displayScope: 'labels.access' },
  { scope: 'noaccess', displayScope: 'labels.noaccess' },
  { scope: 'read', displayScope: 'labels.read' },
  { scope: 'write', displayScope: 'labels.write' },
]

export const initialLicenseGroupRight: LicenseGroupRightInput = {
  rightName: '',
  scopes: [],
}
