import { Grid, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import {
  CloudLicensePoolInput,
  //License,
  //LicenseFilterUpdater,
  LicenseGroup,
  LicensePool,
  //PaginationParams,
  AdminLicenseInput,
  useGetAllLicenseGroupsQuery,
  useLicensePoolsQuery,
  //useLicensesQuery,
} from '../../../../api/models'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { CsButton } from '@csinstruments/cs-react-theme'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { useTranslation } from 'react-i18next'
import { isLeakageType, isTrialPool } from '../../../helpers/stringHelpers'
import constants from '../../../../constants/constants'
//import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'

type OrgUserLicInputProps = {
  LicPoolInp: CloudLicensePoolInput[]
  singleUserLicInp: AdminLicenseInput[]
  changeInp: (licPoolInp: CloudLicensePoolInput[], singleUserLicInp: AdminLicenseInput[]) => void
}

export const OrgUserLicInput: React.FC<OrgUserLicInputProps> = ({ LicPoolInp, singleUserLicInp, changeInp }) => {
  const { t } = useTranslation('common')

  const { DEFAULT_USER_LICENSE_SIZE } = constants

  const [availableLicensePools, setAvailableLicensePools] = useState<LicensePool[]>([])

  const [licenseGroups, setLicenseGroups] = useState<LicenseGroup[]>([])
  const { data, loading: licensePoolsLoading } = useLicensePoolsQuery()

  const { data: LicGroups } = useGetAllLicenseGroupsQuery()
  useEffect(() => {
    if (data?.licensePools) {
      // not include trial pool
      // show only pools with poolsize < pools.n_in_use
      const licensePoolsAvailable = data.licensePools.reduce(
        (agg: LicensePool[], crPool) =>
          crPool && (crPool?.pool_size as number) - (crPool?.n_in_use as number) > 0 && !isTrialPool(crPool.poolName)
            ? ([...agg, crPool] as LicensePool[])
            : agg,
        [],
      )

      setAvailableLicensePools(licensePoolsAvailable)
      const guestPool = licensePoolsAvailable.find(
        (p) => p.license_type.licenseType === 'guest' && isLeakageType(p.license_type.displayNameKey),
      )
      const userPool = licensePoolsAvailable.find(
        (p) => p.license_type.licenseType === 'user' && isLeakageType(p.license_type.displayNameKey),
      )
      const defaultGuestLicenseInput = {
        licensePoolID: guestPool?.id ?? '',
        numberOfLicenses: guestPool?.pool_size
          ? guestPool.pool_size - (guestPool.pool_size - 5 * DEFAULT_USER_LICENSE_SIZE)
          : 5 * DEFAULT_USER_LICENSE_SIZE,
      }
      const defaultUserLicenseInput = {
        licensePoolID: userPool?.id ?? '',
        numberOfLicenses: userPool?.pool_size
          ? userPool.pool_size - (userPool.pool_size - DEFAULT_USER_LICENSE_SIZE)
          : DEFAULT_USER_LICENSE_SIZE,
      }
      const defaultCloudLicensesInput: CloudLicensePoolInput[] = [defaultGuestLicenseInput, defaultUserLicenseInput]
      if (!LicPoolInp.length) {
        changeInp(defaultCloudLicensesInput, singleUserLicInp)
      }
    }
  }, [DEFAULT_USER_LICENSE_SIZE, LicPoolInp.length, changeInp, data?.licensePools, singleUserLicInp])

  useEffect(() => {
    if (LicGroups?.getAllLicenseGroups.length) {
      setLicenseGroups(LicGroups?.getAllLicenseGroups as LicenseGroup[])

      const leakageGroup = LicGroups?.getAllLicenseGroups.find(
        (lg) => lg?.displayNameKey && isLeakageType(lg.displayNameKey),
      )
      const defaultLeakageSingleUserLicInput = {
        groupname: leakageGroup?.groupname ?? '',
        licenseType: 'user',
      }
      //console.log({ defaultLeakageSingleUserLicInput })
      if (!singleUserLicInp.length) {
        changeInp(LicPoolInp, [defaultLeakageSingleUserLicInput])
      }
    }
  }, [LicGroups?.getAllLicenseGroups, LicPoolInp, changeInp, licenseGroups, singleUserLicInp.length])

  const addLicPool = (id: string) => {
    //console.log('id', id)
    const curPool = availableLicensePools.find((pl) => pl.id === id)
    const tmpLicePoolInp: CloudLicensePoolInput = {
      licensePoolID: curPool?.id as string,
      numberOfLicenses: 0,
    }
    const tmpLicensesPoolInp =
      LicPoolInp.length && LicPoolInp.some((lpl) => lpl.licensePoolID === curPool?.id)
        ? LicPoolInp
        : [tmpLicePoolInp, ...LicPoolInp]

    const tmpAdminLiceInp: AdminLicenseInput[] =
      singleUserLicInp.length && singleUserLicInp.some((lscp) => lscp.licenseType === curPool?.license_type.licenseType)
        ? singleUserLicInp
        : [
            ...singleUserLicInp,
            {
              groupname: licenseGroups.find((lg) => lg.id === curPool?.license_type.groupId)?.groupname as string,
              licenseType: curPool?.license_type.licenseType as string,
            },
          ]

    changeInp(
      tmpLicensesPoolInp,
      tmpAdminLiceInp.filter((x) => x.licenseType !== 'guest'),
    )
  }

  const removeLicPool = (id: string) => {
    const tmplicensesPoolInp = LicPoolInp.length ? LicPoolInp.filter((crPool) => crPool.licensePoolID !== id) : []
    const curPool = availableLicensePools.find((pl) => pl.id === id)
    //changeInp(tmplicensesPoolInp, singleUserLicInp)
    const tmpSingleLicense = singleUserLicInp.length
      ? singleUserLicInp.filter((crInp) => crInp.licenseType !== curPool?.license_type.licenseType)
      : []
    changeInp(tmplicensesPoolInp, tmpSingleLicense)
  }

  const changeNoLicense = useCallback(
    (id: string, noLicenses: string) => {
      const tmpLicensesPoolInp =
        LicPoolInp.length && LicPoolInp.some((lpInp) => lpInp.licensePoolID === id)
          ? LicPoolInp.map((crPoolInp) =>
              crPoolInp.licensePoolID === id
                ? { licensePoolID: id, numberOfLicenses: (noLicenses as unknown) as number }
                : crPoolInp,
            )
          : [{ licensePoolID: id, numberOfLicenses: (noLicenses as unknown) as number }]
      changeInp(tmpLicensesPoolInp, singleUserLicInp)
    },
    [LicPoolInp, changeInp, singleUserLicInp],
  )

  return (
    <>
      {/* <Grid item xs={12}>
          <PaginationInfoLimitUpdater
            paginationParams={paginationParams}
            setPaginationParams={setPaginationParams}
            updateEntries={refetch}
          />
        </Grid>
        <Grid item xs={12} /> */}
      <ProgressBar show={licensePoolsLoading} />
      {availableLicensePools.length &&
        availableLicensePools.map((avPool) => (
          <Grid key={avPool.id} item container spacing={2}>
            <Grid item container xs={2} lg={6}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                {avPool.poolName.toUpperCase()}
              </Typography>
              <Grid item container spacing={2}>
                {LicPoolInp.map(
                  (crInp) =>
                    crInp.licensePoolID === avPool.id && (
                      <Grid item xs={3} lg={5} key={crInp.licensePoolID}>
                        <TextField
                          label={t('licensePools.nLicenses')}
                          type={'number'}
                          //defaultValue={10}
                          error={crInp.numberOfLicenses > (avPool?.pool_size as number) - (avPool.n_in_use as number)}
                          helperText={
                            crInp.numberOfLicenses > (avPool?.pool_size as number) - (avPool.n_in_use as number) &&
                            t('error.exceededLimit', { name: avPool.poolName })
                          }
                          fullWidth
                          value={crInp.numberOfLicenses}
                          onChange={({ target }) => {
                            changeNoLicense(crInp.licensePoolID, target.value)
                          }}
                        />
                      </Grid>
                    ),
                )}
              </Grid>
            </Grid>
            <Grid item>
              <CsButton
                disabled={!!LicPoolInp.some((lip) => lip.licensePoolID === avPool.id)}
                onClick={() => addLicPool(avPool.id)}
              >
                <AddBoxIcon />
              </CsButton>
            </Grid>
            <Grid item>
              <CsButton
                disabled={!LicPoolInp.length || !LicPoolInp.some((lip) => lip.licensePoolID === avPool.id)}
                onClick={() => removeLicPool(avPool.id)}
              >
                <DeleteForeverIcon />
              </CsButton>
            </Grid>
          </Grid>
        ))}
    </>
  )
}
