import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
//import { createHashHistory } from 'history'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import common_de from './translations/de/common.json'
import common_en from './translations/en/common.json'
import { keycloak } from './app/helpers/keycloakService'
//import { environment } from './app/helpers/environment'
import ThemeProvider from './app/components/partials/ThemeProvider'

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'de',
  resources: {
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
  },
})

//const hashHistory = createHashHistory()
//const [token, refreshToken] = getTokens()
ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <ThemeProvider>
      <Router>
        <ReactKeycloakProvider
          //onEvent={keycloakEventHandler}
          authClient={keycloak}
          initOptions={{ onLoad: 'login-required' }}
          autoRefreshToken={false}
        >
          <App />
        </ReactKeycloakProvider>
      </Router>
    </ThemeProvider>
  </I18nextProvider>,
  document.getElementById('root'),
)
