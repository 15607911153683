import { DataTable, DataTableColumn, DataTableRow } from '@csinstruments/cs-react-theme'
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import {
  GetAllLicenseGroupsDocument,
  LicenseGroup,
  LicenseGroupInput,
  useGetAllLicenseGroupsQuery,
  useRegisterLicenseGroupMutation,
} from '../../../../api/models'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import AddIcon from '@material-ui/icons/Add'
import CreateLicenseGroupRights from './CreateLicenseGroupRights'

const ListLicenseGroups: React.FC = () => {
  const { data } = useGetAllLicenseGroupsQuery()
  const { t } = useTranslation()
  const initLicenseGroupInput: LicenseGroupInput = {
    groupname: '',
    displayNameKey: '',
    descriptionKey: '',
    groupRights: [],
  }
  const [licensGrpInput, setLicensGrpInput] = useState<LicenseGroupInput>(initLicenseGroupInput)
  const [groupName, setGroupName] = useState<string>('')
  const [licenseGrps, setLicenseGrps] = useState<LicenseGroup[]>([])
  const [specialGroup, setSpecialGroups] = useState<boolean>(false)
  const [rows, setRows] = useState<DataTableRow[]>([])
  const [confirmCreate, { data: newLicenseGroup }] = useRegisterLicenseGroupMutation({
    refetchQueries: [
      {
        query: GetAllLicenseGroupsDocument,
      },
    ],
  })
  const [idLabel, groupLabel, pageHeadingLabel, newGroup] = useMemo(() => {
    return [t('objects.id'), t('objects.licenseGroup'), t('objects.licenseGroups'), t('objects.addGroup')]
  }, [t])

  const columns: DataTableColumn[] = useMemo(() => [{ label: idLabel }, { label: groupLabel }], [idLabel, groupLabel])
  useEffect(() => {
    if (data?.getAllLicenseGroups.length) {
      setLicenseGrps(data.getAllLicenseGroups as LicenseGroup[])
      const tmprows = licenseGrps.map((lg) => ({
        id: lg.id,
        values: [lg.id, lg.groupname],
      }))
      setRows(tmprows)
    }
  }, [data?.getAllLicenseGroups, licenseGrps])

  useEffect(() => {
    if (newLicenseGroup?.registerLicenseGroup) {
      setRows((prv) => [
        ...prv,
        {
          id: newLicenseGroup?.registerLicenseGroup,
          values: [newLicenseGroup?.registerLicenseGroup, licensGrpInput.groupname],
        },
      ])
      setLicensGrpInput(initLicenseGroupInput)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLicenseGroup?.registerLicenseGroup])

  const submitLicenseGroup = async (licGrpInput: LicenseGroupInput) => {
    try {
      const submitResult = await confirmCreate({
        variables: {
          licenseGroup: licGrpInput,
        },
      })
      const isSuccess = submitResult?.data?.registerLicenseGroup
      if (isSuccess) {
        setGroupName('')
        setSpecialGroups(false)
      }
    } catch (err) {
      console.error('error creating group', err)
    }
  }
  const changeLiceGrpInput = (val: string) => {
    setGroupName(val)
    const appName = val.split('-').join('')
    setLicensGrpInput({
      groupname: val,
      displayNameKey: `license.${appName}.displayname`,
      descriptionKey: `license.${appName}.description`,
      groupRights: [],
    })
  }
  const updateLicenseGrp = () => {
    setGroupName('')
    setSpecialGroups(true)
  }

  return (
    <>
      <CreateLicenseGroupRights
        create={specialGroup}
        cancelCreate={(c) => {
          setSpecialGroups(c)
          setGroupName('')
        }}
        currentLicenseGroup={licensGrpInput}
        confirmCreate={submitLicenseGroup}
      />
      <PageHeading title={pageHeadingLabel} />
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={groupName}
          label={newGroup}
          variant="outlined"
          onChange={({ target }) => changeLiceGrpInput(target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="add license Group"
                  disabled={!licensGrpInput.groupname || !groupName}
                  onClick={updateLicenseGrp}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <DataTable columns={columns} rows={rows} allowedActions={{ sortable: true }} />
    </>
  )
}

export default ListLicenseGroups
