import { TFunction } from 'i18next'

export const toLocaleDateTime = (timestamp: number | undefined | null, t: TFunction): string => {
  if (!timestamp) {
    return '-'
  }
  const locale = getIsoCode(t)
  const date = new Date(timestamp * 1000)
  const dateString = date.toLocaleDateString(locale)
  const timeString = date.toLocaleTimeString(locale)

  return `${dateString} ${timeString}`
}

export const getIsoCode = (t?: TFunction, locale?: string): string => {
  //console.log({ t })
  return locale || (t && t('i18n.local')) || 'de-DE'
}

export const convertToLocaleDate = (dateToConvert: string, t: TFunction): string => {
  const locale = getIsoCode(t)
  return dateToConvert && dateToConvert ? new Date(dateToConvert).toLocaleString(locale) : '-'
}

export const getRemainingDays = (dateToConvert?: string): number => {
  if (dateToConvert) {
    const currentDate = new Date().getTime()
    const expiryDate = new Date(dateToConvert).getTime()
    const diffTime = expiryDate - currentDate
    const days = Math.ceil(diffTime / (1000 * 3600 * 24))
    return days
  }
  return 0
}

export const getDaysToAdd = (dateToConvert?: string): number => {
  if (dateToConvert) {
    const currentDate = new Date().getTime()
    const expiryDate = new Date(dateToConvert).getTime()
    const diffTime = currentDate - expiryDate
    const days = Math.ceil(diffTime / (1000 * 3600 * 24))
    return days
  }
  return 0
}

export const isExpired = (dateToConvert?: string): boolean => {
  if (dateToConvert) {
    /* const currentDate = new Date().getTime()
    const expiryDate = new Date(dateToConvert).getTime()
    const diffTime = expiryDate - currentDate */
    return getRemainingDays(dateToConvert) <= 0
  }
  return true
}
