import React, { FC, ReactElement, useMemo } from 'react'
//import { useEndUserLicenseActivationsQuery } from '../../../../api/models'
import { Container, Grid } from '@material-ui/core'
import { DataTable } from '../../partials/DataTable/DataTable'
import { PageHeading } from '../../partials/PageHeading'
import { useTranslation } from '../../../hooks/useTranslation'
import { DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'

export const LicenseActivations: FC = ({}): ReactElement => {
  //const { data } = useEndUserLicenseActivationsQuery()
  const { t } = useTranslation()

  const columns: DataTableColumn[] = useMemo(
    () => [{ label: t('licenses.licenseKey') }, { label: t('objects.licenseTypes') }, { label: t('objects.date') }],
    [t],
  )

  const rows: DataTableRow[] = /* data?.endUserLicenseActivations.map((activation) => {
      return {
        id: activation.id,
        values: [activation.license_key, activation.types.join(', '), `${toLocaleDateTime(activation.date)}`],
      }
    }) || */ []

  return (
    <Container style={{ padding: 50 }}>
      <PageHeading title={t('licenses.myActivations')} />
      <Grid container>
        <Grid item xs={12}>
          <DataTable columns={columns} rows={rows} allowedActions={{ sortable: true, licenseGrantable: false }} />
        </Grid>
      </Grid>
    </Container>
  )
}
