const constants = {
  DATE: {
    DEFAULT_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    ZONED_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',
  },
  LOGGING: {
    LOGGERS: {
      BASE: 'sighnhere-management.base',
    },
    LEVELS: {
      TRACE: { index: 0, key: 'trace' },
      DEBUG: { index: 1, key: 'DEBUG' },
      INFO: { index: 2, key: 'info' },
      WARN: { index: 3, key: 'warn' },
      ERROR: { index: 4, key: 'error' },
    },
  },
  NAVIGATION: {
    HOME: '',
    TEST: 'test',
    LIST: 'list-edit',
    LISTS: 'list',
    AUFGABENPLANUNG: 'tasks',

    SERVER_ERROR_PAGE: 'server-error',
    NO_ACCESS_PAGE: 'no-access',
  },
  ROUTES: {},
  PACKET_TYPES: [
    {
      type: 'city',
      typeKey: 'contact.city',
    },
    {
      type: 'country',
      typeKey: 'contact.country',
    },
    {
      type: 'building',
      typeKey: 'packetType.building',
    },
    {
      type: 'branch',
      typeKey: 'packetType.branch',
    },
    {
      type: 'other',
      typeKey: 'packetType.other',
    },
    {
      type: 'location',
      typeKey: 'packetType.location',
    },
    {
      type: 'regular',
      typeKey: 'packetType.regular',
    },
  ],
  DEFAULT_USER_LICENSE_SIZE: 5,
}

export default constants
