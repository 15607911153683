import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import React, { useState } from 'react'
import RefreshIcon from '@material-ui/icons/Refresh'

import { useTranslation } from 'react-i18next'
import { LicensePacketInput } from '../../../hooks/hookhelpers/types'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CloudOrder from '../CloudOrder/CloudOrder'
import { OrderInput, Contact } from '../../../../api/customer.models'
import { PlanType } from '../../../../typeInterfaces/Interfaces'

export type Props = {
  packetInput: LicensePacketInput
  defaultPacketName: string
  updatePacketName: (packetName: string) => void
  changePacketInput: (cldVariables: LicensePacketInput) => void
  refreshKey: () => void
  orderParams: OrderInput
  updateOrderParams: (orderInp: OrderInput) => void
  updateContact: (contact: Contact) => void
  licensePlan: PlanType
  updatePlanType: (plan: PlanType) => void
}

export const CreateLicensePacket: React.FC<Props> = ({
  changePacketInput,
  packetInput,
  defaultPacketName,
  updatePacketName,
  orderParams,
  updateOrderParams,
  updateContact,
  refreshKey,
  licensePlan,
  updatePlanType,
}: Props) => {
  const { t } = useTranslation('common')
  const [editPacketName, setEditPacketName] = useState<boolean>(false)

  return (
    <>
      <Grid item container spacing={3} justify="center" direction="column">
        <Grid item md={12} lg={12}>
          <TextField
            value={packetInput.licenseKey}
            label={t('licenses.licenseKey')}
            variant="filled"
            required
            fullWidth
            //helperText={!licKey && <>Generate New license key</>}
            disabled={!!packetInput.licenseKey}
            onChange={({ target }) => changePacketInput({ ...packetInput, licenseKey: target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    title={t('actions.generate')}
                    disabled={!!packetInput.licenseKey}
                    onClick={() => refreshKey()}
                  >
                    <RefreshIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <CloudOrder orderParams={orderParams} updateOrderParams={updateOrderParams} updateContact={updateContact} />
        <Grid item md={12} lg={12}>
          <TextField
            value={defaultPacketName}
            label={t('labels.PacketName')}
            variant="outlined"
            required
            fullWidth
            placeholder={'cloud License packet'}
            disabled={!editPacketName}
            //helperText={!licKey && <>Generate New license key</>}
            //disabled={true}
            onChange={({ target }) => updatePacketName(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button title={t('actions.edit')} onClick={() => setEditPacketName(!editPacketName)}>
                    <EditOutlinedIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container justify="flex-start" alignItems="center" alignContent="flex-start" spacing={1}>
        <Grid item xs={4}>
          <Typography>{t('licenses.nMonths')}</Typography>
          <Grid item xs={8} alignItems="center" container>
            <Grid item xs={12}>
              <FormLabel component="legend">Lizenz Plan Type</FormLabel>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={licensePlan}
                onChange={({ target: { value } }) => updatePlanType(value as PlanType)}
              >
                <FormControlLabel
                  value={'yearly'}
                  control={<Radio color="primary" />}
                  label={t('labels.yearly')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={'monthly'}
                  control={<Radio color="primary" />}
                  label={t('labels.monthly')}
                  labelPlacement="end"
                />

                {/* <FormControlLabel
              value={'custom'}
              control={<Radio color="primary" />}
              label={t('labels.customDate')}
              labelPlacement="end"
            /> */}
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <TextField
            value={packetInput.packetConfig.licIntMinutes ?? 0}
            variant="outlined"
            fullWidth
            required
            size="small"
            select
            //disabled={!!cldInput.config.expirationDate}
            onChange={({ target }) =>
              changePacketInput({
                ...packetInput,
                packetConfig: {
                  ...packetInput.packetConfig,
                  licIntMinutes: (target.value as unknown) as number,
                },
              })
            }
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map((nValue) => (
              <MenuItem key={nValue} value={nValue}>
                {nValue}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <Typography>{licensePlan === 'monthly' ? t('labels.nMonths') : t('labels.nYears')}</Typography>
        </Grid>
      </Grid>
    </>
  )
}
