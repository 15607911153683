import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import Select from '@material-ui/core/Select'
import { Checkbox, FormControl, Input, ListItemText, MenuItem } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type DropdownProps = {
  valueSelected: (value: string[]) => void
  values: string[]
  label: string
  multiple?: boolean
  preselect?: string[]
}

export const Dropdown: FC<DropdownProps> = ({ valueSelected, values, preselect, multiple, label }): ReactElement => {
  const [selectedValues, setSelectedValues] = useState<string[]>([])
  const preselectRef = useRef('unset')
  const { t } = useTranslation('common')

  const valueWasSelected = useCallback(
    (val: string[]) => {
      valueSelected(val)
      setSelectedValues(val)
    },
    [setSelectedValues, valueSelected],
  )

  useEffect(() => {
    if (preselect && (preselectRef.current === 'unset' || preselectRef.current !== preselect.join(','))) {
      preselectRef.current = preselect.join(',')
      valueWasSelected(preselect)
    }
  }, [preselect, valueWasSelected])

  return (
    <>
      <FormControl fullWidth={true}>
        <InputLabel>{t(label)}</InputLabel>
        <Select
          multiple={multiple}
          displayEmpty={true}
          input={<Input fullWidth={true} />}
          fullWidth={true}
          renderValue={
            multiple && selectedValues.length
              ? (selected) => (selected as string[]).join(', ')
              : (selected) => selected as string
          }
          value={multiple ? selectedValues : selectedValues.length && !multiple ? [selectedValues[0]] : ''}
          onChange={(e) => valueWasSelected((multiple ? e.target.value : [e.target.value]) as string[])}
        >
          {values.map((val, i) => (
            <MenuItem key={`${val}${i}`} value={val}>
              {multiple && <Checkbox checked={selectedValues.indexOf(val) > -1} />}
              <ListItemText primary={val} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
