import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};


export enum ActionType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export type ActivatedCloudLicense = {
  __typename?: 'ActivatedCloudLicense';
  organizationName: Scalars['String'];
  licensePackets: Array<Maybe<ActivatedLicensePacket>>;
};

export type ActivatedCloudLicenseInput = {
  organizationName: Scalars['String'];
  licensePackets?: Maybe<Array<Maybe<ActivatedLicensePacketInput>>>;
};

export type ActivatedLicense = {
  __typename?: 'ActivatedLicense';
  id: Scalars['String'];
  reserved?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ActivatedLicenseGroup = {
  __typename?: 'ActivatedLicenseGroup';
  id: Scalars['String'];
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  licenseTypes: Array<Maybe<ActivatedLicenseType>>;
};

export type ActivatedLicenseGroupInput = {
  id: Scalars['String'];
  licenseTypes?: Maybe<Array<Maybe<ActivatedLicenseTypeInput>>>;
};

export type ActivatedLicenseInput = {
  id: Scalars['String'];
};

export type ActivatedLicensePacket = {
  __typename?: 'ActivatedLicensePacket';
  id: Scalars['String'];
  packetName: Scalars['String'];
  expirationDate: Scalars['String'];
  licenseGroups: Array<Maybe<ActivatedLicenseGroup>>;
};

export type ActivatedLicensePacketInfo = {
  __typename?: 'ActivatedLicensePacketInfo';
  licensePacketActivatedAt: Scalars['String'];
  licensePacketActivator: Scalars['String'];
  expirationDate: Scalars['String'];
};

export type ActivatedLicensePacketInput = {
  id: Scalars['String'];
  licenseGroups?: Maybe<Array<Maybe<ActivatedLicenseGroupInput>>>;
};

export type ActivatedLicenseType = {
  __typename?: 'ActivatedLicenseType';
  id: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  licenses: Array<Maybe<ActivatedLicense>>;
};

export type ActivatedLicenseTypeInput = {
  id: Scalars['String'];
  licenses?: Maybe<Array<Maybe<ActivatedLicenseInput>>>;
};

export type ActivatedOrganizationLicense = {
  __typename?: 'ActivatedOrganizationLicense';
  id: Scalars['String'];
  organizationLicenseId: Scalars['String'];
  organizationName: Scalars['String'];
  cloudLicenseType: Scalars['String'];
  activationDate: Scalars['String'];
  expirationDate: Scalars['String'];
  licensePackets: Array<Maybe<ActivatedLicensePacket>>;
};

export type ActivatedOrganizationLicenseInfo = {
  __typename?: 'ActivatedOrganizationLicenseInfo';
  organizationName: Scalars['String'];
  organizationActivatedAt: Scalars['String'];
  organizationActivator: Scalars['String'];
  expirationDate: Scalars['String'];
};

export type AdminLicenseInput = {
  groupname: Scalars['String'];
  licenseType: Scalars['String'];
};

export type AppResourceRights = {
  __typename?: 'AppResourceRights';
  appResourceId: Scalars['String'];
  scopes: Array<Maybe<Scalars['String']>>;
};

export type AssignableLicenseInput = {
  licenseGroupID?: Maybe<Scalars['String']>;
  licenseTypeID?: Maybe<Scalars['String']>;
  licensePacketID?: Maybe<Scalars['String']>;
  licenseID?: Maybe<Scalars['String']>;
};

export type AssignedCloudLicenses = {
  __typename?: 'AssignedCloudLicenses';
  licensePoolID: Scalars['String'];
  poolName: Scalars['String'];
  poolSize: Scalars['Int'];
  numberOfLicenses: Scalars['Int'];
};

export type AssignedLicensePackets = {
  __typename?: 'AssignedLicensePackets';
  id: Scalars['String'];
  packetName: Scalars['String'];
  packetType: Scalars['String'];
  activationStatus: Scalars['Boolean'];
  packetKey: Scalars['String'];
  creationDate: Scalars['String'];
  activatedBy?: Maybe<Scalars['String']>;
};

export type CloudActionJournal = {
  __typename?: 'CloudActionJournal';
  id: Scalars['ID'];
  action_user: Scalars['String'];
  license_name?: Maybe<Scalars['String']>;
  license_pool_name?: Maybe<Scalars['String']>;
  license_type_name?: Maybe<Scalars['String']>;
  action_type: ActionType;
  date: Scalars['String'];
};

export type CloudCompaniesInfo = {
  __typename?: 'CloudCompaniesInfo';
  cloudLicenseID: Scalars['String'];
  expirationDate: Scalars['String'];
  realmId: Scalars['String'];
  realmName: Scalars['String'];
  licenseKey: Scalars['String'];
};

export type CloudDashboard = {
  __typename?: 'CloudDashboard';
  nLicensePools: Scalars['Int'];
  nLicenses: Scalars['Int'];
  nLicenseTypes: Scalars['Int'];
  nTrialLicenses: Scalars['Int'];
  lastTrialCloudLicenseDate?: Maybe<Scalars['String']>;
  lastCloudLicenseCreationDate?: Maybe<Scalars['String']>;
  lastLicensePoolCreationDate?: Maybe<Scalars['String']>;
  lastLicenseTypeName?: Maybe<Scalars['String']>;
  nUsers: Scalars['Int'];
};

export type CloudLicense = {
  __typename?: 'CloudLicense';
  id: Scalars['String'];
  licenseKey: Scalars['String'];
  editorId: Scalars['String'];
  organizationName: Scalars['String'];
  cloudLicenseType: Scalars['String'];
  dateModified: Scalars['String'];
  dateActivated?: Maybe<Scalars['String']>;
  expirationDate: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order_id: Scalars['String'];
  activatedBy?: Maybe<Scalars['String']>;
  licenseGroups: Array<Maybe<CloudLicenseGroup>>;
  assignedPackets?: Maybe<Array<Maybe<AssignedLicensePackets>>>;
};

export type CloudLicenseConfigInput = {
  expirationDate?: Maybe<Scalars['String']>;
  licIntMinutes?: Maybe<Scalars['Int']>;
};

export type CloudLicenseDetails = {
  __typename?: 'CloudLicenseDetails';
  id: Scalars['String'];
  licenseKey: Scalars['String'];
  editorId: Scalars['String'];
  realmId?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  cloudLicenseType: Scalars['String'];
  dateCreated?: Maybe<Scalars['String']>;
  dateModified: Scalars['String'];
  dateActivated?: Maybe<Scalars['String']>;
  expirationDate: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order_id: Scalars['String'];
  activatedBy?: Maybe<Scalars['String']>;
  licenseIntMinutes: Scalars['Int'];
  cloudLicensePackets?: Maybe<Array<Maybe<CloudLicensePacketDetails>>>;
  detailLog?: Maybe<Array<Maybe<CloudLicenseLog>>>;
};

export type CloudLicenseGroup = {
  __typename?: 'CloudLicenseGroup';
  id: Scalars['String'];
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  licenseTypes: Array<Maybe<CloudLicenseType>>;
};

export type CloudLicenseInfo = {
  __typename?: 'CloudLicenseInfo';
  cloudLicenseInfo: CloudLicense;
  journal?: Maybe<Array<Maybe<CloudActionJournal>>>;
};

export type CloudLicenseLog = {
  __typename?: 'CloudLicenseLog';
  id: Scalars['String'];
  licenseKey: Scalars['String'];
  editorId: Scalars['String'];
  actionType: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  licenseIntMinutes: Scalars['Int'];
  dateCreated: Scalars['String'];
  dateModified: Scalars['String'];
};

export type CloudLicensePacket = {
  __typename?: 'CloudLicensePacket';
  packetId: Scalars['String'];
  packetName: Scalars['String'];
  numberOfLicenses: Scalars['Int'];
  assignedLicenses: Scalars['Int'];
  reservedLicenses: Array<Maybe<Scalars['String']>>;
};

export type CloudLicensePacketDetails = {
  __typename?: 'CloudLicensePacketDetails';
  id: Scalars['ID'];
  orderID: Scalars['String'];
  packetName: Scalars['String'];
  packetType: Scalars['String'];
  packetLicenseKey: Scalars['String'];
  activationStatus: Scalars['Boolean'];
  creationDate: Scalars['String'];
  activatedBy?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  licenseIntMinutes?: Maybe<Scalars['Int']>;
  cloudlicensePacketGroups: Array<Maybe<CloudLicensePacketGroup>>;
  packetLog?: Maybe<Array<Maybe<PacketHistory>>>;
};

export type CloudLicensePacketGroup = {
  __typename?: 'CloudLicensePacketGroup';
  groupId: Scalars['ID'];
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  licenseGroupTypeDetails: Array<Maybe<LicenseGroupTypeDetails>>;
};

export type CloudLicensePoolInput = {
  licensePoolID: Scalars['ID'];
  numberOfLicenses: Scalars['Int'];
};

export type CloudLicenseServiceVersionInformation = {
  __typename?: 'CloudLicenseServiceVersionInformation';
  /** version of user cloud license service */
  version: Scalars['String'];
  /** version int git + commit (git describe) */
  versionGit: Scalars['String'];
  /** timestamp of the iam build */
  buildTimestamp: Scalars['String'];
};

export type CloudLicenseType = {
  __typename?: 'CloudLicenseType';
  id: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  rights: Array<Maybe<LicenseGroupRight>>;
  numberOfLicenses: Scalars['Int'];
  assignedLicenses: Scalars['Int'];
  numberOfReservedLicenses: Scalars['Int'];
  licensePackets: Array<Maybe<CloudLicensePacket>>;
};

export type CloudLicensesActivationsPage = {
  __typename?: 'CloudLicensesActivationsPage';
  cloudLicensesActivations: Array<Maybe<CloudLicenseDetails>>;
  totalSize: Scalars['Int'];
};

export type CloudLicensesListPage = {
  __typename?: 'CloudLicensesListPage';
  cloudLicensesList: Array<Maybe<CloudLicenseDetails>>;
  totalSize: Scalars['Int'];
};

export type CloudLicensesPage = {
  __typename?: 'CloudLicensesPage';
  cloudLicenses: Array<Maybe<CloudLicense>>;
  totalSize: Scalars['Int'];
};

export type CloudOrganizationLicense = {
  __typename?: 'CloudOrganizationLicense';
  id: Scalars['String'];
  organizationLicenseId: Scalars['String'];
  organizationName: Scalars['String'];
  cloudLicenseType: Scalars['String'];
  activationDate: Scalars['String'];
  expirationDate: Scalars['String'];
  licenseGroups: Array<Maybe<CloudLicenseGroup>>;
};

export type ContactLicenseInfo = {
  __typename?: 'ContactLicenseInfo';
  ID: Scalars['String'];
  orderID: Scalars['String'];
  dateCreated: Scalars['String'];
  dateUpdated: Scalars['String'];
  licensekey: Scalars['String'];
  licensePacketType: Scalars['String'];
  licensePacketName: Scalars['String'];
};

export type EndUserLicenseActivation = {
  __typename?: 'EndUserLicenseActivation';
  id: Scalars['ID'];
  license_key: Scalars['String'];
  date: Scalars['Int'];
  types: Array<Scalars['String']>;
};

export type License = {
  __typename?: 'License';
  id: Scalars['ID'];
  license_key: Scalars['String'];
  date_modified: Scalars['String'];
  license_pool_id: Scalars['ID'];
  cloud_license_id: Scalars['ID'];
  order_id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  activation_maximum?: Maybe<Scalars['Int']>;
  nActivations: Scalars['Int'];
  editor_id: Scalars['String'];
};

export type LicenseFilterUpdater = {
  license_pool_id?: Maybe<Scalars['ID']>;
  cloud_license_id?: Maybe<Scalars['ID']>;
  license_key?: Maybe<Scalars['String']>;
};

export type LicenseGroup = {
  __typename?: 'LicenseGroup';
  id: Scalars['String'];
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  groupRights: Array<Maybe<LicenseGroupRight>>;
};

export type LicenseGroupInput = {
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  groupRights: Array<Maybe<LicenseGroupRightInput>>;
};

export type LicenseGroupRight = {
  __typename?: 'LicenseGroupRight';
  rightName: Scalars['String'];
  scopes: Array<Maybe<Scalars['String']>>;
};

export type LicenseGroupRightInput = {
  rightName: Scalars['String'];
  scopes: Array<Maybe<Scalars['String']>>;
};

export type LicenseGroupTypeDetails = {
  __typename?: 'LicenseGroupTypeDetails';
  id: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  numberOfLicenses: Scalars['Int'];
  assignedLicenses: Scalars['Int'];
  licenseUsers?: Maybe<Array<Maybe<ActivatedLicense>>>;
  numberOfReservedLicenses: Scalars['Int'];
  typeLogs?: Maybe<Array<Maybe<LicenseTypeHistory>>>;
};

export type LicenseInstance = {
  __typename?: 'LicenseInstance';
  id: Scalars['ID'];
  license_instance_key: Scalars['String'];
  user_id: Scalars['ID'];
  date_modified: Scalars['String'];
  system_identifiers?: Maybe<Scalars['String']>;
};

export type LicenseInstancePage = {
  __typename?: 'LicenseInstancePage';
  instances: Array<LicenseInstance>;
  total: Scalars['Int'];
};

export type LicenseMetadata = {
  hostname: Scalars['String'];
  uuid: Scalars['String'];
};

export type LicensePacketInfo = {
  __typename?: 'LicensePacketInfo';
  orderId: Scalars['String'];
  creationDate: Scalars['String'];
  activatedOrganizationInfo?: Maybe<ActivatedOrganizationLicenseInfo>;
  activatedLicensePacketInfo?: Maybe<ActivatedLicensePacketInfo>;
  licensePacket: ActivatedLicensePacket;
  assignedGroupLicences?: Maybe<Array<Maybe<CloudLicensePacketGroup>>>;
};

export type LicensePage = {
  __typename?: 'LicensePage';
  licenses: Array<License>;
  total: Scalars['Int'];
};

export type LicensePool = {
  __typename?: 'LicensePool';
  id: Scalars['ID'];
  poolName: Scalars['String'];
  editor_id: Scalars['ID'];
  license_type: LicenseType;
  order_id: Scalars['ID'];
  date_modified: Scalars['String'];
  date_Created: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
  n_in_use: Scalars['Int'];
  group_id: Scalars['ID'];
};

export type LicensePoolFilter = {
  type_key?: Maybe<Scalars['String']>;
};

export type LicensePoolIdInput = {
  poolName: Scalars['String'];
  group_id: Scalars['ID'];
  license_type_id: Scalars['ID'];
  expiration_date: Scalars['String'];
  order_id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
};

export type LicensePoolInput = {
  poolName: Scalars['String'];
  groupname: Scalars['String'];
  type_key: Scalars['String'];
  expiration_date: Scalars['String'];
  order_id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
};

export type LicenseType = {
  __typename?: 'LicenseType';
  id: Scalars['ID'];
  groupId: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  activationMaximum: Scalars['Int'];
  date_Created: Scalars['String'];
};

export type LicenseTypeCountParams = {
  licensePacketLicenseTypeId: Scalars['String'];
  newNumberOfLicenses: Scalars['Int'];
  licensesToDelete?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LicenseTypeHistory = {
  __typename?: 'LicenseTypeHistory';
  typeId: Scalars['String'];
  typeName: Scalars['String'];
  numberOfLicenses: Scalars['Int'];
  dateCreated: Scalars['String'];
  dateModified: Scalars['String'];
  modifiedBy: Scalars['String'];
  actionType: Scalars['String'];
};

export type LicenseTypeInput = {
  groupId: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  activationMaximum: Scalars['Int'];
  groupRights: Array<Maybe<LicenseGroupRightInput>>;
};

export type LicenseTypePage = {
  __typename?: 'LicenseTypePage';
  licenseTypes: Array<LicenseType>;
  total: Scalars['Int'];
};

export type LicenseTypeRights = {
  __typename?: 'LicenseTypeRights';
  licenseTypeId: Scalars['String'];
  appResourceRights?: Maybe<Array<Maybe<AppResourceRights>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createLicenseType: Scalars['ID'];
  updateLicenseType: Scalars['Boolean'];
  deleteLicenseType: Scalars['Boolean'];
  createCloudLicense: Scalars['String'];
  assignLicensesById?: Maybe<Array<Maybe<SingleUserCloudLicense>>>;
  createLicensePacket: Scalars['String'];
  updatePacketName: Scalars['Boolean'];
  activateCloudLicense?: Maybe<ActivatedCloudLicense>;
  updateCloudLicense?: Maybe<ActivatedCloudLicense>;
  updateCloudLicenseExpirationDate: Scalars['Boolean'];
  updateLicensePacketExpirationDate: Scalars['Boolean'];
  deactivateCloudLicense: Scalars['Boolean'];
  activateLicensePacket?: Maybe<ActivatedLicensePacket>;
  deactivateLicensePacket: Scalars['Boolean'];
  assignLicenses?: Maybe<Array<Maybe<SingleUserCloudLicense>>>;
  transferLicenses?: Maybe<Array<Maybe<SingleUserCloudLicense>>>;
  freeLicenses: Scalars['Boolean'];
  reserveLicense: Scalars['Boolean'];
  changeLicenseNumber: Scalars['Boolean'];
  changeLicenseNumberPerOrder: Scalars['Boolean'];
  changeLicenseTypeCountPerOrder: Scalars['Boolean'];
  deleteCloudLicenseFull: Scalars['Boolean'];
  clearCloudLicenseInstanceLogs: Scalars['Boolean'];
  registerLicenseGroup: Scalars['ID'];
  createLicensePoolID: Scalars['ID'];
  createLicensePool: Scalars['ID'];
  updateLicensePoolID: Scalars['Boolean'];
  updateLicensePool: Scalars['Boolean'];
  deleteLicensePool: Scalars['Boolean'];
  initTrialLicenseConfig: Scalars['Boolean'];
  createTrialLicense?: Maybe<Scalars['String']>;
  clearTrialLicenseLogs: Scalars['Boolean'];
};


export type MutationCreateLicenseTypeArgs = {
  licenseType: LicenseTypeInput;
};


export type MutationUpdateLicenseTypeArgs = {
  id: Scalars['ID'];
  licenseType: LicenseTypeInput;
};


export type MutationDeleteLicenseTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCloudLicenseArgs = {
  licenseKey: Scalars['String'];
  orderId: Scalars['String'];
  organizationName: Scalars['String'];
  licenses: Array<Maybe<CloudLicensePoolInput>>;
  adminLicenses: Array<Maybe<AdminLicenseInput>>;
  config: CloudLicenseConfigInput;
};


export type MutationAssignLicensesByIdArgs = {
  realmName: Scalars['String'];
  userId: Scalars['String'];
  licenses: Array<Maybe<SingleUserCloudLicenseIdInput>>;
  assignableLicenses?: Maybe<ActivatedCloudLicenseInput>;
};


export type MutationCreateLicensePacketArgs = {
  licenseKey: Scalars['String'];
  orderId: Scalars['String'];
  cloudLicenseId: Scalars['String'];
  licenses: Array<Maybe<CloudLicensePoolInput>>;
  packetConfig: CloudLicenseConfigInput;
  licensePacketName: Scalars['String'];
  licensePacketType: Scalars['String'];
};


export type MutationUpdatePacketNameArgs = {
  realmId: Scalars['ID'];
  packetId: Scalars['ID'];
  newPacketName: Scalars['String'];
};


export type MutationActivateCloudLicenseArgs = {
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateCloudLicenseArgs = {
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
  adminId: Scalars['String'];
};


export type MutationUpdateCloudLicenseExpirationDateArgs = {
  cloudLicenseId: Scalars['ID'];
  config: CloudLicenseConfigInput;
};


export type MutationUpdateLicensePacketExpirationDateArgs = {
  cloudLicenseId: Scalars['ID'];
  packetID: Scalars['ID'];
  orderID?: Maybe<Scalars['String']>;
  config: CloudLicenseConfigInput;
};


export type MutationDeactivateCloudLicenseArgs = {
  licenseKey: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationActivateLicensePacketArgs = {
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  reserve?: Maybe<Scalars['Boolean']>;
};


export type MutationDeactivateLicensePacketArgs = {
  licenseKey: Scalars['String'];
};


export type MutationAssignLicensesArgs = {
  realmName: Scalars['String'];
  userId: Scalars['String'];
  licenses: Array<Maybe<SingleUserCloudLicenseInput>>;
  assignableLicenses?: Maybe<ActivatedCloudLicenseInput>;
};


export type MutationTransferLicensesArgs = {
  realmName: Scalars['String'];
  userId1: Scalars['String'];
  userId2: Scalars['String'];
  keepLics: Scalars['Boolean'];
};


export type MutationFreeLicensesArgs = {
  realmName: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationReserveLicenseArgs = {
  licenseId: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationChangeLicenseNumberArgs = {
  cloudLicenseId: Scalars['String'];
  licensePacketId: Scalars['String'];
  licensePacketLicenseTypeId: Scalars['String'];
  newNumberOfLicenses: Scalars['Int'];
  licensesToDelete?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationChangeLicenseNumberPerOrderArgs = {
  cloudLicenseId: Scalars['String'];
  licensePacketId: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  licensePacketLicenseTypeId: Scalars['String'];
  newNumberOfLicenses: Scalars['Int'];
  licensesToDelete?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationChangeLicenseTypeCountPerOrderArgs = {
  cloudLicenseId: Scalars['String'];
  licensePacketId: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  licenseTypeCount: Array<LicenseTypeCountParams>;
};


export type MutationDeleteCloudLicenseFullArgs = {
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
};


export type MutationClearCloudLicenseInstanceLogsArgs = {
  cloudLicenseId: Scalars['String'];
};


export type MutationRegisterLicenseGroupArgs = {
  licenseGroup?: Maybe<LicenseGroupInput>;
};


export type MutationCreateLicensePoolIdArgs = {
  licensePool: LicensePoolIdInput;
};


export type MutationCreateLicensePoolArgs = {
  licensePool: LicensePoolInput;
};


export type MutationUpdateLicensePoolIdArgs = {
  id: Scalars['ID'];
  licensePool: LicensePoolIdInput;
};


export type MutationUpdateLicensePoolArgs = {
  id: Scalars['ID'];
  licensePool: LicensePoolInput;
};


export type MutationDeleteLicensePoolArgs = {
  id: Scalars['ID'];
};


export type MutationInitTrialLicenseConfigArgs = {
  licenses: Array<Maybe<CloudLicensePoolInput>>;
  adminLicenses: Array<Maybe<SingleUserCloudLicenseInput>>;
  trialDays: Scalars['Int'];
};


export type MutationClearTrialLicenseLogsArgs = {
  cloudLicenseId: Scalars['String'];
};

export type OrganizationUserCloudLicense = {
  __typename?: 'OrganizationUserCloudLicense';
  cloudLicenseType: Scalars['String'];
  expirationDate: Scalars['String'];
  licenses: Array<Maybe<SingleUserCloudLicense>>;
};

export type PacketHistory = {
  __typename?: 'PacketHistory';
  logId: Scalars['String'];
  actionType: Scalars['String'];
  licenseKey: Scalars['String'];
  dateModified: Scalars['String'];
  modifiedBy: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  packetName: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  licenseIntMinutes?: Maybe<Scalars['Int']>;
  orderID?: Maybe<Scalars['String']>;
};

export type PaginationParams = {
  limit?: Maybe<Scalars['Int']>;
  Offset?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  licenseTypes: LicenseTypePage;
  licenseType?: Maybe<LicenseType>;
  getAllLicenseTypes: Array<Maybe<LicenseType>>;
  getLicenseGroupLicenseTypeRights: Array<Maybe<LicenseTypeRights>>;
  cloudCompanies: Array<Maybe<CloudCompaniesInfo>>;
  getclServiceVersion: CloudLicenseServiceVersionInformation;
  generateKey: Scalars['String'];
  getActivatedCloudLicensePackets: ActivatedCloudLicense;
  getListCloudLicenses: CloudLicensesPage;
  getListActivatedCloudLicenses: CloudLicensesPage;
  getCloudLicenseById: CloudLicenseInfo;
  getCloudLicenseDetails: CloudLicenseDetails;
  getCloudLicenseDetailsByKey: CloudLicenseDetails;
  getCloudLicenseDetailsByOrderID: Array<Maybe<CloudLicenseDetails>>;
  getCloudLicense: CloudOrganizationLicense;
  getAssignedLicenses: OrganizationUserCloudLicense;
  getLicensePacketInformation?: Maybe<LicensePacketInfo>;
  getCloudLicensesList: CloudLicensesListPage;
  getActivatedCloudLicenses: CloudLicensesActivationsPage;
  getContactLicenseInfo: Array<Maybe<ContactLicenseInfo>>;
  dashboardInfo: CloudDashboard;
  getAllLicenseGroups: Array<Maybe<LicenseGroup>>;
  getLicenseGroupByName?: Maybe<LicenseGroup>;
  getLicensePoolByName: LicensePool;
  licensePools: Array<Maybe<LicensePool>>;
  licensePool: LicensePool;
  hasFreeTrialLicense: Scalars['Boolean'];
};


export type QueryLicenseTypesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryLicenseTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetLicenseGroupLicenseTypeRightsArgs = {
  licenseGroupId: Scalars['String'];
};


export type QueryGenerateKeyArgs = {
  cloudLicenseId?: Maybe<Scalars['String']>;
};


export type QueryGetActivatedCloudLicensePacketsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetListCloudLicensesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryGetListActivatedCloudLicensesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryGetCloudLicenseByIdArgs = {
  ID: Scalars['ID'];
};


export type QueryGetCloudLicenseDetailsArgs = {
  ID: Scalars['ID'];
};


export type QueryGetCloudLicenseDetailsByKeyArgs = {
  licenseKey: Scalars['String'];
};


export type QueryGetCloudLicenseDetailsByOrderIdArgs = {
  orderIDs: Array<Maybe<Scalars['ID']>>;
};


export type QueryGetCloudLicenseArgs = {
  realmName: Scalars['String'];
};


export type QueryGetAssignedLicensesArgs = {
  realmName: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetLicensePacketInformationArgs = {
  licenseKey: Scalars['String'];
};


export type QueryGetCloudLicensesListArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryGetActivatedCloudLicensesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  fetchTrial: Scalars['Boolean'];
};


export type QueryGetLicenseGroupByNameArgs = {
  groupname: Scalars['String'];
};


export type QueryGetLicensePoolByNameArgs = {
  poolName: Scalars['String'];
};


export type QueryLicensePoolsArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<LicensePoolFilter>;
};


export type QueryLicensePoolArgs = {
  id: Scalars['ID'];
};

export type SimpleLicenseType = {
  __typename?: 'SimpleLicenseType';
  id: Scalars['ID'];
  type_key: Scalars['String'];
  activation_maximum?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type SingleUserCloudLicense = {
  __typename?: 'SingleUserCloudLicense';
  id: Scalars['String'];
  groupId: Scalars['String'];
  licenseTypeId: Scalars['String'];
  packetId: Scalars['String'];
  groupname: Scalars['String'];
  licenseType: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  reserved?: Maybe<Scalars['String']>;
  regularLicense?: Maybe<Scalars['String']>;
  permissions: Array<Maybe<LicenseGroupRight>>;
  expirationDate: Scalars['String'];
};

export type SingleUserCloudLicenseIdInput = {
  licenseTypeId: Scalars['String'];
  packetId: Scalars['String'];
  regularLicense?: Maybe<Scalars['String']>;
};

export type SingleUserCloudLicenseInput = {
  licenseGroupID: Scalars['String'];
  licenseTypeID: Scalars['String'];
  licensePacketID: Scalars['String'];
  regularLicense?: Maybe<Scalars['String']>;
};

export type CreateLicenseTypeMutationVariables = Exact<{
  licenseType: LicenseTypeInput;
}>;


export type CreateLicenseTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createLicenseType'>
);

export type UpdateLicenseTypeMutationVariables = Exact<{
  id: Scalars['ID'];
  licenseType: LicenseTypeInput;
}>;


export type UpdateLicenseTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLicenseType'>
);

export type DeleteLicenseTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLicenseTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLicenseType'>
);

export type RegisterLicenseGroupMutationVariables = Exact<{
  licenseGroup: LicenseGroupInput;
}>;


export type RegisterLicenseGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'registerLicenseGroup'>
);

export type CreateCloudLicenseMutationVariables = Exact<{
  licenseKey: Scalars['String'];
  orderId: Scalars['String'];
  organizationName: Scalars['String'];
  licenses: Array<Maybe<CloudLicensePoolInput>> | Maybe<CloudLicensePoolInput>;
  adminLicenses: Array<Maybe<AdminLicenseInput>> | Maybe<AdminLicenseInput>;
  config: CloudLicenseConfigInput;
}>;


export type CreateCloudLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCloudLicense'>
);

export type CreateLicensePacketMutationVariables = Exact<{
  licenseKey: Scalars['String'];
  orderId: Scalars['String'];
  cloudLicenseId: Scalars['String'];
  licenses: Array<Maybe<CloudLicensePoolInput>> | Maybe<CloudLicensePoolInput>;
  packetConfig: CloudLicenseConfigInput;
  licensePacketName: Scalars['String'];
  licensePacketType: Scalars['String'];
}>;


export type CreateLicensePacketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createLicensePacket'>
);

export type DeactivateCloudLicenseMutationVariables = Exact<{
  licenseKey: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type DeactivateCloudLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateCloudLicense'>
);

export type FreeLicensesMutationVariables = Exact<{
  realmName: Scalars['String'];
  userId: Scalars['String'];
}>;


export type FreeLicensesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'freeLicenses'>
);

export type CreateLicensePoolIdMutationVariables = Exact<{
  licensePool: LicensePoolIdInput;
}>;


export type CreateLicensePoolIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createLicensePoolID'>
);

export type CreateLicensePoolMutationVariables = Exact<{
  licensePool: LicensePoolInput;
}>;


export type CreateLicensePoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createLicensePool'>
);

export type UpdateLicensePoolIdMutationVariables = Exact<{
  id: Scalars['ID'];
  licensePool: LicensePoolIdInput;
}>;


export type UpdateLicensePoolIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLicensePoolID'>
);

export type UpdateLicensePoolMutationVariables = Exact<{
  id: Scalars['ID'];
  licensePool: LicensePoolInput;
}>;


export type UpdateLicensePoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLicensePool'>
);

export type DeleteLicensePoolMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLicensePoolMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLicensePool'>
);

export type DeleteCloudLicensePermanentlyMutationVariables = Exact<{
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
}>;


export type DeleteCloudLicensePermanentlyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCloudLicenseFull'>
);

export type DeactivateLicensePacketMutationVariables = Exact<{
  licenseKey: Scalars['String'];
}>;


export type DeactivateLicensePacketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateLicensePacket'>
);

export type ChangeLicenseNumberMutationVariables = Exact<{
  cloudLicenseId: Scalars['String'];
  licensePacketId: Scalars['String'];
  licensePacketLicenseTypeId: Scalars['String'];
  newNumberOfLicenses: Scalars['Int'];
}>;


export type ChangeLicenseNumberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeLicenseNumber'>
);

export type ExtendCloudLicenseMutationVariables = Exact<{
  cloudLicenseId: Scalars['ID'];
  config: CloudLicenseConfigInput;
}>;


export type ExtendCloudLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCloudLicenseExpirationDate'>
);

export type ExtendLicensePacketExpiryMutationVariables = Exact<{
  cloudLicenseId: Scalars['ID'];
  packetID: Scalars['ID'];
  orderID?: Maybe<Scalars['String']>;
  config: CloudLicenseConfigInput;
}>;


export type ExtendLicensePacketExpiryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLicensePacketExpirationDate'>
);

export type ChangeLicenseNumberPerOrderMutationVariables = Exact<{
  cloudLicenseId: Scalars['String'];
  licensePacketId: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  licensePacketLicenseTypeId: Scalars['String'];
  newNumberOfLicenses: Scalars['Int'];
}>;


export type ChangeLicenseNumberPerOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeLicenseNumberPerOrder'>
);

export type ChangeLicenseTypeCountPerOrderMutationVariables = Exact<{
  cloudLicenseId: Scalars['String'];
  licensePacketId: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  licenseTypeCount: Array<LicenseTypeCountParams> | LicenseTypeCountParams;
}>;


export type ChangeLicenseTypeCountPerOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeLicenseTypeCountPerOrder'>
);

export type LicenseTypesQueryVariables = Exact<{
  pageInfo?: Maybe<PaginationParams>;
}>;


export type LicenseTypesQuery = (
  { __typename?: 'Query' }
  & { licenseTypes: (
    { __typename?: 'LicenseTypePage' }
    & Pick<LicenseTypePage, 'total'>
    & { licenseTypes: Array<(
      { __typename?: 'LicenseType' }
      & Pick<LicenseType, 'id' | 'groupId' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'activationMaximum' | 'date_Created'>
    )> }
  ) }
);

export type DashboardInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardInfoQuery = (
  { __typename?: 'Query' }
  & { dashboardInfo: (
    { __typename?: 'CloudDashboard' }
    & Pick<CloudDashboard, 'nLicensePools' | 'nLicenses' | 'nLicenseTypes' | 'nTrialLicenses' | 'lastTrialCloudLicenseDate' | 'lastCloudLicenseCreationDate' | 'lastLicensePoolCreationDate' | 'lastLicenseTypeName' | 'nUsers'>
  ) }
);

export type LicenseTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LicenseTypeQuery = (
  { __typename?: 'Query' }
  & { licenseType?: Maybe<(
    { __typename?: 'LicenseType' }
    & Pick<LicenseType, 'id' | 'groupId' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'activationMaximum'>
  )> }
);

export type GetCloudLicenseQueryVariables = Exact<{
  realm: Scalars['String'];
}>;


export type GetCloudLicenseQuery = (
  { __typename?: 'Query' }
  & { getCloudLicense: (
    { __typename?: 'CloudOrganizationLicense' }
    & Pick<CloudOrganizationLicense, 'id' | 'organizationLicenseId' | 'organizationName' | 'cloudLicenseType' | 'activationDate' | 'expirationDate'>
    & { licenseGroups: Array<Maybe<(
      { __typename?: 'CloudLicenseGroup' }
      & Pick<CloudLicenseGroup, 'id' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
      & { licenseTypes: Array<Maybe<(
        { __typename?: 'CloudLicenseType' }
        & Pick<CloudLicenseType, 'id' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
        & { licensePackets: Array<Maybe<(
          { __typename?: 'CloudLicensePacket' }
          & Pick<CloudLicensePacket, 'packetId' | 'packetName' | 'numberOfLicenses' | 'assignedLicenses' | 'reservedLicenses'>
        )>> }
      )>> }
    )>> }
  ) }
);

export type GetAssignedLicensesQueryVariables = Exact<{
  realm: Scalars['String'];
  userId: Scalars['String'];
}>;


export type GetAssignedLicensesQuery = (
  { __typename?: 'Query' }
  & { getAssignedLicenses: (
    { __typename?: 'OrganizationUserCloudLicense' }
    & Pick<OrganizationUserCloudLicense, 'cloudLicenseType' | 'expirationDate'>
    & { licenses: Array<Maybe<(
      { __typename?: 'SingleUserCloudLicense' }
      & Pick<SingleUserCloudLicense, 'groupname' | 'licenseType' | 'expirationDate'>
      & { permissions: Array<Maybe<(
        { __typename?: 'LicenseGroupRight' }
        & Pick<LicenseGroupRight, 'rightName' | 'scopes'>
      )>> }
    )>> }
  ) }
);

export type GetAllLicenseGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllLicenseGroupsQuery = (
  { __typename?: 'Query' }
  & { getAllLicenseGroups: Array<Maybe<(
    { __typename?: 'LicenseGroup' }
    & Pick<LicenseGroup, 'id' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
    & { groupRights: Array<Maybe<(
      { __typename?: 'LicenseGroupRight' }
      & Pick<LicenseGroupRight, 'rightName' | 'scopes'>
    )>> }
  )>> }
);

export type GetLicenseGroupByNameQueryVariables = Exact<{
  groupname: Scalars['String'];
}>;


export type GetLicenseGroupByNameQuery = (
  { __typename?: 'Query' }
  & { getLicenseGroupByName?: Maybe<(
    { __typename?: 'LicenseGroup' }
    & Pick<LicenseGroup, 'id' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
    & { groupRights: Array<Maybe<(
      { __typename?: 'LicenseGroupRight' }
      & Pick<LicenseGroupRight, 'rightName' | 'scopes'>
    )>> }
  )> }
);

export type GetLicensePoolByNameQueryVariables = Exact<{
  poolName: Scalars['String'];
}>;


export type GetLicensePoolByNameQuery = (
  { __typename?: 'Query' }
  & { getLicensePoolByName: (
    { __typename?: 'LicensePool' }
    & Pick<LicensePool, 'id' | 'poolName' | 'editor_id' | 'order_id' | 'date_modified' | 'note' | 'pool_size' | 'n_in_use' | 'group_id'>
    & { license_type: (
      { __typename?: 'LicenseType' }
      & Pick<LicenseType, 'id' | 'groupId' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'activationMaximum'>
    ) }
  ) }
);

export type LicensePoolsQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<LicensePoolFilter>;
}>;


export type LicensePoolsQuery = (
  { __typename?: 'Query' }
  & { licensePools: Array<Maybe<(
    { __typename?: 'LicensePool' }
    & Pick<LicensePool, 'id' | 'poolName' | 'editor_id' | 'order_id' | 'date_modified' | 'date_Created' | 'note' | 'pool_size' | 'n_in_use' | 'group_id'>
    & { license_type: (
      { __typename?: 'LicenseType' }
      & Pick<LicenseType, 'id' | 'groupId' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'activationMaximum'>
    ) }
  )>> }
);

export type LicensePoolQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LicensePoolQuery = (
  { __typename?: 'Query' }
  & { licensePool: (
    { __typename?: 'LicensePool' }
    & Pick<LicensePool, 'id' | 'poolName' | 'editor_id' | 'order_id' | 'date_modified' | 'note' | 'pool_size' | 'n_in_use' | 'group_id'>
    & { license_type: (
      { __typename?: 'LicenseType' }
      & Pick<LicenseType, 'id' | 'groupId' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'activationMaximum'>
    ) }
  ) }
);

export type GenerateLicenseKeyQueryVariables = Exact<{
  cloudLicenseId?: Maybe<Scalars['String']>;
}>;


export type GenerateLicenseKeyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generateKey'>
);

export type GetListCloudLicensesQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
}>;


export type GetListCloudLicensesQuery = (
  { __typename?: 'Query' }
  & { getListCloudLicenses: (
    { __typename?: 'CloudLicensesPage' }
    & Pick<CloudLicensesPage, 'totalSize'>
    & { cloudLicenses: Array<Maybe<(
      { __typename?: 'CloudLicense' }
      & Pick<CloudLicense, 'id' | 'licenseKey' | 'editorId' | 'organizationName' | 'cloudLicenseType' | 'dateModified' | 'expirationDate' | 'note' | 'order_id'>
      & { licenseGroups: Array<Maybe<(
        { __typename?: 'CloudLicenseGroup' }
        & Pick<CloudLicenseGroup, 'id' | 'groupname'>
        & { licenseTypes: Array<Maybe<(
          { __typename?: 'CloudLicenseType' }
          & Pick<CloudLicenseType, 'id' | 'licenseType' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
          & { licensePackets: Array<Maybe<(
            { __typename?: 'CloudLicensePacket' }
            & Pick<CloudLicensePacket, 'packetId' | 'packetName' | 'numberOfLicenses' | 'assignedLicenses' | 'reservedLicenses'>
          )>> }
        )>> }
      )>> }
    )>> }
  ) }
);

export type ActivatedCloudLicensesQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
}>;


export type ActivatedCloudLicensesQuery = (
  { __typename?: 'Query' }
  & { getListActivatedCloudLicenses: (
    { __typename?: 'CloudLicensesPage' }
    & Pick<CloudLicensesPage, 'totalSize'>
    & { cloudLicenses: Array<Maybe<(
      { __typename?: 'CloudLicense' }
      & Pick<CloudLicense, 'id' | 'licenseKey' | 'editorId' | 'organizationName' | 'dateModified' | 'expirationDate' | 'dateActivated' | 'note' | 'activatedBy' | 'order_id'>
      & { licenseGroups: Array<Maybe<(
        { __typename?: 'CloudLicenseGroup' }
        & Pick<CloudLicenseGroup, 'id' | 'groupname'>
        & { licenseTypes: Array<Maybe<(
          { __typename?: 'CloudLicenseType' }
          & Pick<CloudLicenseType, 'id' | 'licenseType' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
          & { licensePackets: Array<Maybe<(
            { __typename?: 'CloudLicensePacket' }
            & Pick<CloudLicensePacket, 'packetId' | 'packetName' | 'numberOfLicenses' | 'assignedLicenses' | 'reservedLicenses'>
          )>> }
        )>> }
      )>> }
    )>> }
  ) }
);

export type GetContactLicenseInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactLicenseInfoQuery = (
  { __typename?: 'Query' }
  & { getContactLicenseInfo: Array<Maybe<(
    { __typename?: 'ContactLicenseInfo' }
    & Pick<ContactLicenseInfo, 'ID' | 'orderID' | 'dateCreated' | 'dateUpdated' | 'licensekey' | 'licensePacketType' | 'licensePacketName'>
  )>> }
);

export type ViewCloudLicenseQueryVariables = Exact<{
  ID: Scalars['ID'];
}>;


export type ViewCloudLicenseQuery = (
  { __typename?: 'Query' }
  & { getCloudLicenseById: (
    { __typename?: 'CloudLicenseInfo' }
    & { cloudLicenseInfo: (
      { __typename?: 'CloudLicense' }
      & Pick<CloudLicense, 'id' | 'licenseKey' | 'editorId' | 'organizationName' | 'cloudLicenseType' | 'dateModified' | 'dateActivated' | 'expirationDate' | 'note' | 'order_id' | 'activatedBy'>
      & { licenseGroups: Array<Maybe<(
        { __typename?: 'CloudLicenseGroup' }
        & Pick<CloudLicenseGroup, 'id' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
        & { licenseTypes: Array<Maybe<(
          { __typename?: 'CloudLicenseType' }
          & Pick<CloudLicenseType, 'id' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
          & { licensePackets: Array<Maybe<(
            { __typename?: 'CloudLicensePacket' }
            & Pick<CloudLicensePacket, 'packetId' | 'packetName' | 'numberOfLicenses' | 'assignedLicenses' | 'reservedLicenses'>
          )>> }
        )>> }
      )>>, assignedPackets?: Maybe<Array<Maybe<(
        { __typename?: 'AssignedLicensePackets' }
        & Pick<AssignedLicensePackets, 'id' | 'packetName' | 'packetType' | 'activationStatus' | 'packetKey' | 'creationDate' | 'activatedBy'>
      )>>> }
    ), journal?: Maybe<Array<Maybe<(
      { __typename?: 'CloudActionJournal' }
      & Pick<CloudActionJournal, 'id' | 'action_user' | 'license_name' | 'license_pool_name' | 'license_type_name' | 'action_type' | 'date'>
    )>>> }
  ) }
);

export type GetCloudLiceneseDetailsQueryVariables = Exact<{
  ID: Scalars['ID'];
}>;


export type GetCloudLiceneseDetailsQuery = (
  { __typename?: 'Query' }
  & { getCloudLicenseDetails: (
    { __typename?: 'CloudLicenseDetails' }
    & Pick<CloudLicenseDetails, 'id' | 'licenseKey' | 'editorId' | 'realmId' | 'organizationName' | 'cloudLicenseType' | 'dateCreated' | 'dateModified' | 'dateActivated' | 'expirationDate' | 'note' | 'order_id' | 'activatedBy' | 'licenseIntMinutes'>
    & { detailLog?: Maybe<Array<Maybe<(
      { __typename?: 'CloudLicenseLog' }
      & Pick<CloudLicenseLog, 'id' | 'licenseKey' | 'editorId' | 'actionType' | 'expirationDate' | 'licenseIntMinutes' | 'dateCreated' | 'dateModified'>
    )>>>, cloudLicensePackets?: Maybe<Array<Maybe<(
      { __typename?: 'CloudLicensePacketDetails' }
      & Pick<CloudLicensePacketDetails, 'id' | 'orderID' | 'packetName' | 'packetType' | 'packetLicenseKey' | 'activationStatus' | 'creationDate' | 'activatedBy' | 'activationDate' | 'licenseIntMinutes' | 'expirationDate'>
      & { cloudlicensePacketGroups: Array<Maybe<(
        { __typename?: 'CloudLicensePacketGroup' }
        & Pick<CloudLicensePacketGroup, 'groupId' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
        & { licenseGroupTypeDetails: Array<Maybe<(
          { __typename?: 'LicenseGroupTypeDetails' }
          & Pick<LicenseGroupTypeDetails, 'id' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
          & { licenseUsers?: Maybe<Array<Maybe<(
            { __typename?: 'ActivatedLicense' }
            & Pick<ActivatedLicense, 'id' | 'reserved' | 'userId'>
          )>>>, typeLogs?: Maybe<Array<Maybe<(
            { __typename?: 'LicenseTypeHistory' }
            & Pick<LicenseTypeHistory, 'typeId' | 'typeName' | 'numberOfLicenses' | 'dateCreated' | 'dateModified' | 'modifiedBy' | 'actionType'>
          )>>> }
        )>> }
      )>>, packetLog?: Maybe<Array<Maybe<(
        { __typename?: 'PacketHistory' }
        & Pick<PacketHistory, 'logId' | 'actionType' | 'licenseKey' | 'dateModified' | 'modifiedBy' | 'groupName' | 'packetName' | 'expirationDate' | 'licenseIntMinutes' | 'orderID'>
      )>>> }
    )>>> }
  ) }
);

export type GetCloudLicensesListQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
}>;


export type GetCloudLicensesListQuery = (
  { __typename?: 'Query' }
  & { getCloudLicensesList: (
    { __typename?: 'CloudLicensesListPage' }
    & Pick<CloudLicensesListPage, 'totalSize'>
    & { cloudLicensesList: Array<Maybe<(
      { __typename?: 'CloudLicenseDetails' }
      & Pick<CloudLicenseDetails, 'id' | 'licenseKey' | 'editorId' | 'organizationName' | 'cloudLicenseType' | 'dateCreated' | 'dateModified' | 'dateActivated' | 'expirationDate' | 'note' | 'order_id' | 'licenseIntMinutes' | 'activatedBy'>
      & { cloudLicensePackets?: Maybe<Array<Maybe<(
        { __typename?: 'CloudLicensePacketDetails' }
        & Pick<CloudLicensePacketDetails, 'id' | 'orderID' | 'packetName' | 'packetType' | 'packetLicenseKey' | 'activationStatus' | 'creationDate' | 'activatedBy' | 'activationDate' | 'expirationDate' | 'licenseIntMinutes'>
        & { cloudlicensePacketGroups: Array<Maybe<(
          { __typename?: 'CloudLicensePacketGroup' }
          & Pick<CloudLicensePacketGroup, 'groupId' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
          & { licenseGroupTypeDetails: Array<Maybe<(
            { __typename?: 'LicenseGroupTypeDetails' }
            & Pick<LicenseGroupTypeDetails, 'id' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
            & { licenseUsers?: Maybe<Array<Maybe<(
              { __typename?: 'ActivatedLicense' }
              & Pick<ActivatedLicense, 'id' | 'reserved' | 'userId'>
            )>>> }
          )>> }
        )>> }
      )>>> }
    )>> }
  ) }
);

export type GetActivatedCloudLicensesQueryVariables = Exact<{
  paginationInfo?: Maybe<PaginationParams>;
  fetchTrial: Scalars['Boolean'];
}>;


export type GetActivatedCloudLicensesQuery = (
  { __typename?: 'Query' }
  & { getActivatedCloudLicenses: (
    { __typename?: 'CloudLicensesActivationsPage' }
    & Pick<CloudLicensesActivationsPage, 'totalSize'>
    & { cloudLicensesActivations: Array<Maybe<(
      { __typename?: 'CloudLicenseDetails' }
      & Pick<CloudLicenseDetails, 'id' | 'licenseKey' | 'editorId' | 'realmId' | 'organizationName' | 'cloudLicenseType' | 'dateModified' | 'dateActivated' | 'expirationDate' | 'note' | 'order_id' | 'activatedBy'>
      & { cloudLicensePackets?: Maybe<Array<Maybe<(
        { __typename?: 'CloudLicensePacketDetails' }
        & Pick<CloudLicensePacketDetails, 'id' | 'orderID' | 'packetName' | 'packetType' | 'packetLicenseKey' | 'activationStatus' | 'creationDate' | 'activatedBy' | 'activationDate' | 'expirationDate' | 'licenseIntMinutes'>
        & { cloudlicensePacketGroups: Array<Maybe<(
          { __typename?: 'CloudLicensePacketGroup' }
          & Pick<CloudLicensePacketGroup, 'groupId' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
          & { licenseGroupTypeDetails: Array<Maybe<(
            { __typename?: 'LicenseGroupTypeDetails' }
            & Pick<LicenseGroupTypeDetails, 'id' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
            & { licenseUsers?: Maybe<Array<Maybe<(
              { __typename?: 'ActivatedLicense' }
              & Pick<ActivatedLicense, 'id' | 'reserved' | 'userId'>
            )>>> }
          )>> }
        )>> }
      )>>> }
    )>> }
  ) }
);

export type GetCloudLicenseDetailsByOrderIdQueryVariables = Exact<{
  orderIDs: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
}>;


export type GetCloudLicenseDetailsByOrderIdQuery = (
  { __typename?: 'Query' }
  & { getCloudLicenseDetailsByOrderID: Array<Maybe<(
    { __typename?: 'CloudLicenseDetails' }
    & Pick<CloudLicenseDetails, 'id' | 'licenseKey' | 'editorId' | 'realmId' | 'organizationName' | 'cloudLicenseType' | 'dateCreated' | 'dateModified' | 'dateActivated' | 'expirationDate' | 'note' | 'order_id' | 'activatedBy' | 'licenseIntMinutes'>
    & { cloudLicensePackets?: Maybe<Array<Maybe<(
      { __typename?: 'CloudLicensePacketDetails' }
      & Pick<CloudLicensePacketDetails, 'id' | 'orderID' | 'packetName' | 'packetType' | 'packetLicenseKey' | 'activationStatus' | 'creationDate' | 'activatedBy' | 'activationDate' | 'licenseIntMinutes'>
      & { cloudlicensePacketGroups: Array<Maybe<(
        { __typename?: 'CloudLicensePacketGroup' }
        & Pick<CloudLicensePacketGroup, 'groupId' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
        & { licenseGroupTypeDetails: Array<Maybe<(
          { __typename?: 'LicenseGroupTypeDetails' }
          & Pick<LicenseGroupTypeDetails, 'id' | 'licenseType' | 'displayNameKey' | 'descriptionKey' | 'numberOfLicenses' | 'assignedLicenses' | 'numberOfReservedLicenses'>
          & { licenseUsers?: Maybe<Array<Maybe<(
            { __typename?: 'ActivatedLicense' }
            & Pick<ActivatedLicense, 'id' | 'reserved' | 'userId'>
          )>>>, typeLogs?: Maybe<Array<Maybe<(
            { __typename?: 'LicenseTypeHistory' }
            & Pick<LicenseTypeHistory, 'typeId' | 'typeName' | 'numberOfLicenses' | 'dateCreated' | 'dateModified' | 'modifiedBy' | 'actionType'>
          )>>> }
        )>> }
      )>>, packetLog?: Maybe<Array<Maybe<(
        { __typename?: 'PacketHistory' }
        & Pick<PacketHistory, 'logId' | 'actionType' | 'licenseKey' | 'dateModified' | 'modifiedBy' | 'groupName' | 'packetName' | 'expirationDate' | 'licenseIntMinutes' | 'orderID'>
      )>>> }
    )>>> }
  )>> }
);

export type GetAllLicenseTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllLicenseTypesQuery = (
  { __typename?: 'Query' }
  & { getAllLicenseTypes: Array<Maybe<(
    { __typename?: 'LicenseType' }
    & Pick<LicenseType, 'id' | 'groupId' | 'licenseType'>
  )>> }
);


export const CreateLicenseTypeDocument = gql`
    mutation CreateLicenseType($licenseType: LicenseTypeInput!) {
  createLicenseType(licenseType: $licenseType)
}
    `;
export type CreateLicenseTypeMutationFn = Apollo.MutationFunction<CreateLicenseTypeMutation, CreateLicenseTypeMutationVariables>;

/**
 * __useCreateLicenseTypeMutation__
 *
 * To run a mutation, you first call `useCreateLicenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseTypeMutation, { data, loading, error }] = useCreateLicenseTypeMutation({
 *   variables: {
 *      licenseType: // value for 'licenseType'
 *   },
 * });
 */
export function useCreateLicenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicenseTypeMutation, CreateLicenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicenseTypeMutation, CreateLicenseTypeMutationVariables>(CreateLicenseTypeDocument, options);
      }
export type CreateLicenseTypeMutationHookResult = ReturnType<typeof useCreateLicenseTypeMutation>;
export type CreateLicenseTypeMutationResult = Apollo.MutationResult<CreateLicenseTypeMutation>;
export type CreateLicenseTypeMutationOptions = Apollo.BaseMutationOptions<CreateLicenseTypeMutation, CreateLicenseTypeMutationVariables>;
export const UpdateLicenseTypeDocument = gql`
    mutation UpdateLicenseType($id: ID!, $licenseType: LicenseTypeInput!) {
  updateLicenseType(id: $id, licenseType: $licenseType)
}
    `;
export type UpdateLicenseTypeMutationFn = Apollo.MutationFunction<UpdateLicenseTypeMutation, UpdateLicenseTypeMutationVariables>;

/**
 * __useUpdateLicenseTypeMutation__
 *
 * To run a mutation, you first call `useUpdateLicenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenseTypeMutation, { data, loading, error }] = useUpdateLicenseTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      licenseType: // value for 'licenseType'
 *   },
 * });
 */
export function useUpdateLicenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLicenseTypeMutation, UpdateLicenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLicenseTypeMutation, UpdateLicenseTypeMutationVariables>(UpdateLicenseTypeDocument, options);
      }
export type UpdateLicenseTypeMutationHookResult = ReturnType<typeof useUpdateLicenseTypeMutation>;
export type UpdateLicenseTypeMutationResult = Apollo.MutationResult<UpdateLicenseTypeMutation>;
export type UpdateLicenseTypeMutationOptions = Apollo.BaseMutationOptions<UpdateLicenseTypeMutation, UpdateLicenseTypeMutationVariables>;
export const DeleteLicenseTypeDocument = gql`
    mutation DeleteLicenseType($id: ID!) {
  deleteLicenseType(id: $id)
}
    `;
export type DeleteLicenseTypeMutationFn = Apollo.MutationFunction<DeleteLicenseTypeMutation, DeleteLicenseTypeMutationVariables>;

/**
 * __useDeleteLicenseTypeMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseTypeMutation, { data, loading, error }] = useDeleteLicenseTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLicenseTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseTypeMutation, DeleteLicenseTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseTypeMutation, DeleteLicenseTypeMutationVariables>(DeleteLicenseTypeDocument, options);
      }
export type DeleteLicenseTypeMutationHookResult = ReturnType<typeof useDeleteLicenseTypeMutation>;
export type DeleteLicenseTypeMutationResult = Apollo.MutationResult<DeleteLicenseTypeMutation>;
export type DeleteLicenseTypeMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseTypeMutation, DeleteLicenseTypeMutationVariables>;
export const RegisterLicenseGroupDocument = gql`
    mutation RegisterLicenseGroup($licenseGroup: LicenseGroupInput!) {
  registerLicenseGroup(licenseGroup: $licenseGroup)
}
    `;
export type RegisterLicenseGroupMutationFn = Apollo.MutationFunction<RegisterLicenseGroupMutation, RegisterLicenseGroupMutationVariables>;

/**
 * __useRegisterLicenseGroupMutation__
 *
 * To run a mutation, you first call `useRegisterLicenseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterLicenseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerLicenseGroupMutation, { data, loading, error }] = useRegisterLicenseGroupMutation({
 *   variables: {
 *      licenseGroup: // value for 'licenseGroup'
 *   },
 * });
 */
export function useRegisterLicenseGroupMutation(baseOptions?: Apollo.MutationHookOptions<RegisterLicenseGroupMutation, RegisterLicenseGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterLicenseGroupMutation, RegisterLicenseGroupMutationVariables>(RegisterLicenseGroupDocument, options);
      }
export type RegisterLicenseGroupMutationHookResult = ReturnType<typeof useRegisterLicenseGroupMutation>;
export type RegisterLicenseGroupMutationResult = Apollo.MutationResult<RegisterLicenseGroupMutation>;
export type RegisterLicenseGroupMutationOptions = Apollo.BaseMutationOptions<RegisterLicenseGroupMutation, RegisterLicenseGroupMutationVariables>;
export const CreateCloudLicenseDocument = gql`
    mutation CreateCloudLicense($licenseKey: String!, $orderId: String!, $organizationName: String!, $licenses: [CloudLicensePoolInput]!, $adminLicenses: [AdminLicenseInput]!, $config: CloudLicenseConfigInput!) {
  createCloudLicense(
    licenseKey: $licenseKey
    orderId: $orderId
    organizationName: $organizationName
    licenses: $licenses
    adminLicenses: $adminLicenses
    config: $config
  )
}
    `;
export type CreateCloudLicenseMutationFn = Apollo.MutationFunction<CreateCloudLicenseMutation, CreateCloudLicenseMutationVariables>;

/**
 * __useCreateCloudLicenseMutation__
 *
 * To run a mutation, you first call `useCreateCloudLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCloudLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCloudLicenseMutation, { data, loading, error }] = useCreateCloudLicenseMutation({
 *   variables: {
 *      licenseKey: // value for 'licenseKey'
 *      orderId: // value for 'orderId'
 *      organizationName: // value for 'organizationName'
 *      licenses: // value for 'licenses'
 *      adminLicenses: // value for 'adminLicenses'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useCreateCloudLicenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCloudLicenseMutation, CreateCloudLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCloudLicenseMutation, CreateCloudLicenseMutationVariables>(CreateCloudLicenseDocument, options);
      }
export type CreateCloudLicenseMutationHookResult = ReturnType<typeof useCreateCloudLicenseMutation>;
export type CreateCloudLicenseMutationResult = Apollo.MutationResult<CreateCloudLicenseMutation>;
export type CreateCloudLicenseMutationOptions = Apollo.BaseMutationOptions<CreateCloudLicenseMutation, CreateCloudLicenseMutationVariables>;
export const CreateLicensePacketDocument = gql`
    mutation createLicensePacket($licenseKey: String!, $orderId: String!, $cloudLicenseId: String!, $licenses: [CloudLicensePoolInput]!, $packetConfig: CloudLicenseConfigInput!, $licensePacketName: String!, $licensePacketType: String!) {
  createLicensePacket(
    licenseKey: $licenseKey
    orderId: $orderId
    cloudLicenseId: $cloudLicenseId
    licenses: $licenses
    packetConfig: $packetConfig
    licensePacketName: $licensePacketName
    licensePacketType: $licensePacketType
  )
}
    `;
export type CreateLicensePacketMutationFn = Apollo.MutationFunction<CreateLicensePacketMutation, CreateLicensePacketMutationVariables>;

/**
 * __useCreateLicensePacketMutation__
 *
 * To run a mutation, you first call `useCreateLicensePacketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicensePacketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicensePacketMutation, { data, loading, error }] = useCreateLicensePacketMutation({
 *   variables: {
 *      licenseKey: // value for 'licenseKey'
 *      orderId: // value for 'orderId'
 *      cloudLicenseId: // value for 'cloudLicenseId'
 *      licenses: // value for 'licenses'
 *      packetConfig: // value for 'packetConfig'
 *      licensePacketName: // value for 'licensePacketName'
 *      licensePacketType: // value for 'licensePacketType'
 *   },
 * });
 */
export function useCreateLicensePacketMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicensePacketMutation, CreateLicensePacketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicensePacketMutation, CreateLicensePacketMutationVariables>(CreateLicensePacketDocument, options);
      }
export type CreateLicensePacketMutationHookResult = ReturnType<typeof useCreateLicensePacketMutation>;
export type CreateLicensePacketMutationResult = Apollo.MutationResult<CreateLicensePacketMutation>;
export type CreateLicensePacketMutationOptions = Apollo.BaseMutationOptions<CreateLicensePacketMutation, CreateLicensePacketMutationVariables>;
export const DeactivateCloudLicenseDocument = gql`
    mutation DeactivateCloudLicense($licenseKey: String!, $realmName: String!) {
  deactivateCloudLicense(licenseKey: $licenseKey, realmName: $realmName)
}
    `;
export type DeactivateCloudLicenseMutationFn = Apollo.MutationFunction<DeactivateCloudLicenseMutation, DeactivateCloudLicenseMutationVariables>;

/**
 * __useDeactivateCloudLicenseMutation__
 *
 * To run a mutation, you first call `useDeactivateCloudLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCloudLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCloudLicenseMutation, { data, loading, error }] = useDeactivateCloudLicenseMutation({
 *   variables: {
 *      licenseKey: // value for 'licenseKey'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeactivateCloudLicenseMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateCloudLicenseMutation, DeactivateCloudLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateCloudLicenseMutation, DeactivateCloudLicenseMutationVariables>(DeactivateCloudLicenseDocument, options);
      }
export type DeactivateCloudLicenseMutationHookResult = ReturnType<typeof useDeactivateCloudLicenseMutation>;
export type DeactivateCloudLicenseMutationResult = Apollo.MutationResult<DeactivateCloudLicenseMutation>;
export type DeactivateCloudLicenseMutationOptions = Apollo.BaseMutationOptions<DeactivateCloudLicenseMutation, DeactivateCloudLicenseMutationVariables>;
export const FreeLicensesDocument = gql`
    mutation FreeLicenses($realmName: String!, $userId: String!) {
  freeLicenses(realmName: $realmName, userId: $userId)
}
    `;
export type FreeLicensesMutationFn = Apollo.MutationFunction<FreeLicensesMutation, FreeLicensesMutationVariables>;

/**
 * __useFreeLicensesMutation__
 *
 * To run a mutation, you first call `useFreeLicensesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFreeLicensesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [freeLicensesMutation, { data, loading, error }] = useFreeLicensesMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFreeLicensesMutation(baseOptions?: Apollo.MutationHookOptions<FreeLicensesMutation, FreeLicensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FreeLicensesMutation, FreeLicensesMutationVariables>(FreeLicensesDocument, options);
      }
export type FreeLicensesMutationHookResult = ReturnType<typeof useFreeLicensesMutation>;
export type FreeLicensesMutationResult = Apollo.MutationResult<FreeLicensesMutation>;
export type FreeLicensesMutationOptions = Apollo.BaseMutationOptions<FreeLicensesMutation, FreeLicensesMutationVariables>;
export const CreateLicensePoolIdDocument = gql`
    mutation CreateLicensePoolID($licensePool: LicensePoolIDInput!) {
  createLicensePoolID(licensePool: $licensePool)
}
    `;
export type CreateLicensePoolIdMutationFn = Apollo.MutationFunction<CreateLicensePoolIdMutation, CreateLicensePoolIdMutationVariables>;

/**
 * __useCreateLicensePoolIdMutation__
 *
 * To run a mutation, you first call `useCreateLicensePoolIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicensePoolIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicensePoolIdMutation, { data, loading, error }] = useCreateLicensePoolIdMutation({
 *   variables: {
 *      licensePool: // value for 'licensePool'
 *   },
 * });
 */
export function useCreateLicensePoolIdMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicensePoolIdMutation, CreateLicensePoolIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicensePoolIdMutation, CreateLicensePoolIdMutationVariables>(CreateLicensePoolIdDocument, options);
      }
export type CreateLicensePoolIdMutationHookResult = ReturnType<typeof useCreateLicensePoolIdMutation>;
export type CreateLicensePoolIdMutationResult = Apollo.MutationResult<CreateLicensePoolIdMutation>;
export type CreateLicensePoolIdMutationOptions = Apollo.BaseMutationOptions<CreateLicensePoolIdMutation, CreateLicensePoolIdMutationVariables>;
export const CreateLicensePoolDocument = gql`
    mutation CreateLicensePool($licensePool: LicensePoolInput!) {
  createLicensePool(licensePool: $licensePool)
}
    `;
export type CreateLicensePoolMutationFn = Apollo.MutationFunction<CreateLicensePoolMutation, CreateLicensePoolMutationVariables>;

/**
 * __useCreateLicensePoolMutation__
 *
 * To run a mutation, you first call `useCreateLicensePoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicensePoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicensePoolMutation, { data, loading, error }] = useCreateLicensePoolMutation({
 *   variables: {
 *      licensePool: // value for 'licensePool'
 *   },
 * });
 */
export function useCreateLicensePoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicensePoolMutation, CreateLicensePoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicensePoolMutation, CreateLicensePoolMutationVariables>(CreateLicensePoolDocument, options);
      }
export type CreateLicensePoolMutationHookResult = ReturnType<typeof useCreateLicensePoolMutation>;
export type CreateLicensePoolMutationResult = Apollo.MutationResult<CreateLicensePoolMutation>;
export type CreateLicensePoolMutationOptions = Apollo.BaseMutationOptions<CreateLicensePoolMutation, CreateLicensePoolMutationVariables>;
export const UpdateLicensePoolIdDocument = gql`
    mutation UpdateLicensePoolID($id: ID!, $licensePool: LicensePoolIDInput!) {
  updateLicensePoolID(id: $id, licensePool: $licensePool)
}
    `;
export type UpdateLicensePoolIdMutationFn = Apollo.MutationFunction<UpdateLicensePoolIdMutation, UpdateLicensePoolIdMutationVariables>;

/**
 * __useUpdateLicensePoolIdMutation__
 *
 * To run a mutation, you first call `useUpdateLicensePoolIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicensePoolIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicensePoolIdMutation, { data, loading, error }] = useUpdateLicensePoolIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      licensePool: // value for 'licensePool'
 *   },
 * });
 */
export function useUpdateLicensePoolIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLicensePoolIdMutation, UpdateLicensePoolIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLicensePoolIdMutation, UpdateLicensePoolIdMutationVariables>(UpdateLicensePoolIdDocument, options);
      }
export type UpdateLicensePoolIdMutationHookResult = ReturnType<typeof useUpdateLicensePoolIdMutation>;
export type UpdateLicensePoolIdMutationResult = Apollo.MutationResult<UpdateLicensePoolIdMutation>;
export type UpdateLicensePoolIdMutationOptions = Apollo.BaseMutationOptions<UpdateLicensePoolIdMutation, UpdateLicensePoolIdMutationVariables>;
export const UpdateLicensePoolDocument = gql`
    mutation UpdateLicensePool($id: ID!, $licensePool: LicensePoolInput!) {
  updateLicensePool(id: $id, licensePool: $licensePool)
}
    `;
export type UpdateLicensePoolMutationFn = Apollo.MutationFunction<UpdateLicensePoolMutation, UpdateLicensePoolMutationVariables>;

/**
 * __useUpdateLicensePoolMutation__
 *
 * To run a mutation, you first call `useUpdateLicensePoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicensePoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicensePoolMutation, { data, loading, error }] = useUpdateLicensePoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      licensePool: // value for 'licensePool'
 *   },
 * });
 */
export function useUpdateLicensePoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLicensePoolMutation, UpdateLicensePoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLicensePoolMutation, UpdateLicensePoolMutationVariables>(UpdateLicensePoolDocument, options);
      }
export type UpdateLicensePoolMutationHookResult = ReturnType<typeof useUpdateLicensePoolMutation>;
export type UpdateLicensePoolMutationResult = Apollo.MutationResult<UpdateLicensePoolMutation>;
export type UpdateLicensePoolMutationOptions = Apollo.BaseMutationOptions<UpdateLicensePoolMutation, UpdateLicensePoolMutationVariables>;
export const DeleteLicensePoolDocument = gql`
    mutation DeleteLicensePool($id: ID!) {
  deleteLicensePool(id: $id)
}
    `;
export type DeleteLicensePoolMutationFn = Apollo.MutationFunction<DeleteLicensePoolMutation, DeleteLicensePoolMutationVariables>;

/**
 * __useDeleteLicensePoolMutation__
 *
 * To run a mutation, you first call `useDeleteLicensePoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicensePoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicensePoolMutation, { data, loading, error }] = useDeleteLicensePoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLicensePoolMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicensePoolMutation, DeleteLicensePoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicensePoolMutation, DeleteLicensePoolMutationVariables>(DeleteLicensePoolDocument, options);
      }
export type DeleteLicensePoolMutationHookResult = ReturnType<typeof useDeleteLicensePoolMutation>;
export type DeleteLicensePoolMutationResult = Apollo.MutationResult<DeleteLicensePoolMutation>;
export type DeleteLicensePoolMutationOptions = Apollo.BaseMutationOptions<DeleteLicensePoolMutation, DeleteLicensePoolMutationVariables>;
export const DeleteCloudLicensePermanentlyDocument = gql`
    mutation DeleteCloudLicensePermanently($licenseKey: String!, $realmId: String!) {
  deleteCloudLicenseFull(licenseKey: $licenseKey, realmId: $realmId)
}
    `;
export type DeleteCloudLicensePermanentlyMutationFn = Apollo.MutationFunction<DeleteCloudLicensePermanentlyMutation, DeleteCloudLicensePermanentlyMutationVariables>;

/**
 * __useDeleteCloudLicensePermanentlyMutation__
 *
 * To run a mutation, you first call `useDeleteCloudLicensePermanentlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCloudLicensePermanentlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCloudLicensePermanentlyMutation, { data, loading, error }] = useDeleteCloudLicensePermanentlyMutation({
 *   variables: {
 *      licenseKey: // value for 'licenseKey'
 *      realmId: // value for 'realmId'
 *   },
 * });
 */
export function useDeleteCloudLicensePermanentlyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCloudLicensePermanentlyMutation, DeleteCloudLicensePermanentlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCloudLicensePermanentlyMutation, DeleteCloudLicensePermanentlyMutationVariables>(DeleteCloudLicensePermanentlyDocument, options);
      }
export type DeleteCloudLicensePermanentlyMutationHookResult = ReturnType<typeof useDeleteCloudLicensePermanentlyMutation>;
export type DeleteCloudLicensePermanentlyMutationResult = Apollo.MutationResult<DeleteCloudLicensePermanentlyMutation>;
export type DeleteCloudLicensePermanentlyMutationOptions = Apollo.BaseMutationOptions<DeleteCloudLicensePermanentlyMutation, DeleteCloudLicensePermanentlyMutationVariables>;
export const DeactivateLicensePacketDocument = gql`
    mutation DeactivateLicensePacket($licenseKey: String!) {
  deactivateLicensePacket(licenseKey: $licenseKey)
}
    `;
export type DeactivateLicensePacketMutationFn = Apollo.MutationFunction<DeactivateLicensePacketMutation, DeactivateLicensePacketMutationVariables>;

/**
 * __useDeactivateLicensePacketMutation__
 *
 * To run a mutation, you first call `useDeactivateLicensePacketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateLicensePacketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateLicensePacketMutation, { data, loading, error }] = useDeactivateLicensePacketMutation({
 *   variables: {
 *      licenseKey: // value for 'licenseKey'
 *   },
 * });
 */
export function useDeactivateLicensePacketMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateLicensePacketMutation, DeactivateLicensePacketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateLicensePacketMutation, DeactivateLicensePacketMutationVariables>(DeactivateLicensePacketDocument, options);
      }
export type DeactivateLicensePacketMutationHookResult = ReturnType<typeof useDeactivateLicensePacketMutation>;
export type DeactivateLicensePacketMutationResult = Apollo.MutationResult<DeactivateLicensePacketMutation>;
export type DeactivateLicensePacketMutationOptions = Apollo.BaseMutationOptions<DeactivateLicensePacketMutation, DeactivateLicensePacketMutationVariables>;
export const ChangeLicenseNumberDocument = gql`
    mutation ChangeLicenseNumber($cloudLicenseId: String!, $licensePacketId: String!, $licensePacketLicenseTypeId: String!, $newNumberOfLicenses: Int!) {
  changeLicenseNumber(
    cloudLicenseId: $cloudLicenseId
    licensePacketId: $licensePacketId
    licensePacketLicenseTypeId: $licensePacketLicenseTypeId
    newNumberOfLicenses: $newNumberOfLicenses
  )
}
    `;
export type ChangeLicenseNumberMutationFn = Apollo.MutationFunction<ChangeLicenseNumberMutation, ChangeLicenseNumberMutationVariables>;

/**
 * __useChangeLicenseNumberMutation__
 *
 * To run a mutation, you first call `useChangeLicenseNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLicenseNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLicenseNumberMutation, { data, loading, error }] = useChangeLicenseNumberMutation({
 *   variables: {
 *      cloudLicenseId: // value for 'cloudLicenseId'
 *      licensePacketId: // value for 'licensePacketId'
 *      licensePacketLicenseTypeId: // value for 'licensePacketLicenseTypeId'
 *      newNumberOfLicenses: // value for 'newNumberOfLicenses'
 *   },
 * });
 */
export function useChangeLicenseNumberMutation(baseOptions?: Apollo.MutationHookOptions<ChangeLicenseNumberMutation, ChangeLicenseNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeLicenseNumberMutation, ChangeLicenseNumberMutationVariables>(ChangeLicenseNumberDocument, options);
      }
export type ChangeLicenseNumberMutationHookResult = ReturnType<typeof useChangeLicenseNumberMutation>;
export type ChangeLicenseNumberMutationResult = Apollo.MutationResult<ChangeLicenseNumberMutation>;
export type ChangeLicenseNumberMutationOptions = Apollo.BaseMutationOptions<ChangeLicenseNumberMutation, ChangeLicenseNumberMutationVariables>;
export const ExtendCloudLicenseDocument = gql`
    mutation ExtendCloudLicense($cloudLicenseId: ID!, $config: CloudLicenseConfigInput!) {
  updateCloudLicenseExpirationDate(
    cloudLicenseId: $cloudLicenseId
    config: $config
  )
}
    `;
export type ExtendCloudLicenseMutationFn = Apollo.MutationFunction<ExtendCloudLicenseMutation, ExtendCloudLicenseMutationVariables>;

/**
 * __useExtendCloudLicenseMutation__
 *
 * To run a mutation, you first call `useExtendCloudLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendCloudLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendCloudLicenseMutation, { data, loading, error }] = useExtendCloudLicenseMutation({
 *   variables: {
 *      cloudLicenseId: // value for 'cloudLicenseId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useExtendCloudLicenseMutation(baseOptions?: Apollo.MutationHookOptions<ExtendCloudLicenseMutation, ExtendCloudLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtendCloudLicenseMutation, ExtendCloudLicenseMutationVariables>(ExtendCloudLicenseDocument, options);
      }
export type ExtendCloudLicenseMutationHookResult = ReturnType<typeof useExtendCloudLicenseMutation>;
export type ExtendCloudLicenseMutationResult = Apollo.MutationResult<ExtendCloudLicenseMutation>;
export type ExtendCloudLicenseMutationOptions = Apollo.BaseMutationOptions<ExtendCloudLicenseMutation, ExtendCloudLicenseMutationVariables>;
export const ExtendLicensePacketExpiryDocument = gql`
    mutation ExtendLicensePacketExpiry($cloudLicenseId: ID!, $packetID: ID!, $orderID: String, $config: CloudLicenseConfigInput!) {
  updateLicensePacketExpirationDate(
    cloudLicenseId: $cloudLicenseId
    packetID: $packetID
    orderID: $orderID
    config: $config
  )
}
    `;
export type ExtendLicensePacketExpiryMutationFn = Apollo.MutationFunction<ExtendLicensePacketExpiryMutation, ExtendLicensePacketExpiryMutationVariables>;

/**
 * __useExtendLicensePacketExpiryMutation__
 *
 * To run a mutation, you first call `useExtendLicensePacketExpiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendLicensePacketExpiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendLicensePacketExpiryMutation, { data, loading, error }] = useExtendLicensePacketExpiryMutation({
 *   variables: {
 *      cloudLicenseId: // value for 'cloudLicenseId'
 *      packetID: // value for 'packetID'
 *      orderID: // value for 'orderID'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useExtendLicensePacketExpiryMutation(baseOptions?: Apollo.MutationHookOptions<ExtendLicensePacketExpiryMutation, ExtendLicensePacketExpiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtendLicensePacketExpiryMutation, ExtendLicensePacketExpiryMutationVariables>(ExtendLicensePacketExpiryDocument, options);
      }
export type ExtendLicensePacketExpiryMutationHookResult = ReturnType<typeof useExtendLicensePacketExpiryMutation>;
export type ExtendLicensePacketExpiryMutationResult = Apollo.MutationResult<ExtendLicensePacketExpiryMutation>;
export type ExtendLicensePacketExpiryMutationOptions = Apollo.BaseMutationOptions<ExtendLicensePacketExpiryMutation, ExtendLicensePacketExpiryMutationVariables>;
export const ChangeLicenseNumberPerOrderDocument = gql`
    mutation ChangeLicenseNumberPerOrder($cloudLicenseId: String!, $licensePacketId: String!, $orderId: String, $licensePacketLicenseTypeId: String!, $newNumberOfLicenses: Int!) {
  changeLicenseNumberPerOrder(
    cloudLicenseId: $cloudLicenseId
    licensePacketId: $licensePacketId
    orderId: $orderId
    licensePacketLicenseTypeId: $licensePacketLicenseTypeId
    newNumberOfLicenses: $newNumberOfLicenses
  )
}
    `;
export type ChangeLicenseNumberPerOrderMutationFn = Apollo.MutationFunction<ChangeLicenseNumberPerOrderMutation, ChangeLicenseNumberPerOrderMutationVariables>;

/**
 * __useChangeLicenseNumberPerOrderMutation__
 *
 * To run a mutation, you first call `useChangeLicenseNumberPerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLicenseNumberPerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLicenseNumberPerOrderMutation, { data, loading, error }] = useChangeLicenseNumberPerOrderMutation({
 *   variables: {
 *      cloudLicenseId: // value for 'cloudLicenseId'
 *      licensePacketId: // value for 'licensePacketId'
 *      orderId: // value for 'orderId'
 *      licensePacketLicenseTypeId: // value for 'licensePacketLicenseTypeId'
 *      newNumberOfLicenses: // value for 'newNumberOfLicenses'
 *   },
 * });
 */
export function useChangeLicenseNumberPerOrderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeLicenseNumberPerOrderMutation, ChangeLicenseNumberPerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeLicenseNumberPerOrderMutation, ChangeLicenseNumberPerOrderMutationVariables>(ChangeLicenseNumberPerOrderDocument, options);
      }
export type ChangeLicenseNumberPerOrderMutationHookResult = ReturnType<typeof useChangeLicenseNumberPerOrderMutation>;
export type ChangeLicenseNumberPerOrderMutationResult = Apollo.MutationResult<ChangeLicenseNumberPerOrderMutation>;
export type ChangeLicenseNumberPerOrderMutationOptions = Apollo.BaseMutationOptions<ChangeLicenseNumberPerOrderMutation, ChangeLicenseNumberPerOrderMutationVariables>;
export const ChangeLicenseTypeCountPerOrderDocument = gql`
    mutation changeLicenseTypeCountPerOrder($cloudLicenseId: String!, $licensePacketId: String!, $orderId: String, $licenseTypeCount: [LicenseTypeCountParams!]!) {
  changeLicenseTypeCountPerOrder(
    cloudLicenseId: $cloudLicenseId
    licensePacketId: $licensePacketId
    orderId: $orderId
    licenseTypeCount: $licenseTypeCount
  )
}
    `;
export type ChangeLicenseTypeCountPerOrderMutationFn = Apollo.MutationFunction<ChangeLicenseTypeCountPerOrderMutation, ChangeLicenseTypeCountPerOrderMutationVariables>;

/**
 * __useChangeLicenseTypeCountPerOrderMutation__
 *
 * To run a mutation, you first call `useChangeLicenseTypeCountPerOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeLicenseTypeCountPerOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeLicenseTypeCountPerOrderMutation, { data, loading, error }] = useChangeLicenseTypeCountPerOrderMutation({
 *   variables: {
 *      cloudLicenseId: // value for 'cloudLicenseId'
 *      licensePacketId: // value for 'licensePacketId'
 *      orderId: // value for 'orderId'
 *      licenseTypeCount: // value for 'licenseTypeCount'
 *   },
 * });
 */
export function useChangeLicenseTypeCountPerOrderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeLicenseTypeCountPerOrderMutation, ChangeLicenseTypeCountPerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeLicenseTypeCountPerOrderMutation, ChangeLicenseTypeCountPerOrderMutationVariables>(ChangeLicenseTypeCountPerOrderDocument, options);
      }
export type ChangeLicenseTypeCountPerOrderMutationHookResult = ReturnType<typeof useChangeLicenseTypeCountPerOrderMutation>;
export type ChangeLicenseTypeCountPerOrderMutationResult = Apollo.MutationResult<ChangeLicenseTypeCountPerOrderMutation>;
export type ChangeLicenseTypeCountPerOrderMutationOptions = Apollo.BaseMutationOptions<ChangeLicenseTypeCountPerOrderMutation, ChangeLicenseTypeCountPerOrderMutationVariables>;
export const LicenseTypesDocument = gql`
    query LicenseTypes($pageInfo: PaginationParams) {
  licenseTypes(paginationInfo: $pageInfo) {
    licenseTypes {
      id
      groupId
      licenseType
      displayNameKey
      descriptionKey
      activationMaximum
      date_Created
    }
    total
  }
}
    `;

/**
 * __useLicenseTypesQuery__
 *
 * To run a query within a React component, call `useLicenseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseTypesQuery({
 *   variables: {
 *      pageInfo: // value for 'pageInfo'
 *   },
 * });
 */
export function useLicenseTypesQuery(baseOptions?: Apollo.QueryHookOptions<LicenseTypesQuery, LicenseTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseTypesQuery, LicenseTypesQueryVariables>(LicenseTypesDocument, options);
      }
export function useLicenseTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseTypesQuery, LicenseTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseTypesQuery, LicenseTypesQueryVariables>(LicenseTypesDocument, options);
        }
export type LicenseTypesQueryHookResult = ReturnType<typeof useLicenseTypesQuery>;
export type LicenseTypesLazyQueryHookResult = ReturnType<typeof useLicenseTypesLazyQuery>;
export type LicenseTypesQueryResult = Apollo.QueryResult<LicenseTypesQuery, LicenseTypesQueryVariables>;
export const DashboardInfoDocument = gql`
    query dashboardInfo {
  dashboardInfo {
    nLicensePools
    nLicenses
    nLicenseTypes
    nTrialLicenses
    lastTrialCloudLicenseDate
    lastCloudLicenseCreationDate
    lastLicensePoolCreationDate
    lastLicenseTypeName
    nUsers
  }
}
    `;

/**
 * __useDashboardInfoQuery__
 *
 * To run a query within a React component, call `useDashboardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardInfoQuery(baseOptions?: Apollo.QueryHookOptions<DashboardInfoQuery, DashboardInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardInfoQuery, DashboardInfoQueryVariables>(DashboardInfoDocument, options);
      }
export function useDashboardInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardInfoQuery, DashboardInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardInfoQuery, DashboardInfoQueryVariables>(DashboardInfoDocument, options);
        }
export type DashboardInfoQueryHookResult = ReturnType<typeof useDashboardInfoQuery>;
export type DashboardInfoLazyQueryHookResult = ReturnType<typeof useDashboardInfoLazyQuery>;
export type DashboardInfoQueryResult = Apollo.QueryResult<DashboardInfoQuery, DashboardInfoQueryVariables>;
export const LicenseTypeDocument = gql`
    query LicenseType($id: ID!) {
  licenseType(id: $id) {
    id
    groupId
    licenseType
    displayNameKey
    descriptionKey
    activationMaximum
  }
}
    `;

/**
 * __useLicenseTypeQuery__
 *
 * To run a query within a React component, call `useLicenseTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLicenseTypeQuery(baseOptions: Apollo.QueryHookOptions<LicenseTypeQuery, LicenseTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenseTypeQuery, LicenseTypeQueryVariables>(LicenseTypeDocument, options);
      }
export function useLicenseTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenseTypeQuery, LicenseTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenseTypeQuery, LicenseTypeQueryVariables>(LicenseTypeDocument, options);
        }
export type LicenseTypeQueryHookResult = ReturnType<typeof useLicenseTypeQuery>;
export type LicenseTypeLazyQueryHookResult = ReturnType<typeof useLicenseTypeLazyQuery>;
export type LicenseTypeQueryResult = Apollo.QueryResult<LicenseTypeQuery, LicenseTypeQueryVariables>;
export const GetCloudLicenseDocument = gql`
    query GetCloudLicense($realm: String!) {
  getCloudLicense(realmName: $realm) {
    id
    organizationLicenseId
    organizationName
    cloudLicenseType
    activationDate
    expirationDate
    licenseGroups {
      id
      groupname
      displayNameKey
      descriptionKey
      licenseTypes {
        id
        licenseType
        displayNameKey
        descriptionKey
        numberOfLicenses
        assignedLicenses
        numberOfReservedLicenses
        licensePackets {
          packetId
          packetName
          numberOfLicenses
          assignedLicenses
          reservedLicenses
        }
      }
    }
  }
}
    `;

/**
 * __useGetCloudLicenseQuery__
 *
 * To run a query within a React component, call `useGetCloudLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudLicenseQuery({
 *   variables: {
 *      realm: // value for 'realm'
 *   },
 * });
 */
export function useGetCloudLicenseQuery(baseOptions: Apollo.QueryHookOptions<GetCloudLicenseQuery, GetCloudLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCloudLicenseQuery, GetCloudLicenseQueryVariables>(GetCloudLicenseDocument, options);
      }
export function useGetCloudLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCloudLicenseQuery, GetCloudLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCloudLicenseQuery, GetCloudLicenseQueryVariables>(GetCloudLicenseDocument, options);
        }
export type GetCloudLicenseQueryHookResult = ReturnType<typeof useGetCloudLicenseQuery>;
export type GetCloudLicenseLazyQueryHookResult = ReturnType<typeof useGetCloudLicenseLazyQuery>;
export type GetCloudLicenseQueryResult = Apollo.QueryResult<GetCloudLicenseQuery, GetCloudLicenseQueryVariables>;
export const GetAssignedLicensesDocument = gql`
    query GetAssignedLicenses($realm: String!, $userId: String!) {
  getAssignedLicenses(realmName: $realm, userId: $userId) {
    cloudLicenseType
    expirationDate
    licenses {
      groupname
      licenseType
      permissions {
        rightName
        scopes
      }
      expirationDate
    }
  }
}
    `;

/**
 * __useGetAssignedLicensesQuery__
 *
 * To run a query within a React component, call `useGetAssignedLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedLicensesQuery({
 *   variables: {
 *      realm: // value for 'realm'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAssignedLicensesQuery(baseOptions: Apollo.QueryHookOptions<GetAssignedLicensesQuery, GetAssignedLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignedLicensesQuery, GetAssignedLicensesQueryVariables>(GetAssignedLicensesDocument, options);
      }
export function useGetAssignedLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignedLicensesQuery, GetAssignedLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignedLicensesQuery, GetAssignedLicensesQueryVariables>(GetAssignedLicensesDocument, options);
        }
export type GetAssignedLicensesQueryHookResult = ReturnType<typeof useGetAssignedLicensesQuery>;
export type GetAssignedLicensesLazyQueryHookResult = ReturnType<typeof useGetAssignedLicensesLazyQuery>;
export type GetAssignedLicensesQueryResult = Apollo.QueryResult<GetAssignedLicensesQuery, GetAssignedLicensesQueryVariables>;
export const GetAllLicenseGroupsDocument = gql`
    query GetAllLicenseGroups {
  getAllLicenseGroups {
    id
    groupname
    displayNameKey
    descriptionKey
    groupRights {
      rightName
      scopes
    }
  }
}
    `;

/**
 * __useGetAllLicenseGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllLicenseGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLicenseGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLicenseGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLicenseGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLicenseGroupsQuery, GetAllLicenseGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLicenseGroupsQuery, GetAllLicenseGroupsQueryVariables>(GetAllLicenseGroupsDocument, options);
      }
export function useGetAllLicenseGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLicenseGroupsQuery, GetAllLicenseGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLicenseGroupsQuery, GetAllLicenseGroupsQueryVariables>(GetAllLicenseGroupsDocument, options);
        }
export type GetAllLicenseGroupsQueryHookResult = ReturnType<typeof useGetAllLicenseGroupsQuery>;
export type GetAllLicenseGroupsLazyQueryHookResult = ReturnType<typeof useGetAllLicenseGroupsLazyQuery>;
export type GetAllLicenseGroupsQueryResult = Apollo.QueryResult<GetAllLicenseGroupsQuery, GetAllLicenseGroupsQueryVariables>;
export const GetLicenseGroupByNameDocument = gql`
    query getLicenseGroupByName($groupname: String!) {
  getLicenseGroupByName(groupname: $groupname) {
    id
    groupname
    displayNameKey
    descriptionKey
    groupRights {
      rightName
      scopes
    }
  }
}
    `;

/**
 * __useGetLicenseGroupByNameQuery__
 *
 * To run a query within a React component, call `useGetLicenseGroupByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicenseGroupByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicenseGroupByNameQuery({
 *   variables: {
 *      groupname: // value for 'groupname'
 *   },
 * });
 */
export function useGetLicenseGroupByNameQuery(baseOptions: Apollo.QueryHookOptions<GetLicenseGroupByNameQuery, GetLicenseGroupByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLicenseGroupByNameQuery, GetLicenseGroupByNameQueryVariables>(GetLicenseGroupByNameDocument, options);
      }
export function useGetLicenseGroupByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicenseGroupByNameQuery, GetLicenseGroupByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLicenseGroupByNameQuery, GetLicenseGroupByNameQueryVariables>(GetLicenseGroupByNameDocument, options);
        }
export type GetLicenseGroupByNameQueryHookResult = ReturnType<typeof useGetLicenseGroupByNameQuery>;
export type GetLicenseGroupByNameLazyQueryHookResult = ReturnType<typeof useGetLicenseGroupByNameLazyQuery>;
export type GetLicenseGroupByNameQueryResult = Apollo.QueryResult<GetLicenseGroupByNameQuery, GetLicenseGroupByNameQueryVariables>;
export const GetLicensePoolByNameDocument = gql`
    query GetLicensePoolByName($poolName: String!) {
  getLicensePoolByName(poolName: $poolName) {
    id
    poolName
    editor_id
    license_type {
      id
      groupId
      licenseType
      displayNameKey
      descriptionKey
      activationMaximum
    }
    order_id
    date_modified
    note
    pool_size
    n_in_use
    group_id
  }
}
    `;

/**
 * __useGetLicensePoolByNameQuery__
 *
 * To run a query within a React component, call `useGetLicensePoolByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicensePoolByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicensePoolByNameQuery({
 *   variables: {
 *      poolName: // value for 'poolName'
 *   },
 * });
 */
export function useGetLicensePoolByNameQuery(baseOptions: Apollo.QueryHookOptions<GetLicensePoolByNameQuery, GetLicensePoolByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLicensePoolByNameQuery, GetLicensePoolByNameQueryVariables>(GetLicensePoolByNameDocument, options);
      }
export function useGetLicensePoolByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicensePoolByNameQuery, GetLicensePoolByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLicensePoolByNameQuery, GetLicensePoolByNameQueryVariables>(GetLicensePoolByNameDocument, options);
        }
export type GetLicensePoolByNameQueryHookResult = ReturnType<typeof useGetLicensePoolByNameQuery>;
export type GetLicensePoolByNameLazyQueryHookResult = ReturnType<typeof useGetLicensePoolByNameLazyQuery>;
export type GetLicensePoolByNameQueryResult = Apollo.QueryResult<GetLicensePoolByNameQuery, GetLicensePoolByNameQueryVariables>;
export const LicensePoolsDocument = gql`
    query LicensePools($paginationInfo: PaginationParams, $filter: LicensePoolFilter) {
  licensePools(paginationInfo: $paginationInfo, filter: $filter) {
    id
    poolName
    editor_id
    license_type {
      id
      groupId
      licenseType
      displayNameKey
      descriptionKey
      activationMaximum
    }
    order_id
    date_modified
    date_Created
    note
    pool_size
    n_in_use
    group_id
  }
}
    `;

/**
 * __useLicensePoolsQuery__
 *
 * To run a query within a React component, call `useLicensePoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensePoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensePoolsQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLicensePoolsQuery(baseOptions?: Apollo.QueryHookOptions<LicensePoolsQuery, LicensePoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicensePoolsQuery, LicensePoolsQueryVariables>(LicensePoolsDocument, options);
      }
export function useLicensePoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicensePoolsQuery, LicensePoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicensePoolsQuery, LicensePoolsQueryVariables>(LicensePoolsDocument, options);
        }
export type LicensePoolsQueryHookResult = ReturnType<typeof useLicensePoolsQuery>;
export type LicensePoolsLazyQueryHookResult = ReturnType<typeof useLicensePoolsLazyQuery>;
export type LicensePoolsQueryResult = Apollo.QueryResult<LicensePoolsQuery, LicensePoolsQueryVariables>;
export const LicensePoolDocument = gql`
    query LicensePool($id: ID!) {
  licensePool(id: $id) {
    id
    poolName
    editor_id
    license_type {
      id
      groupId
      licenseType
      displayNameKey
      descriptionKey
      activationMaximum
    }
    order_id
    date_modified
    note
    pool_size
    n_in_use
    group_id
  }
}
    `;

/**
 * __useLicensePoolQuery__
 *
 * To run a query within a React component, call `useLicensePoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensePoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensePoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLicensePoolQuery(baseOptions: Apollo.QueryHookOptions<LicensePoolQuery, LicensePoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicensePoolQuery, LicensePoolQueryVariables>(LicensePoolDocument, options);
      }
export function useLicensePoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicensePoolQuery, LicensePoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicensePoolQuery, LicensePoolQueryVariables>(LicensePoolDocument, options);
        }
export type LicensePoolQueryHookResult = ReturnType<typeof useLicensePoolQuery>;
export type LicensePoolLazyQueryHookResult = ReturnType<typeof useLicensePoolLazyQuery>;
export type LicensePoolQueryResult = Apollo.QueryResult<LicensePoolQuery, LicensePoolQueryVariables>;
export const GenerateLicenseKeyDocument = gql`
    query GenerateLicenseKey($cloudLicenseId: String) {
  generateKey(cloudLicenseId: $cloudLicenseId)
}
    `;

/**
 * __useGenerateLicenseKeyQuery__
 *
 * To run a query within a React component, call `useGenerateLicenseKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateLicenseKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateLicenseKeyQuery({
 *   variables: {
 *      cloudLicenseId: // value for 'cloudLicenseId'
 *   },
 * });
 */
export function useGenerateLicenseKeyQuery(baseOptions?: Apollo.QueryHookOptions<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>(GenerateLicenseKeyDocument, options);
      }
export function useGenerateLicenseKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>(GenerateLicenseKeyDocument, options);
        }
export type GenerateLicenseKeyQueryHookResult = ReturnType<typeof useGenerateLicenseKeyQuery>;
export type GenerateLicenseKeyLazyQueryHookResult = ReturnType<typeof useGenerateLicenseKeyLazyQuery>;
export type GenerateLicenseKeyQueryResult = Apollo.QueryResult<GenerateLicenseKeyQuery, GenerateLicenseKeyQueryVariables>;
export const GetListCloudLicensesDocument = gql`
    query GetListCloudLicenses($paginationInfo: PaginationParams) {
  getListCloudLicenses(paginationInfo: $paginationInfo) {
    cloudLicenses {
      id
      licenseKey
      editorId
      organizationName
      cloudLicenseType
      dateModified
      expirationDate
      note
      order_id
      licenseGroups {
        id
        groupname
        licenseTypes {
          id
          licenseType
          numberOfLicenses
          assignedLicenses
          numberOfReservedLicenses
          licensePackets {
            packetId
            packetName
            numberOfLicenses
            assignedLicenses
            reservedLicenses
          }
        }
      }
    }
    totalSize
  }
}
    `;

/**
 * __useGetListCloudLicensesQuery__
 *
 * To run a query within a React component, call `useGetListCloudLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListCloudLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListCloudLicensesQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *   },
 * });
 */
export function useGetListCloudLicensesQuery(baseOptions?: Apollo.QueryHookOptions<GetListCloudLicensesQuery, GetListCloudLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListCloudLicensesQuery, GetListCloudLicensesQueryVariables>(GetListCloudLicensesDocument, options);
      }
export function useGetListCloudLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListCloudLicensesQuery, GetListCloudLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListCloudLicensesQuery, GetListCloudLicensesQueryVariables>(GetListCloudLicensesDocument, options);
        }
export type GetListCloudLicensesQueryHookResult = ReturnType<typeof useGetListCloudLicensesQuery>;
export type GetListCloudLicensesLazyQueryHookResult = ReturnType<typeof useGetListCloudLicensesLazyQuery>;
export type GetListCloudLicensesQueryResult = Apollo.QueryResult<GetListCloudLicensesQuery, GetListCloudLicensesQueryVariables>;
export const ActivatedCloudLicensesDocument = gql`
    query ActivatedCloudLicenses($paginationInfo: PaginationParams) {
  getListActivatedCloudLicenses(paginationInfo: $paginationInfo) {
    cloudLicenses {
      id
      licenseKey
      editorId
      organizationName
      dateModified
      expirationDate
      dateActivated
      note
      activatedBy
      order_id
      licenseGroups {
        id
        groupname
        licenseTypes {
          id
          licenseType
          numberOfLicenses
          assignedLicenses
          numberOfReservedLicenses
          licensePackets {
            packetId
            packetName
            numberOfLicenses
            assignedLicenses
            reservedLicenses
          }
        }
      }
    }
    totalSize
  }
}
    `;

/**
 * __useActivatedCloudLicensesQuery__
 *
 * To run a query within a React component, call `useActivatedCloudLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivatedCloudLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivatedCloudLicensesQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *   },
 * });
 */
export function useActivatedCloudLicensesQuery(baseOptions?: Apollo.QueryHookOptions<ActivatedCloudLicensesQuery, ActivatedCloudLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivatedCloudLicensesQuery, ActivatedCloudLicensesQueryVariables>(ActivatedCloudLicensesDocument, options);
      }
export function useActivatedCloudLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivatedCloudLicensesQuery, ActivatedCloudLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivatedCloudLicensesQuery, ActivatedCloudLicensesQueryVariables>(ActivatedCloudLicensesDocument, options);
        }
export type ActivatedCloudLicensesQueryHookResult = ReturnType<typeof useActivatedCloudLicensesQuery>;
export type ActivatedCloudLicensesLazyQueryHookResult = ReturnType<typeof useActivatedCloudLicensesLazyQuery>;
export type ActivatedCloudLicensesQueryResult = Apollo.QueryResult<ActivatedCloudLicensesQuery, ActivatedCloudLicensesQueryVariables>;
export const GetContactLicenseInfoDocument = gql`
    query getContactLicenseInfo {
  getContactLicenseInfo {
    ID
    orderID
    dateCreated
    dateUpdated
    licensekey
    licensePacketType
    licensePacketName
  }
}
    `;

/**
 * __useGetContactLicenseInfoQuery__
 *
 * To run a query within a React component, call `useGetContactLicenseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactLicenseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactLicenseInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactLicenseInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetContactLicenseInfoQuery, GetContactLicenseInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactLicenseInfoQuery, GetContactLicenseInfoQueryVariables>(GetContactLicenseInfoDocument, options);
      }
export function useGetContactLicenseInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactLicenseInfoQuery, GetContactLicenseInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactLicenseInfoQuery, GetContactLicenseInfoQueryVariables>(GetContactLicenseInfoDocument, options);
        }
export type GetContactLicenseInfoQueryHookResult = ReturnType<typeof useGetContactLicenseInfoQuery>;
export type GetContactLicenseInfoLazyQueryHookResult = ReturnType<typeof useGetContactLicenseInfoLazyQuery>;
export type GetContactLicenseInfoQueryResult = Apollo.QueryResult<GetContactLicenseInfoQuery, GetContactLicenseInfoQueryVariables>;
export const ViewCloudLicenseDocument = gql`
    query viewCloudLicense($ID: ID!) {
  getCloudLicenseById(ID: $ID) {
    cloudLicenseInfo {
      id
      licenseKey
      editorId
      organizationName
      cloudLicenseType
      dateModified
      dateActivated
      expirationDate
      note
      order_id
      activatedBy
      licenseGroups {
        id
        groupname
        displayNameKey
        descriptionKey
        licenseTypes {
          id
          licenseType
          displayNameKey
          descriptionKey
          numberOfLicenses
          assignedLicenses
          numberOfReservedLicenses
          licensePackets {
            packetId
            packetName
            numberOfLicenses
            assignedLicenses
            reservedLicenses
          }
        }
      }
      assignedPackets {
        id
        packetName
        packetType
        activationStatus
        packetKey
        creationDate
        activatedBy
      }
    }
    journal {
      id
      action_user
      license_name
      license_pool_name
      license_type_name
      action_type
      date
    }
  }
}
    `;

/**
 * __useViewCloudLicenseQuery__
 *
 * To run a query within a React component, call `useViewCloudLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCloudLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCloudLicenseQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useViewCloudLicenseQuery(baseOptions: Apollo.QueryHookOptions<ViewCloudLicenseQuery, ViewCloudLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewCloudLicenseQuery, ViewCloudLicenseQueryVariables>(ViewCloudLicenseDocument, options);
      }
export function useViewCloudLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewCloudLicenseQuery, ViewCloudLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewCloudLicenseQuery, ViewCloudLicenseQueryVariables>(ViewCloudLicenseDocument, options);
        }
export type ViewCloudLicenseQueryHookResult = ReturnType<typeof useViewCloudLicenseQuery>;
export type ViewCloudLicenseLazyQueryHookResult = ReturnType<typeof useViewCloudLicenseLazyQuery>;
export type ViewCloudLicenseQueryResult = Apollo.QueryResult<ViewCloudLicenseQuery, ViewCloudLicenseQueryVariables>;
export const GetCloudLiceneseDetailsDocument = gql`
    query GetCloudLiceneseDetails($ID: ID!) {
  getCloudLicenseDetails(ID: $ID) {
    id
    licenseKey
    editorId
    realmId
    organizationName
    cloudLicenseType
    dateCreated
    dateModified
    dateActivated
    expirationDate
    note
    order_id
    activatedBy
    licenseIntMinutes
    detailLog {
      id
      licenseKey
      editorId
      actionType
      expirationDate
      licenseIntMinutes
      dateCreated
      dateModified
    }
    cloudLicensePackets {
      id
      orderID
      packetName
      packetType
      packetLicenseKey
      activationStatus
      creationDate
      activatedBy
      activationDate
      licenseIntMinutes
      expirationDate
      cloudlicensePacketGroups {
        groupId
        groupname
        displayNameKey
        descriptionKey
        licenseGroupTypeDetails {
          id
          licenseType
          displayNameKey
          descriptionKey
          numberOfLicenses
          assignedLicenses
          licenseUsers {
            id
            reserved
            userId
          }
          numberOfReservedLicenses
          typeLogs {
            typeId
            typeName
            numberOfLicenses
            dateCreated
            dateModified
            modifiedBy
            actionType
          }
        }
      }
      packetLog {
        logId
        actionType
        licenseKey
        dateModified
        modifiedBy
        groupName
        packetName
        expirationDate
        licenseIntMinutes
        orderID
      }
    }
  }
}
    `;

/**
 * __useGetCloudLiceneseDetailsQuery__
 *
 * To run a query within a React component, call `useGetCloudLiceneseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudLiceneseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudLiceneseDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCloudLiceneseDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCloudLiceneseDetailsQuery, GetCloudLiceneseDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCloudLiceneseDetailsQuery, GetCloudLiceneseDetailsQueryVariables>(GetCloudLiceneseDetailsDocument, options);
      }
export function useGetCloudLiceneseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCloudLiceneseDetailsQuery, GetCloudLiceneseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCloudLiceneseDetailsQuery, GetCloudLiceneseDetailsQueryVariables>(GetCloudLiceneseDetailsDocument, options);
        }
export type GetCloudLiceneseDetailsQueryHookResult = ReturnType<typeof useGetCloudLiceneseDetailsQuery>;
export type GetCloudLiceneseDetailsLazyQueryHookResult = ReturnType<typeof useGetCloudLiceneseDetailsLazyQuery>;
export type GetCloudLiceneseDetailsQueryResult = Apollo.QueryResult<GetCloudLiceneseDetailsQuery, GetCloudLiceneseDetailsQueryVariables>;
export const GetCloudLicensesListDocument = gql`
    query getCloudLicensesList($paginationInfo: PaginationParams) {
  getCloudLicensesList(paginationInfo: $paginationInfo) {
    cloudLicensesList {
      id
      licenseKey
      editorId
      organizationName
      cloudLicenseType
      dateCreated
      dateModified
      dateActivated
      expirationDate
      note
      order_id
      licenseIntMinutes
      activatedBy
      cloudLicensePackets {
        id
        orderID
        packetName
        packetType
        packetLicenseKey
        activationStatus
        creationDate
        activatedBy
        activationDate
        expirationDate
        licenseIntMinutes
        cloudlicensePacketGroups {
          groupId
          groupname
          displayNameKey
          descriptionKey
          licenseGroupTypeDetails {
            id
            licenseType
            displayNameKey
            descriptionKey
            numberOfLicenses
            assignedLicenses
            licenseUsers {
              id
              reserved
              userId
            }
            numberOfReservedLicenses
          }
        }
      }
    }
    totalSize
  }
}
    `;

/**
 * __useGetCloudLicensesListQuery__
 *
 * To run a query within a React component, call `useGetCloudLicensesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudLicensesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudLicensesListQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *   },
 * });
 */
export function useGetCloudLicensesListQuery(baseOptions?: Apollo.QueryHookOptions<GetCloudLicensesListQuery, GetCloudLicensesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCloudLicensesListQuery, GetCloudLicensesListQueryVariables>(GetCloudLicensesListDocument, options);
      }
export function useGetCloudLicensesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCloudLicensesListQuery, GetCloudLicensesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCloudLicensesListQuery, GetCloudLicensesListQueryVariables>(GetCloudLicensesListDocument, options);
        }
export type GetCloudLicensesListQueryHookResult = ReturnType<typeof useGetCloudLicensesListQuery>;
export type GetCloudLicensesListLazyQueryHookResult = ReturnType<typeof useGetCloudLicensesListLazyQuery>;
export type GetCloudLicensesListQueryResult = Apollo.QueryResult<GetCloudLicensesListQuery, GetCloudLicensesListQueryVariables>;
export const GetActivatedCloudLicensesDocument = gql`
    query GetActivatedCloudLicenses($paginationInfo: PaginationParams, $fetchTrial: Boolean!) {
  getActivatedCloudLicenses(
    paginationInfo: $paginationInfo
    fetchTrial: $fetchTrial
  ) {
    cloudLicensesActivations {
      id
      licenseKey
      editorId
      realmId
      organizationName
      cloudLicenseType
      dateModified
      dateActivated
      expirationDate
      note
      order_id
      activatedBy
      cloudLicensePackets {
        id
        orderID
        packetName
        packetType
        packetLicenseKey
        activationStatus
        creationDate
        activatedBy
        activationDate
        expirationDate
        licenseIntMinutes
        cloudlicensePacketGroups {
          groupId
          groupname
          displayNameKey
          descriptionKey
          licenseGroupTypeDetails {
            id
            licenseType
            displayNameKey
            descriptionKey
            numberOfLicenses
            assignedLicenses
            numberOfReservedLicenses
            licenseUsers {
              id
              reserved
              userId
            }
          }
        }
      }
    }
    totalSize
  }
}
    `;

/**
 * __useGetActivatedCloudLicensesQuery__
 *
 * To run a query within a React component, call `useGetActivatedCloudLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivatedCloudLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivatedCloudLicensesQuery({
 *   variables: {
 *      paginationInfo: // value for 'paginationInfo'
 *      fetchTrial: // value for 'fetchTrial'
 *   },
 * });
 */
export function useGetActivatedCloudLicensesQuery(baseOptions: Apollo.QueryHookOptions<GetActivatedCloudLicensesQuery, GetActivatedCloudLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivatedCloudLicensesQuery, GetActivatedCloudLicensesQueryVariables>(GetActivatedCloudLicensesDocument, options);
      }
export function useGetActivatedCloudLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivatedCloudLicensesQuery, GetActivatedCloudLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivatedCloudLicensesQuery, GetActivatedCloudLicensesQueryVariables>(GetActivatedCloudLicensesDocument, options);
        }
export type GetActivatedCloudLicensesQueryHookResult = ReturnType<typeof useGetActivatedCloudLicensesQuery>;
export type GetActivatedCloudLicensesLazyQueryHookResult = ReturnType<typeof useGetActivatedCloudLicensesLazyQuery>;
export type GetActivatedCloudLicensesQueryResult = Apollo.QueryResult<GetActivatedCloudLicensesQuery, GetActivatedCloudLicensesQueryVariables>;
export const GetCloudLicenseDetailsByOrderIdDocument = gql`
    query getCloudLicenseDetailsByOrderID($orderIDs: [ID]!) {
  getCloudLicenseDetailsByOrderID(orderIDs: $orderIDs) {
    id
    licenseKey
    editorId
    realmId
    organizationName
    cloudLicenseType
    dateCreated
    dateModified
    dateActivated
    expirationDate
    note
    order_id
    activatedBy
    licenseIntMinutes
    cloudLicensePackets {
      id
      orderID
      packetName
      packetType
      packetLicenseKey
      activationStatus
      creationDate
      activatedBy
      activationDate
      licenseIntMinutes
      cloudlicensePacketGroups {
        groupId
        groupname
        displayNameKey
        descriptionKey
        licenseGroupTypeDetails {
          id
          licenseType
          displayNameKey
          descriptionKey
          numberOfLicenses
          assignedLicenses
          licenseUsers {
            id
            reserved
            userId
          }
          numberOfReservedLicenses
          typeLogs {
            typeId
            typeName
            numberOfLicenses
            dateCreated
            dateModified
            modifiedBy
            actionType
          }
        }
      }
      packetLog {
        logId
        actionType
        licenseKey
        dateModified
        modifiedBy
        groupName
        packetName
        expirationDate
        licenseIntMinutes
        orderID
      }
    }
  }
}
    `;

/**
 * __useGetCloudLicenseDetailsByOrderIdQuery__
 *
 * To run a query within a React component, call `useGetCloudLicenseDetailsByOrderIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudLicenseDetailsByOrderIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudLicenseDetailsByOrderIdQuery({
 *   variables: {
 *      orderIDs: // value for 'orderIDs'
 *   },
 * });
 */
export function useGetCloudLicenseDetailsByOrderIdQuery(baseOptions: Apollo.QueryHookOptions<GetCloudLicenseDetailsByOrderIdQuery, GetCloudLicenseDetailsByOrderIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCloudLicenseDetailsByOrderIdQuery, GetCloudLicenseDetailsByOrderIdQueryVariables>(GetCloudLicenseDetailsByOrderIdDocument, options);
      }
export function useGetCloudLicenseDetailsByOrderIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCloudLicenseDetailsByOrderIdQuery, GetCloudLicenseDetailsByOrderIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCloudLicenseDetailsByOrderIdQuery, GetCloudLicenseDetailsByOrderIdQueryVariables>(GetCloudLicenseDetailsByOrderIdDocument, options);
        }
export type GetCloudLicenseDetailsByOrderIdQueryHookResult = ReturnType<typeof useGetCloudLicenseDetailsByOrderIdQuery>;
export type GetCloudLicenseDetailsByOrderIdLazyQueryHookResult = ReturnType<typeof useGetCloudLicenseDetailsByOrderIdLazyQuery>;
export type GetCloudLicenseDetailsByOrderIdQueryResult = Apollo.QueryResult<GetCloudLicenseDetailsByOrderIdQuery, GetCloudLicenseDetailsByOrderIdQueryVariables>;
export const GetAllLicenseTypesDocument = gql`
    query GetAllLicenseTypes {
  getAllLicenseTypes {
    id
    groupId
    licenseType
  }
}
    `;

/**
 * __useGetAllLicenseTypesQuery__
 *
 * To run a query within a React component, call `useGetAllLicenseTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLicenseTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLicenseTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLicenseTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLicenseTypesQuery, GetAllLicenseTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLicenseTypesQuery, GetAllLicenseTypesQueryVariables>(GetAllLicenseTypesDocument, options);
      }
export function useGetAllLicenseTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLicenseTypesQuery, GetAllLicenseTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLicenseTypesQuery, GetAllLicenseTypesQueryVariables>(GetAllLicenseTypesDocument, options);
        }
export type GetAllLicenseTypesQueryHookResult = ReturnType<typeof useGetAllLicenseTypesQuery>;
export type GetAllLicenseTypesLazyQueryHookResult = ReturnType<typeof useGetAllLicenseTypesLazyQuery>;
export type GetAllLicenseTypesQueryResult = Apollo.QueryResult<GetAllLicenseTypesQuery, GetAllLicenseTypesQueryVariables>;