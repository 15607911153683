import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type FullUserProfile = {
  __typename?: 'FullUserProfile';
  userId: Scalars['String'];
  CreationDate: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Data: Array<Maybe<UserProfileData>>;
  Image: Array<Maybe<UserProfileImage>>;
  Email: Array<Maybe<UserProfileEmail>>;
  OptIn: Array<Maybe<UserProfileOptIn>>;
};

export type FullUserProfileDataInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileEmailInput = {
  Email: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileImageInput = {
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileInput = {
  userId: Scalars['String'];
  CreationDate: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Data: Array<Maybe<FullUserProfileDataInput>>;
  Image: Array<Maybe<FullUserProfileImageInput>>;
  Email: Array<Maybe<FullUserProfileEmailInput>>;
  OptIn: Array<Maybe<FullUserProfileOptInInput>>;
};

export type FullUserProfileOptInInput = {
  Newsletter: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createUserProfileEmail: Scalars['Boolean'];
  updateUserProfileEmailOptin: Scalars['Boolean'];
  deleteUserProfileEmailFull: Scalars['Boolean'];
  createUserProfile: Scalars['Boolean'];
  updateUserProfileData: Scalars['Boolean'];
  updateUserProfileOptin: Scalars['Boolean'];
  updateUserProfileImage: Scalars['Boolean'];
  changeUserProfileEmail: Scalars['Boolean'];
  deleteUserProfile: Scalars['Boolean'];
  deleteUserProfileFull: Scalars['Boolean'];
  userProfileImport: Scalars['Boolean'];
};


export type MutationCreateUserProfileEmailArgs = {
  userProfile: UserProfileEmailInput;
};


export type MutationUpdateUserProfileEmailOptinArgs = {
  userProfileOptin: UserProfileEmailOptinInput;
};


export type MutationDeleteUserProfileEmailFullArgs = {
  email: Scalars['String'];
};


export type MutationCreateUserProfileArgs = {
  userProfile: UserProfileInput;
};


export type MutationUpdateUserProfileDataArgs = {
  userProfileData: UserProfileDataInput;
};


export type MutationUpdateUserProfileOptinArgs = {
  userProfileOptin: UserProfileOptinInput;
};


export type MutationUpdateUserProfileImageArgs = {
  userProfileImage: UserProfileIdImageInput;
};


export type MutationUserProfileImportArgs = {
  userProfiles: Array<Maybe<FullUserProfileInput>>;
};

export type Query = {
  __typename?: 'Query';
  /** get user profile version information */
  getVersion: UserProfileVersionInformation;
  getUserProfile: UserProfile;
  getUserInfo: Array<Maybe<UserInfo>>;
  getUserProfiles?: Maybe<Array<UserProfileLight>>;
  getUserProfilesByEmailID?: Maybe<Array<Maybe<UserProfileLight>>>;
  userProfileExport: Array<Maybe<FullUserProfile>>;
  /**
   * searches for the given string in all fields of the user data. If there
   * is a match in the row the corresponding id of the user is returned.
   */
  searchUserData: Array<Scalars['String']>;
};


export type QueryGetUserProfilesArgs = {
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetUserProfilesByEmailIdArgs = {
  emailIDs?: Maybe<Array<Scalars['String']>>;
};


export type QuerySearchUserDataArgs = {
  searchString: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  company: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  UserID: Scalars['String'];
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Image?: Maybe<UserProfileIdImage>;
  CreationDate: Scalars['String'];
};

export type UserProfileData = {
  __typename?: 'UserProfileData';
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileDataInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
};

export type UserProfileEmail = {
  __typename?: 'UserProfileEmail';
  Email: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileEmailInput = {
  Email: Scalars['String'];
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  Date: Scalars['String'];
  Toolname?: Maybe<Scalars['String']>;
};

export type UserProfileEmailOptinInput = {
  Email: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Date: Scalars['String'];
  Toolname?: Maybe<Scalars['String']>;
};

export type UserProfileIdImage = {
  __typename?: 'UserProfileIdImage';
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
};

export type UserProfileIdImageInput = {
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
};

export type UserProfileImage = {
  __typename?: 'UserProfileImage';
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  CreationDate: Scalars['String'];
};

export type UserProfileLight = {
  __typename?: 'UserProfileLight';
  userId: Scalars['String'];
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
};

export type UserProfileOptIn = {
  __typename?: 'UserProfileOptIn';
  Newsletter: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileOptinInput = {
  Newsletter: Scalars['Boolean'];
};

export type UserProfileVersionInformation = {
  __typename?: 'UserProfileVersionInformation';
  /** version of user profile service */
  version: Scalars['String'];
  /** timestamp of the iam build */
  buildTimestamp: Scalars['String'];
};

export type GetUserProfilesQueryVariables = Exact<{
  userIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetUserProfilesQuery = (
  { __typename?: 'Query' }
  & { getUserProfiles?: Maybe<Array<(
    { __typename?: 'UserProfileLight' }
    & Pick<UserProfileLight, 'userId' | 'Title' | 'FirstName' | 'LastName' | 'CompanyName' | 'Phone' | 'Mobile' | 'Fax' | 'Email' | 'Street' | 'Zip' | 'City' | 'Country' | 'Department' | 'Relationship'>
  )>> }
);

export type GetUserProfilesByEmailIdQueryVariables = Exact<{
  emailIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetUserProfilesByEmailIdQuery = (
  { __typename?: 'Query' }
  & { getUserProfilesByEmailID?: Maybe<Array<Maybe<(
    { __typename?: 'UserProfileLight' }
    & Pick<UserProfileLight, 'userId' | 'Title' | 'FirstName' | 'LastName' | 'CompanyName' | 'Phone' | 'Mobile' | 'Fax' | 'Email' | 'Street' | 'Zip' | 'City' | 'Country' | 'Department' | 'Relationship'>
  )>>> }
);


export const GetUserProfilesDocument = gql`
    query getUserProfiles($userIds: [String]) {
  getUserProfiles(userIds: $userIds) {
    userId
    Title
    FirstName
    LastName
    CompanyName
    Phone
    Mobile
    Fax
    Email
    Street
    Zip
    City
    Country
    Department
    Relationship
  }
}
    `;

/**
 * __useGetUserProfilesQuery__
 *
 * To run a query within a React component, call `useGetUserProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfilesQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetUserProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfilesQuery, GetUserProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfilesQuery, GetUserProfilesQueryVariables>(GetUserProfilesDocument, options);
      }
export function useGetUserProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfilesQuery, GetUserProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfilesQuery, GetUserProfilesQueryVariables>(GetUserProfilesDocument, options);
        }
export type GetUserProfilesQueryHookResult = ReturnType<typeof useGetUserProfilesQuery>;
export type GetUserProfilesLazyQueryHookResult = ReturnType<typeof useGetUserProfilesLazyQuery>;
export type GetUserProfilesQueryResult = Apollo.QueryResult<GetUserProfilesQuery, GetUserProfilesQueryVariables>;
export const GetUserProfilesByEmailIdDocument = gql`
    query getUserProfilesByEmailID($emailIDs: [String!]) {
  getUserProfilesByEmailID(emailIDs: $emailIDs) {
    userId
    Title
    FirstName
    LastName
    CompanyName
    Phone
    Mobile
    Fax
    Email
    Street
    Zip
    City
    Country
    Department
    Relationship
  }
}
    `;

/**
 * __useGetUserProfilesByEmailIdQuery__
 *
 * To run a query within a React component, call `useGetUserProfilesByEmailIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfilesByEmailIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfilesByEmailIdQuery({
 *   variables: {
 *      emailIDs: // value for 'emailIDs'
 *   },
 * });
 */
export function useGetUserProfilesByEmailIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfilesByEmailIdQuery, GetUserProfilesByEmailIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfilesByEmailIdQuery, GetUserProfilesByEmailIdQueryVariables>(GetUserProfilesByEmailIdDocument, options);
      }
export function useGetUserProfilesByEmailIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfilesByEmailIdQuery, GetUserProfilesByEmailIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfilesByEmailIdQuery, GetUserProfilesByEmailIdQueryVariables>(GetUserProfilesByEmailIdDocument, options);
        }
export type GetUserProfilesByEmailIdQueryHookResult = ReturnType<typeof useGetUserProfilesByEmailIdQuery>;
export type GetUserProfilesByEmailIdLazyQueryHookResult = ReturnType<typeof useGetUserProfilesByEmailIdLazyQuery>;
export type GetUserProfilesByEmailIdQueryResult = Apollo.QueryResult<GetUserProfilesByEmailIdQuery, GetUserProfilesByEmailIdQueryVariables>;