import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LicenseGroupInput, LicenseGroupRightInput } from '../../../../api/models'
import { assignScopes, initialLicenseGroupRight } from '../../../helpers/sharedInitialvalues'
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined'
import { CsButtonText } from '@csinstruments/cs-react-theme'

interface LicenseGroupRightsProps {
  create: boolean
  cancelCreate: (cancel: boolean) => void
  currentLicenseGroup: LicenseGroupInput
  confirmCreate: (lg: LicenseGroupInput) => Promise<void>
}

const CreateLicenseGroupRights: React.FC<LicenseGroupRightsProps> = ({
  create,
  cancelCreate,
  currentLicenseGroup,
  confirmCreate,
}) => {
  const { t } = useTranslation('common')
  const [licenseGroup, setLicenseGroup] = useState<LicenseGroupInput>(currentLicenseGroup)
  const [licenseGrpRights, setLicenseGrpRights] = useState<LicenseGroupRightInput[]>([])
  const [rightName, setRightName] = useState<string>('')

  useEffect(() => {
    if (currentLicenseGroup) {
      setLicenseGroup(currentLicenseGroup)
    }
  }, [currentLicenseGroup])

  const addGroupRight = (rightName: string) => {
    const grpRight = {
      rightName: rightName,
      scopes: assignScopes.filter((aScope) => !['noaccess', 'access'].includes(aScope.scope)).map((x) => x.scope),
    }
    const tempLicenseRight: LicenseGroupRightInput[] =
      licenseGrpRights.length && licenseGrpRights.some((grp) => grp.rightName !== rightName)
        ? [...licenseGrpRights, grpRight]
        : [grpRight]
    setLicenseGrpRights(tempLicenseRight)
    setLicenseGroup((prv) => ({
      ...prv,
      groupRights: tempLicenseRight,
    }))
    setRightName('')
  }

  const deleteGroupRight = (rightName: string) => {
    const finalGroupRights = licenseGrpRights.filter((grpRight) => grpRight.rightName !== rightName)
    setLicenseGrpRights(finalGroupRights)
    setLicenseGroup((prv) => ({
      ...prv,
      groupRights: finalGroupRights,
    }))
  }
  const changeScopes = (rightName: string, value: string) => {
    const currentGrp = licenseGrpRights.find((grp) => grp.rightName === rightName)
    const changeScopes: string[] = currentGrp?.scopes.includes(value)
      ? (currentGrp.scopes.filter((sc) => sc !== value) as string[])
      : [...(currentGrp?.scopes as string[]), value]
    const filteredGroupRightScopes = licenseGrpRights.map((grp) =>
      grp.rightName === rightName
        ? {
            ...grp,
            scopes: changeScopes,
          }
        : grp,
    )
    setLicenseGrpRights(filteredGroupRightScopes)
    setLicenseGroup((prv) => ({
      ...prv,
      groupRights: filteredGroupRightScopes,
    }))
  }
  const closeDialog = () => {
    setLicenseGrpRights([initialLicenseGroupRight])
    cancelCreate(false)
    setRightName('')
  }

  const saveLicenseGroup = async () => {
    //console.log('licegro', licenseGroup)
    try {
      await confirmCreate(licenseGroup)
    } catch (er) {
      console.error('error creating licenseGroup', er)
    }
  }

  return (
    <>
      <Dialog open={create} onClose={closeDialog} disableEscapeKeyDown aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t('objects.licenseGroup')} {':'} {licenseGroup.groupname.toLocaleUpperCase()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t('labels.addGroupRights')}</DialogContentText>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={rightName}
                label={t('labels.rightName')}
                variant="outlined"
                onChange={({ target }) => setRightName(target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="add license Group"
                        disabled={!rightName || licenseGroup.groupRights.some((grp) => grp?.rightName === rightName)}
                        onClick={() => addGroupRight(rightName)}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} />
            {licenseGroup.groupRights.map((l) => (
              <Grid key={l?.rightName} item container spacing={2}>
                {l?.rightName && (
                  <>
                    <Grid item>
                      <TextField
                        fullWidth
                        value={l?.rightName}
                        label={t('labels.rightName')}
                        variant="standard"
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CsButtonText
                                /*  label={t('actions.delete')} */ onClick={() => deleteGroupRight(l.rightName)}
                              >
                                <RemoveOutlinedIcon />
                              </CsButtonText>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item container spacing={2}>
                      {assignScopes.map((scgrp) => (
                        <FormControlLabel
                          key={scgrp.scope}
                          label={t(scgrp.displayScope)}
                          control={
                            <Checkbox
                              color="primary"
                              checked={l.scopes.includes(scgrp.scope)}
                              value={scgrp.scope}
                              onChange={({ target }) => changeScopes(l.rightName, target.value)}
                            />
                          }
                        />
                      ))}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid item container justify="space-around" spacing={2}>
            <Grid item xs={3} lg={4}>
              <Button onClick={closeDialog} fullWidth variant="contained" color="primary">
                {t('actions.back')}
              </Button>
            </Grid>
            <Grid item xs={3} lg={4}>
              <Button
                onClick={saveLicenseGroup}
                variant="contained"
                fullWidth
                disabled={
                  !licenseGroup.groupRights.length ||
                  !licenseGroup.groupname ||
                  !licenseGroup.groupRights.some((g) => !!g?.rightName && g?.scopes.length)
                }
                color="primary"
              >
                {t('actions.add')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default CreateLicenseGroupRights
