import React, { FC, ReactElement } from 'react'
import { PaginationParams } from '../../../../api/models'
import { useTranslation } from '../../../hooks/useTranslation'
import { InputLabel, MenuItem, Select } from '@material-ui/core'

type PaginationInfoLimitUpdaterProps = {
  paginationParams: PaginationParams
  setPaginationParams: React.Dispatch<React.SetStateAction<PaginationParams>>
  updateEntries?: () => void
}

export const PaginationInfoLimitUpdater: FC<PaginationInfoLimitUpdaterProps> = ({
  paginationParams,
  setPaginationParams,
  updateEntries,
}): ReactElement => {
  const { t } = useTranslation()

  const nEntriesLabel = t('pagination.nEntries')
  const changeSelectedValues = (val: unknown) => {
    setPaginationParams((paginationParams) => ({ ...paginationParams, limit: val as number }))
    updateEntries && updateEntries()
  }

  return (
    <>
      <InputLabel id="pagination-updater">{nEntriesLabel}</InputLabel>
      <Select
        labelId="pagination-updater"
        value={paginationParams.limit || 0}
        onChange={({ target }) => changeSelectedValues(target.value)}
      >
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </>
  )
}
