import React, { FC, ReactElement, useEffect, /*  useEffect, */ useMemo, useState } from 'react'
import { DashboardTile } from './DashboardTile'
import { LocalOffer, People, Pool, Storefront } from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import { ROUTES } from '../../../helpers/routes'
import { useTranslation } from '../../../hooks/useTranslation'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined'
//import { useDashboardInfoQuery } from '../../../../api/models'
//import { toLocaleDateTime } from '../../../helpers/dateTime'
import { /* getTokens, */ hasUserRoles } from '../../../helpers/keycloakService'
//import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { useKeycloak } from '@react-keycloak/web'
import { useDashboardInfoQuery } from '../../../../api/models'
import { convertToLocaleDate } from '../../../helpers/dateTime'
import { useCmServicesDashboardQuery } from '../../../../api/customer.models'

type DashboardInfo = {
  nLicensePools: number
  nLicenses: number
  nTrialLicenses: number
  nMerchants: number
  nCustomers: number
  nLicenseTypes: number
  nUsers: number

  poolsSubtitle: string
  triaLicensesSubtitle: string
  licensesSubtitle: string
  merchantsSubtitle: string
  customersSubtitle: string
  typesSubtitle: string
  usersSubtitle: string
}

export const Dashboard: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const { keycloak } = useKeycloak()
  //const { data, loading } = useDashboardInfoQuery()
  const [info, setInfo] = useState<DashboardInfo>({
    nLicensePools: 0,
    nLicenses: 0,
    nTrialLicenses: 0,
    nMerchants: 0,
    nCustomers: 0,
    nUsers: 0,
    nLicenseTypes: 0,
    poolsSubtitle: '',
    triaLicensesSubtitle: '',
    licensesSubtitle: '',
    merchantsSubtitle: '',
    usersSubtitle: '',
    customersSubtitle: '',
    typesSubtitle: '',
  })

  const token = keycloak.token

  const { data: customerServiceInfo } = useCmServicesDashboardQuery()
  const { data: licenseDashboardInfo } = useDashboardInfoQuery()
  const masterUser = useMemo(() => {
    return hasUserRoles(token).master
  }, [token])

  const [
    poolsTitle,
    licensesTitle,
    merchantsTitle,
    customersTitle,
    typesTitle,
    newestLabel,
    trialLicenseLabel,
  ] = useMemo(
    () => [
      t('objects.licensePools'),
      t('objects.licenses'),
      t('objects.merchants'),
      t('objects.customers'),
      t('objects.licenseTypes'),
      t('objects.newest'),
      t('licenses.trialLicenses'),
      //t('objects.totalUsers'),
    ],
    [t],
  )

  useEffect(() => {
    /* if (!customerServiceInfo?.cmServicesDashboard || !licenseDashboardInfo?.dashboardInfo) {
      return
    } */

    if (customerServiceInfo?.cmServicesDashboard && customerServiceInfo?.cmServicesDashboard) {
      const {
        nCustomers,
        nMerchants,
        lastCustomerName,
        lastMerchantName,
        //lastCustomerCreationDate,
        //lastMerchantCreationDate,
      } = customerServiceInfo.cmServicesDashboard
      setInfo((prv) => ({
        ...prv,
        nCustomers: nCustomers,
        nMerchants: nMerchants,
        customersSubtitle: `${newestLabel}: ` + lastCustomerName,
        merchantsSubtitle: `${newestLabel}: ` + lastMerchantName,
      }))
    }

    if (licenseDashboardInfo?.dashboardInfo && licenseDashboardInfo?.dashboardInfo) {
      const {
        nLicensePools,
        nLicenseTypes,
        nLicenses,
        nTrialLicenses,
        lastTrialCloudLicenseDate,
        lastCloudLicenseCreationDate,
        lastLicensePoolCreationDate,
        lastLicenseTypeName,
      } = licenseDashboardInfo.dashboardInfo
      setInfo((prv) => ({
        ...prv,
        nLicensePools: nLicensePools,
        nLicenseTypes: nLicenseTypes,
        nLicenses: nLicenses,
        nTrialLicenses: nTrialLicenses,
        triaLicensesSubtitle: `${newestLabel}: ` + convertToLocaleDate(lastTrialCloudLicenseDate ?? '', t),
        licensesSubtitle: `${newestLabel}: ` + convertToLocaleDate(lastCloudLicenseCreationDate ?? '', t),
        poolsSubtitle: `${newestLabel}: ` + convertToLocaleDate(lastLicensePoolCreationDate ?? '', t),
        typesSubtitle: `${newestLabel}: ` + lastLicenseTypeName,
      }))
    }
    /*  const {
      nLicensePools,
      nLicenses,
      nMerchants,
      nCustomers,
      nUsers,
      nLicenseTypes,
      lastCustomerName,
      lastLicenseCreationDate,
      lastLicensePoolCreationDate,
      lastMerchantCreationDate,
      lastLicenseTypeName,
    } = data.dashboardInfo */

    /*  const poolsSubtitle = `${newestLabel}: ${toLocaleDateTime(lastLicensePoolCreationDate) || '-'}`
    const licensesSubtitle = `${newestLabel}: ${toLocaleDateTime(lastLicenseCreationDate) || '-'}`
    const merchantsSubtitle = `${newestLabel}: ${toLocaleDateTime(lastMerchantCreationDate)}`
    const usersSubtitle = `${totalUsersLabel}: ${nUsers}`
    const customersSubtitle = `${newestLabel}: ${lastCustomerName || '-'}`
    const typesSubtitle = `${newestLabel}: ${lastLicenseTypeName || '-'}` */
  }, [customerServiceInfo?.cmServicesDashboard, licenseDashboardInfo?.dashboardInfo, newestLabel, t])

  return (
    <>
      <Grid container alignItems="stretch" alignContent="space-between" spacing={3}>
        <Grid item xs={12}>
          {/* <ProgressBar show={loading} /> */}
        </Grid>
        <DashboardTile
          title={poolsTitle}
          subtitle={info.poolsSubtitle}
          icon={Pool}
          number={info.nLicensePools}
          link={ROUTES.licensePools}
        />
        {masterUser && (
          <DashboardTile
            title={t('objects.users')}
            subtitle={info.usersSubtitle}
            icon={People}
            number={info.nUsers}
            link={ROUTES.users}
          />
        )}
        {!masterUser && (
          <>
            <DashboardTile
              title={licensesTitle}
              subtitle={info.licensesSubtitle}
              icon={LibraryBooksIcon}
              number={info.nLicenses}
              link={ROUTES.licenses}
            />
            <DashboardTile
              title={trialLicenseLabel}
              subtitle={info.triaLicensesSubtitle}
              icon={LibraryBooksIcon}
              number={info.nTrialLicenses}
              link={ROUTES.licenseInstances + '/?trialLicenses=true'}
            />
            <DashboardTile
              title={merchantsTitle}
              subtitle={info.merchantsSubtitle}
              icon={Storefront}
              number={info.nMerchants}
              link={ROUTES.merchants}
            />
            <DashboardTile
              title={customersTitle}
              subtitle={info.customersSubtitle}
              icon={People}
              number={info.nCustomers}
              link={ROUTES.customers}
            />
          </>
        )}
        <DashboardTile
          title={typesTitle}
          subtitle={info.typesSubtitle}
          icon={LocalOffer}
          number={info.nLicenseTypes}
          link={ROUTES.licenseTypes}
        />
      </Grid>
    </>
  )
}
