import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  CloudLicensePacketDetails,
  CloudLicensePacketGroup,
  LicenseGroupTypeDetails,
  useDeactivateLicensePacketMutation,
} from '../../../../api/models'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { convertToLocaleDate, getRemainingDays } from '../../../helpers/dateTime'
import { Add, AddToPhotos } from '@material-ui/icons'
//import { GraphQLEndpoints } from '../../../helpers/apolloClient'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/EditOutlined'
//import { generatePath, useHistory } from 'react-router-dom'
//import { ROUTES } from '../../../helpers/routes'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { CloudLicenseType } from '../../../hooks/cloudLicense'
import { ROUTES } from '../../../helpers/routes'
import { generatePath, useHistory } from 'react-router-dom'
import { getPacketName, valueOrNullToString } from '../../../helpers/stringHelpers'
import BlockIcon from '@material-ui/icons/Block'
//import { us } from '../../../../api/organization.models'
import { ChangeLicenseNumberInput, LicenseTypesCountToUpdate } from '../../../../typeInterfaces/Interfaces'
import { ChangeLicenseCountModal } from '../../partials/ChangeLicenseInputModal/ChangeLicenseCountModal'
import { useKeycloak } from '@react-keycloak/web'
//import AutorenewIcon from '@material-ui/icons/Autorenew'
//import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ExtensionIcon from '@material-ui/icons/Extension'
import { ExtendLicenseModal } from '../../partials/ExtendLicenseModal/ExtendLicenseModal'
import { Contact } from '../../../../api/customer.models'
import { UserProfileLight } from '../../../../api/user.models'
import { sortDateString } from '../../../helpers/stringHelpers'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { useDeactivateOrganizationMutation } from '../../../../api/organization.models'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import ChangeLicenseTypesCountModal from '../../partials/ChangeLicenseInputModal/ChangeLicenseTypesCountModal'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function LicensePacketRows(Props: {
  packetGroups?: CloudLicensePacketDetails[]
  cloudlicenseId: string
  licenseKey: string
  organizationName: string
}) {
  const { packetGroups, licenseKey, cloudlicenseId, organizationName } = Props
  const { t } = useTranslation()

  const trialLicense = useQueryParam('trialLicenses')
  const [deactivatePacket] = useDeactivateLicensePacketMutation()
  // const [changeLicenseCountInput, setChangeLicenseCountInput] = useState<ChangeLicenseNumberInput>({
  //   cloudLicenseId: '',
  //   licensePacketId: '',
  //   licensePacketLicenseTypeId: '',
  //   newNumberOfLicense: 0,
  // })
  const [licTypesCountToUpdate, setLicTypesCountToUpdate] = useState<LicenseTypesCountToUpdate>({
    cloudLicenseId: '',
    licensePacketId: '',
    licTypesCount: [],
  })
  const [openRenew, setOpenRenew] = React.useState<boolean>(false)
  const [openChangeModel, setOpenChangeModal] = useState<boolean>(false)
  const [packetName, setPacketName] = useState<string>('')
  const [extendLicensePacketId, setExtendLicensePacketId] = useState<string>('')
  const [packetGroupLicenseTypes, setPacketGroupLicenseTypes] = useState<LicenseGroupTypeDetails[]>([])
  const { keycloak } = useKeycloak()
  const isTrialLicense = useMemo(() => trialLicense === 'true', [trialLicense])
  if (!packetGroups?.length) {
    return <TableRow></TableRow>
  }
  const confirmDeactivate = (licenseKey: string) => {
    if (licenseKey) {
      deactivatePacket({
        variables: {
          licenseKey,
        },
      })
        .then((res) => {
          if (res.data?.deactivateLicensePacket) {
            console.log('deactivated Packet Successfully')
            keycloak.login()
          }
        })
        .catch((e) => console.error('some thing gone wrong', e))
    }
    //console.log('deactivated Packet Successfully')
  }
  const handleGoToLicense = (packetId: string, packetGroup: CloudLicensePacketGroup[], packetName: string) => {
    //console.log({ packetId }, { packetGroup })
    const userPacketType = packetGroup[0].licenseGroupTypeDetails.find((type) => type?.licenseType === 'user')
    const guestPacketType = packetGroup[0].licenseGroupTypeDetails.find((type) => type?.licenseType === 'guest')

    const updLicCount: LicenseTypesCountToUpdate = {
      cloudLicenseId: cloudlicenseId,
      licensePacketId: packetId,
      licTypesCount: [
        {
          licensePacketLicenseTypeId: userPacketType?.id ?? '',
          newNumberOfLicenses: userPacketType?.numberOfLicenses ?? 0,
        },
        {
          licensePacketLicenseTypeId: guestPacketType?.id ?? '',
          newNumberOfLicenses: guestPacketType?.numberOfLicenses ?? 0,
        },
      ],
    }
    setLicTypesCountToUpdate(updLicCount)
    setPacketName(packetName)
    setPacketGroupLicenseTypes(packetGroup[0].licenseGroupTypeDetails as LicenseGroupTypeDetails[])
    setOpenChangeModal(true)
  }

  const renewLicense = (selectPacketId: string) => {
    setExtendLicensePacketId(selectPacketId)
    setOpenRenew(true)
  }
  const licExpiryColor = (licExpiryDate: string) => {
    const remainingDays = getRemainingDays(licExpiryDate)

    if (remainingDays < 90) {
      return 'error'
    }
    return 'inherit'
  }
  return (
    <>
      {/* <ChangeLicenseCountModal
        onClose={setOpenChangeModal}
        openChangeLicenseCountModel={openChangeModel}
        currentTypeCountInput={changeLicenseCountInput}
        licensePacketLicenseTypes={packetGroupLicenseTypes}
        packetName={packetName}
        organizationName={organizationName}
      /> */}
      <ChangeLicenseTypesCountModal
        onClose={setOpenChangeModal}
        openChangeLicenseCountModel={openChangeModel}
        licensePacketLicenseTypes={packetGroupLicenseTypes}
        currentTypeCountInput={licTypesCountToUpdate}
        packetName={packetName}
        organizationName={organizationName}
      />

      <ExtendLicenseModal
        modalHeading={organizationName}
        openExtendModal={openRenew}
        closeExtendModal={setOpenRenew}
        cloudLicenseId={cloudlicenseId}
        packetId={extendLicensePacketId}
      />

      {packetGroups.map((pkt) => (
        <TableRow key={pkt?.id}>
          <TableCell component="th" scope="row">
            {pkt?.packetLicenseKey}
          </TableCell>
          <TableCell>{pkt?.orderID}</TableCell>

          <TableCell>
            <Typography variant="subtitle2" gutterBottom>
              {pkt?.packetName && `${t('labels.packet')}: ${getPacketName(pkt.packetName, t)}`}
            </Typography>

            {pkt.expirationDate && pkt.activationStatus && (
              <Typography color={licExpiryColor(pkt.expirationDate)} variant="inherit">
                {t('licenses.expiryDate')} : {convertToLocaleDate(pkt.expirationDate, t)}
              </Typography>
            )}
          </TableCell>

          <TableCell>{pkt?.activatedBy}</TableCell>
          <TableCell>
            {pkt?.cloudlicensePacketGroups.map((pGroup) => (
              <Grid item container key={pGroup?.groupId}>
                <Grid item>
                  <Chip label={pGroup?.groupname} style={{ marginRight: '15px' }} />
                </Grid>

                {pGroup?.licenseGroupTypeDetails.map((lt) => (
                  <Typography key={lt?.id} gutterBottom style={{ marginRight: '10px' }}>
                    {lt?.licenseType}: {lt?.assignedLicenses} / {lt?.numberOfLicenses}
                  </Typography>
                ))}
              </Grid>
            ))}
          </TableCell>
          <TableCell>
            <Box margin={1}>
              <Table size="small">
                <TableBody>
                  {pkt?.cloudlicensePacketGroups.map((pktGroup) => (
                    <TableRow key={pktGroup?.groupname}>
                      {pktGroup?.licenseGroupTypeDetails.some(
                        (ltdetails) => ltdetails?.licenseUsers?.filter((ltuser) => ltuser?.userId).length,
                      ) ? (
                        <TableCell>
                          <>
                            <Box>
                              <Grid item container>
                                <Grid item>
                                  <Chip label={pktGroup?.groupname} style={{ marginRight: '15px' }} />
                                </Grid>
                              </Grid>
                            </Box>
                          </>

                          <Box>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t('objects.type')}</TableCell>
                                  <TableCell>{t('objects.userProfileService')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pktGroup?.licenseGroupTypeDetails.map((types) => (
                                  <TableRow key={types?.id}>
                                    <TableCell>{types?.licenseType}</TableCell>
                                    <TableCell>
                                      <Table size="small">
                                        <TableBody>
                                          {types?.licenseUsers?.map((user) =>
                                            user?.userId ? (
                                              <TableRow key={user?.id}>
                                                <TableCell>{user?.userId}</TableCell>
                                              </TableRow>
                                            ) : (
                                              <TableRow key={user?.id}></TableRow>
                                            ),
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </TableCell>
          <TableCell>
            {pkt.packetLicenseKey === licenseKey || !pkt.activationDate ? (
              <></>
            ) : (
              <IconButton
                disabled={
                  pkt.packetLicenseKey === licenseKey ||
                  pkt.cloudlicensePacketGroups.some((pgrp) =>
                    pgrp?.licenseGroupTypeDetails.some((grptype) =>
                      grptype?.licenseUsers?.some((lu) => lu?.userId && lu.userId),
                    ),
                  )
                }
                onClick={() => confirmDeactivate(pkt.packetLicenseKey)}
              >
                <BlockIcon />
              </IconButton>
            )}

            <IconButton component="span" title={t('licenses.renew')} onClick={() => renewLicense(pkt.id)}>
              <ExtensionIcon />
            </IconButton>

            <IconButton
              color="primary"
              component="span"
              disabled={isTrialLicense}
              onClick={() =>
                handleGoToLicense(
                  pkt.id,
                  (pkt.cloudlicensePacketGroups as unknown) as CloudLicensePacketGroup[],
                  pkt.packetName,
                )
              }
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

export const initAdminInfo: UserProfileLight = {
  userId: '',
  Title: '',
  FirstName: '',
  LastName: '',
  CompanyName: '',
  Phone: '',
  Mobile: '',
  Country: '',
  Email: '',
  Newsletter: false,
  Department: '',
  Relationship: '',
  TermsOfUse: false,
}
function ShowContact(props: {
  contact?: Contact
  customerNumber?: string
  adminInfo?: UserProfileLight
  headline: string
}) {
  const { contact, headline, adminInfo, customerNumber } = props
  const { t } = useTranslation()

  const TableRows = contact?.id ? (
    <TableRow key={contact?.id}>
      <TableCell>{contact?.first_name}</TableCell>
      <TableCell>{contact?.last_name}</TableCell>
      <TableCell>{contact?.company}</TableCell>
      <TableCell>{contact?.street}</TableCell>
      <TableCell>{contact?.post_code}</TableCell>
      <TableCell>{contact?.city}</TableCell>
      <TableCell>{contact?.country_code}</TableCell>
      <TableCell>{contact?.note}</TableCell>
      <TableCell>{customerNumber}</TableCell>
      <TableCell>{contact?.email}</TableCell>
    </TableRow>
  ) : (
    <TableRow key={adminInfo?.userId}>
      <TableCell>{valueOrNullToString(adminInfo?.FirstName)}</TableCell>
      <TableCell>{valueOrNullToString(adminInfo?.LastName)}</TableCell>
      <TableCell>{valueOrNullToString(adminInfo?.CompanyName)}</TableCell>
      <TableCell>{valueOrNullToString(adminInfo?.Street)}</TableCell>
      <TableCell>{valueOrNullToString(adminInfo?.Zip)}</TableCell>
      <TableCell>{valueOrNullToString(adminInfo?.City)}</TableCell>
      <TableCell>{valueOrNullToString(adminInfo?.Country)}</TableCell>
      <TableCell>{''}</TableCell>
      <TableCell>{''}</TableCell>
      <TableCell>{valueOrNullToString(adminInfo?.Email)}</TableCell>
    </TableRow>
  )
  return (
    <Box margin={1} style={{ marginTop: '30px' }}>
      <Typography variant="h6" gutterBottom component="div">
        {t(headline)}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '10%' }}>{t('contact.firstName')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.familyName')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.company')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.street')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.zip')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.city')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.country')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.note')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.number')}</TableCell>
            <TableCell style={{ width: '10%' }}>{t('contact.email')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{TableRows}</TableBody>
      </Table>
    </Box>
  )
}

function Row(props: {
  licenseInstance: CloudLicenseType
  deleteAction: (id: string) => void
  refresh: () => void
  //userProfiles?: GetUserProfilesQuery['getUserProfiles']
}) {
  const { licenseInstance } = props
  //const { system_identifiers } = licenseInstance
  //const { RawMessage: SystemIdentifiers } = JSON.parse(system_identifiers || '')
  const [open, setOpen] = React.useState(false)

  const classes = useRowStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [deactivateCloudOrganiztion] = useDeactivateOrganizationMutation()
  //const { keycloak } = useKeycloak()

  const confirmDeactivateOrganiztion = async (licenseString: string, realmName: string) => {
    if (licenseString && realmName) {
      try {
        const res = await deactivateCloudOrganiztion({
          variables: {
            licenseString,
            realmName,
          },
        })
        if (res.data?.deactivateOrganization) {
          console.log('deactivated successfully')
        }
      } catch (err) {
        console.log({ err })
      }
    }
  }
  //console.log('org', licenseInstance.cloudLicensesCreated?.licenseKey, 'admin', licenseInstance.adminData)
  //const userProfile = userProfiles?.find((userProfile) => userProfile?.userId === licenseInstance.user_id)
  /* const userProfileContact: Contact | undefined = userProfile
    ? {
        id: userProfile.userId,
        first_name: userProfile.FirstName,
        last_name: userProfile.LastName,
        company: userProfile.CompanyName,
        country_code: userProfile.Country,
      }
    : undefined */

  /* const handleGoToLicense = () => {
    const path = generatePath(ROUTES.editLicense, { id: licenseInstance.license.id })
    history.push(path)
  } */

  const handleBatchGrant = () => {
    const route = licenseInstance.cloudLicensesCreated?.id
      ? ROUTES.batchGrantLicense + `?cloudid=${licenseInstance.cloudLicensesCreated?.id}`
      : ''

    route && history.push(route)
  }

  const handleViewLicenseInfo = () => {
    const viewRoute = generatePath(ROUTES.viewCloudInfo, { id: licenseInstance.cloudLicensesCreated?.id })
    history.push(viewRoute)
  }

  const licePackets = licenseInstance.cloudLicensesCreated?.cloudLicensePackets?.length
    ? (licenseInstance.cloudLicensesCreated?.cloudLicensePackets as CloudLicensePacketDetails[])
    : []

  const instanceLicenseKey = licenseInstance.cloudLicensesCreated?.licenseKey ?? ''

  const instanceCloudId = licenseInstance.cloudLicensesCreated?.id ?? ''

  const organizationName = licenseInstance.cloudLicensesCreated?.organizationName ?? ''

  const licExpiryColor = useMemo(() => {
    if (licenseInstance.cloudLicensesCreated?.expirationDate) {
      const remainingDays = getRemainingDays(licenseInstance.cloudLicensesCreated?.expirationDate)

      if (remainingDays < 90) {
        return 'error'
      }
      return
    }
  }, [licenseInstance.cloudLicensesCreated?.id])

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{licenseInstance.cloudLicensesCreated?.licenseKey}</TableCell>
        <TableCell>
          {licePackets.some(
            (p) => p.activationStatus && p?.expirationDate && getRemainingDays(p?.expirationDate) < 90,
          ) && (
            <Tooltip title={t('error.packetExpiry') ?? ''} placement="bottom">
              <ErrorOutlineOutlinedIcon color="error" fontSize="small" />
            </Tooltip>
          )}
          {licenseInstance.cloudLicensesCreated?.organizationName}
        </TableCell>
        <TableCell>{valueOrNullToString(licenseInstance.adminData?.Country)}</TableCell>
        <TableCell>{valueOrNullToString(licenseInstance.adminData?.City)}</TableCell>
        <TableCell>{convertToLocaleDate(`${licenseInstance.cloudLicensesCreated?.dateActivated}`, t)}</TableCell>
        <TableCell>
          <Typography variant="inherit" color={licExpiryColor}>
            {convertToLocaleDate(`${licenseInstance.cloudLicensesCreated?.expirationDate}`, t)}
          </Typography>
        </TableCell>
        <TableCell style={{ minWidth: '200px' }}>
          {/* <IconButton
            color="primary"
            component="span"
            onClick={() =>
              licenseInstance.cloudLicensesCreated?.id && deleteAction(licenseInstance.cloudLicensesCreated?.id)
            }
          >
            <DeleteOutline />
          </IconButton> */}
          {/* <IconButton color="primary" component="span" onClick={() => handleGoToLicense()}>
            <EditIcon />
          </IconButton> */}
          <IconButton
            disabled={!licenseInstance.cloudLicensesCreated?.order_id}
            color="primary"
            component="span"
            title={t('licenses.batchGrantLicense')}
            onClick={() => handleBatchGrant()}
          >
            <AddToPhotos />
          </IconButton>
          <IconButton
            color="primary"
            component="span"
            disabled={licenseInstance.cloudLicensesCreated?.order_id !== 'trial-version'}
            onClick={() =>
              licenseInstance.cloudLicensesCreated?.licenseKey &&
              licenseInstance.cloudLicensesCreated.realmId &&
              confirmDeactivateOrganiztion(
                licenseInstance.cloudLicensesCreated?.licenseKey,
                licenseInstance.cloudLicensesCreated.realmId,
              )
            }
          >
            <DeleteForeverIcon />
          </IconButton>

          <IconButton
            color="primary"
            component="span"
            title={t('objects.license') + ' info'}
            onClick={handleViewLicenseInfo}
          >
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography variant="h6" gutterBottom component="div">
                {t('objects.license')}
              </Typography> */}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('objects.license')}</TableCell>
                    {/* <TableCell>{t('note')}</TableCell> */}
                    {/* <TableCell>{t('licensePools.orderNumber')}</TableCell>
                    <TableCell>{t('objects.licenseTypes')}</TableCell>
                    <TableCell>{t('instance.licenseMetadata')}</TableCell>
                    <TableCell>{t('licenses.maximumActivations')}</TableCell>
                    <TableCell>{t('objects.activations')}</TableCell> */}
                    {/* <TableCell>{t('objects.userProfileService')}</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={licenseInstance.cloudLicensesCreated?.id}>
                    <TableCell>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('licenses.licenseKey')}</TableCell>
                            <TableCell>{t('licensePools.orderNumber')}</TableCell>
                            <TableCell>{t('objects.licenseTypes')}</TableCell>
                            <TableCell>{t('instance.owner')}</TableCell>
                            <TableCell>{t('licenses.nLicenses')}</TableCell>
                            <TableCell>{t('instance.licenseMetadata')}</TableCell>
                            <TableCell>{}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <LicensePacketRows
                            packetGroups={licePackets}
                            licenseKey={instanceLicenseKey}
                            cloudlicenseId={instanceCloudId}
                            organizationName={organizationName}
                          />
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            {licenseInstance.adminData?.userId && (
              <ShowContact adminInfo={licenseInstance.adminData} headline={'objects.licenseServer'} />
            )}
            {/* {userProfiles && userProfile && (
              <ShowContact contact={userProfileContact} headline={'objects.userProfilService'} />
            )} */}
            {licenseInstance?.orderInfo?.customer?.contact?.id && (
              <ShowContact
                contact={licenseInstance?.orderInfo?.customer?.contact}
                customerNumber={licenseInstance.orderInfo.customer.customer_number}
                headline={'journal.CustomerID'}
              />
            )}
            {licenseInstance?.orderInfo?.merchant?.contact?.id && (
              <ShowContact
                contact={licenseInstance?.orderInfo?.merchant?.contact}
                customerNumber={licenseInstance.orderInfo.merchant.customer_number}
                headline={'journal.MerchantID'}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

type Props = {
  licenseInstances: CloudLicenseType[]
  refetch: () => void
  showHeadline?: boolean
  csvDownload?: boolean
}

export const LicenseInstancesTable: FC<Props> = ({
  licenseInstances,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refetch,
  showHeadline = true,
  csvDownload = false,
}): ReactElement => {
  const { t } = useTranslation()
  const [filteredLicInstances, setFilteredLicInstances] = useState<CloudLicenseType[]>([])
  const [sortToggle, setSortToggle] = useState<boolean>(false)

  useEffect(() => {
    if (licenseInstances.length) {
      setFilteredLicInstances([...licenseInstances])
    }
  }, [licenseInstances])
  //const [deleteAction, { loading: deleteLoading }] = useDeactivateLicenseMutation()

  /* const { data: userProfiles } = useGetUserProfilesQuery({
    variables: {
      userIds:
        licenseInstances.map((licenseInstance) => {
          return licenseInstance.user_id
        }) || [],
    },
    context: { clientName: GraphQLEndpoints.UserProfileService },
  }) */

  const deactivateLicense = (): void => {
    /* if (!deleteLoading) {
      deleteAction({ variables: { id } }).then(() => refetch())
    } */
  }

  const sortLicensesByExpiry = (toggle: boolean): void => {
    setSortToggle(toggle)
    const sortedInstance = filteredLicInstances.slice().sort((a, b) => {
      if (a.cloudLicensesCreated?.expirationDate && b.cloudLicensesCreated?.expirationDate)
        if (toggle) {
          return sortDateString(a.cloudLicensesCreated.expirationDate, b.cloudLicensesCreated.expirationDate)
        } else {
          return sortDateString(b.cloudLicensesCreated.expirationDate, a.cloudLicensesCreated.expirationDate)
        }

      return 0
    })

    setFilteredLicInstances(sortedInstance)
  }

  const sortLicensesByActivationDate = (toggle: boolean): void => {
    setSortToggle(toggle)
    const sortedInstance = filteredLicInstances.slice().sort((a, b) => {
      if (a.cloudLicensesCreated?.dateActivated && b.cloudLicensesCreated?.dateActivated)
        if (toggle) {
          return sortDateString(a.cloudLicensesCreated.dateActivated, b.cloudLicensesCreated.dateActivated)
        } else {
          return sortDateString(b.cloudLicensesCreated.dateActivated, a.cloudLicensesCreated.dateActivated)
        }

      return 0
    })

    setFilteredLicInstances(sortedInstance)
  }

  const sortByCompanyName = (toggle: boolean): void => {
    setSortToggle(toggle)
    const sortedInstance = filteredLicInstances.slice().sort((a, b) => {
      if (a.cloudLicensesCreated?.organizationName && b.cloudLicensesCreated?.organizationName)
        if (toggle) {
          return a.cloudLicensesCreated.organizationName > b.cloudLicensesCreated.organizationName ? -1 : 1
        } else {
          return a.cloudLicensesCreated.organizationName > b.cloudLicensesCreated.organizationName ? 1 : -1
        }

      return 1
    })

    setFilteredLicInstances(sortedInstance)
  }

  return (
    <>
      {showHeadline && (
        <Grid item xs={12}>
          <Typography color="secondary" style={{ paddingTop: 50 }} variant={'h5'}>
            {t('objects.activations')}
          </Typography>
        </Grid>
      )}
      {csvDownload && (
        <FixedActionBar
          labels={['grantLicenseLabel']}
          actions={[() => '']}
          icons={[Add]}
          buttonProps={[{ solid: true }]}
        />
      )}

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('licenses.licenseKey')}</TableCell>
                <TableCell>
                  <>
                    {t('contact.company')}
                    <IconButton
                      aria-label="sortByCompany"
                      title="Sort"
                      size="small"
                      onClick={() => sortByCompanyName(!sortToggle)}
                    >
                      <ImportExportIcon />
                    </IconButton>
                  </>
                </TableCell>
                <TableCell>{t('contact.country')}</TableCell>
                <TableCell>{t('contact.city')}</TableCell>
                <TableCell>
                  <>
                    {t('instance.dateCreated')}
                    <IconButton
                      aria-label="SortByExpiryDate"
                      size="small"
                      title="Sort"
                      onClick={() => sortLicensesByActivationDate(!sortToggle)}
                    >
                      <ImportExportIcon />
                    </IconButton>
                  </>
                </TableCell>
                <TableCell>
                  <>
                    {t('licenses.expiryDate')}
                    <IconButton
                      aria-label="SortByExpiryDate"
                      size="small"
                      title="Sort"
                      onClick={() => sortLicensesByExpiry(!sortToggle)}
                    >
                      <ImportExportIcon />
                    </IconButton>
                  </>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLicInstances.map((instance) => (
                <Row
                  key={instance.cloudLicensesCreated?.id}
                  licenseInstance={instance}
                  deleteAction={deactivateLicense}
                  refresh={refetch}
                  //userProfiles={instance.registrationData}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}
