/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Save } from '@material-ui/icons'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import { useTranslation } from '../../../hooks/useTranslation'
import { Text } from '../../partials/Inputs/Text'
import { Dropdown } from '../../partials/Inputs/Dropdown'

import { ResultAlert } from '../../partials/ResultAlert'
import countries from 'i18n-iso-countries'
import countriesEn from 'i18n-iso-countries/langs/en.json'
//import { JournalTable } from '../Journal/JournalTable'
import { ROUTES } from '../../../helpers/routes'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { JournalTable } from '../Journal/JournalTable'
import {
  GetCustomerByIdQuery,
  ContactInput,
  useGetCustomerByIdQuery,
  useGetMerchantByIdQuery,
  useCreateCustomerMutation,
  useCreateMerchantMutation,
  useUpdateCustomerMutation,
  useUpdateMerchantMutation,
  useGetOrdersByContactIdQuery,
  Order,
} from '../../../../api/customer.models'

type CreateOrEditContactParams = {
  id?: string
}

const getCountryPreselect = (
  customer?: GetCustomerByIdQuery,
  //merchant?: MerchantQuery,
  countryNames?: string[],
  countryCodes?: string[],
): string => {
  if (!countryNames || !countryCodes) {
    return ''
  }

  const code = customer?.customer?.contact?.country_code ?? ''
  if (!code) {
    return ''
  }

  const countryIndex = countryCodes.findIndex((cc) => cc === code)
  return countryIndex > 0 && countryIndex < countryNames.length ? countryNames[countryIndex] : ''
}

export const CreateOrEditContact: FC = ({}): ReactElement => {
  const location = useLocation()
  const { t } = useTranslation()
  const creatingCustomer = useMemo(() => location.pathname.includes('customer'), [location])
  const [contactInput, setContactInput] = useState<ContactInput>({})
  const [showFailAlert, setShowFailAlert] = useState(false)
  const [customerNumber, setCustomerNumber] = useState<string>('')
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [countryNames, setCountryNames] = useState<string[]>([])
  const [countryCodes, setCountryCodes] = useState<string[]>([])
  const history = useHistory()

  const { id } = useParams<CreateOrEditContactParams>()

  const { data: existingCustomer, loading: fetchingCustomerData } = useGetCustomerByIdQuery({
    skip: !id || !creatingCustomer,
    variables: { id: id || '' },
  })
  const { data: existingMerchant, loading: fetchingMerchantData } = useGetMerchantByIdQuery({
    skip: !id || creatingCustomer,
    variables: { id: id || '' },
  })

  const [createCustomerMutation, { data: customerData, loading: createCustomerLoading }] = useCreateCustomerMutation()
  const [createMerchantMutation, { data: merchantData, loading: createMerchantLoading }] = useCreateMerchantMutation()

  const [
    updateCustomerMutation,
    { data: updateCustomerData, loading: updateCustomerLoading },
  ] = useUpdateCustomerMutation()
  const [
    updateMerchantMutation,
    { data: updateMerchantData, loading: updateMerchantLoading },
  ] = useUpdateMerchantMutation()

  const { data: orderInfo } = useGetOrdersByContactIdQuery({
    variables: {
      contactID: id ?? '',
    },
    skip: !id,
  })

  //console.log(orderInfo?.getOrdersByContactID.order)

  const countryPreselect = useMemo(() => getCountryPreselect(existingCustomer, countryNames, countryCodes), [
    existingCustomer,
    ,
    countryCodes,
    countryNames,
  ])

  useEffect(() => {
    if (existingCustomer && existingCustomer.customer?.contact) {
      // just removing __typename from object for it to be valid when sent to backend
      const { customer_number } = existingCustomer.customer
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, id, ...contact } = existingCustomer.customer?.contact
      setContactInput({ ...contact })
      setCustomerNumber(customer_number)
    } else if (existingMerchant && existingMerchant.merchant?.contact) {
      // just removing __typename from object for it to be valid when sent to backend

      const { customer_number } = existingMerchant.merchant
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, id, ...contact } = existingMerchant.merchant?.contact
      setContactInput({ ...contact })
      setCustomerNumber(customer_number)
    }
  }, [existingCustomer, existingMerchant])

  useEffect(() => {
    countries.registerLocale(countriesEn)
    const names = countries.getNames('en', { select: 'official' })
    setCountryCodes(['', ...Object.keys(names)])
    setCountryNames(['', ...Object.values(names)])
  }, [])

  useEffect(() => {
    if (
      (customerData && customerData.createCustomer) ||
      (merchantData && merchantData.createMerchant) ||
      (updateMerchantData && updateMerchantData.updateMerchant) ||
      (updateCustomerData && updateCustomerData.updateCustomer)
    ) {
      setShowSuccessAlert(true)
      history.push(creatingCustomer ? ROUTES.customers : ROUTES.merchants)
      if (!id) {
        setContactInput({})
      }
    }
  }, [
    history,
    creatingCustomer,
    customerData,
    customerData?.createCustomer,
    merchantData,
    merchantData?.createMerchant,
    updateMerchantData,
    updateMerchantData?.updateMerchant,
    updateCustomerData,
    updateCustomerData?.updateCustomer,
    id,
  ])

  const [
    createLabel,
    editLabel,
    createCustomerLabel,
    createMerchantLabel,
    editCustomerLabel,
    editMerchantLabel,
    salutationLabel,
    firstNameLabel,
    familyNameLabel,
    companyLabel,
    positionLabel,
    phoneLabel,
    mobileLabel,
    emailLabel,
    websiteLabel,
    streetLabel,
    zipLabel,
    cityLabel,
    stateLabel,
    countryLabel,
    noteLabel,
    numberLabel,
    faxLabel,
    creationFailedLabel,
    creationSucceededLabel,
  ] = useMemo(() => {
    return [
      t('actions.create'),
      t('actions.edit'),
      t('customers.create'),
      t('merchants.create'),
      t('customers.edit'),
      t('merchants.edit'),
      t('contact.salutation') + '*',
      t('contact.firstName') + '*',
      t('contact.familyName') + '*',
      t('contact.company') + `*`,
      t('contact.position'),
      t('contact.phone'),
      t('contact.mobile'),
      t('contact.email') + '*',
      t('contact.website'),
      t('contact.street') + '*',
      t('contact.zip') + '*',
      t('contact.city') + '*',
      t('contact.state'),
      t('contact.country'),
      t('contact.note'),
      t('contact.number'),
      t('contact.fax'),
      t('contact.creationFailed'),
      t('contact.creationSucceeded'),
    ]
  }, [t])

  const doCreate = useCallback(() => {
    if (
      !contactInput.company ||
      !contactInput.street ||
      !contactInput.city ||
      !contactInput.country_code ||
      !contactInput.post_code ||
      !customerNumber
    ) {
      setShowFailAlert(true)
      return
    }

    if (creatingCustomer) {
      if (!id) {
        createCustomerMutation({
          variables: { customerNumber: customerNumber, contact: contactInput },
        }).catch(() => {
          setShowFailAlert(true)
        })
      } else {
        updateCustomerMutation({
          variables: { id, customerNumber: customerNumber, contact: { ...contactInput } },
        }).catch(() => {
          setShowFailAlert(true)
        })
      }
    } else {
      if (!id) {
        createMerchantMutation({
          variables: { contact: contactInput, customerNumber: customerNumber },
        }).catch(() => {
          setShowFailAlert(true)
        })
      } else {
        updateMerchantMutation({
          variables: { id, contact: contactInput, customerNumber: customerNumber },
        }).catch(() => {
          setShowFailAlert(true)
        })
      }
    }
  }, [
    contactInput,
    creatingCustomer,
    id,
    createCustomerMutation,
    customerNumber,
    updateCustomerMutation,
    createMerchantMutation,
    updateMerchantMutation,
  ])

  const pageHeading = useMemo(
    () =>
      creatingCustomer ? (id ? editCustomerLabel : createCustomerLabel) : id ? editMerchantLabel : createMerchantLabel,
    [creatingCustomer, createCustomerLabel, createMerchantLabel, id, editCustomerLabel, editMerchantLabel],
  )

  const updateContactInput = useCallback((fieldName: string, value: string) => {
    setContactInput((ci) => {
      return { ...ci, [fieldName]: value }
    })
  }, [])

  const getValue = useCallback(
    (fieldName: string) => {
      return (contactInput[fieldName as keyof ContactInput] || '') as string
    },
    [contactInput],
  )

  const updateCountryCode = useCallback(
    (value: string) => {
      const index = countryNames.findIndex((name) => value === name)

      if (index < 0) {
        return
      }
      setContactInput((ci) => {
        return { ...ci, country_code: countryCodes[index] }
      })
    },
    [countryNames, countryCodes],
  )

  return (
    <>
      <FixedActionBar
        labels={[id ? editLabel : createLabel]}
        actions={[doCreate]}
        icons={[Save]}
        buttonProps={[{ solid: true }]}
      />
      <ResultAlert
        alertText={creationFailedLabel}
        showAlert={showFailAlert}
        modifyShowAlert={setShowFailAlert}
        severity="warning"
      />
      <ResultAlert
        alertText={creationSucceededLabel}
        showAlert={showSuccessAlert}
        modifyShowAlert={setShowSuccessAlert}
      />
      <PageHeading title={pageHeading} />
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <ProgressBar
            show={
              createCustomerLoading ||
              createMerchantLoading ||
              updateCustomerLoading ||
              updateMerchantLoading ||
              fetchingCustomerData ||
              fetchingMerchantData
            }
          />
        </Grid>
        <Grid item lg={12} />
        <Grid container spacing={2} /* style={{ paddingTop: 20, paddingBottom: 50 }} */>
          {/* <Text
            label={salutationLabel}
            columns={2}
            mdColumns={2}
            //aloneInRow
            changeHandler={(val) => updateContactInput('salutation', val)}
            value={getValue('salutation')}
          />
          <Text
            label={firstNameLabel}
            columns={5}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('first_name', val)}
            value={getValue('first_name')}
          />
          <Text
            label={familyNameLabel}
            columns={5}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('last_name', val)}
            value={getValue('last_name')}
          /> */}
          <Text
            label={companyLabel}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('company', val)}
            value={getValue('company')}
          />
          {/* <Text
            label={positionLabel}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('position', val)}
            value={getValue('position')}
          />
          <Text
            label={phoneLabel}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('phone', val)}
            value={getValue('phone')}
          />
          <Text
            label={mobileLabel}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('mobile', val)}
            value={getValue('mobile')}
          />
          <Text
            label={faxLabel}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('fax', val)}
            value={getValue('fax')}
          />
          <Text
            label={emailLabel}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('email', val)}
            value={getValue('email')}
          />
          <Text
            label={websiteLabel}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('website', val)}
            value={getValue('website')}
          /> */}
          <Text
            label={streetLabel}
            mdColumns={8}
            changeHandler={(val) => updateContactInput('street', val)}
            value={getValue('street')}
          />
          <Text
            label={zipLabel}
            mdColumns={3}
            changeHandler={(val) => updateContactInput('post_code', val)}
            value={getValue('post_code')}
          />
          <Text
            label={cityLabel}
            mdColumns={4}
            changeHandler={(val) => updateContactInput('city', val)}
            value={getValue('city')}
          />
          <Text
            label={stateLabel}
            mdColumns={4}
            changeHandler={(val) => updateContactInput('state', val)}
            value={getValue('state')}
          />
          <Grid item xs={4}>
            <Dropdown
              label={countryLabel}
              preselect={[countryPreselect]}
              multiple={false}
              valueSelected={(val) => updateCountryCode(val.length > 0 ? val[0] : '')}
              values={countryNames}
            />
          </Grid>
          <Text
            label={noteLabel}
            columns={6}
            changeHandler={(val) => updateContactInput('note', val)}
            value={getValue('note')}
          />
          <Text
            label={numberLabel}
            columns={6}
            changeHandler={(val) => setCustomerNumber(val)}
            value={customerNumber}
          />

          {id && orderInfo?.getOrdersByContactID.order.length ? (
            <JournalTable
              showTitle
              filter={{ customer_id: id }}
              orders={(orderInfo?.getOrdersByContactID.order as Order[]) ?? []}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  )
}
