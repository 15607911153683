import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { FixedActionBar, StepperWizard } from '@csinstruments/cs-react-theme'
import { Forward } from '@material-ui/icons'
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined'
import { CloudLicensePoolInput, useGenerateLicenseKeyLazyQuery } from '../../../../api/models'
//import { Dropdown } from '../../partials/Inputs/Dropdown'
import { useQueryParam } from '../../../hooks/useQueryParam'
import { ResultAlert } from '../../partials/ResultAlert'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { SimpleActionPage } from '../../partials/SimpleActionPage'
import { CreateLicensePacket } from './CreateLicensePacket'
import LicensePacketPool from './LicensePacketPool'
import { LicensePacketInput } from '../../../hooks/hookhelpers/types'
import { useCreateCloudLicensePacket } from '../../../hooks/cloudLicense'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { Contact, OrderInput } from '../../../../api/customer.models'
import { PlanType } from '../../../../typeInterfaces/Interfaces'
import { caclLicMonthsToMin, calcLicIntMinutes } from '../CloudSubscriptions/CloudTypes'

export const BatchGrantLicenses: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const cloudLicenseId = useQueryParam('cloudid')
  /* const preselectedMerchant = useQueryParam('merchant')
  const preselectedCustomer = useQueryParam('customer') */
  const [showFailAlert, setShowFailAlert] = useState(false)
  const [licensePlan, setLicensePlan] = useState<PlanType>('yearly')
  const [activeStep, setActiveStep] = React.useState(0)

  const [selectedContact, setSelectedContact] = useState<Contact>()

  const [packetName, setPacketName] = useState<string>('')

  const [orderParams, setOrderParams] = useState<OrderInput>({
    order_number: '',
    order_date: new Date().toISOString(),
    contact_id: '',
  })

  const [getKey, { data: licenseNewKey }] = useGenerateLicenseKeyLazyQuery()
  const initialSteps = [t('labels.companyInfo'), t('labels.assignLicenses')]
  const steps = React.useMemo(() => {
    return initialSteps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])
  /* const { data: cloudLicInfo } = useViewCloudLicenseQuery({
    variables: {
      ID: cloudLicenseId,
    },
    skip: !cloudLicenseId,
  }) */
  const { submitFunc, error, loading, success } = useCreateCloudLicensePacket()

  const history = useHistory()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 < 0 ? 0 : prevActiveStep - 1))
  }

  const onStepClick = useCallback((step: number) => {
    setActiveStep(step)
  }, [])
  const [licensePacketInput, setLicensePacketInput] = useState<LicensePacketInput>({
    licenseKey: '',
    licensePacketName: '',
    licensePacketType: '',
    licenses: [],
    cloudLicenseId: cloudLicenseId,
    packetConfig: {
      licIntMinutes: 1,
    },
  })

  const changePacketPoolInpt = (poolInput: CloudLicensePoolInput[]) => {
    setLicensePacketInput((prv) => ({
      ...prv,
      licenses: poolInput,
    }))
  }

  useEffect(() => {
    if (licenseNewKey?.generateKey) {
      setLicensePacketInput((prv) => ({
        ...prv,
        licenseKey: licenseNewKey?.generateKey,
      }))
    }
    if (!licensePacketInput.licenseKey) {
      getKey({
        variables: {
          cloudLicenseId: cloudLicenseId,
        },
      })
    }
    if (licensePacketInput.licensePacketName && !packetName) {
      setPacketName(licensePacketInput.licensePacketName)
    }
  }, [
    licensePacketInput.licenseKey,
    licensePacketInput.licensePacketName,
    packetName,
    getKey,
    licenseNewKey?.generateKey,
    cloudLicenseId,
  ])
  useEffect(() => {
    setLicensePacketInput((prv) => ({
      ...prv,
      licensePacketName: selectedContact?.city ?? '',
    }))
    setPacketName(selectedContact?.city ?? '')
  }, [selectedContact])
  //console.log({ orderParams })
  const submitLicensePacket = useCallback(async () => {
    const { licIntMinutes } = licensePacketInput.packetConfig
    if (licIntMinutes) {
      const licMint =
        licensePlan == 'monthly' ? caclLicMonthsToMin(licIntMinutes) : calcLicIntMinutes(licIntMinutes * 365)
      await submitFunc(orderParams, {
        ...licensePacketInput,
        packetConfig: {
          licIntMinutes: licMint,
        },
      })
    }
  }, [licensePacketInput, orderParams, submitFunc])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [batchGrantLicenseLabel, grantLabel, creationFailedLabel] = useMemo(() => {
    return [
      t('licenses.batchGrantLicense'),
      t('actions.grant'),
      t('objects.merchants'),
      t('licenses.maximumActivations') + '*',
      t('objects.note'),
      t('customers.none'),
      t('licenses.creationFailed'),
      t('licenses.nLicenses'),
      t('objects.customers'),
      t('objects.orderNumber'),
    ]
  }, [t])

  const changePacketVar = (packetParams: LicensePacketInput): void => {
    setLicensePacketInput((prv) => ({
      ...prv,
      packetParams,
    }))
  }

  const fixedActionBarProps = useMemo(() => {
    if (activeStep !== 0) {
      return {
        icons: [KeyboardBackspaceOutlinedIcon, Forward],
        labels: ['actions.back', activeStep === 1 ? grantLabel : 'actions.next'],
        actions: [handleBack, activeStep === 1 ? submitLicensePacket : handleNext],
        buttonProps: [{}, { solid: true }],
      }
    }
    return {
      icons: [Forward],
      labels: ['actions.next'],
      actions: [handleNext],
      buttonProps: [{ solid: true }],
    }
  }, [activeStep, grantLabel, submitLicensePacket])
  useEffect(() => {
    if (success) {
      history.push(generatePath(ROUTES.viewCloudInfo, { id: cloudLicenseId }))
    }
    if (error) {
      setShowFailAlert(true)
    }
  }, [cloudLicenseId, error, history, success])

  const disbaledAction = useMemo(() => {
    if (activeStep === 0) {
      return !orderParams.order_number || !selectedContact?.id
    }
    return !licensePacketInput.licenses.length
  }, [activeStep, selectedContact, orderParams, licensePacketInput.licenses])
  return (
    <>
      <SimpleActionPage>
        <ResultAlert
          alertText={error ? t(error) : ''}
          showAlert={showFailAlert}
          modifyShowAlert={setShowFailAlert}
          severity="error"
        />
        <StepperWizard labels={steps} step={activeStep} onStepClicked={onStepClick} />
        <ProgressBar show={loading || false} />
        <FixedActionBar
          labels={fixedActionBarProps.labels}
          actions={fixedActionBarProps.actions}
          icons={fixedActionBarProps.icons}
          disabled={[disbaledAction, disbaledAction]}
          buttonProps={fixedActionBarProps.buttonProps}
        />
        <PageHeading title={batchGrantLicenseLabel} />

        {activeStep === 0 && (
          <CreateLicensePacket
            changePacketInput={changePacketVar}
            updateOrderParams={setOrderParams}
            packetInput={licensePacketInput}
            defaultPacketName={packetName}
            orderParams={orderParams}
            updatePacketName={setPacketName}
            updateContact={setSelectedContact}
            refreshKey={getKey}
            licensePlan={licensePlan}
            updatePlanType={setLicensePlan}
          />
        )}
        {activeStep === 1 && (
          <LicensePacketPool packetPoolInput={licensePacketInput.licenses} changePacketPool={changePacketPoolInpt} />
        )}
      </SimpleActionPage>
    </>
  )
}
