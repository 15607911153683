import Keycloak, { KeycloakConfig } from 'keycloak-js'
import { environment } from './environment'
//import { ROUTES } from './routes'

//keycloak init options
export const KEYCLOAK_INIT_OPTIONS: KeycloakConfig = {
  url: environment.LOGIN_URL,
  realm: environment.LOGIN_REALM,
  clientId: 'login',
}

export const keycloak = new Keycloak(KEYCLOAK_INIT_OPTIONS)

export const updateToken = async (): Promise<void> => {
  try {
    if (keycloak.isTokenExpired(300)) {
      const tokenUpdated = await keycloak.updateToken(5)
      if (tokenUpdated) return
    }
  } catch (error) {
    console.error('problem while refreshing token')
    console.error(error)
  }
}

export const keycloakEventHandler = async (event: unknown): Promise<void> => {
  if (event === 'onAuthSuccess') {
    if (keycloak.isTokenExpired(50)) {
      await updateToken()
    }
  }
  if (event === 'onReady') {
    await updateToken()
  }
  if (event === 'onTokenExpired') {
    await updateToken()
  }
}

export const isLoggedIn = (): boolean => {
  return !!localStorage.getItem('keycloak-token')
}

export const getTokens = (): [string | null, string | null] => {
  return [localStorage.getItem('keycloak-token'), localStorage.getItem('keycloak-refresh-token')]
}

export const setTokens = (token: string, idToken: string, refreshToken: string): void => {
  localStorage.setItem('keycloak-token', token)
  localStorage.setItem('keycloak-refresh-token', refreshToken)
  localStorage.setItem('keycloak-id-token', idToken)
}

export const keycloakLogout = async (): Promise<void> => {
  localStorage.removeItem('keycloak-token')
  localStorage.removeItem('keycloak-refresh-token')
  localStorage.removeItem('keycloak-id-token')
  return keycloak.logout({ redirectUri: environment.WEB_URL })
}

type RealmAccess = {
  licenseserver: [string]
}

export const hasUserRoles = (
  token?: string | null,
): { master: boolean; mandant: boolean; user: boolean; anyRole: boolean } => {
  if (!token) {
    return { master: false, mandant: false, user: false, anyRole: false }
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  const realmAccess = JSON.parse(jsonPayload) as RealmAccess
  //console.log('tokendecode', realmAccess)
  const master = realmAccess.licenseserver.includes('masteruser')
  const mandant = realmAccess.licenseserver.includes('mandant')
  const user = realmAccess.licenseserver.includes('user')

  return {
    master,
    mandant,
    user,
    anyRole: master || mandant || user,
  }
}
