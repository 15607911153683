import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  //Contact,
  PaginationParams,
} from '../../../../api/models'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { DataTable } from '../../partials/DataTable/DataTable'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
import { Add /* , Description */ } from '@material-ui/icons'
import { ROUTES } from '../../../helpers/routes'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import Search from '../../partials/Inputs/Search'
import { Customer, Merchant, useGetAllmerchantsQuery, useGetCustomersQuery } from '../../../../api/customer.models'
//import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
//import { createCsvExport } from '../../../helpers/csvExport'
//import { usePushDownloadFile } from '../../../hooks/usePushDownloadFile'

const contactsToRows = (contacts: Merchant[] | Customer[], searchText?: string): DataTableRow[] => {
  if (searchText) {
    const searchPattern = new RegExp(searchText)
    const searchFilter = contacts
      .slice()
      .sort()
      .map((contact: Merchant | Customer) => {
        return {
          id: contact.id,
          values: [
            contact.customer_number,
            `${contact.contact?.company}`,
            //`${contact?.contact?.salutation} ${contact?.contact?.first_name} ${contact?.contact?.last_name}`,
            /* (contact?.contact?.email ? 'Mail: ' + contact?.contact.email : '') +
              (contact?.contact?.phone ? ' Fon: ' + contact?.contact.phone : ''), */
            `${contact?.contact?.street} ${contact?.contact?.post_code} ${contact?.contact?.city}${
              contact?.contact?.country_code ? ', ' + contact?.contact?.country_code : ''
            }`,
          ],
        }
      })
    return searchFilter.filter((cfl) => searchPattern && JSON.stringify(cfl).search(searchPattern) > 0)
  }
  return contacts
    .slice()
    .sort()
    .map((contact: Merchant | Customer) => {
      return {
        id: contact.id,
        values: [
          contact.customer_number,
          `${contact.contact?.company}`,
          //`${contact?.contact?.salutation} ${contact?.contact?.first_name} ${contact?.contact?.last_name}`,
          /* (contact?.contact?.email ? 'Mail: ' + contact?.contact.email : '') +
            (contact?.contact?.phone ? ' Fon: ' + contact?.contact.phone : ''), */
          `${contact?.contact?.street} ${contact?.contact?.post_code} ${contact?.contact?.city}${
            contact?.contact?.country_code ? ', ' + contact?.contact?.country_code : ''
          }`,
        ],
      }
    })
}

export const ListContacts: FC = ({}): ReactElement => {
  const location = useLocation()
  const { t } = useTranslation()
  const history = useHistory()
  const isCustomerPage = useMemo(() => location.pathname.includes('customer'), [location])
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 50, Offset: 0 })
  const [searchTerm, setSearchTerm] = useState('')
  //const [selectedID, setSelectedID] = useState('')
  //const [modalOpen, setModalOpen] = useState(false)
  //const [deleteCustomerMutation] = useDeleteCustomerMutation()
  //const [deleteMerchantMutation] = useDeleteMerchantMutation()
  //const { downloadAction } = usePushDownloadFile()

  const { data: customerData /* refetch: refetchCustomers  */ } = useGetCustomersQuery({
    skip: !isCustomerPage,
    variables: {
      pageInfo: paginationData,
    },
  })
  const { data: merchantData /* , refetch: refetchMerchants */ } = useGetAllmerchantsQuery({
    skip: isCustomerPage,
    variables: { pagination: paginationData },
  })

  const nEntries = useMemo(() => {
    if (customerData) {
      return customerData.customers.total
    }
    if (merchantData) {
      return merchantData.merchants.total
    }

    return 0
  }, [customerData, merchantData])

  /* const csvAction = () => {
    false && createCsvExport().then((data) => downloadAction(data, 'customers.csv'))
  }
 */
  const [
    companyLabel,
    numberLabel,
    createLabel,
    pageHeadingCustomers,
    pageHeadingMerchants,
    //csvExportLabel,
    //idLabel,
    //nameLabel,
    //contactLabel,
    addressLabel,
    //licensesLabel,
    searchLabel,
  ] = useMemo(
    () => [
      t('contact.company'),
      t('contact.number'),
      t('actions.add'),
      t('objects.customers'),
      t('objects.merchants'),
      //t('actions.csvExport'),
      //t('contact.id'),
      //t('contact.name'),
      //t('contact.contact'),
      t('contact.address'),
      //t('objects.licenses'),
      t('actions.search'),
    ],
    [t],
  )

  const columns: DataTableColumn[] = useMemo(
    () => [
      { label: numberLabel },
      { label: companyLabel },
      //{ label: contactLabel },
      { label: addressLabel },
      //{ label: licensesLabel },
    ],
    [numberLabel, companyLabel, /*  contactLabel, */ addressLabel],
  )

  const rows: DataTableRow[] = useMemo(() => {
    if (isCustomerPage && customerData) {
      const contacts = customerData.customers.customers.length ? (customerData.customers.customers as Customer[]) : []
      //const ids = customerData.customers.customers.map((c) => c.id)
      //const cNumber= customerData.customers.customers.map((c) => c. )
      //const nLicenses = customerData.customers.customers.map((c) => c.nLicenses)
      return contactsToRows(contacts, searchTerm)
    } else if (!isCustomerPage && merchantData) {
      const contacts = merchantData.merchants.merchants.length ? (merchantData.merchants.merchants as Merchant[]) : []
      //const ids = merchantData.merchants.merchants.map((m) => m.id)
      //const nLicenses = merchantData.merchants.merchants.map((m) => m.nLicenses)
      return contactsToRows(contacts, searchTerm)
    }
    return []
  }, [isCustomerPage, customerData, merchantData, searchTerm])

  const goToEditPage = useCallback(
    (id: string) => {
      const route = isCustomerPage
        ? generatePath(ROUTES.editCustomer, { id })
        : generatePath(ROUTES.editMerchant, { id })
      history.push(route)
    },
    [history, isCustomerPage],
  )

  /* const deletionConfirmed = useCallback(() => {
    isCustomerPage
      ? deleteCustomerMutation({ variables: { id: selectedID } }).then(() => refetchCustomers())
      : deleteMerchantMutation({ variables: { id: selectedID } }).then(() => refetchMerchants())
  }, [selectedID, deleteCustomerMutation, deleteMerchantMutation, isCustomerPage, refetchCustomers, refetchMerchants]) */

  const goToGrantLicensePage = useCallback(
    (id: string) => {
      const route = ROUTES.grantLicense + (isCustomerPage ? `?customer=${id}` : `?merchant=${id}`)
      history.push(route)
    },
    [history, isCustomerPage],
  )

  /* const goToBatchGrantLicensePage = useCallback(
    (id: string) => {
      const route = ROUTES.batchGrantLicense + `?merchant=${id}`
      history.push(route)
    },
    [history],
  ) */

  /* const goToCreateOrderPage = useCallback(
    (id: string) => {
      const route = isCustomerPage ? ROUTES.createOrder + `?customer=${id}` : ROUTES.createOrder + `?merchant=${id}`
      history.push(route)
    },
    [history, isCustomerPage],
  ) */

  const goToAddPage = useCallback(() => {
    isCustomerPage ? history.push(ROUTES.addCustomer) : history.push(ROUTES.addMerchant)
  }, [history, isCustomerPage])

  const pageHeading = useMemo(
    () => (isCustomerPage ? `${pageHeadingCustomers} (${nEntries})` : `${pageHeadingMerchants} (${nEntries})`),
    [isCustomerPage, pageHeadingCustomers, pageHeadingMerchants, nEntries],
  )

  const searchTermChanged = useCallback((term: string) => {
    setSearchTerm(term)
  }, [])

  const tableButtonAction = useCallback(
    (id: string, action: DataItemAction) => {
      switch (action) {
        case 'edit':
          goToEditPage(id)
          break
        case 'delete':
          console.log({ id }, 'delete not implemented')
          break
        default:
          goToGrantLicensePage(id)
          break
      }
    },
    [goToEditPage, goToGrantLicensePage],
  )

  return (
    <>
      {/* <StandardConfirmationModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        text={t('modals.standardText')}
        handleConfirm={deletionConfirmed}
      /> */}
      <FixedActionBar
        labels={[/* csvExportLabel, */ createLabel]}
        actions={[/* csvAction, */ goToAddPage]}
        icons={[/* Description, */ Add]}
        buttonProps={[/* {}, */ { solid: true }]}
      />
      <PageHeading title={pageHeading} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={6}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Search label={searchLabel} changeFunction={searchTermChanged} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            // filterString={searchTerm}
            filterColumn={1}
            rows={rows}
            onButtonClicked={tableButtonAction}
            allowedActions={{ editable: true, licenseGrantable: true, sortable: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <LicenseServerPagination
            paginationParams={paginationData}
            setPaginationParams={setPaginationData}
            nTotal={nEntries}
          />
        </Grid>
      </Grid>
    </>
  )
}
