import { CsButton } from '@csinstruments/cs-react-theme'
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
  MenuItem,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  FormLabel,
} from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CloudLicenseConfigInput,
  useExtendLicensePacketExpiryMutation,
  useGetCloudLiceneseDetailsQuery,
} from '../../../../api/models'
import {
  caclLicMonthsToMin,
  calcLicIntMinutes,
  calcLicMinToMonths,
  calcMonth,
} from '../../pages/CloudSubscriptions/CloudTypes'
import { useStyles } from '../ChangeLicenseInputModal/ChangeLicenseCountModelStyle'
import { getDaysToAdd, getRemainingDays, isExpired } from '../../../helpers/dateTime'
import { DatePicker } from '../Inputs/DatePicker/DatePicker'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { PlanType } from '../../../../typeInterfaces/Interfaces'

import { OrderInput, useCreateOrderMutation, useGetOrdersByIdsQuery } from '../../../../api/customer.models'
import CloudOrder from '../../pages/CloudOrder/CloudOrder'

type ExtendLicenseModalProps = {
  cloudLicenseId: string
  packetId: string
  openExtendModal: boolean
  modalHeading: string
  closeExtendModal: (close: boolean) => void
}

export const ExtendLicenseModal: React.FC<ExtendLicenseModalProps> = ({
  cloudLicenseId,
  packetId,
  modalHeading,
  closeExtendModal,
  openExtendModal,
}: ExtendLicenseModalProps) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [initLicIntMin, setInitLicMin] = useState<number>(0)

  const [licensePlan, setLicensePlan] = useState<PlanType>('yearly')
  const [packetName, setPacketName] = useState<string>('')
  const [prevOrderId, setPreviousOrderId] = useState<string>()
  const [newOrderInput, setNewOrderInput] = useState<OrderInput>({
    order_number: '',
    order_date: new Date().toISOString(),
    contact_id: '',
  })
  //const [orderContact, setOrderContact] = useState<Contact>()

  const [currentExpiryDate, setcurrentExpiryDate] = useState<string>()
  const [currentConfig, setCurrentConfig] = useState<CloudLicenseConfigInput>({
    licIntMinutes: 1,
  })
  const { keycloak } = useKeycloak()

  const { data, loading } = useGetCloudLiceneseDetailsQuery({
    variables: {
      ID: cloudLicenseId,
    },
    skip: !cloudLicenseId || !openExtendModal,
  })

  const orderInfo = useGetOrdersByIdsQuery({
    variables: {
      orderIds: prevOrderId ? [prevOrderId] : [],
    },
    skip: !prevOrderId,
  })

  const [createNewOrder] = useCreateOrderMutation()

  const [confirmExtension, extensionResult] = useExtendLicensePacketExpiryMutation()

  useEffect(() => {
    if (data?.getCloudLicenseDetails?.cloudLicensePackets?.find((clp) => clp?.id === packetId)) {
      const { expirationDate, licenseIntMinutes, packetName, orderID, activationStatus } = {
        ...data.getCloudLicenseDetails.cloudLicensePackets.find((clp) => clp?.id === packetId),
      }
      //console.log({ orderID })
      setPreviousOrderId(orderID)
      packetName && setPacketName(packetName)
      licenseIntMinutes && setInitLicMin(licenseIntMinutes)
      expirationDate && activationStatus && setcurrentExpiryDate(expirationDate)
    }
  }, [data?.getCloudLicenseDetails, packetId])

  useEffect(() => {
    if (orderInfo.data?.getOrdersByIds.length) {
      const customer = orderInfo.data.getOrdersByIds[0]?.customer
      if (customer?.contact?.id) {
        //console.log({ customer })
        //setOrderContact(customer.contact)
        setNewOrderInput((prv) => ({
          ...prv,
          customer_id: customer.id,
          contact_id: customer?.contact?.id ?? '',
        }))
      }
      const merchant = orderInfo.data.getOrdersByIds[0]?.merchant
      if (merchant?.contact?.id) {
        //console.log({ merchant })
        //setOrderContact(merchant.contact)
        setNewOrderInput((prv) => ({
          ...prv,
          merchant_id: merchant.id,
          contact_id: merchant.contact?.id ?? '',
        }))
      }
    }
  }, [orderInfo.data?.getOrdersByIds])

  const handleClose = () => {
    setLicensePlan('yearly')
    setCurrentConfig({ licIntMinutes: 1 })
    closeExtendModal(false)
  }

  const submitNewOrder = async () => {
    try {
      if (newOrderInput.order_number) {
        //console.log({ newOrderInput })
        const resp = await createNewOrder({
          variables: {
            order: newOrderInput,
          },
        })
        return resp.data?.createOrder
      }
    } catch (err) {
      console.log({ err })
    }
  }

  const submitLicExpiryExtension = async (orderId?: string) => {
    try {
      const licInp = currentConfig.licIntMinutes ?? 0
      const defLicInp = licensePlan == 'monthly' ? caclLicMonthsToMin(licInp) : calcLicIntMinutes(licInp * 365)

      const graceLicInp = currentExpiryDate ? 0 : calcLicIntMinutes(getDaysToAdd(currentExpiryDate))
      const upLicDays = graceLicInp + defLicInp
      const upLicIntMin = currentExpiryDate && !isExpired(currentExpiryDate) ? defLicInp : upLicDays
      const succeeded = await confirmExtension({
        variables: {
          cloudLicenseId: cloudLicenseId,
          packetID: packetId,
          orderID: orderId,
          config: {
            //...currentConfig,
            licIntMinutes: upLicIntMin + initLicIntMin,
          },
        },
      })
      if (succeeded.data?.updateLicensePacketExpirationDate) {
        handleClose()
        keycloak.login()
      }
    } catch (err) {
      console.log({ err })
    }
  }
  const submitChangeInput = async () => {
    if (newOrderInput.order_number.trim()) {
      const orderId = await submitNewOrder()
      if (orderId) {
        await submitLicExpiryExtension(orderId)
      }
    } else {
      await submitLicExpiryExtension()
    }
  }
  const expiresLabel = useMemo(() => {
    const remainingDays = currentExpiryDate ? getRemainingDays(currentExpiryDate) : undefined
    if (remainingDays) {
      return remainingDays + ' ' + t('licenses.days')
    }
    return calcMonth(initLicIntMin, t)
    //return `${licMonths} ${t('licenses.nMonths')}`
  }, [currentExpiryDate, initLicIntMin, t])
  return (
    <>
      <Dialog
        className={classes.modal}
        open={openExtendModal}
        onClose={() => handleClose()}
        aria-labelledby="license-extend"
        disableEscapeKeyDown
      >
        <DialogTitle id="license-extend" disableTypography>
          <Typography variant={'h5'} align="center">
            {`${modalHeading} // ${packetName}`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography
                align="center"
                color={currentExpiryDate && parseInt(expiresLabel) < 90 ? 'error' : 'secondary'}
                gutterBottom
              >
                {t('licenses.expiresIn', { nValue: expiresLabel })}
              </Typography>
            </Grid>

            <Grid item xs={1} />
            <CloudOrder
              orderParams={newOrderInput}
              updateContact={() => {
                return
              }}
              updateOrderParams={setNewOrderInput}
            />

            <Grid item xs={8} alignItems="center" container>
              <Grid item xs={12}>
                <FormLabel component="legend">Lizenz Plan Type</FormLabel>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  value={licensePlan}
                  onChange={({ target: { value } }) => setLicensePlan(value as PlanType)}
                >
                  <FormControlLabel
                    value={'yearly'}
                    control={<Radio color="primary" />}
                    label={t('labels.yearly')}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={'monthly'}
                    control={<Radio color="primary" />}
                    label={t('labels.monthly')}
                    labelPlacement="end"
                  />

                  {/* <FormControlLabel
              value={'custom'}
              control={<Radio color="primary" />}
              label={t('labels.customDate')}
              labelPlacement="end"
            /> */}
                </RadioGroup>
              </Grid>
            </Grid>

            {licensePlan !== 'custom' ? (
              <Grid item xs={10} container justify="center" alignItems="center" spacing={1}>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <Typography>{t('labels.extendLicense')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={`${currentConfig.licIntMinutes}`}
                    variant="outlined"
                    fullWidth
                    required
                    select
                    size="small"
                    //disabled={!!cldInput.config.expirationDate}
                    onChange={({ target }) => {
                      console.log(target.value)
                      setCurrentConfig({
                        licIntMinutes: (target.value as unknown) as number,
                      })
                    }}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((nValue) => (
                      <MenuItem key={nValue} value={nValue}>
                        {nValue}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    {licensePlan === 'monthly' ? t('labels.nMonths') : t('labels.nYears') + ' ' + t('labels.fromToday')}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    label={t('labels.extendLicense')}
                    xs={5}
                    preselect={currentConfig.expirationDate ? new Date(currentConfig.expirationDate) : new Date()}
                    onChange={(d) => {
                      setCurrentConfig({ expirationDate: d.toISOString() })
                    }}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CsButton onClick={() => handleClose()}>{t('modals.cancel')}</CsButton>

          <CsButton disabled={loading || extensionResult.loading} onClick={submitChangeInput} solid={true}>
            {t('modals.confirm')}
          </CsButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExtendLicenseModal
