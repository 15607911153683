import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { FixedActionBar, StepperWizard } from '@csinstruments/cs-react-theme'
//import { Box, Grid } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { Forward } from '@material-ui/icons'
//import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined'

import { Redirect } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import SimpleActionPage from '../../partials/SimpleActionPage'
//import OrganizationLicenseInput from './OrganizationLicenseInput'
import { caclLicMonthsToMin, calcLicIntMinutes, initCloudLicenseInputVar } from './CloudTypes'
import {
  //CloudLicenseConfigInput,
  CloudLicensePoolInput,
  useGenerateLicenseKeyLazyQuery,
  AdminLicenseInput,
} from '../../../../api/models'
import { OrgUserLicInput } from './OrgUserLicInput'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import OrganizationCompanyInfo from './OrganizationCompanyInfo'
import { ResultAlert } from '../../partials/ResultAlert'
import { transformRealmName } from '../../../helpers/stringHelpers'
import { CloudLicenseInput } from '../../../hooks/hookhelpers/types'
import { useCreateCloudLicense } from '../../../hooks/cloudLicense'
import { Contact, OrderInput } from '../../../../api/customer.models'
import { PlanType } from '../../../../typeInterfaces/Interfaces'

const CreateEditCloud: React.FC = () => {
  const { t } = useTranslation('common')
  const [cloudLicenseVariables, setCloudLicenseVariables] = useState<CloudLicenseInput>(initCloudLicenseInputVar)

  const [activeStep, setActiveStep] = React.useState(0)
  const [defaultRealmName, setDefaultRealmName] = useState<string>('')
  const [selectedContact, setSelectedContact] = useState<Contact>()
  const [licensePlan, setLicensePlan] = useState<PlanType>('yearly')

  const [orderParams, setOrderParams] = useState<OrderInput>({
    order_number: '',
    order_date: new Date().toISOString(),
    contact_id: '',
  })

  const { loading, success, error, submitFunc } = useCreateCloudLicense()

  const steps = React.useMemo(() => {
    return [t('labels.companyInfo'), t('labels.assignLicenses')]
  }, [t])

  const [showError, setShowError] = React.useState<boolean>(false)
  const [getKey, { data: licNewKey }] = useGenerateLicenseKeyLazyQuery()
  //onst { data: realmInfo } = useGetIaServicRealmInfoQuery()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 <= 0 ? 0 : prevActiveStep - 1))
  }

  const onStepClick = useCallback((step: number) => {
    setActiveStep(step)
  }, [])

  const handleOrgLicInpVar = useCallback((cldInputParams: CloudLicenseInput) => {
    setCloudLicenseVariables((prv) => ({
      ...prv,
      ...cldInputParams,
    }))
  }, [])

  const handleOrgUserLicInpt = useCallback(
    (licPoolInp: CloudLicensePoolInput[], singleUserLicInp: AdminLicenseInput[]) => {
      setCloudLicenseVariables({ ...cloudLicenseVariables, licenses: licPoolInp, adminLicenses: singleUserLicInp })
    },
    [cloudLicenseVariables],
  )

  const handleContact = (contact: Contact) => {
    setSelectedContact(contact)
  }

  useEffect(() => {
    if (licNewKey?.generateKey) {
      setCloudLicenseVariables((prv) => ({
        ...prv,
        licenseKey: licNewKey.generateKey,
      }))
    }
    if (!cloudLicenseVariables.licenseKey) {
      getKey()
    }
    if (cloudLicenseVariables.organizationName && !defaultRealmName) {
      setDefaultRealmName(cloudLicenseVariables.organizationName)
    }
  }, [
    cloudLicenseVariables.licenseKey,
    cloudLicenseVariables.organizationName,
    defaultRealmName,
    getKey,
    licNewKey?.generateKey,
  ])
  useEffect(() => {
    setDefaultRealmName(transformRealmName(selectedContact?.company + orderParams.order_number))
  }, [orderParams.order_number, selectedContact, setDefaultRealmName])

  const createCloudLicense = useCallback(async () => {
    if (cloudLicenseVariables.config.licIntMinutes) {
      const { licIntMinutes } = cloudLicenseVariables.config
      const cldSubmitInput: CloudLicenseInput = {
        ...cloudLicenseVariables,
        config: {
          licIntMinutes:
            licensePlan == 'monthly' ? caclLicMonthsToMin(licIntMinutes) : calcLicIntMinutes(licIntMinutes * 365),
        },
        organizationName: defaultRealmName,
      }
      await submitFunc(orderParams, cldSubmitInput)
    }
  }, [cloudLicenseVariables, defaultRealmName, orderParams, submitFunc])

  const fixedActionBarProps = useMemo(() => {
    if (activeStep !== 0) {
      return {
        icons: [ArrowBackIcon, activeStep === 1 ? SaveIcon : Forward],
        labels: ['actions.back', activeStep === 1 ? 'actions.create' : 'actions.next'],
        actions: [handleBack, activeStep === 1 ? createCloudLicense : handleNext],
        buttonProps: [{}, { solid: true }],
      }
    }
    return {
      icons: [Forward],
      labels: ['actions.next'],
      actions: [handleNext],
      buttonProps: [{ solid: true }],
    }
  }, [activeStep, createCloudLicense])

  useEffect(() => {
    if (!!error) {
      setShowError(true)
    }
  }, [error])

  if (success) {
    return <Redirect to={{ pathname: ROUTES.licenses }} />
  }

  return (
    <>
      <SimpleActionPage>
        <StepperWizard labels={steps} step={activeStep} onStepClicked={onStepClick} />
        <ProgressBar show={loading || false} />
        <ResultAlert
          severity="error"
          alertText={error ? t(error) : 'something wrong'}
          showAlert={showError}
          modifyShowAlert={setShowError}
        />

        {activeStep === 0 && (
          <OrganizationCompanyInfo
            defaultRealmName={defaultRealmName}
            setDefaultRealmName={setDefaultRealmName}
            cldInput={cloudLicenseVariables}
            changeOrg={handleOrgLicInpVar}
            refreshKey={getKey}
            orderParams={orderParams}
            updateOrderParams={setOrderParams}
            updateContact={handleContact}
            licensePlan={licensePlan}
            updatePlanType={setLicensePlan}
          />
        )}

        {activeStep === 1 && (
          <OrgUserLicInput
            LicPoolInp={cloudLicenseVariables.licenses}
            singleUserLicInp={cloudLicenseVariables.adminLicenses}
            changeInp={handleOrgUserLicInpt}
          />
        )}

        <FixedActionBar
          actions={fixedActionBarProps.actions}
          labels={fixedActionBarProps.labels}
          largeButtons
          //fixedButtonSizes={[]}
          icons={fixedActionBarProps.icons}
          buttonProps={fixedActionBarProps.buttonProps}
          //disabled={[]}
        />
      </SimpleActionPage>
    </>
  )
}

export default CreateEditCloud
