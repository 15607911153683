import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import RefreshIcon from '@material-ui/icons/Refresh'
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded'

import CloudOrder from '../CloudOrder/CloudOrder'
import { CloudLicenseInput } from '../../../hooks/hookhelpers/types'
import { OrderInput, Contact } from '../../../../api/customer.models'
import { PlanType } from '../../../../typeInterfaces/Interfaces'

type OrganizationCompanyProps = {
  cldInput: CloudLicenseInput
  defaultRealmName: string
  setDefaultRealmName: React.Dispatch<React.SetStateAction<string>>
  changeOrg: (cldVariables: CloudLicenseInput) => void
  refreshKey: () => void
  orderParams: OrderInput
  updateOrderParams: (orderInp: OrderInput) => void
  updateContact: (contact: Contact) => void
  licensePlan: PlanType
  updatePlanType: (plan: PlanType) => void
}

const OrganizationCompanyInfo: React.FC<OrganizationCompanyProps> = ({
  cldInput,
  changeOrg,
  refreshKey,
  defaultRealmName,
  setDefaultRealmName,
  orderParams,
  updateOrderParams,
  updateContact,
  licensePlan,
  updatePlanType,
}: OrganizationCompanyProps) => {
  const [editOrgName, setEditOrgName] = useState<boolean>(false)

  const { t } = useTranslation('common')

  return (
    <>
      <Grid item container spacing={3} justify="center" direction="column">
        {/* <ProgressBar show={loadingContactOrders} /> */}

        <Grid item md={12} lg={12}>
          <TextField
            value={cldInput.licenseKey}
            label={t('licenses.licenseKey')}
            variant="filled"
            required
            fullWidth
            helperText={!cldInput.licenseKey && <>Generate New license key</>}
            disabled={!!cldInput.licenseKey}
            onChange={({ target }) =>
              changeOrg({
                ...cldInput,
                licenseKey: target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button title={t('actions.generate')} disabled={!!cldInput.licenseKey} onClick={refreshKey}>
                    <RefreshIcon />
                  </Button>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyRoundedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <CloudOrder orderParams={orderParams} updateOrderParams={updateOrderParams} updateContact={updateContact} />
        <Grid item container justify="flex-start" alignItems="center" alignContent="flex-start" spacing={1}>
          <Grid item xs={4}>
            <Typography>{t('licenses.nMonths')}</Typography>
            <Grid item xs={8} alignItems="center" container>
              <Grid item xs={12}>
                <FormLabel component="legend">Lizenz Plan Type</FormLabel>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  value={licensePlan}
                  onChange={({ target: { value } }) => updatePlanType(value as PlanType)}
                >
                  <FormControlLabel
                    value={'yearly'}
                    control={<Radio color="primary" />}
                    label={t('labels.yearly')}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={'monthly'}
                    control={<Radio color="primary" />}
                    label={t('labels.monthly')}
                    labelPlacement="end"
                  />

                  {/* <FormControlLabel
              value={'custom'}
              control={<Radio color="primary" />}
              label={t('labels.customDate')}
              labelPlacement="end"
            /> */}
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <TextField
              value={cldInput.config.licIntMinutes}
              variant="outlined"
              fullWidth
              required
              size="small"
              select
              //disabled={!!cldInput.config.expirationDate}
              onChange={({ target }) =>
                changeOrg({
                  ...cldInput,
                  config: {
                    ...cldInput.config,
                    licIntMinutes: (target.value as unknown) as number,
                  },
                })
              }
            >
              {Array.from({ length: 12 }, (_, i) => i + 1).map((nValue) => (
                <MenuItem key={nValue} value={nValue}>
                  {nValue}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <Typography>{licensePlan === 'monthly' ? t('labels.nMonths') : t('labels.nYears')}</Typography>
          </Grid>
        </Grid>

        <Grid item md={12} lg={12}>
          <TextField
            value={defaultRealmName}
            //label={t('contact.company')}
            label={'Realm'}
            variant="outlined"
            required
            fullWidth
            disabled={!editOrgName}
            placeholder={'newFirmaRealm'}
            type="text"
            error={/[ `!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/.test(defaultRealmName)}
            //helperText={!OrganizationName && <p>Enter Organization Name</p>}
            onChange={({ target }) => setDefaultRealmName(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button title={t('actions.edit')} onClick={() => setEditOrgName(!editOrgName)}>
                    <EditOutlinedIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OrganizationCompanyInfo
