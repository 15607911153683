import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};


export type CloudLicenseInfo = {
  __typename?: 'CloudLicenseInfo';
  /** organizations */
  cloudLicenseOrganizations: Array<Maybe<CloudLicenseOrganization>>;
};

export type CloudLicenseOrganization = {
  __typename?: 'CloudLicenseOrganization';
  /** id of the realm */
  realmId: Scalars['String'];
  /** displayname of the organization */
  displayName: Scalars['String'];
  /** cloud users */
  cloudLicenseUsers: Array<Maybe<CloudLicenseUser>>;
};

export type CloudLicenseUser = {
  __typename?: 'CloudLicenseUser';
  /** email address of the user */
  email: Scalars['String'];
  orgUserId: Scalars['String'];
  isRootAdmin: Scalars['Boolean'];
};

export enum LicenseUser {
  /** regular user */
  Regular = 'regular',
  /** cs user - no license required */
  Csuser = 'csuser'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * register a user with the given email and returns the user Id. The user still
   * has to register normally but will then get this id. (only for development purposes)
   */
  registerUserId: Scalars['String'];
  /** UserID is the id of a registered user which becomes the root admin. If not user ID from the access token is used. */
  activateOrganization?: Maybe<Scalars['String']>;
  /**
   * activate a license packet for an exisiting organization. The user with the given email will be created as local admin (but with
   * no access to existing companies/projects). RealmId must be id of the realm for which the license packet was created. Packetname
   * can be arbitrary string. Licenses can be marked as reserved so that they can not be assigned freely - should be false for now.
   */
  activateLicensePacket?: Maybe<Scalars['String']>;
  /**
   * Update organization / cloud license (usually used to update a trial license). Note the invoker
   * must be the root admin of the organization. That is each user can only update the trial license
   * he has created.
   */
  updateOrganizationLicense?: Maybe<Scalars['String']>;
  /** change the organization (realm) name shown (does not correspond to the keycloak realmname) */
  changeDisplayName?: Maybe<Scalars['Boolean']>;
  /** change user's organization email  */
  updateOrgUserEmail: Scalars['Boolean'];
  /** deactivate organization - all data will be removed */
  deactivateOrganization?: Maybe<Scalars['Boolean']>;
  /** update Packet Name. Packet Name must be unique per organization. */
  changePacketName?: Maybe<Scalars['String']>;
};


export type MutationRegisterUserIdArgs = {
  email: Scalars['String'];
};


export type MutationActivateOrganizationArgs = {
  licenseString: Scalars['String'];
  displayName: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
};


export type MutationActivateLicensePacketArgs = {
  licenseString: Scalars['String'];
  realmId: Scalars['String'];
  packetName?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  reserved: Scalars['Boolean'];
};


export type MutationUpdateOrganizationLicenseArgs = {
  licenseString: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationChangeDisplayNameArgs = {
  realmName: Scalars['String'];
  displayName: Scalars['String'];
};


export type MutationUpdateOrgUserEmailArgs = {
  realmName: Scalars['String'];
  newEmail: Scalars['String'];
};


export type MutationDeactivateOrganizationArgs = {
  licenseString: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationChangePacketNameArgs = {
  realmName: Scalars['String'];
  packetId: Scalars['String'];
  packetName: Scalars['String'];
};

export type OrganizationAccess = {
  __typename?: 'OrganizationAccess';
  /** id of the organization */
  organizationID: Scalars['String'];
  /** name of the realm - name used by iam */
  organizationName: Scalars['String'];
  /** name of the organization which should be displayed to the user - instead of realmName */
  displayName: Scalars['String'];
  /** username in the realm */
  username: Scalars['String'];
  /** list of roles the user has in this realm */
  appRoles: Array<UpAppRole>;
  /** licenses assigned to the user */
  licenses: Array<SingleUserLicense>;
};

export type Query = {
  __typename?: 'Query';
  /** get the organizations to which the user has access */
  getOrganizationAccess: Array<OrganizationAccess>;
  /**
   * returns true if the invoker is the root admin (false otherwise). Only the root
   * admin should be allowed to clear database.
   */
  isRootAdmin: Scalars['Boolean'];
  isRootAdminEx: Array<Maybe<Scalars['Boolean']>>;
  getCloudLicenseInfo: CloudLicenseInfo;
};


export type QueryIsRootAdminArgs = {
  realmName: Scalars['String'];
};


export type QueryIsRootAdminExArgs = {
  realmName: Array<Maybe<Scalars['String']>>;
};


export type QueryGetCloudLicenseInfoArgs = {
  organizationID?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** license assigned to a user */
export type SingleUserLicense = {
  __typename?: 'SingleUserLicense';
  /** id of the license packet to which this license belongs */
  licensePacketId: Scalars['String'];
  /** id of the group (application), e.g. leak-reporter */
  licenseGroupID: Scalars['String'];
  /** id type of the license e.g. user or guest */
  licenseTypeID: Scalars['String'];
  /** id of the license */
  licenseID: Scalars['String'];
  /** date until the license is valid */
  expirationDate: Scalars['String'];
  /** if null or regular a license of the cloud licenses. csuser is a free license */
  regularLicense?: Maybe<LicenseUser>;
};

/** App specific user roles */
export type UpAppRole = {
  __typename?: 'UPAppRole';
  /** id of the application */
  appID: Scalars['String'];
  /** name of the app to which the role list corresponds */
  appName: Scalars['String'];
  /** roles granted to the user for the given app */
  roles: Array<UpAppRoleRole>;
};

export type UpAppRoleRole = {
  __typename?: 'UPAppRoleRole';
  /** id of the role */
  roleID: Scalars['String'];
  /** name of the role */
  roleName: Scalars['String'];
  /** key of the role, null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export type GetCloudLicenseInfoQueryVariables = Exact<{
  organizationIDs?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetCloudLicenseInfoQuery = (
  { __typename?: 'Query' }
  & { getCloudLicenseInfo: (
    { __typename?: 'CloudLicenseInfo' }
    & { cloudLicenseOrganizations: Array<Maybe<(
      { __typename?: 'CloudLicenseOrganization' }
      & Pick<CloudLicenseOrganization, 'realmId' | 'displayName'>
      & { cloudLicenseUsers: Array<Maybe<(
        { __typename?: 'CloudLicenseUser' }
        & Pick<CloudLicenseUser, 'email' | 'orgUserId' | 'isRootAdmin'>
      )>> }
    )>> }
  ) }
);

export type DeactivateOrganizationMutationVariables = Exact<{
  licenseString: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type DeactivateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deactivateOrganization'>
);


export const GetCloudLicenseInfoDocument = gql`
    query getCloudLicenseInfo($organizationIDs: [String]) {
  getCloudLicenseInfo(organizationID: $organizationIDs) {
    cloudLicenseOrganizations {
      realmId
      displayName
      cloudLicenseUsers {
        email
        orgUserId
        isRootAdmin
      }
    }
  }
}
    `;

/**
 * __useGetCloudLicenseInfoQuery__
 *
 * To run a query within a React component, call `useGetCloudLicenseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudLicenseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudLicenseInfoQuery({
 *   variables: {
 *      organizationIDs: // value for 'organizationIDs'
 *   },
 * });
 */
export function useGetCloudLicenseInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCloudLicenseInfoQuery, GetCloudLicenseInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCloudLicenseInfoQuery, GetCloudLicenseInfoQueryVariables>(GetCloudLicenseInfoDocument, options);
      }
export function useGetCloudLicenseInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCloudLicenseInfoQuery, GetCloudLicenseInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCloudLicenseInfoQuery, GetCloudLicenseInfoQueryVariables>(GetCloudLicenseInfoDocument, options);
        }
export type GetCloudLicenseInfoQueryHookResult = ReturnType<typeof useGetCloudLicenseInfoQuery>;
export type GetCloudLicenseInfoLazyQueryHookResult = ReturnType<typeof useGetCloudLicenseInfoLazyQuery>;
export type GetCloudLicenseInfoQueryResult = Apollo.QueryResult<GetCloudLicenseInfoQuery, GetCloudLicenseInfoQueryVariables>;
export const DeactivateOrganizationDocument = gql`
    mutation deactivateOrganization($licenseString: String!, $realmName: String!) {
  deactivateOrganization(licenseString: $licenseString, realmName: $realmName)
}
    `;
export type DeactivateOrganizationMutationFn = Apollo.MutationFunction<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>;

/**
 * __useDeactivateOrganizationMutation__
 *
 * To run a mutation, you first call `useDeactivateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateOrganizationMutation, { data, loading, error }] = useDeactivateOrganizationMutation({
 *   variables: {
 *      licenseString: // value for 'licenseString'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useDeactivateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>(DeactivateOrganizationDocument, options);
      }
export type DeactivateOrganizationMutationHookResult = ReturnType<typeof useDeactivateOrganizationMutation>;
export type DeactivateOrganizationMutationResult = Apollo.MutationResult<DeactivateOrganizationMutation>;
export type DeactivateOrganizationMutationOptions = Apollo.BaseMutationOptions<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>;