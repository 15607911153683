/* eslint-disable @typescript-eslint/no-unused-vars */
import { userInfo } from 'os'
import { useEffect, useMemo, useState } from 'react'
import {
  OrderInfo,
  useGetOrdersByIdsLazyQuery,
  useGetOrdersByIdsQuery,
  OrderInput,
  useCreateOrderMutation,
} from '../../api/customer.models'

import {
  CloudLicenseDetails,
  CloudLicensePacketDetails,
  //ContactOrders,
  PaginationParams,
  useCreateCloudLicenseMutation,
  useCreateLicensePacketMutation,
  useGetActivatedCloudLicensesQuery,
  useGetCloudLicensesListQuery,
} from '../../api/models'
import { useGetCloudLicenseInfoLazyQuery } from '../../api/organization.models'
import {
  UserProfileLight,
  useGetUserProfilesLazyQuery,
  useGetUserProfilesByEmailIdLazyQuery,
} from '../../api/user.models'
import { caclLicMonthsToMin, calcLicMinToMonths } from '../components/pages/CloudSubscriptions/CloudTypes'
import { useGetCloudLicenses } from '../components/partials/CloudInfoProvider/CloudInfoProvider'
import { sortDateString } from '../helpers/stringHelpers'
import { CloudLicenseInput, LicensePacketInput } from './hookhelpers/types'
export type CloudLicenseType = {
  cloudLicensesCreated?: CloudLicenseDetails
  registrationData?: string[]
  adminData?: UserProfileLight
  orderInfo?: OrderInfo
}
export const useGetAllCloudLicenses = (
  pagination: PaginationParams,
): { data: CloudLicenseType[]; loadingData: boolean; refresh: () => void; total?: number } => {
  const { data: createdCloudList, refetch: fetchAgain } = useGetCloudLicensesListQuery({
    variables: {
      paginationInfo: pagination,
    },
  })
  const [getOrdersInfo, { data: cloudOrdersInfo }] = useGetOrdersByIdsLazyQuery()
  const [getProfiles, { data: profileInfo, loading: loadingProfile }] = useGetUserProfilesLazyQuery()

  useEffect(() => {
    if (createdCloudList?.getCloudLicensesList.cloudLicensesList.length) {
      const filteredCloudList = createdCloudList.getCloudLicensesList.cloudLicensesList.filter(
        (cl) => cl?.order_id !== 'trial-version',
      )
      const editor_ids = filteredCloudList.map((cl) => cl?.editorId ?? '')
      const order_Ids = filteredCloudList.map((cl) => cl?.order_id ?? '')
      const packetOrderIDs = filteredCloudList
        .map((cl) => cl?.cloudLicensePackets?.map((pkt) => pkt?.orderID ?? '') ?? [])
        .flat()
      const getProfileIDs = editor_ids.filter((id, index) => id && editor_ids.findIndex((eID) => eID === id) === index)
      editor_ids.length &&
        getProfiles({
          variables: {
            userIds: getProfileIDs,
          },
        })
      order_Ids.length &&
        getOrdersInfo({
          variables: {
            orderIds: packetOrderIDs.filter((id) => id),
          },
        })
    }
  }, [createdCloudList?.getCloudLicensesList, getOrdersInfo, getProfiles])

  const ordersInfo = useMemo(() => {
    if (cloudOrdersInfo?.getOrdersByIds.length) {
      return cloudOrdersInfo?.getOrdersByIds
    } else return []
  }, [cloudOrdersInfo])

  const totalLicenses = useMemo(() => {
    return createdCloudList?.getCloudLicensesList.totalSize
  }, [createdCloudList?.getCloudLicensesList.totalSize])

  const companyLicenses = useMemo(() => {
    if (
      profileInfo?.getUserProfiles &&
      ordersInfo.length &&
      createdCloudList?.getCloudLicensesList.cloudLicensesList.length
    ) {
      const { getCloudLicensesList } = createdCloudList
      const { getUserProfiles } = profileInfo
      const temp: CloudLicenseType[] = getCloudLicensesList.cloudLicensesList
        ?.filter((cl) => cl?.order_id !== 'trial-version')
        .map((c) => ({
          cloudLicensesCreated: {
            ...c,
            licenseIntMinutes: c?.licenseIntMinutes ?? 0,
            cloudLicensePackets: c?.cloudLicensePackets?.map((cpacket) => ({
              ...cpacket,
              orderID: ordersInfo.find((ord) => ord?.order?.id === cpacket?.orderID)?.order?.order_number ?? '',
            })),
            editorId: getUserProfiles.find((p) => p?.userId === c?.editorId)?.Email ?? '',
          } as CloudLicenseDetails,
          orderInfo: ordersInfo.find((ord) => ord?.order?.id === c?.order_id) as OrderInfo,
        }))
        .sort((a, b) => (a.cloudLicensesCreated.dateModified > b.cloudLicensesCreated.dateModified ? -1 : 1))
      return temp
      //setCompanyLicenses(temp)
    }
    return []
  }, [createdCloudList, ordersInfo, profileInfo])

  return {
    data: companyLicenses,
    total: totalLicenses,
    loadingData: loadingProfile,
    refresh: fetchAgain,
  }
}

export const useGetCloudInstances = (
  pagination: PaginationParams,
  fetchTrialLicenses: boolean,
  searchString?: string,
): { data: CloudLicenseType[]; loadingData: boolean; refresh: () => void; total?: number } => {
  const [companyLicenses, setCompanyLicenses] = useState<CloudLicenseType[]>([])
  const [orderIds, setOrderIds] = useState<string[]>([])
  const [editorIds, setEditorIds] = useState<string[]>([])

  const { data: activatedList, refetch } = useGetActivatedCloudLicensesQuery({
    variables: { paginationInfo: pagination, fetchTrial: fetchTrialLicenses },
  })

  const [getRealmInfo, { data: realmInfo }] = useGetCloudLicenseInfoLazyQuery()
  const { data: cloudOrdersInfo } = useGetOrdersByIdsQuery({
    variables: {
      orderIds: orderIds,
    },
    skip: !orderIds.length,
  })
  //const { data: allOrderswithContact, loading: loadingOrders } = useGetAllContactOrdersQuery()
  const [getUserProfilesList, { data: profileInfo, loading: loadingProfile }] = useGetUserProfilesLazyQuery()
  const [getUserbyEmail, { data: userInfo }] = useGetUserProfilesByEmailIdLazyQuery()

  useEffect(() => {
    if (activatedList?.getActivatedCloudLicenses.cloudLicensesActivations.length) {
      const filteredCloudList = activatedList?.getActivatedCloudLicenses.cloudLicensesActivations.filter(
        (cl) => cl?.order_id !== 'trial-version',
      )
      const editor_ids = filteredCloudList.map((cl) => cl?.editorId ?? '')
      const order_Ids = filteredCloudList.flatMap(
        (cl) => cl?.cloudLicensePackets?.map((p) => p?.orderID as string) ?? [],
      )
      editor_ids.length && setEditorIds(editor_ids)
      order_Ids.length && setOrderIds(order_Ids.filter((o) => o))
    }
  }, [activatedList?.getActivatedCloudLicenses.cloudLicensesActivations])

  const ordersInfo = useMemo(() => {
    if (cloudOrdersInfo?.getOrdersByIds.length) {
      return cloudOrdersInfo?.getOrdersByIds
    }
  }, [cloudOrdersInfo])

  const totalLicenses = useMemo(() => {
    return activatedList?.getActivatedCloudLicenses.totalSize
  }, [activatedList?.getActivatedCloudLicenses.totalSize])

  useEffect(() => {
    if (activatedList?.getActivatedCloudLicenses.cloudLicensesActivations) {
      const realmIds = activatedList.getActivatedCloudLicenses.cloudLicensesActivations
        .filter((c) => c?.realmId && c.order_id !== 'trial-version')
        .map((cl) => cl?.realmId) as string[]
      getRealmInfo({
        variables: {
          organizationIDs: realmIds,
        },
      })
    }
  }, [activatedList?.getActivatedCloudLicenses])

  useEffect(() => {
    if (editorIds.length && realmInfo?.getCloudLicenseInfo) {
      const cldUserEmailIds = realmInfo.getCloudLicenseInfo.cloudLicenseOrganizations.flatMap((o) =>
        o?.cloudLicenseUsers.map((u) => (u?.isRootAdmin && u?.email ? u.email : '')),
      )
      const allUserIDs = [...editorIds.filter((id) => id)]
      const uniqueUserIds = allUserIDs.filter((id, index) => allUserIDs.findIndex((eID) => eID === id) == index)
      getUserProfilesList({
        variables: {
          userIds: uniqueUserIds,
        },
      })
      const uniqueEmailIds = cldUserEmailIds.filter(
        (emailID, ix) => emailID && cldUserEmailIds.findIndex((eID) => eID === emailID) === ix,
      ) as string[]
      getUserbyEmail({
        variables: {
          emailIDs: uniqueEmailIds,
        },
      })
    }
  }, [editorIds, getUserProfilesList, getUserbyEmail, realmInfo?.getCloudLicenseInfo])

  useEffect(() => {
    if (
      realmInfo &&
      ordersInfo?.length &&
      activatedList?.getActivatedCloudLicenses.cloudLicensesActivations.length &&
      (profileInfo?.getUserProfiles?.length || userInfo?.getUserProfilesByEmailID?.length)
    ) {
      const { getActivatedCloudLicenses } = activatedList
      const getUserProfiles = profileInfo?.getUserProfiles
      const userInfos = userInfo?.getUserProfilesByEmailID?.length ? userInfo.getUserProfilesByEmailID : []
      const {
        getCloudLicenseInfo: { cloudLicenseOrganizations },
      } = realmInfo
      const temp: CloudLicenseType[] = getActivatedCloudLicenses.cloudLicensesActivations
        ?.filter((cl) => cl?.order_id !== 'trial-version')
        .map((c) => {
          const userActivated =
            cloudLicenseOrganizations
              .find((co) => co?.realmId == c?.realmId)
              ?.cloudLicenseUsers.find((ua) => !!ua?.isRootAdmin)?.email ?? ''
          const orgUsers = cloudLicenseOrganizations.find((co) => co?.realmId == c?.realmId)?.cloudLicenseUsers
          const packetAdmin = (packt?: CloudLicensePacketDetails): string => {
            const defaultPacketAdmin = userActivated
            const packAdmin = orgUsers?.find((ouser) => ouser?.orgUserId === packt?.activatedBy)?.email ?? ''
            return packt?.packetType === 'regular' ? defaultPacketAdmin : packAdmin
          }
          const getTypeUserEmail = (userId?: string): string => {
            return orgUsers?.find((orgUser) => orgUser?.orgUserId === userId)?.email ?? ''
          }
          return {
            cloudLicensesCreated: {
              ...c,
              activatedBy: userActivated,
              organizationName:
                cloudLicenseOrganizations.find((org) => org?.realmId === c?.realmId)?.displayName ??
                c?.organizationName,
              editorId: getUserProfiles?.find((user) => user?.userId === c?.editorId)?.Email ?? '',
              cloudLicensePackets: c?.cloudLicensePackets?.map((pkt) => ({
                ...pkt,
                orderID: ordersInfo.find((ord) => ord?.order?.id === pkt?.orderID)?.order?.order_number ?? '',
                cloudlicensePacketGroups: pkt?.cloudlicensePacketGroups.map((pktGroup) => ({
                  ...pktGroup,
                  licenseGroupTypeDetails: pktGroup?.licenseGroupTypeDetails.map((grpType) => ({
                    ...grpType,
                    licenseUsers: grpType?.licenseUsers?.map((typeUser) => ({
                      ...typeUser,
                      userId: typeUser?.userId ? getTypeUserEmail(typeUser?.userId) : undefined,
                    })),
                  })),
                })),
                activatedBy: pkt ? packetAdmin(pkt) : '',
              })),
            } as CloudLicenseDetails,
            adminData: userInfos?.find((prof) =>
              orgUsers?.some((ouser) => ouser?.email === prof?.Email),
            ) as UserProfileLight,
            registrationData: orgUsers?.length ? orgUsers?.map((u) => (u?.email ? u.email : '')) : [],
            orderInfo: ordersInfo.find((ord) => ord?.order?.id === c?.order_id) as OrderInfo,
          }
        })
        .sort((a, b) => sortDateString(a.cloudLicensesCreated.expirationDate, b.cloudLicensesCreated.expirationDate))
      //console.log({ temp })
      setCompanyLicenses(temp)
    }
  }, [activatedList, ordersInfo, profileInfo, realmInfo, userInfo])

  return {
    data: companyLicenses,
    total: totalLicenses,
    loadingData: loadingProfile,
    refresh: refetch,
  }
}

export const useGetCloudTrialInstances = (
  pagination: PaginationParams,
  fetchTrialLicenses: boolean,
): { data: CloudLicenseType[]; loadingData: boolean; refresh: () => void; total?: number } => {
  const [companyLicenses, setCompanyLicenses] = useState<CloudLicenseType[]>([])
  const [editorIds, setEditorIds] = useState<string[]>([])

  const { data: activatedList, refetch, loading } = useGetActivatedCloudLicensesQuery({
    skip: !fetchTrialLicenses,

    variables: { paginationInfo: pagination, fetchTrial: fetchTrialLicenses },
  })

  const [getRealmInfo, { data: realmInfo }] = useGetCloudLicenseInfoLazyQuery()

  const [getUserProfilesList, { data: profileInfo }] = useGetUserProfilesLazyQuery()

  useEffect(() => {
    if (activatedList?.getActivatedCloudLicenses.cloudLicensesActivations.length) {
      const filteredCloudList = activatedList?.getActivatedCloudLicenses.cloudLicensesActivations.filter(
        (cl) => cl?.order_id === 'trial-version',
      )
      const editor_ids = filteredCloudList.map((cl) => cl?.editorId ?? '')
      //const order_Ids = filteredCloudList.flatMap((cl) => cl?.cloudLicensePackets?.map((p) => p?.orderID ?? '') ?? [])
      editor_ids.length && setEditorIds(editor_ids)
      //order_Ids.length && setOrderIds(order_Ids)
    }
  }, [activatedList?.getActivatedCloudLicenses.cloudLicensesActivations])

  const totalLicenses = useMemo(() => {
    return activatedList?.getActivatedCloudLicenses.totalSize
  }, [activatedList?.getActivatedCloudLicenses.totalSize])

  useEffect(() => {
    if (activatedList?.getActivatedCloudLicenses.cloudLicensesActivations) {
      const realmIds = activatedList.getActivatedCloudLicenses.cloudLicensesActivations
        .filter((c) => c?.realmId && c.order_id === 'trial-version')
        .map((cl) => cl?.realmId) as string[]
      getRealmInfo({
        variables: {
          organizationIDs: realmIds,
        },
      })
    }
  }, [activatedList?.getActivatedCloudLicenses])

  useEffect(() => {
    if (editorIds.length) {
      //const fetchUsers = realmInfo.cloudLicenseUsers.map((u) => u?.userId ?? '')
      const allUserIDs = [...editorIds.filter((id) => id)]
      const uniqueUserIds = allUserIDs.filter((id, index) => allUserIDs.findIndex((eID) => eID === id) == index)
      getUserProfilesList({
        variables: {
          userIds: uniqueUserIds,
        },
      })
    }
  }, [editorIds, getUserProfilesList])

  useEffect(() => {
    if (
      activatedList?.getActivatedCloudLicenses.cloudLicensesActivations.length &&
      realmInfo?.getCloudLicenseInfo &&
      profileInfo?.getUserProfiles?.length
    ) {
      const { getActivatedCloudLicenses } = activatedList
      const { getUserProfiles } = profileInfo
      const {
        getCloudLicenseInfo: { cloudLicenseOrganizations },
      } = realmInfo

      const temp: CloudLicenseType[] = getActivatedCloudLicenses.cloudLicensesActivations
        ?.filter((cl) => cl?.order_id === 'trial-version')
        .map((c) => {
          const orgUsers = cloudLicenseOrganizations.find((co) => co?.realmId === c?.realmId)?.cloudLicenseUsers
          const userActivated = orgUsers?.find((ou) => !!ou?.isRootAdmin)?.email ?? ''
          const packetAdmin = (packt?: CloudLicensePacketDetails): string => {
            return orgUsers?.find((ou) => ou?.orgUserId === packt?.activatedBy)?.email ?? ''
          }
          const getTypeUserEmail = (userId?: string): string => {
            return orgUsers?.find((ou) => ou?.orgUserId === userId)?.email ?? ''
          }
          return {
            cloudLicensesCreated: {
              ...c,
              activatedBy: userActivated,
              organizationName:
                cloudLicenseOrganizations.find((org) => org?.realmId === c?.realmId)?.displayName ??
                c?.organizationName,
              editorId: getUserProfiles.find((user) => user?.userId === c?.editorId)?.Email ?? '',
              cloudLicensePackets: c?.cloudLicensePackets?.map((pkt) => ({
                ...pkt,
                cloudlicensePacketGroups: pkt?.cloudlicensePacketGroups.map((pktGroup) => ({
                  ...pktGroup,
                  licenseGroupTypeDetails: pktGroup?.licenseGroupTypeDetails.map((grpType) => ({
                    ...grpType,
                    licenseUsers: grpType?.licenseUsers?.map((typeUser) => ({
                      ...typeUser,
                      userId: typeUser?.userId ? getTypeUserEmail(typeUser?.userId) : undefined,
                    })),
                  })),
                })),
                activatedBy: pkt ? packetAdmin(pkt) : '',
              })),
            } as CloudLicenseDetails,
            adminData: getUserProfiles.find((prof) =>
              orgUsers?.some((ou) => ou?.email === prof?.Email),
            ) as UserProfileLight,
            registrationData: orgUsers?.length ? orgUsers?.map((ou) => (ou?.email ? ou.email : '')) : [],
          }
        })
        .sort((a, b) => sortDateString(a.cloudLicensesCreated.dateModified, b.cloudLicensesCreated.dateModified))
      setCompanyLicenses(temp)
    }
  }, [activatedList, profileInfo, realmInfo])
  //console.log({ createdCloudlicenses })
  return {
    data: companyLicenses,
    total: totalLicenses,
    loadingData: loading,
    refresh: refetch,
  }
}

export type CreateUpdateCloudResponse = {
  error?: string
  success?: boolean
  loading?: boolean
  submitFunc: (csOrder: OrderInput, csLicenseParams: CloudLicenseInput) => Promise<void>
}

export const useCreateCloudLicense = (): CreateUpdateCloudResponse => {
  const [err, setErr] = useState<string>()
  const [createOrder, orderStatus] = useCreateOrderMutation()
  const [createCloudLicense, licenseStatus] = useCreateCloudLicenseMutation()

  const submit = async (csOrder: OrderInput, csLicenseParams: CloudLicenseInput): Promise<void> => {
    try {
      if (!csOrder.order_number && !csOrder.order_date) {
        setErr('error.OrderFailed')
      }
      const { data: cOrder } = await createOrder({
        variables: {
          order: csOrder,
        },
      })
      if (cOrder?.createOrder) {
        const createdLic = await createCloudLicense({
          variables: {
            ...csLicenseParams,
            orderId: cOrder?.createOrder,
            config: {
              licIntMinutes: csLicenseParams.config.licIntMinutes ?? 0,
              expirationDate: undefined,
            },
          },
        })
        createdLic.data?.createCloudLicense && console.log(createdLic.data?.createCloudLicense)
      }
    } catch (e) {
      console.error({ e })
    }
  }
  return {
    error: orderStatus.error?.message
      ? 'error.createOrderFailed'
      : licenseStatus.error?.message
      ? 'error.createLicenseFailed'
      : undefined,
    loading: orderStatus.loading || licenseStatus.loading,
    success: !!licenseStatus.data?.createCloudLicense,
    submitFunc: submit,
  }
}

export type CreateUpdateLicensePacketResponse = {
  error?: string
  success?: boolean
  loading?: boolean
  submitFunc: (csOrder: OrderInput, csLicPacketOParams: LicensePacketInput) => Promise<void>
}

export const useCreateCloudLicensePacket = (): CreateUpdateLicensePacketResponse => {
  const [err, setErr] = useState<string>()
  const [createOrder, orderStatus] = useCreateOrderMutation()
  const [createLicensePacket, licenePacketStatus] = useCreateLicensePacketMutation()
  const submit = async (csOrder: OrderInput, csLicPacketOParams: LicensePacketInput): Promise<void> => {
    try {
      if (!csOrder.order_number && !csOrder.order_date) {
        setErr('error.OrderFailed')
        return
      }
      const { data: cOrder } = await createOrder({
        variables: {
          order: csOrder,
        },
      })
      if (cOrder?.createOrder) {
        const createdLicPacket = await createLicensePacket({
          variables: {
            ...csLicPacketOParams,
            orderId: cOrder?.createOrder,
          },
        })
        if (!createdLicPacket.data?.createLicensePacket) {
          setErr('licensePacket.creationFailed')
        }
      } else {
        setErr('order.createOrderFailed')
        return
      }
    } catch (e) {
      console.error({ e })
    }
  }
  return {
    error: orderStatus.error?.message
      ? 'error.createOrderFailed'
      : licenePacketStatus.error?.message
      ? 'error.createPacketFailed'
      : undefined,
    loading: orderStatus.loading || licenePacketStatus.loading,
    success: !!licenePacketStatus.data?.createLicensePacket,
    submitFunc: submit,
  }
}
