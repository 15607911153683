import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import {
  //LicenseGroupTypeDetails,
  //LicensesQuery,
  PaginationParams,
  //useGetContactLicenseInfoQuery,
  //useGetListCloudLicensesQuery,
} from '../../../../api/models'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import { CsButton, FixedActionBar } from '@csinstruments/cs-react-theme'
import { Add } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
/* import {
  contactToString,
  numberOrInfiniteToString,
  poolToString,
  valueOrNullToString,
} from '../../../helpers/stringHelpers' */
//import { toLocaleDateTime } from '../../../helpers/dateTime'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
//import { LicenseFilterUpdater } from './LicenseFilterUpdater'

import { CloudLicenseType, useGetAllCloudLicenses } from '../../../hooks/cloudLicense'
import { ProgressBar } from '../../partials/ProgressBar/ProgressBar'
import { LicenseFilterParams } from '../../../../typeInterfaces/Interfaces'
import Search from '../../partials/Inputs/Search'
import ListLicensesTable from './ListLicensesTable'

/* type TlicenseType = {
  licenseType: string
  id: string
  groupName: string
  licenseTypeTotal: LicenseTypeSizes[]
}
type CloudTotalLicenseTypes = {
  licenseType: string
  id: string
  totalSize: number
  assigned: number
  groupName: string
} */

/* const licensesToRows = (t: TFunction, myCloudLicenses?: CloudLicenseType[]): DataTableRow[] => {
  if (myCloudLicenses?.length) {
    return myCloudLicenses.map((cl) => {
      const nlicCounts = getLicCount(cl.cloudLicensesCreated)

      const rowData = {
        id: cl.cloudLicensesCreated?.id,
        values: [
          cl.cloudLicensesCreated?.licenseKey,
          //cl.cloudLicensesCreated?.organizationName,
          cl.orderInfo?.merchant?.contact?.company ?? '',
          cl.orderInfo?.customer?.contact?.company ?? '',
          `${nlicCounts}`,
          convertToLocaleDate(`${cl.cloudLicensesCreated?.dateModified}`, t),
          cl.cloudLicensesCreated?.licenseIntMinutes,
          cl.cloudLicensesCreated?.editorId ?? '',
        ],
      } as DataTableRow
      return rowData
    })
  }
  return []
} */

export const ListLicenses = (): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 100, Offset: 0 })
  const [filter, setFilter] = useState<LicenseFilterParams>({})
  //const [selectedID, setSelectedID] = useState('')
  const [myCloudLicdata, setMyCloudLicdata] = useState<CloudLicenseType[]>([])
  const [filteredCloudLicdata, setfilteredCloudLicdata] = useState<CloudLicenseType[]>([])
  //const [licenseRows, setLicenseRows] = useState<DataTableRow[]>([])

  const [modalOpen, setModalOpen] = useState(false)

  const { data, loadingData, total, refresh } = useGetAllCloudLicenses(paginationData)

  //const { data: cloudList } = useGetListCloudLicensesQuery()
  //const { data: cloudRegistered } = useGetContactLicenseInfoQuery()
  //const [deleteLicenseMutation] = useDeleteLicenseMutation()
  //const { refetch } = useLicensesQuery({ variables: { paginationInfo: paginationData, filter } })
  /* useEffect(() => {
    if (data?.length) {
      console.log(data)
    }
  }, [data]) */
  /* useEffect(() => {
    if (cloudList) {
      console.log({
        cloudList,
      })
    }
  }, [cloudList]) */

  useEffect(() => {
    if (data && data.length) {
      setMyCloudLicdata(data)
      setfilteredCloudLicdata(data)
    }
  }, [data, data?.length])
  const [
    //validMonths,
    //licensesCount,
    licensesLabel,
    //idLabel,
    //keyLabel,
    //licencePoolsLabel,
    //merchantsLabel,
    //customersLabel,
    //noteLabel,
    //maximumActivationsLabel,
    //activationsLabel,
    //createdAtLabel,
    //updatedAtLabel,
    //updatedByLabel,
    grantLicenseLabel,
  ] = useMemo(() => {
    return [
      //t('licenses.nMonths'),
      //t('licenses.nLicenses'),
      t('objects.licenses'),
      //t('objects.id'),
      //t('licenses.licenseKey'),
      //t('objects.licensePools'),
      //t('objects.merchants'),
      //t('objects.customers'),
      //t('objects.note'),
      //t('licenses.maximumActivations'),
      //t('licenses.activations'),
      //t('licenses.createdAt'),
      //t('licenses.updatedAt'),
      //t('licenses.updatedBy'),
      t('licenses.grantLicense'),
    ]
  }, [t])

  /* const rows: DataTableRow[] = useMemo(() => {
    if (myCloudLicdata.length) {
      return licensesToRows(t, myCloudLicdata)
    }
    return []
  }, [myCloudLicdata, t]) */

  /* useEffect(() => {
    setLicenseRows(licensesToRows(t, filteredCloudLicdata))
  }, [filteredCloudLicdata, t]) */

  /* const licenseRows = useMemo(() => {
    return licensesToRows(t, filteredCloudLicdata)
  }, [filteredCloudLicdata, t]) */

  /* const columns: DataTableColumn[] = useMemo(
    () => [
      //{ label: idLabel },
      { label: keyLabel },
      //{ label: 'Realm' },
      //{ label: licencePoolsLabel },
      { label: merchantsLabel },
      { label: customersLabel },
      { label: licensesCount },
      //{ label: noteLabel },
      //{ label: maximumActivationsLabel },
      //{ label: activationsLabel },
      { label: createdAtLabel },
      { label: validMonths },
      //{ label: updatedAtLabel },
      { label: updatedByLabel },
    ],
    [keyLabel, merchantsLabel, customersLabel, licensesCount, createdAtLabel, validMonths, updatedByLabel],
  ) */

  const grantLicenseAction = useCallback(() => {
    return history.push(ROUTES.grantLicense)
  }, [history])

  /* const deletionConfirmed = useCallback(() => {
    deleteLicenseMutation({ variables: { id: selectedID } }).then(() => refetch())
  }, [selectedID, deleteLicenseMutation, refetch]) */

  /* const buttonAction = useCallback(
    (id: string, action: DataItemAction) => {
      if (action === 'delete') {
        setModalOpen(true)
        //setSelectedID(id)
      } else if (action === 'batchGrantLicense') {
        //const batchOrder = myCloudLicdata?.find((d) => d.cloudLicensesCreated?.id === id)?.orderInfo?.order
        //console.log({ batchOrder })
        //const batchMerchantID = batchOrder?.merchant_id ? batchOrder?.merchant_id : ''
        //const batchCustomerID = batchOrder?.customer_id ? batchOrder?.customer_id : ''

        const route = ROUTES.batchGrantLicense + `?cloudid=${id}`
        // + (batchCustomerID ? `&customer=${batchCustomerID}` : `&merchant=${batchMerchantID}`)
        //const path = generatePath(ROUTES.batchGrantLicense, { id })
        history.push(route)
      } else if (action === 'edit') {
        const path = generatePath(ROUTES.editLicense, { id })
        history.push(path)
      } else if (action === 'view') {
        const path = generatePath(ROUTES.viewCloudInfo, { id })
        history.push(path)
      }
    },
    [history],
  ) */

  const refreshFilter = useCallback(() => {
    refresh()
    if (filter.searchText && myCloudLicdata) {
      const searchPattern = new RegExp(filter.searchText)
      //console.log({ searchPattern }, filter.searchText)
      const filteredLicenseInstances = myCloudLicdata.filter(
        (fl) => searchPattern && JSON.stringify(fl).search(searchPattern) > 0,
      )
      //console.log({ filteredLicenseInstances })
      setfilteredCloudLicdata(filteredLicenseInstances)
    } else {
      setfilteredCloudLicdata(myCloudLicdata)
    }
  }, [refresh, filter.searchText, myCloudLicdata])

  return (
    <>
      <PageHeading title={licensesLabel} />
      <StandardConfirmationModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        text={t('modals.standardText')}
        handleConfirm={() => {
          return
        }}
      />
      <FixedActionBar
        labels={[grantLicenseLabel]}
        actions={[grantLicenseAction]}
        icons={[Add]}
        buttonProps={[{ solid: true }]}
      />
      <Grid container spacing={2} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={4}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Search
                label={t('actions.search')}
                changeFunction={(searchString) =>
                  setFilter((prv) => ({
                    ...prv,
                    searchText: searchString,
                  }))
                }
                onEnter={refreshFilter}
              />
            </Grid>
            <Grid item xs={4}>
              <CsButton solid={true} onClick={refreshFilter}>
                {t('actions.filter')}
              </CsButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ProgressBar show={loadingData} />
        </Grid>
        <Grid item xs={12}>
          <ListLicensesTable licensesCreated={filteredCloudLicdata} refetch={refreshFilter} />
          {/* <DataTable
            columns={columns}
            onButtonClicked={buttonAction}
            rows={licenseRows}
            filterColumn={0}
            filterString={filter.license_key ?? ''}
            allowedActions={{
              editable: true,
              deletable: true,
              batchGrantLicense: true,
              viewable: true,
              sortable: !!myCloudLicdata.length,
            }}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <LicenseServerPagination
            paginationParams={paginationData}
            setPaginationParams={setPaginationData}
            nTotal={total ?? 0}
          />
        </Grid>
      </Grid>
    </>
  )
}
