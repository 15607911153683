import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { PageHeading } from '../../partials/PageHeading'
import { Grid } from '@material-ui/core'
import Search from '../../partials/Inputs/Search'
import { PaginationInfoLimitUpdater } from '../../partials/Pagination/PaginationInfoLimitUpdater'
import {
  LicenseGroup,
  LicenseType,
  PaginationParams,
  useDeleteLicenseTypeMutation,
  useGetAllLicenseGroupsQuery,
  useLicenseTypesQuery,
} from '../../../../api/models'
import { DataTable } from '../../partials/DataTable/DataTable'
import { LicenseServerPagination } from '../../partials/Pagination/LicenseServerPagination'
import { DataTableColumn, DataTableRow } from '../../partials/DataTable/DataTableTypes'
//import { convertToLocaleDate } from '../../../helpers/dateTime'
import { StandardConfirmationModal } from '../../partials/StandardConfirmationModal/StandardConfirmationModal'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../helpers/routes'
import { hasUserRoles } from '../../../helpers/keycloakService'
import { FixedActionBar } from '@csinstruments/cs-react-theme'
import { Add } from '@material-ui/icons'
import { useKeycloak } from '@react-keycloak/web'
import { convertToLocaleDate } from '../../../helpers/dateTime'

export const ListLicenseTypes: FC = ({}): ReactElement => {
  const { t } = useTranslation()
  const { keycloak } = useKeycloak()
  const [paginationData, setPaginationData] = useState<PaginationParams>({ limit: 100, Offset: 0 })
  const [searchTerm, setSearchTerm] = useState('')
  const [deleteMutation] = useDeleteLicenseTypeMutation()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedID, setSelectedID] = useState('')
  const [lGroups, setLGroups] = useState<LicenseGroup[]>([])
  const { data: getAllLicenseGroups } = useGetAllLicenseGroupsQuery()
  const { data, refetch } = useLicenseTypesQuery({
    variables: { pageInfo: paginationData },
  })
  const history = useHistory()

  const token = keycloak.token
  const masterUser = useMemo(() => {
    return hasUserRoles(token).master
  }, [token])

  const [
    licenseTypesLabel,
    licenseGrouplabel,
    searchLabel,
    idLabel,
    keyLabel,
    maxActivationsLabel,
    descriptionLabel,
    createdAtLabel,
  ] = useMemo(() => {
    return [
      t('objects.licenseTypes'),
      t('objects.licenseGroups'),
      t('actions.search'),
      t('objects.id'),
      t('licenseTypes.key'),
      t('licenses.maximumActivations'),
      t('licenseTypes.description'),
      t('licenseTypes.createdAt'),
    ]
  }, [t])

  const typesToRows = useCallback(
    (licenseTypes: LicenseType[], licenseGrps: LicenseGroup[]): DataTableRow[] => {
      if (!licenseTypes) {
        return []
      }
      const transformedLicenseTypes = licenseTypes.reduce(
        (agg: LicenseType[], x) =>
          licenseGrps.some((lGrp) => lGrp.id === x.groupId)
            ? [...agg, { ...x, groupId: lGroups.find((lgp) => lgp.id === x.groupId)?.groupname as string }]
            : agg,
        [],
      )
      return transformedLicenseTypes.map((lt) => {
        return {
          id: lt.id,
          values: [
            lt.id,
            lt.licenseType,
            lt.groupId,
            `${lt.activationMaximum}`,
            t(`${lt.descriptionKey}`),
            convertToLocaleDate(lt.date_Created, t),
          ],
        }
      })
    },
    [lGroups, t],
  )

  const searchTermChanged = useCallback((term: string) => {
    setSearchTerm(term)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rows: DataTableRow[] = useMemo(() => {
    if (data?.licenseTypes.licenseTypes && lGroups.length) return typesToRows(data?.licenseTypes.licenseTypes, lGroups)
    return []
  }, [data?.licenseTypes.licenseTypes, lGroups, typesToRows])

  const columns: DataTableColumn[] = useMemo(
    () => [
      { label: idLabel },
      { label: keyLabel },
      { label: licenseGrouplabel },
      { label: maxActivationsLabel, numeric: true },
      { label: descriptionLabel },
      { label: createdAtLabel },
    ],
    [idLabel, keyLabel, maxActivationsLabel, descriptionLabel, createdAtLabel, licenseGrouplabel],
  )

  const confirmDeleteLicenseType = async () => {
    try {
      const result = await deleteMutation({
        variables: { id: selectedID },
      })
      if (result.data?.deleteLicenseType) {
        refetch()
      }
    } catch (er) {
      console.error('error deleting', er)
    }
  }
  useEffect(() => {
    if (getAllLicenseGroups?.getAllLicenseGroups.length) {
      setLGroups(getAllLicenseGroups?.getAllLicenseGroups as LicenseGroup[])
    }
  }, [getAllLicenseGroups?.getAllLicenseGroups])

  return (
    <>
      <StandardConfirmationModal
        handleClose={() => setModalOpen(false)}
        open={modalOpen}
        text={t('modals.standardText')}
        handleConfirm={confirmDeleteLicenseType}
      />
      {masterUser && (
        <FixedActionBar
          labels={[t('actions.add')]}
          actions={[() => history.push(ROUTES.addLicenseType)]}
          icons={[Add]}
          buttonProps={[{ solid: true }]}
        />
      )}
      <PageHeading title={licenseTypesLabel} />
      <Grid container spacing={3} style={{ paddingTop: 20 }}>
        <Grid item xs={12} md={6}>
          <PaginationInfoLimitUpdater paginationParams={paginationData} setPaginationParams={setPaginationData} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Search label={searchLabel} changeFunction={searchTermChanged} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            onButtonClicked={(id, action) => {
              if (action === 'delete') {
                setSelectedID(id)
                setModalOpen(true)
              } else {
                history.push(generatePath(ROUTES.editLicenseType, { id }))
              }
            }}
            filterString={searchTerm}
            rows={rows}
            allowedActions={{ sortable: true, deletable: masterUser, editable: masterUser }}
          />
        </Grid>
        <Grid item xs={12}>
          <LicenseServerPagination
            paginationParams={paginationData}
            setPaginationParams={setPaginationData}
            nTotal={data?.licenseTypes.total ?? 0}
          />
        </Grid>
      </Grid>
    </>
  )
}
