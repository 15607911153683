import { Grid, TextField, InputAdornment, MenuItem, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded'
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded'
//import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded'
import { useQueryParam } from '../../../hooks/useQueryParam'
import {
  OrderInput,
  Contact,
  Customer,
  Merchant,
  useGetAllmerchantsQuery,
  useGetCustomersQuery,
} from '../../../../api/customer.models'

type Props = {
  orderParams: OrderInput
  updateOrderParams: (ordParams: OrderInput) => void
  //contact: Contact
  updateContact: (contact: Contact) => void
}

export const initOrderParams: OrderInput = {
  order_date: new Date().toISOString(),
  contact_id: '',
  order_number: '',
}
const CloudOrder: React.FC<Props> = ({ orderParams, updateOrderParams, updateContact }: Props) => {
  const { t } = useTranslation('common')
  const preselectedCustomerId = useQueryParam('customer')
  const preselectedMerchantId = useQueryParam('merchant')
  //const cloudLicenseId = useQueryParam('cloudid')
  const [allCustomers, setAllCustomers] = useState<Customer[]>([])
  const [allMerchants, setAllMerchants] = useState<Merchant[]>([])
  //const [selectedContact, setSelectedContact] = useState<Contact>()
  const [selectedOrderParams, setSelectedOrderParams] = useState<OrderInput>(initOrderParams)

  const { data: merchants } = useGetAllmerchantsQuery({
    skip: !!preselectedCustomerId,
  })

  const { data: customers } = useGetCustomersQuery({
    skip: !!preselectedMerchantId,
  })

  useEffect(() => {
    if (merchants?.merchants.merchants.length) {
      setAllMerchants(merchants.merchants.merchants)
    }
    if (customers?.customers.customers.length) {
      setAllCustomers(customers.customers.customers)
    }
    setSelectedOrderParams(orderParams)
  }, [merchants?.merchants.merchants, customers?.customers.customers, orderParams])

  useEffect(() => {
    if (!orderParams.customer_id && preselectedCustomerId && allCustomers.length) {
      const preCustomer = allCustomers.find((c) => preselectedCustomerId && c.id === preselectedCustomerId)
      updateContact(preCustomer?.contact as Contact)
      setSelectedOrderParams({
        ...initOrderParams,
        contact_id: preCustomer?.contact?.id ?? '',
        merchant_id: undefined,
        customer_id: preselectedCustomerId,
      })
      updateOrderParams({
        ...orderParams,
        contact_id: preCustomer?.contact?.id ?? '',
        merchant_id: undefined,
        customer_id: preselectedCustomerId,
      })
    } else if (!orderParams.merchant_id && preselectedMerchantId && allMerchants.length) {
      const preSelectMerchant = allMerchants.find((m) => preselectedMerchantId && m.id === preselectedMerchantId)
      updateContact(preSelectMerchant?.contact as Contact)
      setSelectedOrderParams({
        ...initOrderParams,
        contact_id: preSelectMerchant?.contact?.id ?? '',
        merchant_id: preselectedMerchantId,
        customer_id: undefined,
      })
      updateOrderParams({
        ...orderParams,
        customer_id: undefined,
        merchant_id: preselectedMerchantId ?? '',
        contact_id: preSelectMerchant?.contact?.id ?? '',
      })
    }
    if ((orderParams.merchant_id || orderParams.customer_id) && orderParams.contact_id && orderParams.order_number) {
      setSelectedOrderParams(orderParams)
    }
  }, [
    allCustomers,
    allCustomers.length,
    allMerchants,
    orderParams,
    preselectedCustomerId,
    preselectedMerchantId,
    updateContact,
    updateOrderParams,
  ])

  /* useEffect(() => {
    updateRealmName && updateRealmName(transformRealmName(selectedContact?.company + selectedOrderParams.order_number))
  }, [realmName, selectedContact, selectedOrderParams, updateRealmName]) */

  /* useEffect(() => {
    updatePacketName && updatePacketName(selectedContact?.city ?? '')
  }, [packetName, selectedContact, updatePacketName]) */

  const changeOrdInp = (orderParams: OrderInput, type: 'Customer' | 'Merchant'): void => {
    if (type === 'Customer') {
      const selCustomer = allCustomers.find((c) => c.id === orderParams.customer_id)
      updateContact(selCustomer?.contact as Contact)
      setSelectedOrderParams({
        ...selectedOrderParams,
        contact_id: selCustomer?.contact?.id ?? '',
        customer_id: orderParams.customer_id,
        merchant_id: undefined,
      })
      updateOrderParams({
        ...selectedOrderParams,
        contact_id: selCustomer?.contact?.id ?? '',
        customer_id: orderParams.customer_id,
        merchant_id: undefined,
      })
    }
    if (type === 'Merchant') {
      const selMerchant = allMerchants.find((m) => m.id === orderParams.merchant_id)
      console.log({ selMerchant })
      updateContact(selMerchant?.contact as Contact)
      setSelectedOrderParams({
        ...selectedOrderParams,
        contact_id: selMerchant?.contact?.id ?? '',
        merchant_id: orderParams.merchant_id,
        customer_id: undefined,
      })
      updateOrderParams({
        ...selectedOrderParams,
        contact_id: selMerchant?.contact?.id ?? '',
        merchant_id: orderParams.merchant_id,
        customer_id: undefined,
      })
    }
  }
  const updateOrderNumber = (ordNumber: string) => {
    setSelectedOrderParams((prv) => ({
      ...prv,
      order_number: ordNumber,
    }))
    updateOrderParams({
      ...selectedOrderParams,
      order_number: ordNumber,
    })
  }

  //console.log({ orderParams })
  const MenuContact = (contact: Customer | Merchant) =>
    ` ${contact.customer_number} // ${contact.contact?.company} // ${contact.contact?.city}`
  return (
    <>
      {/* <Grid item container spacing={1}> */}
      {!preselectedCustomerId && (
        <Grid item md={12} lg={12}>
          <TextField
            value={selectedOrderParams?.merchant_id ?? ''}
            label={t('objects.merchant')}
            select
            variant="outlined"
            fullWidth
            //disabled={!!preselectedMerchantId}
            onChange={({ target }) => changeOrdInp({ ...selectedOrderParams, merchant_id: target.value }, 'Merchant')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessRoundedIcon />
                </InputAdornment>
              ),
            }}
          >
            {/* <MenuItem /> */}
            {allMerchants.map((m) => (
              <MenuItem key={m?.id} value={m?.id}>
                <Typography>{MenuContact(m)}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}

      {!preselectedMerchantId && (
        <Grid item md={12} lg={12}>
          <TextField
            value={selectedOrderParams?.customer_id ?? ''}
            label={t('objects.customer')}
            select
            variant="outlined"
            fullWidth
            //disabled={!!preselectedCustomerId}
            onChange={({ target }) => changeOrdInp({ ...selectedOrderParams, customer_id: target.value }, 'Customer')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleRoundedIcon />
                </InputAdornment>
              ),
            }}
          >
            {/* <MenuItem /> */}
            {allCustomers.map((ct) => (
              <MenuItem key={ct?.id} value={ct?.id}>
                <Typography>{MenuContact(ct)}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item md={12} lg={12}>
        <TextField
          fullWidth
          variant="outlined"
          label={t('objects.orderNumber')}
          required
          value={selectedOrderParams.order_number}
          onChange={({ target }) => updateOrderNumber(target.value)}
        />
      </Grid>
    </>
  )
}

export default CloudOrder
